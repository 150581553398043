import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { z } from 'zod';

import { queryClient } from '@/App';
import { FormSchema } from '@/constants/formSchema/FixedAllocationFormSchema';
import api from '@/services/apiSgft';
import { FixedAllocationData } from '@/types/FixedAllocation';

import { Button } from '../atoms/Button';
import ControlledCheckbox from '../atoms/ControlledCheckbox';
import ControlledSimpleSelect from '../atoms/ControlledSimpleSelect';
import Modal from '../atoms/Modal';
import SearchSelect from '../atoms/SearchSelect';
import { PositionWithAllocation } from '../organisms/ChartGraph';

interface CreateFixedAllocationModalProps {
  isOpen: boolean;
  onClose: () => void;
  data: PositionWithAllocation;
}

const CreateFixedAllocationModal: React.FC<CreateFixedAllocationModalProps> = ({
  isOpen,
  onClose,
  data,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState('');
  const { positionId } = data;
  const methods = useForm<z.infer<typeof FormSchema>>({
    defaultValues: {
      board: data.board.id,
      management: data?.management?.id,
      coordination: data?.coordination?.id,
      workStation: data?.workStation.id,
      regime: data.regime,
      reportTo: data.reportsTo.length > 1 ? -1 : data.reportsTo.at(0)?.id,
      jobTitle: data.generalInformationJobTitle?.id,
      isIntermediary: data.isIntermediary,
    },
  });

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    setIsSubmitting(true);

    try {
      await api.post(`position/allocate-fixedly-in-position/`, {
        fixedPositionAllocationId: data.fixedPositionAllocationId,
        positionId,
      });
      toast.success('Alocação fixa criada com sucesso!', {
        theme: 'colored',
        toastId: 'success',
      });

      closeAndClear();
    } catch (error: any) {
      toast.error(
        `${'Erro ao salvar alocação fixa, tente novamente mais tarde!'}`,
      );
      throw error;
    } finally {
      setIsSubmitting(false);
    }
  };

  const closeAndClear = () => {
    methods.reset();
    onClose();
    queryClient.invalidateQueries(['employees-chart']);
    queryClient.invalidateQueries(['fixed-allocation']);
    queryClient.invalidateQueries(['roles-chart']);
  };

  const fetchFixedAllocations = async () => {
    try {
      const response = await api.get(
        '/position/get-all-fixed-position-allocation',
      );
      return response.data;
    } catch (e: any) {
      const errorMessage =
        e.response?.data?.message || 'Erro ao carregar os dados';
      toast.error(errorMessage, {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const { data: fixedAllocationData } = useQuery<FixedAllocationData[]>(
    ['fixed-allocation'],
    fetchFixedAllocations,
    {
      retry: false,
    },
  );

  const fixedAllocationOptions =
    fixedAllocationData
      ?.filter((el) =>
        el.employee.name.toLowerCase().includes((name ?? '').toLowerCase()),
      )
      ?.map((employee) => ({
        value: employee.id,
        label: employee.employee.name,
      })) ?? [];

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeAndClear}
      title={'Criar alocação fixa'}
      className="w-[48rem] max-w-[85vw]"
    >
      <FormProvider {...methods}>
        <form
          className="space-between flex h-[90%] flex-col px-5 py-2"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className="mb-4 flex w-[45rem] justify-between">
            <ControlledSimpleSelect
              title={'Diretoria'}
              required
              control={methods.control}
              name={`board`}
              width="14.5rem"
              values={[{ value: data.board?.id, label: data.board?.name }]}
              disabled
            />
            <ControlledSimpleSelect
              title={'Gerência'}
              required
              control={methods.control}
              name={'management'}
              width="14.5rem"
              values={[
                { value: data.management?.id, label: data?.management.name },
              ]}
              disabled
            />
            <ControlledSimpleSelect
              title={'Coordenação'}
              required
              control={methods.control}
              name={'coordination'}
              width="14.5rem"
              values={[
                {
                  value: data.coordination?.id,
                  label: data.coordination?.name,
                },
              ]}
              disabled
            />
          </div>
          <div className="mb-4 flex w-[45rem] justify-between">
            <ControlledSimpleSelect
              title={'Cargo'}
              required
              control={methods.control}
              name={`jobTitle`}
              width="14.5rem"
              values={[
                {
                  value: data.generalInformationJobTitle?.id,
                  label: data.generalInformationJobTitle?.name,
                },
              ]}
              disabled
            />
            <ControlledSimpleSelect
              title={'Local de Trabalho'}
              required
              control={methods.control}
              name={'workStation'}
              width="14.5rem"
              values={[
                { value: data.workStation?.id, label: data.workStation?.name },
              ]}
              disabled
            />
            <ControlledSimpleSelect
              title={'Regime'}
              required
              control={methods.control}
              name={'regime'}
              width="14.5rem"
              values={[
                { value: 'ADM', label: 'ADM' },
                { value: 'TURNO', label: 'TURNO' },
              ]}
              disabled
            />
          </div>
          <div className="mb-4 flex justify-between">
            <ControlledSimpleSelect
              title={'Reporte (posição)'}
              required
              control={methods.control}
              name={'reportTo'}
              width="22rem"
              values={
                data.reportsTo.length > 0
                  ? [
                      { value: -1, label: 'Reportes Múltiplos' },
                      {
                        value: data.reportsTo.at(0)?.id || '',
                        label: data.reportsTo.at(0)?.name || '',
                      },
                    ]
                  : []
              }
              disabled
            />
            <SearchSelect
              {...methods.register('fixedPositionAllocationId')}
              control={methods.control}
              title="Nome"
              options={fixedAllocationOptions}
              required
              setSearch={(e) => setName(e)}
              className="w-[22rem]"
            />
          </div>
          <div>
            <ControlledCheckbox
              title={'Posicionar em nível intermediário na estrutura'}
              name="isIntermediary"
              control={methods.control}
              disabled
            />
          </div>
          <div className="my-2 mt-[2rem] flex w-full justify-between">
            <Button
              type="button"
              className="h-8 min-w-36 border-2 border-[#193CB9] bg-white text-[#193CB9] hover:bg-[#e9eaf1]"
              onClick={closeAndClear}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              className=" h-8 min-w-36"
              isLoading={isSubmitting}
            >
              Salvar
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default CreateFixedAllocationModal;
