import { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { queryClient } from '@/App';
import EmptyIcon from '@/assets/icons/empty.svg';
import { columns } from '@/constants/tableColumns/TrainingFileTeamsColumns';
import { trainingFileStatus } from '@/constants/trainingFileStatus';
import api from '@/services/apiSgft';
import { TrainingFile } from '@/types/TrainingFile';
import { cn } from '@/utils/cn';
import { dateInUTCFormat } from '@/utils/formatDate';
import { getFirstAndLastName } from '@/utils/getFirstAndLastName';
import { trackEvent } from '@/utils/trackEvent';

import LightTooltip from '../atoms/LightTooltip';
import SimpleMenu from '../atoms/Menu';
import ModalConfirmation from '../molecules/ConfirmationModal';
import { TrainingFileInstructors } from '../molecules/TrainingFileInstructors';
import TrainingFileModal from '../molecules/TrainingFileModal';
import Table from './Table';

interface TrainingFileCardProps {
  trainingFile: TrainingFile;
}
const TrainingFileCard = ({ trainingFile }: TrainingFileCardProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [disableSendToApproval, setDisableSendToApproval] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isInstructorsModalOpen, setIsInstructorsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const navigate = useNavigate();
  const handleToggleCard = () => {
    setIsOpen((prev) => !prev);
  };
  const handleOpenDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };
  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };
  const handleOpenEditModal = () => {
    setIsEditModalOpen(true);
  };
  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handleOpenInstructorsModal = () => {
    setIsInstructorsModalOpen(true);
  };
  const handleCloseInstructorsModal = () => {
    setIsInstructorsModalOpen(false);
  };
  const handleDeleteModal = async () => {
    try {
      await api.delete(`/training-files/${trainingFile.id}`);
      toast.success('Ficheiro excluído com sucesso', { theme: 'colored' });
      queryClient.invalidateQueries(['training-files']);
    } catch {
      toast.error('Erro ao excluir ficheiro', { theme: 'colored' });
    }
  };
  const handleOpenNewTrainingPlanning = () => {
    navigate(`/home/ficheiros/${trainingFile.id}/planejamento-de-turma`);
  };

  const handleExtractTrainingFile = async () => {
    try {
      const response = await api.get(`/extraction/training-file`, {
        params: {
          trainingFileId: trainingFile.id,
        },
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      );
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', 'Extração Ficheiro.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      trackEvent('Download do ficheiro', 'file_download', url);
    } catch (e) {
      toast.error('Erro ao realizar extração', {
        theme: 'colored',
        toastId: 'error',
      });
      trackEvent(
        'Download do ficheiro',
        'file_download',
        'Erro ao realizar o Download',
      );
    }
  };

  const handleSendToApproval = async () => {
    try {
      setDisableSendToApproval(true);
      await api.post(`/training-files/${trainingFile.id}/send-to-approval`);

      trackEvent(
        'Enviado para aprovação',
        'training_file_sent_to_approval',
        String(trainingFile.id),
      );
      toast.success('Ficheiro enviado para aprovação dos gestores', {
        theme: 'colored',
        toastId: 'success',
      });
    } catch (e) {
      toast.error('Erro ao enviar para aprovação', {
        theme: 'colored',
        toastId: 'error',
      });
      trackEvent(
        'Erro ao enviar para aprovação',
        'training_file_sent_to_approval',
        String(trainingFile.id),
      );
      setDisableSendToApproval(false);
    }
  };

  const options = [
    {
      name: 'Adicionar Novo Planejamento',
      onClick: handleOpenNewTrainingPlanning,
      disabled: trainingFile.status === trainingFileStatus.FINISHED,
    },
    {
      name: 'Detalhamento das Turmas',
      onClick: () =>
        navigate(`/home/ficheiros/detalhamento/${trainingFile.id}`),
      disabled: trainingFile.teams.length === 0,
    },
    {
      name: 'Cadastrar Instrutores',
      onClick: handleOpenInstructorsModal,
      disabled: false,
    },
    {
      name: 'Extrair Ficheiro',
      onClick: handleExtractTrainingFile,
      disabled: trainingFile.teams.length === 0,
    },
    {
      name: 'Editar Ficheiro',
      onClick: handleOpenEditModal,
      disabled: trainingFile.status === trainingFileStatus.FINISHED,
    },
    {
      name: 'Excluir Ficheiro',
      onClick: handleOpenDeleteModal,
      disabled: trainingFile.status === trainingFileStatus.FINISHED,
    },
    {
      name: 'Enviar para Aprovação',
      onClick: handleSendToApproval,
      disabled:
        trainingFile.sentToApproval ||
        trainingFile.status === trainingFileStatus.CONCLUDED ||
        trainingFile.status === trainingFileStatus.FINISHED ||
        trainingFile.teams.length === 0 ||
        disableSendToApproval,
    },
  ];
  return (
    <div className="flex min-w-full flex-col rounded-md bg-white">
      {isDeleteModalOpen && (
        <ModalConfirmation
          confirmAction={handleDeleteModal}
          title="Excluir Ficheiro"
          isOpen={isDeleteModalOpen}
          onClose={handleCloseDeleteModal}
          description={`Todas as turmas planejadas serão canceladas. Tem certeza que deseja excluir o ficheiro ${trainingFile.name} (${trainingFile.trainingFileNumber})?`}
        />
      )}
      {isEditModalOpen && (
        <TrainingFileModal
          isOpen={isEditModalOpen}
          trainingFile={trainingFile}
          onClose={handleCloseEditModal}
        />
      )}
      {isInstructorsModalOpen && (
        <TrainingFileInstructors
          onClose={handleCloseInstructorsModal}
          trainingFile={trainingFile}
        />
      )}
      <div
        className={cn(
          'flex min-h-16 w-full justify-between gap-2 rounded-lg bg-white p-2 text-xs text-gray-600',
          trainingFile.teams.length === 0 && isOpen ? 'rounded-b-none' : '',
        )}
      >
        <button onClick={handleToggleCard}>
          <FaChevronDown
            className={`ml-auto fill-primary transition-all ${
              isOpen && 'rotate-180'
            }`}
          />
        </button>
        <div className="flex w-full items-center justify-between  gap-2">
          <div className="flex w-72 items-center justify-start gap-2 overflow-hidden whitespace-nowrap py-1">
            <div className="flex  h-8 items-center gap-2 overflow-hidden rounded-lg bg-[#CFDEFF] px-2 py-1 text-primary">
              Ficheiro:
              <LightTooltip title={trainingFile.name} arrow placement="top-end">
                <span className="overflow-hidden py-2 font-bold">
                  {trainingFile.name}
                </span>
              </LightTooltip>
            </div>
            <SimpleMenu options={options} upDotsButton />
          </div>

          <div className="flex h-8  items-center gap-2 whitespace-nowrap   px-2 py-1">
            Número:
            <span className="font-bold">{trainingFile.trainingFileNumber}</span>
          </div>
          <div className="flex  w-24 items-center  gap-2 whitespace-nowrap rounded-lg px-2 py-1  ">
            Status:
            <span className="font-bold">{trainingFile.status}</span>
          </div>
          <div className="flex w-72 items-center  gap-2 whitespace-nowrap rounded-lg px-2 py-1  ">
            Período de realização:
            <span className="font-bold">
              {`${new Date(trainingFile.startDate).toLocaleDateString('pt-BR', { timeZone: 'UTC' })} - ${new Date(trainingFile.endDate).toLocaleDateString('pt-BR', { timeZone: 'UTC' })}`}
            </span>
          </div>
          <div className="flex max-w-60 items-center  gap-2 overflow-hidden whitespace-nowrap rounded-lg px-2 py-1 ">
            Fornecedor:
            <LightTooltip
              title={trainingFile.supplier.name}
              arrow
              placement="top-end"
            >
              <span className="overflow-hidden text-ellipsis py-2 font-bold">
                {trainingFile.supplier.name}
              </span>
            </LightTooltip>
          </div>
          <div className="flex  w-52 items-center  gap-2 overflow-hidden whitespace-nowrap rounded-lg px-2  py-1">
            Responsável:
            <LightTooltip
              title={trainingFile.responsible}
              arrow
              placement="top-end"
            >
              <span className="overflow-hidden text-ellipsis py-2 font-bold">
                {getFirstAndLastName(trainingFile.responsible)}
              </span>
            </LightTooltip>
          </div>
        </div>
      </div>
      {isOpen &&
        (trainingFile.teams.length > 0 ? (
          <div className="min-h-24 px-4 py-2">
            <Table
              columns={columns}
              data={trainingFile.teams.map((team) => ({
                ...team,
                planningDays: team.planningDays.map((planningDay) => ({
                  ...planningDay,
                  date: dateInUTCFormat(planningDay.date).toISOString(),
                })),
              }))}
              // isWhiteTable={true}
              tBodyClassname="border-none "
              trBodyClassname="border-none"
              tdHeaderClassname="bg-transparent"
              trHeaderClassname="bg-transparent my-4 rounded-md"
              tdClassname="px-0 align-top"
              containerClassname="border-separate border-spacing-y-4"
            />
          </div>
        ) : (
          <div className="flex min-h-40 w-full flex-col items-center justify-center rounded-b-md rounded-t-none bg-white pb-2">
            <img src={EmptyIcon} className="w-32" />
            <div className="w-full text-center text-xs">
              <span className="font-semibold text-blue-800">
                Nenhuma turma cadastrada para esse ficheiro!
              </span>
            </div>
          </div>
        ))}
    </div>
  );
};
export default TrainingFileCard;
