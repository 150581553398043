import { useInfiniteQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import EmployeesWrapper from '@/components/molecules/EmployeesWrapper';
import Subtitle from '@/components/molecules/Subtitle';
import InfiniteTable from '@/components/organisms/InfiniteTable';
import { columns } from '@/constants/tableColumns/EmployeeTrainingColumns';
import api from '@/services/apiSgft';
import { TrainingControlHistory } from '@/types/TrainingControlHistory';

import EmptyIcon from '../../assets/icons/empty.svg';

export const EmployeeTrainings = () => {
  const { employeeId } = useParams();

  const fetchStructures = async ({
    pageParam = 1,
  }): Promise<{
    count: number;
    nextPage: number | null;
    employeeName: string;
    trainingControlHistories: TrainingControlHistory[];
  }> => {
    try {
      const response = await api.get(
        `training-control/${employeeId}/training-control-histories`,
        {
          params: {
            size: 20,
            page: pageParam,
          },
        },
      );
      return response.data;
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(['employee-trainings'], fetchStructures, {
    retry: false,
    getNextPageParam: (actualPage) => {
      return actualPage.nextPage;
    },
  });
  const employeeHistory =
    data?.pages.flatMap((page) => page.trainingControlHistories) ?? [];
  const employeeName = data?.pages[0].employeeName;

  return (
    <div className="h-[90vh] w-full flex-col rounded-md bg-white p-3">
      {employeeName ? (
        <Subtitle subtitle={`${employeeName}`} className="justify-start" />
      ) : null}
      <div className="relative mt-4 flex h-[95%] bg-white">
        <EmployeesWrapper isLoading={isLoading} isError={isError}>
          {employeeHistory.length ? (
            <div className="flex h-full w-full flex-col overflow-hidden rounded-md bg-white">
              <InfiniteTable
                hasNextPage={hasNextPage}
                data={employeeHistory}
                columns={columns()}
                fetchNextPage={fetchNextPage}
                isFetchingNextPage={isFetchingNextPage}
              />
            </div>
          ) : (
            <div className="flex h-full w-full flex-col items-center justify-center bg-white">
              <img src={EmptyIcon} className="w-40" />
              <div className="w-full text-center text-sm">
                <p>Não existem históricos para este colaborador</p>
                <span className="font-semibold text-blue-800">
                  Caso ache que isto seja um erro, por favor entre em contato
                  com o suporte!
                </span>
              </div>
            </div>
          )}
        </EmployeesWrapper>
      </div>
    </div>
  );
};
