import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CgSpinner } from 'react-icons/cg';
import { toast } from 'react-toastify';

import { queryClient } from '@/App';
import { BoardDto } from '@/pages/Board';
import api from '@/services/apiSgft';

import { Button } from '../atoms/Button';
import ControlledSimpleSelect from '../atoms/ControlledSimpleSelect';
import Modal from '../atoms/Modal';
import UncontrolledInputText from '../atoms/UncontrolledInputText';
import ExpandableSubtitle from './ExpandableSubtitle';
import ManagementRegister from './ManagementRegister';

interface BoardModalProps {
  isOpen: boolean;
  onClose: () => void;
  type: 'create' | 'edit';
  data?: BoardDto;
}

const BoardModal: React.FC<BoardModalProps> = ({
  isOpen,
  onClose,
  type,
  data,
}) => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const methods = useForm({
    defaultValues: {
      id: data?.id,
      name: data?.name,
      companyId: data?.company?.id,
      managements:
        data?.managements.map((management) => ({
          id: management.id,
          name: management.name,
          coordinations: management.coordinations || [],
        })) || [],
    },
  });

  const closeAndClear = () => {
    methods.reset();
    onClose();
  };

  const onSubmit = async (form: any) => {
    if (data && !data.active) {
      try {
        await api.put(`/board/update-status`, { id: data.id, active: true });
        toast.success('Diretoria ativada com sucesso!', {
          theme: 'colored',
          toastId: 'success',
        });
        queryClient.invalidateQueries(['get-all-boards-data']);
      } catch (e) {
        toast.error('Erro ao ativar diretoria!', {
          theme: 'colored',
          toastId: 'error',
        });
      }
    } else {
      try {
        setIsSubmiting(true);
        const structure = {
          id: data?.id,
          name: form?.name.toUpperCase(),
          companyId: form?.companyId,
          managements: form?.managements?.map(
            (el: { id: string; name: string }, index: number) => ({
              id: el.id ? Number(el.id) : undefined,
              name: el.name.toUpperCase(),
              coordinations: form?.managements[index]?.coordinations?.map(
                (e: { id: string; name: string }) => ({
                  id: e.id ? Number(e.id) : undefined,
                  name: e.name.toUpperCase(),
                }),
              ),
            }),
          ),
        };
        if (type === 'create') {
          await api.post('board/create', structure);
        } else {
          await api.put('board/update', structure);
        }
        toast.success(
          `Diretoria! ${type === 'create' ? 'criada' : 'salva'} com sucesso!`,
          {
            theme: 'colored',
            toastId: 'success',
          },
        );
        queryClient.invalidateQueries(['get-all-boards-data']);
      } catch (error: any) {
        const errorMessage =
          error?.response?.status < 500
            ? error?.response?.data?.message
            : `Erro ao ${type === 'create' ? 'criar' : 'salvar'} diretoria!`;
        toast.error(errorMessage, {
          theme: 'colored',
          toastId: 'error',
        });
      }
    }
    setIsSubmiting(false);
    closeAndClear();
  };

  const getCompanies = async () => {
    try {
      const response = await api.get('/company/all');
      return response.data.map((el: { id: number; name: string }) => ({
        value: el.id,
        label: el.name,
      }));
    } catch (e) {
      toast.error('Erro ao carregar empresas!', {
        theme: 'colored',
        toastId: 'error',
      });
    }
  };

  const { data: companies, isLoading } = useQuery(
    ['get-all-companies'],
    getCompanies,
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeAndClear}
      title={`${type === 'create' ? 'Cadastro de Diretoria' : 'Edição de Diretoria'}`}
      className="z-[100] w-[40rem] max-w-[85vw]"
    >
      <FormProvider {...methods}>
        <form
          className="space-between flex h-[90%] flex-col px-5 py-2"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {isLoading ? (
            <div className="flex h-full w-full items-center justify-center">
              <CgSpinner
                size={30}
                color="#c1c1c1"
                className="h-full animate-spin"
              />
            </div>
          ) : (
            <div className="flex w-[37rem] flex-col items-center">
              <div className="flex w-full justify-between">
                <UncontrolledInputText
                  title="Diretoria"
                  required
                  width="18rem"
                  {...methods.register('name')}
                  upperCase
                  disabled={data && !data.active}
                />
                <ControlledSimpleSelect
                  title={'Empresa'}
                  required
                  name={'companyId'}
                  width="18rem"
                  values={companies}
                  control={methods.control}
                  disabled={data && !data.active}
                />
              </div>
              <div className="mt-4 w-full">
                <ExpandableSubtitle
                  subtitle={'Detalhamento'}
                  containerClassName="px-0"
                >
                  <ManagementRegister data={data} />
                </ExpandableSubtitle>
              </div>
              <div className="my-2 mt-[1.5rem] flex w-full justify-between">
                <Button
                  type="button"
                  className="h-8 min-w-36 border-2 border-[#193CB9] bg-white text-[#193CB9] hover:bg-[#e9eaf1]"
                  onClick={closeAndClear}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  className="h-8 min-w-36"
                  isLoading={isSubmiting}
                >
                  {`${data && !data.active ? 'Ativar' : 'Salvar'}`}
                </Button>
              </div>
            </div>
          )}
        </form>
      </FormProvider>
    </Modal>
  );
};

export default BoardModal;
