import React from 'react';

import ErrorIcon from '../../assets/icons/error.svg';
import Spinner from '../atoms/Spinner';

interface EmployeesWrapperProps {
  children: React.ReactNode;
  isLoading: boolean;
  isError: boolean;
}

const EmployeesWrapper: React.FC<EmployeesWrapperProps> = (props) => {
  return (
    <React.Fragment>
      {props.isLoading ? (
        <div className="my-4 flex h-full w-full items-center rounded-md bg-white">
          <Spinner size={50} />
        </div>
      ) : props.isError ? (
        <div className="h-full w-full px-[1%]">
          <div className="flex h-full w-full flex-col items-center justify-center rounded-md bg-white">
            <img src={ErrorIcon} alt="Ícone de erro" className="w-24" />
            <div className="flex flex-col items-center text-center">
              Não foi possível carregar os dados
              <span className="font-semibold text-blue-800">
                tente novamente mais tarde!
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="custom-scrollbar flex h-full w-full items-center justify-start gap-4 overflow-y-scroll">
          {props.children}
        </div>
      )}
    </React.Fragment>
  );
};

export default EmployeesWrapper;
