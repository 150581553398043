import { Dialog } from '@headlessui/react';
import React from 'react';
import { twMerge } from 'tailwind-merge';

import CloseButton from './CloseButton';
import Line from './Line';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children?: React.ReactNode;
  className?: string;
}

const Modal: React.FC<ModalProps> = (props: ModalProps) => {
  return props.isOpen ? (
    <Dialog open={true} onClose={props.onClose} className="relative z-[100]">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel
          className={twMerge(
            `custom-scrollbar max-h-full w-full overflow-y-scroll rounded-lg bg-white`,
            props.className ?? '',
          )}
        >
          <div>
            <div className="flex w-[99%] items-center pt-2">
              <Dialog.Title className="flex w-full flex-row px-5">
                <p className="text-center text-xl font-semibold text-primary">
                  {props.title}
                </p>
              </Dialog.Title>
              <CloseButton onClick={props.onClose} className="mt-[-0.5rem]" />
            </div>
            <Line />
          </div>

          {props.children}
        </Dialog.Panel>
      </div>
    </Dialog>
  ) : null;
};

export default Modal;
