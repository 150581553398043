import { IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MouseEventHandler } from 'react';

interface Props {
  onClick: MouseEventHandler;
}

const ArrowDownButton = ({ onClick }: Props) => {
  return (
    <IconButton
      sx={{ size: '10px', color: '#193CB9' }}
      onClick={onClick}
      size="small"
      aria-label="delete"
    >
      <KeyboardArrowDownIcon />
    </IconButton>
  );
};

export default ArrowDownButton;
