const colors: Record<string, string> = {
  Matriz: '#193CB9',
  Alagoas: '#001746',
  'Tucano Sul': '#1FBCDC',
  ADM: '#A9C1FD',
  TURNO: '#D4D9FF',
};

export const getBackgroundColor = (text: string) => {
  if (Object.keys(colors).includes(text)) {
    return colors[text];
  }
  return 'FFFFFF';
};
