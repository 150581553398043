import { DialogContentText } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { Button } from './Button';

interface Props {
  title: string;
  description: string;
  setIsOpen: (value: boolean) => void;
  onConfirmation: () => void;
  className?: string;
  isOpen?: boolean;
  height?: string;
  width?: string;
}

export const ConfirmationDialog = ({
  title,
  description,
  setIsOpen,
  onConfirmation,
  isOpen = true,
  height,
  width,
}: Props) => {
  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: '8px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
          overflowY: 'unset',
          height: height ?? '35vh',
          width: width ?? '40rem',
        },
      }}
      slotProps={{ backdrop: { sx: { background: 'rgba(0, 0, 0, 0.25)' } } }}
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <div className="flex justify-center border-b-[1px] border-[#7b7b7b42] p-2">
        <DialogTitle>
          <div className="px-1 text-center text-[19px] font-semibold text-slate-800">
            {title}
          </div>
        </DialogTitle>
      </div>
      <DialogContent
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <DialogContentText id="confirmation-dialog-description">
          <div className="text-center text-[14px] text-slate-800">
            {description}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          width: 'full',
          display: 'flex',
          justifyContent: 'space-around',
          paddingBottom: '25px',
        }}
      >
        <Button
          type="button"
          className="min-w-40 border-2 border-[#193CB9] bg-white text-[#193CB9] hover:bg-[#e9eaf1]"
          onClick={() => setIsOpen(false)}
        >
          Cancelar
        </Button>
        <Button type="button" className="min-w-40" onClick={onConfirmation}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
