import api from '../../services/apiSgft';
import { EmployeeFiltersAtom } from '../../state/EmployeeFilter.atom';
import { Filter } from '../../types/Filter';

const fetchFilter = async (
  field: string,
  search: string,
  atom?: EmployeeFiltersAtom,
) => {
  const response = await api.get(`${field}/filter`, {
    params: {
      search,
      size: 10,
      page: 1,
      workStation: atom?.workplace,
      management: atom?.management,
      teamStructure: atom?.teamStructure,
    },
  });
  const options = response.data.map(
    (it: { alias?: string; name: string; id: string }) => ({
      label: it.alias ?? it.name,
      value: String(it.id),
    }),
  );

  if (field === 'management') {
    options.push({
      label: 'SEM ALOCAÇÃO SGFT',
      value: '-1',
    });
  }
  return options;
};

export const filters: Filter[] = [
  {
    key: 'management',
    name: 'Gerência',
    options: [],
    isMulti: false,
    asyncFn: (search, atom?: EmployeeFiltersAtom) =>
      fetchFilter('management', search, atom),
    isDisabled: () => false,
  },
  {
    key: 'teamStructure',
    name: 'Estrutura de turma',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: EmployeeFiltersAtom) =>
      fetchFilter('team-structure', search, atom),
    isDisabled: (atom: EmployeeFiltersAtom) =>
      atom.management.length === 0 || atom.management.includes('-1'),
  },
  {
    key: 'workplace',
    name: 'Local de Trabalho',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: EmployeeFiltersAtom) =>
      fetchFilter('work-station', search, atom),
    isDisabled: (atom: EmployeeFiltersAtom) =>
      atom.management.length === 0 || atom.management.includes('-1'),
  },
];
