import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { cn } from '@/utils/cn';

import HiPencilTooltip from './HiPencilTooltip';

interface MenuProps {
  options: {
    name: string;
    onClick: () => void;
    disabled: boolean;
  }[];
  edit?: boolean;
  disabled?: boolean;
  canSave?: boolean;
  iconComponent?: React.ReactNode;
  menuItemsClassName?: string;
  upDotsButton?: boolean;
  upDotsButtonColor?:
    | 'error'
    | 'disabled'
    | 'success'
    | 'secondary'
    | 'action'
    | 'inherit'
    | 'primary'
    | 'info'
    | 'warning';
}

const DefaultIcon: React.FC = () => <MoreHorizIcon fontSize="inherit" />;

const SimpleMenu = ({
  options,
  edit,
  disabled,
  canSave,
  iconComponent,
  menuItemsClassName,
  upDotsButton,
  upDotsButtonColor,
}: MenuProps) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        disabled={disabled}
        className={cn(
          `flex h-10 w-10 min-w-10 items-center justify-center rounded-full bg-transparent text-sm font-medium text-gray-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`,
          !disabled && 'hover:bg-black/10',
          disabled && 'text-gray-300',
        )}
      >
        {upDotsButton ? (
          <MoreVertIcon color={`${upDotsButtonColor ?? 'primary'}`} />
        ) : edit ? (
          <HiPencilTooltip
            canSave={canSave}
            disabled={disabled}
          ></HiPencilTooltip>
        ) : (
          <div>{iconComponent || <DefaultIcon />}</div>
        )}
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className={`z-[1000] mt-2 min-w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none ${menuItemsClassName}`}
      >
        {options.map((option) => (
          <DropdownMenuItem
            key={option.name}
            disabled={option.disabled}
            onClick={option.onClick}
            className={`${option.disabled && 'cursor-not-allowed text-gray-400'} group flex w-full cursor-pointer items-center rounded-md bg-transparent px-2
            py-2 text-[12px] text-[#4A4A4A] duration-500 hover:bg-primary/80 hover:text-white focus:bg-primary focus:text-white`}
          >
            {option.name}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
export default SimpleMenu;
