import { atom } from 'recoil';

export interface ActualUser extends Record<number, string> {
  id: number;
  name: string;
  role: string;
  email: string;
  management_id: number;
  jobTitleId: number;
}

const actualUserAtom = atom<ActualUser>({
  key: 'actualUser',
  default: {
    id: 0,
    name: '',
    role: '',
    email: '',
    management_id: 0,
    jobTitleId: 0,
  },
});

export { actualUserAtom };
