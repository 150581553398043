import api from '../../services/apiSgft';
import { ViewScheduleFiltersAtom } from '../../state/ViewScheduleFilter.atom';
import { Filter } from '../../types/Filter';

const fetchFilter = async (
  field: string,
  search: string,
  atom?: ViewScheduleFiltersAtom,
) => {
  const response = await api.get(`${field}/filter`, {
    params: {
      search,
      size: 10,
      page: 1,
      startDate: atom?.timeRange.startDate,
      endDate: atom?.timeRange.endDate,
      workStation: atom?.workplace,
      management: atom?.management,
      scale: atom?.schedule,
      area: atom?.area,
      sector: atom?.sector,
      employee: atom?.employee,
      teamStructure: atom?.teamStructure,
    },
  });
  return response.data.map(
    (it: { name: string; id: string; alias?: string }) => ({
      label: it.alias ?? it.name,
      value: String(it.id),
    }),
  );
};

export const filters: Filter[] = [
  {
    key: 'timeRange',
    name: 'Período',
    options: [],
    isMulti: false,
    isDisabled: (_: ViewScheduleFiltersAtom) => false,
  },
  {
    key: 'management',
    name: 'Gerência',
    options: [],
    isMulti: false,
    asyncFn: (search, atom?: ViewScheduleFiltersAtom) =>
      fetchFilter('management', search, atom),
    isDisabled: (_: ViewScheduleFiltersAtom) => false,
  },
  {
    key: 'teamStructure',
    name: 'Estrutura de turma',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: ViewScheduleFiltersAtom) =>
      fetchFilter('team-structure', search, atom),
    isDisabled: (atom: ViewScheduleFiltersAtom) => atom.management.length === 0,
  },
  {
    key: 'area',
    name: 'Área',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: ViewScheduleFiltersAtom) =>
      fetchFilter('area', search, atom),
    isDisabled: (atom: ViewScheduleFiltersAtom) => atom.management.length === 0,
  },
  {
    key: 'sector',
    name: 'Setor',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: ViewScheduleFiltersAtom) =>
      fetchFilter('team-structure/sector', search, atom),
    isDisabled: (atom: ViewScheduleFiltersAtom) => atom.management.length === 0,
  },
  {
    key: 'workplace',
    name: 'Local de Trabalho',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: ViewScheduleFiltersAtom) =>
      fetchFilter('work-station', search, atom),
    isDisabled: (atom: ViewScheduleFiltersAtom) => atom.management.length === 0,
  },
  {
    key: 'schedule',
    name: 'Escala',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: ViewScheduleFiltersAtom) =>
      fetchFilter('team-structure/scale', search, atom),
    isSearchable: false,
    isDisabled: (atom: ViewScheduleFiltersAtom) => atom.management.length === 0,
  },
  {
    key: 'employee',
    name: 'Colaborador',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: ViewScheduleFiltersAtom) =>
      fetchFilter('employees', search, atom),
    isSearchable: false,
    isDisabled: (atom: ViewScheduleFiltersAtom) => atom.management.length === 0,
  },
];
