import { FC, useState } from 'react';
import { toast } from 'react-toastify';

import { TrainingType } from '@/constants/TrainingPlanning';

import api from '../../services/apiSgft';
import { Training } from '../../types/Training';
import { JustificationDialog } from '../atoms/JustificationDialog';

interface DesactivateTrainingModalProps {
  trainingId: number;
  isOpen: boolean;
  onClose: () => void;
  matrixRefetch: () => void;
  trainingData?: Training;
  trainingType: TrainingType;
}

const DesactivateTrainingModal: FC<DesactivateTrainingModalProps> = ({
  trainingId,
  isOpen,
  onClose,
  matrixRefetch,
  trainingType,
}) => {
  const [motive, setMotive] = useState('');

  const onSubmit = async () => {
    try {
      const body: any = {
        active: false,
        justificationForDeactivation: motive,
        trainingType,
      };
      await api.put(`trainings/active/${trainingId}`, body);
    } catch (error) {
      toast.error('Erro ao desativar treinamento', {
        theme: 'colored',
        toastId: `error-trainings/${trainingId}`,
      });
      throw error;
    } finally {
      setMotive('');
      matrixRefetch();
      onClose();
    }
  };

  return (
    <JustificationDialog
      title={`Justifique a desativação do treinamento`}
      justification={motive}
      setJustification={setMotive}
      isOpen={isOpen}
      setIsOpen={onClose}
      onConfirmation={() => onSubmit()}
    />
  );
};

export default DesactivateTrainingModal;
