import { CardContent } from '@mui/material';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';

import { sidebarIsOpenAtom } from '@/state/sidebarIsOpen.atom';

import api from '../../services/apiSgft';
import { supplierRegisterAtom } from '../../state/SupplierRegister.atom';
import { Training } from '../../types/Supplier';
import { DueCards } from './DueCards';
import SimpleMenu from './Menu';
import { ShowSupplierTrainings } from './ShowSupplierTrainings';
import { SupplierGeneralInfo } from './SupplierGeneralInfo';

export interface IProps {
  id: number;
  active: boolean;
  name: string;
  email?: string;
  trainings: Training[];
  trainingTypes: string[];
  onlineMaxDays?: number;
  inPersonMaxDays?: number;
  setModalIsOpen: (value: boolean) => void;
}

const SupplierCard = ({
  id,
  active,
  name,
  email,
  trainings,
  trainingTypes,
  onlineMaxDays,
  inPersonMaxDays,
  setModalIsOpen,
}: IProps) => {
  const [isActive, setIsActive] = useState<boolean>(active);
  const [, setSupplierRegister] = useRecoilState(supplierRegisterAtom);

  const options = [
    {
      name: 'Editar Fornecedor',
      onClick: () => {
        setSupplierRegister({
          id,
          name,
          email,
          onlineMaxDays,
          inPersonMaxDays,
          active: isActive,
          trainings,
          trainingTypes,
        });
        setModalIsOpen(true);
      },
      disabled: false,
    },
    {
      name: `${isActive ? 'Desativar' : 'Ativar'}`,
      onClick: async () => {
        try {
          await api.put(`supplier/${id}/activate/${!isActive}`);
          toast.success(
            `Fornecedor(a) ${name} ${!isActive ? 'ativado' : 'desativado'}(a) com sucesso!`,
            {
              theme: 'colored',
              toastId: 'success',
            },
          );
          setIsActive(!isActive);
        } catch (e) {
          toast.error(
            `Erro ao ${isActive ? 'desativar' : 'ativar'} o/a fornecedor(a) ${name}`,
            {
              theme: 'colored',
              toastId: 'error',
            },
          );
        }
      },
      disabled: false,
    },
  ];
  const isSideBarOpen = useRecoilValue(sidebarIsOpenAtom);

  return (
    <CardContent
      sx={{
        background: '#FFFFFF',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        fontFamily: 'inherit',
        flexBasis: '100%',
        width: isSideBarOpen ? '20vw' : '27vw',
      }}
    >
      <div className="flex h-full w-full flex-col justify-between">
        <div className="flex flex-col">
          <div className="flex w-full justify-between">
            <SupplierGeneralInfo
              isActive={isActive}
              name={name}
              email={email}
              setModalIsOpen={setModalIsOpen}
              id={id}
              trainings={trainings}
              trainingTypes={trainingTypes}
            />
            <div className="flex h-full items-start">
              <SimpleMenu options={options} upDotsButton />
            </div>
          </div>
          <div className="mb-4 font-graphie text-[12px] text-[#5A5A5A]">
            <p className="font-bold">
              Tipos de Treinamentos Aplicados:{' '}
              <span className="ml-2 font-normal">
                {trainingTypes.join(', ')}
              </span>
            </p>
          </div>
          <ShowSupplierTrainings trainings={trainings} />
        </div>
        <DueCards
          onlineMaxDays={onlineMaxDays}
          inPersonMaxDays={inPersonMaxDays}
        />
      </div>
    </CardContent>
  );
};

export default SupplierCard;
