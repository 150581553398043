import IconButton from '@mui/material/IconButton';
import React from 'react';
import { CgSpinner } from 'react-icons/cg';
import { MdOutlineFileDownload } from 'react-icons/md';
import { toast } from 'react-toastify';

import { trackEvent } from '@/utils/trackEvent';

import api from '../../services/apiSgft';
import { TrainingControlAtom } from '../../state/TrainingControl.atom';
import LightTooltip from '../atoms/LightTooltip';

interface TrainingControlDownloadButtonProps {
  filteredData: TrainingControlAtom;
}

export const TrainingControlDownloadButton: React.FC<
  TrainingControlDownloadButtonProps
> = ({ filteredData }) => {
  const [loading, setLoading] = React.useState(false);

  async function download() {
    setLoading(true);
    try {
      const response = await api.get(`/extraction/training-control`, {
        params: {
          employee: filteredData.employee,
          role: filteredData.role,
          rmStatus: filteredData.rmStatus,
          training: filteredData.training,
          modality: filteredData.modality,
          part: filteredData.part,
          completionStatus: filteredData.completionStatus,
          expirationStatus: filteredData.expirationStatus,
          management: filteredData.management,
          areaCoordinator: filteredData.areaCoordinator,
          trainingType: filteredData.trainingType,
        },
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      );
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', 'Controle de Treinamentos - SGFT.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      trackEvent('Download do Controle de Treinamentos', 'file_download', url);
    } catch (e) {
      toast.error('Estrutura sem dados', {
        theme: 'colored',
        toastId: 'error',
      });
      trackEvent(
        'Download do Controle de Treinamentos',
        'file_download',
        'Erro ao realizar o Download',
      );
      throw e;
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="cursor-pointer">
      <LightTooltip title="Extrair treinamentos">
        <IconButton onClick={download} disabled={loading}>
          {loading ? (
            <CgSpinner
              size={30}
              color="#c1c1c1"
              className="h-full animate-spin"
            />
          ) : (
            <MdOutlineFileDownload />
          )}
        </IconButton>
      </LightTooltip>
    </div>
  );
};
