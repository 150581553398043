import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import NoPermissionText from '@/components/atoms/NoPermissionText';
import BasicTabs from '@/components/molecules/BasicTabs';
import SideFilter from '@/components/molecules/SideFilter';
import ClosedPendenciesTab from '@/components/organisms/ClosedPendenciesTab';
import OpenPendenciesTab from '@/components/organisms/OpenPendenciesTab';
import { PendenciesFiltersAtom } from '@/state/PendenciesFilter.atom';
import { isReadOnlyUser } from '@/utils/handleSavePermissions';

import { filters } from '../../constants/filters/PendenciesFilter';

const Pendencies = () => {
  const filterValues = useRecoilValue(PendenciesFiltersAtom);
  const setFilterValues = useSetRecoilState(PendenciesFiltersAtom);
  const useResetFilterValues = useResetRecoilState(PendenciesFiltersAtom);

  const handleApplyFilter = (isReseting: boolean) => {
    if (isReseting) useResetFilterValues();
    else {
      setFilterValues(filterValues);
    }
  };
  if (isReadOnlyUser()) return <NoPermissionText />;

  return (
    <div className="h-full w-full font-graphie">
      <SideFilter
        refetchOnChange
        filters={filters}
        atom={PendenciesFiltersAtom}
        applyChanges={handleApplyFilter}
      />
      <BasicTabs
        tabsNames={['Pendências Abertas', 'Pendências Concluídas']}
        tabs={[<OpenPendenciesTab key={0} />, <ClosedPendenciesTab key={1} />]}
      />
    </div>
  );
};

export default Pendencies;
