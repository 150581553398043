import { useState } from 'react';

import EmptyIcon from '@/assets/icons/empty.svg';
import { columns } from '@/constants/tableColumns/TrainingFileInstructorColumns';
import { TrainingFile } from '@/types/TrainingFile';

import Modal from '../atoms/Modal';
import Table from '../organisms/Table';
import { Button } from '../ui/button';
import { TrainingFileInstructorEditModal } from './TrainingFileInstructorEditModal';
interface TrainingFileInstructorsProps {
  trainingFile: TrainingFile;
  onClose: () => void;
}

export const TrainingFileInstructors = ({
  trainingFile,
  onClose,
}: TrainingFileInstructorsProps) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      title="Detalhamento de Instrutores"
      className="max-w-[50rem]"
    >
      {isEditModalOpen && (
        <TrainingFileInstructorEditModal
          trainingFileId={trainingFile.id}
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          trainingFilePeriod={{
            startDate: trainingFile.startDate,
            endDate: trainingFile.endDate,
          }}
        />
      )}

      <div className="custom-scrollbar max-h-[50vh] overflow-y-auto px-4 pb-4 pt-2">
        {trainingFile.instructors.length ? (
          <Table columns={columns} data={trainingFile.instructors} />
        ) : (
          <div className="flex w-full flex-col items-center justify-center py-4">
            <img src={EmptyIcon} className="w-40" />
            <div className="flex flex-col items-center text-sm">
              <p>Não existe nenhum instrutor alocado para esse ficheiro</p>
              <span className="font-semibold text-blue-800">
                Cadastre um para visualizar!
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="flex w-full justify-end px-4 pb-4">
        <Button
          className="h-6 bg-transparent text-xs font-semibold text-primary underline hover:bg-transparent hover:opacity-60"
          onClick={() => setIsEditModalOpen(true)}
        >
          + Adicionar Instrutor
        </Button>
      </div>
    </Modal>
  );
};
