import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

import NumericInput from '@/components/atoms/NumericInput';
import {
  locationOptions,
  Modality,
  modalityOptions,
} from '@/constants/TrainingConstants';

import ControlledSimpleSelect from '../atoms/ControlledSimpleSelect';
import UncontrolledInputText from '../atoms/UncontrolledInputText';

interface DevelopmentTrainingCellProps {
  methods: UseFormReturn<any, any, any>;
  updateDueInputs: () => void;
  idx: number;
  remove: (index: number) => void;
  handleUpdateName: () => void;
}

export const DevelopmentTrainingCell = ({
  methods,
  idx,
  updateDueInputs,
  handleUpdateName,
}: DevelopmentTrainingCellProps) => {
  const modalitiesWatch = methods.watch(`trainings.${idx}.modalities`);
  const inPersonWorkloadWatch = methods.watch(
    `trainings.${idx}.inPersonWorkload`,
  );
  const onlineWorkloadWatch = methods.watch(`trainings.${idx}.onlineWorkload`);

  useEffect(() => {
    if (modalitiesWatch?.includes(Modality.Semipresencial)) {
      methods.setValue(
        `trainings.${idx}.totalWorkload`,
        Number(inPersonWorkloadWatch) + Number(onlineWorkloadWatch),
      );
    } else {
      methods.setValue(`trainings.${idx}.inPersonWorkload`, 0);
      methods.setValue(`trainings.${idx}.onlineWorkload`, 0);
    }
  }, [
    inPersonWorkloadWatch,
    onlineWorkloadWatch,
    modalitiesWatch,
    idx,
    methods,
  ]);

  return (
    <>
      <UncontrolledInputText
        required
        title={'Nome'}
        width="22rem"
        height="38px"
        {...methods.register(`trainings.${idx}.name`)}
        onBlur={handleUpdateName}
      />
      <ControlledSimpleSelect
        title={'Polo de Aplicação'}
        required
        control={methods.control}
        name={`trainings.${idx}.locations`}
        multiple
        width="100%"
        height="38px"
        values={locationOptions}
      />
      <ControlledSimpleSelect
        title={'Modalidade'}
        required
        control={methods.control}
        name={`trainings.${idx}.modalities`}
        multiple
        width="100%"
        height="38px"
        values={modalityOptions}
        onChange={updateDueInputs}
      />
      <div className="mb-4 flex w-[46rem] justify-between gap-4">
        <NumericInput
          required
          title={'Carga Horária Total (h)'}
          className="h-[38px] w-[14rem]"
          {...methods.register(`trainings.${idx}.totalWorkload`)}
          type="number"
          min={0}
          disabled={
            !modalitiesWatch ||
            !modalitiesWatch.length ||
            modalitiesWatch.includes(Modality.Semipresencial)
          }
          defaultValue={0}
        />

        <NumericInput
          required
          title={'Carga Horária Online (h)'}
          className="h-[38px] w-[14rem]"
          {...methods.register(`trainings.${idx}.onlineWorkload`)}
          type="number"
          disabled={
            !modalitiesWatch ||
            !modalitiesWatch.includes(Modality.Semipresencial)
          }
          min={0}
          defaultValue={0}
        />
        <NumericInput
          required
          title={'Carga Horária Presencial (h)'}
          className="h-[38px] w-[14rem]"
          {...methods.register(`trainings.${idx}.inPersonWorkload`)}
          type="number"
          disabled={
            !modalitiesWatch ||
            !modalitiesWatch.includes(Modality.Semipresencial)
          }
          min={0}
          defaultValue={0}
        />
      </div>
    </>
  );
};
