interface Props {
  isCurrent: boolean;
}

const CurrentButton = ({ isCurrent }: Props) => {
  return (
    <>
      {isCurrent && (
        <div
          className={`ml-1 flex w-12 items-center justify-center rounded-[5px] bg-[#193cb9] p-0.5 text-[11px] text-[#ffffff]`}
        >
          ATUAL
        </div>
      )}
    </>
  );
};

export default CurrentButton;
