import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { TrainingTypeEnum } from '@/constants/trainingTypeOptions';
import { trackEvent } from '@/utils/trackEvent';

import { queryClient } from '../../App';
import api from '../../services/apiSgft';
import { supplierRegisterAtom } from '../../state/SupplierRegister.atom';
import { Button } from '../atoms/Button';
import Modal from '../atoms/Modal';
import NumericInput from '../atoms/NumericInput';
import UncontrolledInputText from '../atoms/UncontrolledInputText';
import AllTrainings from '../molecules/AllTrainings';

interface SupplierModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SupplierModal: React.FC<SupplierModalProps> = ({ isOpen, onClose }) => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [onlineMaxDaysInput, setOnlineMaxDaysInput] = useState(false);
  const [inPersonMaxDaysInput, setInPersonMaxDaysInput] = useState(false);
  const supplier = useRecoilValue(supplierRegisterAtom);
  const reset = useResetRecoilState(supplierRegisterAtom);

  const methods = useForm();

  useEffect(() => {
    methods.reset({
      name: supplier.name,
      email: supplier.email,
      trainings: supplier.trainings.map((el) => {
        return {
          trainingType: el.training.trainingType,
          id: el.training.id,
          name: el.training.name,
          locations: el.locations,
          modalities: el.modalities,
          inPersonWorkload: el.training.inPersonWorkload,
          onlineWorkload: el.training.onlineWorkload,
          totalWorkload: el.training.totalWorkload,
        };
      }),
      onlineMaxDays: supplier.onlineMaxDays,
      inPersonMaxDays: supplier.inPersonMaxDays,
    });
  }, [supplier]);

  const onSubmit = async (data: any) => {
    const updatedData = {
      ...data,
      onlineMaxDays: data.onlineMaxDays
        ? Number(data.onlineMaxDays)
        : undefined,
      inPersonMaxDays: data.inPersonMaxDays
        ? Number(data.inPersonMaxDays)
        : undefined,
    };
    const developmentTrainings = updatedData.trainings.filter(
      (training: any) => training.trainingType === TrainingTypeEnum.DEVELOPMENT,
    );
    if (
      new Set(developmentTrainings.map((training: any) => training.name))
        .size !== developmentTrainings.length
    ) {
      toast.error(
        'Não é possível adicionar dois treinamentos com o mesmo nome para um fornecedor',
        {
          theme: 'colored',
          toastId: 'error',
        },
      );
      return;
    }
    setIsSubmiting(true);

    try {
      supplier.id
        ? await api.put(`supplier/${supplier.id}`, updatedData)
        : await api.post('/supplier', updatedData);
      setIsSubmiting(false);
      toast.success(
        `Fornecedor(a) ${supplier.id ? 'editado(a)' : 'cadastrado(a)'} com sucesso!`,
        {
          theme: 'colored',
          toastId: 'success',
        },
      );
      closeAndClear();

      trackEvent(
        data.trainingTypes.join(', '),
        'generate_supplier',
        `${data.trainings.length} treinamentos aplicados`,
      );
    } catch (error: any) {
      toast.error(
        error.response.data.statusCode < 500
          ? error.response.data.message
          : supplier.id
            ? 'Erro ao salvar fornecedor!'
            : 'Erro ao cadastrar fornecedor! Verifique se pelo menos um treinamento foi adicionado!',
        {
          theme: 'colored',
          toastId: 'error',
        },
      );
      throw error;
    } finally {
      setIsSubmiting(false);
    }
  };

  const closeAndClear = () => {
    methods.reset();
    reset();
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey[0] === 'suppliers',
    });
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeAndClear}
      title={`${supplier.id ? 'Editar Fornecedor' : 'Cadastrar Fornecedor'}`}
      className="w-[50rem] max-w-[80vw]"
    >
      <FormProvider {...methods}>
        <form
          className="space-between flex h-[90%] flex-col px-5 py-2"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className="mb-4 flex justify-between">
            <UncontrolledInputText
              required
              title={'Nome'}
              width="22rem"
              {...methods.register('name')}
            />
            <UncontrolledInputText
              title={'Email'}
              width="22rem"
              required={false}
              {...methods.register('email')}
            />
          </div>
          <AllTrainings
            methods={methods}
            setOnlineMaxDaysInput={setOnlineMaxDaysInput}
            setInPersonMaxDaysInput={setInPersonMaxDaysInput}
          />
          <div className="flex justify-start">
            <NumericInput
              title="Prazo para conclusão parte online (dias)"
              {...methods.register(`onlineMaxDays`)}
              className="mr-5 w-[15rem]"
              required
              disabled={!onlineMaxDaysInput}
            />
            <NumericInput
              title="Prazo para conclusão parte presencial (dias)"
              {...methods.register(`inPersonMaxDays`)}
              className="w-[15rem]"
              required
              disabled={!inPersonMaxDaysInput}
            />
          </div>
          <div className="my-2 mt-[2rem] flex w-full justify-between">
            <Button
              type="button"
              className="h-8 min-w-36 border-2 border-[#193CB9] bg-white text-[#193CB9] hover:bg-[#e9eaf1]"
              onClick={closeAndClear}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              className=" h-8 min-w-36"
              isLoading={isSubmiting}
            >
              Salvar
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default SupplierModal;
