import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { trackEvent } from '@/utils/trackEvent';

import api from '../../services/apiSgft';
import { removeTimeZone } from '../../utils/formatDate';
import { Button } from '../atoms/Button';
import Line from '../atoms/Line';
import SimpleSelect from '../atoms/SimpleSelect';
import SubtitleText from '../atoms/SubtitleText';
import TextComponent from '../atoms/TextComponent';

interface Props {
  management: string;
  allocationDate: Date | string;
  teamStructures: any;
  handleClose: any;
  employeeId: number;
  setUpdateData: any;
}

const FirstAllocationCard = ({
  management,
  allocationDate,
  teamStructures,
  handleClose,
  employeeId,
  setUpdateData,
}: Props) => {
  const [teamStructureId, setTeamStructureId] = useState<number | string>('');
  const [teamId, setTeamId] = useState<number | string>('');
  const [isAbleToSave, setIsAbleToSave] = useState(false);
  const [isSavingData, setIsSavingData] = useState(false);

  useEffect(() => {
    if (teamStructureId !== '' && teamId !== '') setIsAbleToSave(true);
  }, [teamId, teamStructureId]);

  const teamStructuresFiltered = teamStructures.map((item: any) => {
    return { value: item.id, label: item.name };
  });

  let teams: { value: number; label: string }[] = [];
  if (teamStructureId !== '') {
    const teamStructure = teamStructures.find(
      (item: any) => item.id === teamStructureId,
    );
    teams = teamStructure.teams.map((team: any) => {
      return { value: team.id, label: team.name };
    });
  }

  const handleSave = () => {
    if (isAbleToSave) {
      setIsSavingData(true);
      api
        .post('/team-employee/createAllocation', {
          employeeId,
          teamId,
          startDate: allocationDate,
        })
        .then((response: any) => {
          toast.success(response.data.message, {
            theme: 'colored',
            toastId: 'success',
          });
          trackEvent(
            management,
            'generate_allocation',
            teams.length > 0 ? teams[0].label : '',
          );
          setUpdateData(true);
          handleClose();
        })
        .catch((error) => {
          const errorMessage =
            error?.response?.status < 500
              ? error?.response?.data?.message
              : 'Sua solicitação não foi processada. Tente novamente mais tarde!';
          toast.error(errorMessage, {
            theme: 'colored',
            toastId: 'error',
          });
        })
        .finally(() => {
          setIsSavingData(false);
        });
    }
  };

  return (
    <div className="mb-5">
      <SubtitleText subtitle={'Adicionar Primeira Alocação'} />
      <Line />
      <div className="mb-3 flex w-full justify-between">
        <TextComponent title={'Gerência'} width="22rem" value={management} />
        <SimpleSelect
          width="10rem"
          title={'Estrutura'}
          values={teamStructuresFiltered}
          value={teamStructureId}
          setState={(id) => setTeamStructureId(id as number)}
        />
        {teamStructureId === '' ? (
          <TextComponent title={'Turma'} width="6rem" value={''} />
        ) : (
          <SimpleSelect
            width="6rem"
            title={'Turma'}
            values={teamStructureId !== '' ? teams : []}
            value={teamId}
            setState={(id) => setTeamId(id as number)}
          />
        )}
        <TextComponent
          title={'Data da Alocação'}
          width="7rem"
          value={
            allocationDate !== ''
              ? removeTimeZone(new Date(allocationDate)).toLocaleDateString(
                  'pt-BR',
                )
              : ''
          }
        />
      </div>
      <div className="flex justify-between pt-5">
        <Button
          type="button"
          title="Cancelar"
          variant="link"
          className="min-w-40 font-semibold ring-1"
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <Button
          type="button"
          className="min-w-40 underline"
          onClick={handleSave}
          isLoading={isSavingData}
          disabled={!isAbleToSave}
        >
          Salvar
        </Button>
      </div>
    </div>
  );
};

export default FirstAllocationCard;
