import { Box, Tab, Tabs } from '@mui/material/';
import React from 'react';

import TabPanel from '../atoms/TabPanel';
interface Props {
  tabsNames: string[];
  tabs: React.ReactNode[];
  background?: string;
  width?: string;
  initialTab?: number;
}

function props(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BasicTabs = ({
  tabsNames,
  tabs,
  background = 'white',
  width = '100%',
  initialTab = 0,
}: Props) => {
  const [value, setValue] = React.useState(initialTab);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        height: '98%',
        marginTop: '1rem',
        background,
        borderRadius: '0.5rem',
        width,
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider', width }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tabs"
          TabIndicatorProps={{
            style: { backgroundColor: '#193CB9' },
          }}
        >
          {tabsNames.map((el, index) => {
            return (
              <Tab
                key={index}
                style={{
                  color: value === index ? '#193CB9' : '#4A4A4A',
                  fontSize: '12px',
                  lineHeight: '9.5px',
                  fontWeight: '500',
                  fontFamily: 'inherit',
                }}
                label={el}
                {...props(index)}
              />
            );
          })}
        </Tabs>
      </Box>
      {tabs.map((el, index) => {
        return (
          <TabPanel key={index} value={value} index={index}>
            {el}
          </TabPanel>
        );
      })}
    </Box>
  );
};

export default BasicTabs;
