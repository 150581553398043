import { ColumnDef } from '@tanstack/react-table';

import TableCellText from '@/components/atoms/TableCellText';
import { Training } from '@/types/Supplier';

export const columns: Array<ColumnDef<Training>> = [
  {
    accessorKey: 'training',
    header: 'Treinamento',
    cell: (props) => {
      return (
        <div className="w-[15rem]">
          <TableCellText
            text={(props.getValue() as { name: string }).name}
            width="15rem"
          />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'modalities',
    header: 'Modalidades',
    cell: (props) => {
      return (
        <div className="w-28">
          <TableCellText
            text={(props.getValue() as Array<string>).join(', ')}
            paddingX="0px"
          />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'locations',
    header: 'Localidades',
    cell: (props) => {
      return (
        <div className="w-28">
          <TableCellText
            text={(props.getValue() as Array<string>).join(', ')}
            paddingX="0px"
          />
        </div>
      );
    },
    enableSorting: false,
  },
];
