import { atom } from 'recoil';

export interface EmployeeFiltersAtom {
  workplace: string[];
  management: string[];
  employee: string;
  teamStructure: string[];
}

export const employeeFiltersAtom = atom<EmployeeFiltersAtom>({
  key: 'employee',
  default: {
    workplace: [],
    management: [],
    employee: '',
    teamStructure: [],
  },
});
