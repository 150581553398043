import { Controller } from 'react-hook-form';

const ControlledCheckbox = ({ title, name, alert, ...rest }: any) => {
  return (
    <div>
      <div className="mb-1 mt-2 flex items-center">
        <Controller
          render={({ field: { onChange, value, ...controllerRest } }) => {
            return (
              <input
                type="checkbox"
                {...rest}
                {...controllerRest}
                checked={value}
                onChange={(e) => {
                  onChange(e);
                  rest.onChange && rest.onChange(e);
                }}
              />
            );
          }}
          name={name}
        />
        <div className="ml-2 text-[12px] text-[#4A4A4A]">{`${title}`}</div>
      </div>
      <div className="mb-3 text-[12px] text-[#b63c3c]">{alert}</div>
    </div>
  );
};

export default ControlledCheckbox;
