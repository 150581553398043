/* eslint-disable react/display-name */
import { ChangeEvent, useRef } from 'react';

import { TrainingTypeEnum } from '@/constants/trainingTypeOptions';
import { TrainingMatrix } from '@/types/Training';

import Check from '../../assets/icons/check.svg';
import Checkbox from '../atoms/Checkbox';

interface TrainingMatrixCellProps {
  isEditMode: boolean;
  rowIndex: number;
  colIndex: number;
  jobTitleId: number;
  trainingId: number;
  isCritical: boolean;
  totalWorkload: number | null;
  validity: number | null;
  recyclingWorkload: number | null;
  trainingsMatrixData: TrainingMatrix;
  modifiedCheckboxes: Map<string, boolean>;
  canSave?: boolean;
  isResponsible?: boolean;
  trainingType: TrainingTypeEnum;
  onChangeCallback: (checked: boolean) => void;
  jobTitleGap: number;
}

const TrainingMatrixCell = ({
  isEditMode,
  rowIndex,
  colIndex,
  trainingId,
  totalWorkload,
  validity,
  recyclingWorkload,
  isCritical,
  jobTitleId,
  trainingsMatrixData,
  modifiedCheckboxes,
  canSave,
  isResponsible,
  trainingType,
  onChangeCallback,
  jobTitleGap,
}: TrainingMatrixCellProps) => {
  const checkboxRef = useRef(null);
  const jobTitleIndex = rowIndex - jobTitleGap;
  const trainingIndex = colIndex;

  const handleIsChecked = () => {
    if (
      trainingsMatrixData?.matrix &&
      trainingsMatrixData?.matrix.length > jobTitleIndex &&
      trainingsMatrixData?.matrix[jobTitleIndex]?.length > trainingIndex
    ) {
      if (modifiedCheckboxes.has(`${jobTitleId}-${trainingId}`)) {
        return modifiedCheckboxes.get(`${jobTitleId}-${trainingId}`) || false;
      } else {
        return trainingsMatrixData?.matrix[jobTitleIndex][trainingIndex];
      }
    }

    return false;
  };

  const handleCheckboxChange = async (event: ChangeEvent<HTMLInputElement>) => {
    return onChangeCallback(event.target?.checked);
  };

  if (rowIndex === 0) {
    if (trainingType === TrainingTypeEnum.REGULATORY) {
      return (
        <div className="flex items-center justify-center px-2 text-[13px] font-bold text-blue-800">
          <div>{`${validity || ''}`}</div>
        </div>
      );
    }
    return (
      <div className="flex items-center justify-center px-2 text-[13px] font-bold text-blue-800">
        <div>{`${isCritical ? 'SIM' : 'NÃO'}`}</div>
      </div>
    );
  } else if (rowIndex === 1) {
    return (
      <div className="flex items-center justify-center px-2 text-[13px] font-bold text-blue-800">
        <div>{`${totalWorkload || ''}`}</div>
      </div>
    );
  } else if (rowIndex === 2) {
    if (trainingType === TrainingTypeEnum.REGULATORY) {
      return (
        <div className="flex items-center justify-center px-2 text-[13px] font-bold text-blue-800">
          <div>{`${recyclingWorkload || ''}`}</div>
        </div>
      );
    }
    return <div></div>;
  } else if (rowIndex === 3 && trainingType === TrainingTypeEnum.REGULATORY) {
    return <div></div>;
  } else {
    const isChecked = handleIsChecked();

    return (
      <div className="flex justify-center">
        {isEditMode ? (
          <Checkbox
            defaultChecked={isChecked}
            ref={checkboxRef}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleCheckboxChange(e);
            }}
            disabled={!canSave && !isResponsible}
          />
        ) : (
          <div>
            {isChecked ? <img src={Check} className="w-8 flex-none" /> : <></>}
          </div>
        )}
      </div>
    );
  }
};

export default TrainingMatrixCell;
