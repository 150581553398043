import { useState } from 'react';
import { toast } from 'react-toastify';

import { queryClient } from '@/App';
import api from '@/services/apiSgft';
import { TrainingFileTeamEmployee } from '@/types/TrainingFile';

import SimpleMenu from '../atoms/Menu';
import EditEmployeeOnTrainingFileTeamModal from '../organisms/EditEmployeeOnTrainingFileTeamModal';
import ModalConfirmation from './ConfirmationModal';
interface TrainingFileTeamEmployeeMenuProps {
  employeeLink: TrainingFileTeamEmployee;
  onlineTraining: boolean;
  asyncTraining: boolean;
}

const TrainingFileTeamEmployeeMenu = ({
  employeeLink,
  onlineTraining,
  asyncTraining,
}: TrainingFileTeamEmployeeMenuProps) => {
  const [isEmployeeInfoModalOpen, setIsEmployeeInfoModalOpen] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [
    isRemoveEmployeeFromTeamModalOpen,
    setIsRemoveEmployeeFromTeamModalOpen,
  ] = useState(false);

  const options = [
    {
      name: 'Informações do colaborador',
      onClick: () => setIsEmployeeInfoModalOpen(true),
      disabled: asyncTraining,
    },
    {
      name: 'Remover da turma',
      onClick: () => setIsRemoveEmployeeFromTeamModalOpen(true),
      disabled: false,
    },
  ];
  const handleRemoveEmployee = async () => {
    setLoadingRemove(true);
    try {
      await api.delete(`training-plannings/employee-link/${employeeLink.id}`);
      toast.success('Colaborador removido com sucesso', {
        theme: 'colored',
        toastId: 'success',
      });
      queryClient.invalidateQueries(['training-file-details']);
    } catch {
      toast.error('Não foi possível remover o colaborador do treinamento', {
        theme: 'colored',
        toastId: 'error',
      });
    } finally {
      setLoadingRemove(false);
    }
  };
  return (
    <>
      {isEmployeeInfoModalOpen && (
        <EditEmployeeOnTrainingFileTeamModal
          isOpen={isEmployeeInfoModalOpen}
          onClose={() => setIsEmployeeInfoModalOpen(false)}
          employee={employeeLink}
          onlineTraining={onlineTraining}
          asyncTraining={asyncTraining}
        />
      )}
      {isRemoveEmployeeFromTeamModalOpen && (
        <ModalConfirmation
          confirmAction={handleRemoveEmployee}
          title="Remover da Turma"
          isOpen={isRemoveEmployeeFromTeamModalOpen}
          onClose={() => setIsRemoveEmployeeFromTeamModalOpen(false)}
          description={`Tem certeza que deseja remover o colaborador ${employeeLink.name} do treinamento?`}
        />
      )}
      <SimpleMenu options={options} upDotsButton />
    </>
  );
};

export default TrainingFileTeamEmployeeMenu;
