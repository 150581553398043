import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { queryClient } from '../../App';
import api from '../../services/apiSgft';
import { ExtraTrip } from '../../types/ExtraTrip';
import { Option } from '../../types/Filter';
import { removeTimeZone } from '../../utils/removeTimezone';
import { Button } from '../atoms/Button';
import { DatePicker, DatePickerRange } from '../atoms/DatePicker';
import Line from '../atoms/Line';
import SimpleSelect from '../atoms/SimpleSelect';
import SimpleTextField from '../atoms/SimpleTextField';
import SubtitleText from '../atoms/SubtitleText';

interface ExtraTripsCardProps {
  extraTrip: ExtraTrip | null;
  extraTripTypes: Option[];
  workStations: Option[];
  employeeId: number;
  onCloseForm: () => void;
  onChangeData: () => void;
}

const ExtraTripsCard = ({
  extraTrip,
  extraTripTypes,
  employeeId,
  workStations,
  onCloseForm,
  onChangeData,
}: ExtraTripsCardProps) => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [dates, setDates] = useState<DatePickerRange | undefined>(
    extraTrip
      ? {
          startDate: removeTimeZone(extraTrip.startDate),
          endDate: removeTimeZone(extraTrip.endDate),
        }
      : {
          startDate: undefined,
          endDate: undefined,
        },
  );
  const [motive, setMotive] = useState(
    extraTrip ? extraTrip.extraTripType.id : '',
  );
  const [workStation, setWorkStation] = useState(
    extraTrip ? extraTrip.workStation.id : '',
  );
  const [entryTime, setEntryTime] = useState(
    extraTrip ? extraTrip.workStation.id : '',
  );
  const [leavingTime, setLeavingTime] = useState(
    extraTrip ? extraTrip.workStation.id : '',
  );
  const [description, setDescription] = useState(
    extraTrip ? extraTrip.description : '',
  );

  const onDateSelect = (dateRange: DatePickerRange | undefined) => {
    if (dateRange?.startDate && !dateRange.endDate) {
      setDates({
        endDate: dateRange.startDate,
        startDate: dateRange.startDate,
      });
      return;
    }
    setDates(dateRange);
  };

  useEffect(() => {
    setDates(
      extraTrip
        ? {
            startDate: removeTimeZone(extraTrip.startDate),
            endDate: removeTimeZone(extraTrip.endDate),
          }
        : {
            startDate: undefined,
            endDate: undefined,
          },
    );
    setDescription(extraTrip ? extraTrip.description : '');
    setMotive(extraTrip ? extraTrip.extraTripType.id : '');
    setLeavingTime(extraTrip ? extraTrip.leavingTime : '');
    setEntryTime(extraTrip ? extraTrip.entryTime : '');
    setWorkStation(extraTrip ? extraTrip.workStation.id : '');
  }, [extraTrip]);
  const handleCreateAndEdit = async () => {
    setIsSubmiting(true);
    const body: any = {
      employeeId,
      extraTripTypeId: Number(motive),
      description,
      startDate: dates?.startDate,
      endDate: dates?.endDate,
      leavingTime,
      entryTime,
      workStationId: workStation,
    };
    let method: 'post' | 'put' = 'post';
    let endpoint = 'extra-trips';
    let action = 'adicionar';
    if (extraTrip) {
      body.extraTripId = Number(extraTrip.id);
      method = 'put';
      endpoint = 'extra-trips/update';
      action = 'alterar';
    }
    api[method](endpoint, body)
      .then(() => {
        toast.success(`Sucesso ao ${action} a ida extra`, {
          theme: 'colored',
          toastId: 'success',
        });
        queryClient.invalidateQueries(['team-structure']);
        onChangeData();
      })
      .catch((error) => {
        const errorMessage =
          error?.response?.status < 500
            ? error?.response?.data?.message
            : 'Não conseguimos processar sua solicitação. Tente novamente mais tarde!';

        toast.error(errorMessage, {
          theme: 'colored',
          toastId: 'error',
        });
      })
      .finally(() => {
        setIsSubmiting(false);
      });
  };
  const isFormDisabled =
    !dates?.startDate ||
    !dates?.endDate ||
    !motive ||
    !description ||
    !workStation ||
    !entryTime ||
    !leavingTime;
  return (
    <div className="mb-5">
      <div>
        <SubtitleText
          subtitle={`${
            extraTrip ? 'Alterar ida extra' : 'Adicionar ida extra'
          }`}
        />
        <Line />
        <div className="mb-3 flex w-full justify-between">
          <div className="w-[12rem]">
            <div className="font-graphie text-[12px] text-[#4A4A4A]">Datas</div>
            <DatePicker
              value={dates}
              onSelect={onDateSelect}
              className={{ button: 'h-8' }}
            />
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-xs text-gray-600">Entrada</span>
            <input
              onChange={(event) => setEntryTime(event.target.value)}
              value={entryTime}
              className="h-full rounded-sm px-2 text-sm text-gray-700 ring-1 ring-gray-300"
              type="time"
            />
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-xs text-gray-600">Saída</span>
            <input
              value={leavingTime}
              onChange={(event) => setLeavingTime(event.target.value)}
              className="h-full rounded-sm px-2 text-sm text-gray-700 ring-1 ring-gray-300"
              type="time"
            />
          </div>
          <div>
            <SimpleSelect
              width="12rem"
              title={'Local de Trabalho'}
              values={workStations}
              value={workStation}
              setState={(value) => setWorkStation(value as number)}
            />
          </div>
          <SimpleSelect
            width="12rem"
            title={'Motivo'}
            values={extraTripTypes}
            value={motive}
            setState={(value) => setMotive(value as number)}
          />
        </div>
        <SimpleTextField
          title="Descrição"
          width="100%"
          value={description}
          setState={(value) => setDescription(value)}
        />
        <div className="mt-4 flex w-full justify-between p-0">
          <Button
            title="Cancelar"
            onClick={onCloseForm}
            variant="outline"
            className="min-w-40  font-semibold ring-1"
          >
            Cancelar
          </Button>

          <Button
            type="button"
            className="min-w-40  underline"
            onClick={handleCreateAndEdit}
            isLoading={isSubmiting}
            disabled={isFormDisabled}
          >
            {extraTrip ? '✔ Salvar alterações' : '✔ Salvar ida extra'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ExtraTripsCard;
