import { ColumnDef } from '@tanstack/react-table';
import { Pencil, Trash } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { queryClient } from '@/App';
import TableCellDate from '@/components/atoms/TableCellDate';
import TableCellText from '@/components/atoms/TableCellText';
import ModalConfirmation from '@/components/molecules/ConfirmationModal';
import { TrainingFileInstructorEditModal } from '@/components/molecules/TrainingFileInstructorEditModal';
import api from '@/services/apiSgft';
import { TrainingFileInstructor } from '@/types/TrainingFile';
import { dateInUTCFormat } from '@/utils/formatDate';

export const columns: Array<ColumnDef<TrainingFileInstructor>> = [
  {
    accessorKey: 'name',
    header: 'Nome',
    cell: (props) => {
      return (
        <div className="w-[14rem] overflow-hidden">
          <TableCellText text={props.getValue() as string} width="14rem" />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'entryDate',
    header: 'Embarque',
    cell: (props) => {
      return (
        <div className="w-24">
          <TableCellDate
            date={new Date(dateInUTCFormat(props.getValue() as string))}
          />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'exitDate',
    header: 'Desembarque',
    cell: (props) => {
      return (
        <div className="w-24">
          <TableCellDate
            date={new Date(dateInUTCFormat(props.getValue() as string))}
          />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'id',
    header: '',
    cell: (props) => {
      const [isEditModalOpen, setIsEditModalOpen] = useState(false);
      const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
      const handleDeleteInstructor = async () => {
        try {
          await api.delete(
            `training-files/${props.row.original.trainingFileId}/instructor/${props.row.original.id}`,
          );
          toast.success('Instrutor excluído com sucesso', {
            theme: 'colored',
            toastId: 'success',
          });

          queryClient.invalidateQueries({ queryKey: ['training-files'] });

          queryClient.invalidateQueries({
            queryKey: ['training-file-details'],
          });
          queryClient.invalidateQueries({
            queryKey: ['training-file-instructors'],
          });
        } catch {
          toast.error('Erro ao excluir', {
            theme: 'colored',
            toastId: 'error',
          });
        }
      };
      return (
        <div className="flex w-12">
          <button
            className="px-3 text-primary"
            onClick={() => setIsEditModalOpen(true)}
          >
            <Pencil size={14} />
            {isEditModalOpen && (
              <TrainingFileInstructorEditModal
                isOpen={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
                instructor={props.row.original}
                trainingFileId={props.row.original.trainingFileId}
                trainingFilePeriod={props.row.original.trainingFilePeriod}
              />
            )}
          </button>
          <button
            className="px-3 text-primary"
            onClick={() => setIsDeleteModalOpen(true)}
          >
            <Trash size={14} />
            {isDeleteModalOpen && (
              <ModalConfirmation
                confirmAction={handleDeleteInstructor}
                title="Excluir instrutor"
                isOpen={isDeleteModalOpen}
                onClose={() => setIsDeleteModalOpen(false)}
                description="Deseja mesmo excluir o instrutor?"
              />
            )}
          </button>
        </div>
      );
    },
    enableSorting: false,
  },
];
