import InfoIcon from '@mui/icons-material/Info';
import { IconButton } from '@mui/material';
import { format } from 'date-fns';

import { removeTimeZone } from '@/utils/formatDate';

import LightTooltip from './LightTooltip';

interface Props {
  vacationCovers?: { startDate: Date; endDate: Date }[];
  className?: string;
}

const TooltipIcon = ({ vacationCovers }: Props) => {
  if (!vacationCovers?.length) {
    return <></>;
  }
  return (
    <LightTooltip
      title={
        <span
          style={{
            whiteSpace: 'pre-line',
          }}
        >
          <div className="font-semibold">Dias que demandam cobertura:</div>
          <div>
            {vacationCovers?.map((el, index) => (
              <div key={`${el.startDate}-${index}`}>
                {el.startDate
                  ? format(removeTimeZone(new Date(el.startDate)), 'dd/MM/yyyy')
                  : ''}
                {' - '}
                {el.endDate
                  ? format(removeTimeZone(new Date(el.endDate)), 'dd/MM/yyyy')
                  : ''}
              </div>
            ))}
          </div>
        </span>
      }
      placement="top"
      arrow
    >
      <IconButton
        sx={{ height: '2rem', width: '2rem' }}
        aria-label="information"
        size="small"
      >
        <InfoIcon fontSize="inherit" />
      </IconButton>
    </LightTooltip>
  );
};

export default TooltipIcon;
