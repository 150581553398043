import { TrainingFilesFiltersAtom } from '@/state/TrainingFilesFilter.atom';

import api from '../../services/apiSgft';
import { Filter } from '../../types/Filter';
import { trainingFileStatus } from '../trainingFileStatus';
import { trainingTypeOptions } from '../trainingTypeOptions';

const fetchFilter = async (
  field: string,
  search: string,
  atom?: TrainingFilesFiltersAtom,
) => {
  const response = await api.get(`${field}/filter`, {
    params: {
      search,
      size: 10,
      page: 1,
      trainingType: atom?.trainingType,
      trainings: atom?.trainings,
      suppliers: atom?.suppliers,
      getAll: true,
    },
  });
  const options = response.data.map((it: { name: string; id: string }) => ({
    label: it.name,
    value: String(it.id),
  }));
  return options;
};

export const filters: Filter[] = [
  {
    key: 'timeRange',
    name: 'Período',
    options: [],
    isMulti: true,
    asyncFn: undefined,
    isDisabled: () => false,
  },
  {
    key: 'trainingFileName',
    name: 'Nome do Ficheiro',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: TrainingFilesFiltersAtom) =>
      fetchFilter('training-files', search, atom),
    isDisabled: () => false,
  },
  {
    key: 'trainingFileNumber',
    name: 'Número do Ficheiro',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: TrainingFilesFiltersAtom) =>
      fetchFilter('training-files/number', search, atom),
    isDisabled: () => false,
  },
  {
    key: 'responsible',
    name: 'Responsável',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: TrainingFilesFiltersAtom) =>
      fetchFilter('training-files/responsible', search, atom),
    isDisabled: () => false,
  },
  {
    key: 'suppliers',
    name: 'Fornecedor',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: TrainingFilesFiltersAtom) =>
      fetchFilter('supplier', search, atom),
    isDisabled: () => false,
  },
  {
    key: 'trainingType',
    name: 'Tipo de Treinamento',
    options: trainingTypeOptions,
    isMulti: true,
    asyncFn: undefined,
    isDisabled: () => false,
  },
  {
    key: 'trainings',
    name: 'Treinamento',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: TrainingFilesFiltersAtom) =>
      fetchFilter('trainings', search, atom),
    isDisabled: () => false,
  },
  {
    key: 'status',
    name: 'Status',
    options: Object.values(trainingFileStatus).map((it) => ({
      label: it,
      value: it,
    })),
    asyncFn: undefined,
    isMulti: true,
    isDisabled: () => false,
  },
];
