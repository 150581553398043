import { useState, useEffect, useRef, useId } from 'react';

interface TableVirtualizationProps<T, H> {
  headers: H[];
  data: T[][];
  columnWidth: number;
  visibleWidth: number;
  extraColumns?: number;
}

interface Virtualized<T, H> {
  data: New<New<T>[]>[];
  headers: New<H>[];
}

interface New<T> {
  value: T;
  key: string;
}

export const useVirtualTable = <T, H>({
  data,
  headers,
  columnWidth,
  visibleWidth,
  extraColumns = 0,
}: TableVirtualizationProps<T, H>) => {
  const [virtualized, setVirtualized] = useState<Virtualized<T, H>>({
    data: [],
    headers: [],
  });
  const tableRef = useRef<HTMLTableElement>(null);
  const visibleColumnsCount =
    Math.ceil(visibleWidth / columnWidth) + extraColumns;
  const id = useId();

  const updateVirtualizedData = () => {
    if (!tableRef.current) return;

    const scrollLeft = tableRef.current.scrollLeft;
    const startIndex = Math.floor(scrollLeft / columnWidth);
    const endIndex = startIndex + visibleColumnsCount;

    const newVirtualizedData = data.map((row, rowIndex) => ({
      value: row.slice(startIndex, endIndex).map((value, columnIndex) => ({
        value,
        key: `${id}-data-${rowIndex}-${columnIndex}`,
      })),
      key: `${id}-data-${rowIndex}`,
    }));

    const newVirtualizedHeaders = headers
      .slice(startIndex, endIndex)
      .map((value, index) => ({
        value,
        key: `${id}-header-${index}`,
      }));

    setVirtualized({
      data: newVirtualizedData,
      headers: newVirtualizedHeaders,
    });
  };

  useEffect(() => {
    updateVirtualizedData(); // Initial setup

    const table = tableRef.current;
    table?.addEventListener('scroll', updateVirtualizedData);

    return () => table?.removeEventListener('scroll', updateVirtualizedData);
  }, [data, visibleColumnsCount, id, columnWidth]);

  return { virtualized, tableRef };
};
