import { ReactFlow } from '@xyflow/react';
import { forwardRef, HTMLAttributes } from 'react';
import { useRecoilValue } from 'recoil';

import { hideEmployeesAtom } from '@/state/HideEmployeesChart.atom';
import { PositionChartDoubleClickArgs } from '@/types/PositionChartDoubleClickArgs';

import ErrorIcon from '../../assets/icons/error.svg';
import ConnectionLine from '../atoms/ConnectionLine';
import CustomNode from '../molecules/CustomNode';

const nodeTypes = {
  custom: CustomNode,
};

const edgeTypes = {
  custom: ConnectionLine,
};

export interface Position {
  id: number;
  board: { id: number; name: string };
  management: { id: number; name: string };
  coordination: { id: number; name: string };
  name: string;
  regime: string;
  actualEmployees?: number;
  amountOfExpectedEmployees?: number;
  amountOfAllocatedEmployees: number;

  active?: boolean;
  workStation: { id: number; name: string; pole: string };
  numberOfReports: number;
  reportsTo: { id: number; name: string }[];
  isIntermediary: boolean;
  isParent?: false;
  generalInformationJobTitle: {
    id: number;
    name: string;
  };
}

export interface PositionWithAllocation extends Position {
  positionId: number;
  isFixedAllocation: boolean;

  employee?: {
    id: number;
    name: string;
    alias?: string;
    employeeNumber: string;
    email: string;
    company: string;
    thirdParty: boolean;
  };
}

export interface Graph {
  board?: string;
  management?: string;
  graph: { positions: (Position | PositionWithAllocation)[] }[];
  coordination?: string;
}
interface Props extends HTMLAttributes<HTMLDivElement> {
  data?: Graph;
  handleChange?: (data: PositionChartDoubleClickArgs) => void;
  defaultViewPort?: { x: number; y: number; zoom: number };
  toPrint?: boolean;
}

const ChartGraph = forwardRef<HTMLDivElement, Props>(
  (
    {
      data,
      handleChange,
      defaultViewPort = {
        x: innerWidth > 1500 ? 100 : 150,
        y: 0,
        zoom: innerWidth > 1500 ? 0.9 : 0.8,
      },
      toPrint = false,
    },
    ref,
  ) => {
    const hideEmployees = useRecoilValue(hideEmployeesAtom);

    if (!data || !data.graph) {
      return (
        <div className="h-full w-full px-[1%]">
          <div className="flex h-full w-full flex-col items-center justify-center rounded-md">
            <img src={ErrorIcon} alt="Ícone de erro" className="w-24" />
            <div className="flex flex-col items-center text-center">
              Por favor, adicione um filtro válido!
            </div>
          </div>
        </div>
      );
    }

    const nodeWidth = 165;
    const nodeHeight = 155;
    const nodeSmallHeigth = 72;

    const sortByParent = (
      data: (PositionWithAllocation | Position)[][],
    ): (PositionWithAllocation | Position)[][] => {
      return data.map((array) => {
        const parentMap = new Map<
          string,
          (PositionWithAllocation | Position)[]
        >();
        array.forEach((item) => {
          const { reportsTo } = item;
          const key = reportsTo
            .slice()
            .sort((a, b) => a.id - b.id)
            .map((report) => report.id)
            .join(',');

          if (!parentMap.has(key)) {
            parentMap.set(key, []);
          }
          const items = parentMap.get(key);
          if (items) {
            items.push(item);
          }
        });
        const sortedKeys = Array.from(parentMap.keys()).sort();
        const sortedArray = sortedKeys.flatMap((key) => {
          const items = parentMap.get(key);
          return items || [];
        });

        return sortedArray;
      });
    };

    const reorderAssessorData = (
      data: (PositionWithAllocation | Position)[][],
    ) => {
      const result = [];

      for (const innerArray of data) {
        const assessors = innerArray.filter((item) => item.isIntermediary);
        const nonAssessors = innerArray.filter((item) => !item.isIntermediary);

        if (assessors.length > 0) {
          result.push(assessors);
        }
        if (nonAssessors.length > 0) {
          result.push(nonAssessors);
        }
      }

      return result;
    };

    const splitIntoChunks = <T,>(array: T[], size: number): T[][] => {
      const chunks: T[][] = [];
      for (let i = 0; i < array.length; i += size) {
        chunks.push(array.slice(i, i + size));
      }
      return chunks;
    };

    const processData = (data: (PositionWithAllocation | Position)[][]) => {
      const result = [];

      for (const innerArray of data) {
        if (innerArray.some((item) => item.isIntermediary)) {
          result.push(
            ...splitIntoChunks(
              innerArray.filter((item) => item.isIntermediary),
              2,
            ),
          );
        } else {
          result.push(innerArray);
        }
      }

      return result;
    };

    let newData = data.graph.map((item) => item.positions);
    newData = sortByParent(newData);
    newData = reorderAssessorData(newData);
    newData = processData(newData);

    const itemsPerRow =
      newData[newData.length - 1].length > 15
        ? 8
        : newData[newData.length - 1].length > 7
          ? 6
          : newData[newData.length - 1].length;

    const spacing = 60;
    const totalParents = newData.filter((el) => el[0]?.isParent).length;
    const initialY = 50;
    let parentsPerRow = 0;
    const initialNodes = newData
      .map((el, level) => {
        let initialX: number;
        let x;
        if (level === newData.length - 1 && el.length > itemsPerRow) {
          const x = [];
          for (let i = 0; i < Math.ceil(el.length / itemsPerRow); i++) {
            const group = el.slice(i * itemsPerRow, (i + 1) * itemsPerRow);
            const groupLength =
              i === Math.ceil(el.length / itemsPerRow) - 1
                ? group.length
                : itemsPerRow;

            initialX =
              (innerWidth - 142) / 2 -
              (groupLength * nodeWidth + (groupLength - 1) * spacing) / 2;

            x.push(
              ...group.map((item, idx) => ({
                id: item.id.toString(),
                position: {
                  x: initialX + idx * (nodeWidth + spacing),
                  y:
                    initialY +
                    (level + i) * (nodeHeight + spacing) -
                    totalParents * (nodeHeight - nodeSmallHeigth),
                },
                data: { ...item, active: true, hideEmployees, handleChange },
                type: 'custom',
              })),
            );
          }
          return x;
        } else {
          initialX =
            (innerWidth - 142) / 2 -
            (el.length * nodeWidth + (el.length - 1) * spacing) / 2;

          x = el.map((e, idx) => {
            const hasChildren =
              level < newData.length - 1 &&
              newData[level + 1].filter((child) =>
                child.reportsTo.some((report) => report.id === el[0].id),
              ).length > 0;
            return {
              id: e.id.toString(),
              position: {
                x:
                  initialX +
                  idx * (nodeWidth + spacing) -
                  (el.length === 1 && el[0].isIntermediary && !hasChildren
                    ? 110
                    : 0),
                y:
                  initialY +
                  level * (nodeHeight + spacing) -
                  parentsPerRow * (nodeHeight - nodeSmallHeigth),
              },
              data: {
                ...e,
                active: true,
                handleChange,
                hideEmployees,
                isOdd: idx % 2 !== 0,
                hasChildren,
              },
              type: 'custom',
            };
          });
        }
        if (newData[level][0].isParent) {
          parentsPerRow++;
        }
        return x;
      })
      .flat();

    const initialEdges = initialNodes
      .flatMap(({ data: el }) => {
        if (el.reportsTo.length > 0) {
          return el.reportsTo.map(
            (reportsToId: { id: { toString: () => string } }) => ({
              id: `e${el.id}-${reportsToId.id}`,
              source: reportsToId.id.toString(),
              target: el.id.toString(),
              type: 'custom',
              data: { ...el, spacing },
            }),
          );
        } else {
          return [
            {
              id: `e${el.id}`,
              source: '',
              target: el.id.toString(),
              type: 'custom',
              data: { ...el, spacing },
            },
          ];
        }
      })
      .filter((el) => el.source !== '');

    if (toPrint) {
      const levels =
        newData.length -
        1 +
        Math.ceil(newData[newData.length - 1].length / itemsPerRow);
      if (levels > 6) {
        defaultViewPort.zoom = 0.55;
        defaultViewPort.x = defaultViewPort.x + innerWidth > 1500 ? 550 : 690;
      } else if (levels > 5) {
        defaultViewPort.zoom = 0.65;
        defaultViewPort.x = defaultViewPort.x + innerWidth > 1500 ? 500 : 725;
      } else if (levels > 4 || itemsPerRow > 7) {
        defaultViewPort.zoom = 0.8;
        defaultViewPort.x = defaultViewPort.x + 135;
      }
    }
    return (
      <ReactFlow
        nodes={initialNodes}
        edges={initialEdges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        ref={ref}
        proOptions={{ hideAttribution: true }}
        defaultViewport={defaultViewPort}
      />
    );
  },
);

ChartGraph.displayName = 'ChartGraph';
export default ChartGraph;
