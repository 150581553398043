import { useState } from 'react';

import { Button } from '../atoms/Button';
import FixedAllocationModal from './FixedAllocationModal';

const CreateFixedAllocation = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="flex">
      <Button
        className="h-7 rounded-full text-xs font-medium leading-5 text-white ring-0"
        onClick={() => setIsOpen(true)}
      >
        + Colaborador com alocação fixa
      </Button>
      {isOpen && (
        <FixedAllocationModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      )}
    </div>
  );
};
export default CreateFixedAllocation;
