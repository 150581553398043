export const formatDate = (date: Date) => {
  const dia = String(date.getDate()).padStart(2, '0');
  const mes = String(date.getMonth() + 1).padStart(2, '0');
  const ano = date.getFullYear();

  return `${ano}-${mes}-${dia}`;
};

export const formatDateFromSlashToDash = (dateString: string) => {
  const [dia, mes, ano] = dateString
    .split('/')
    .map((it) => it.padStart(2, '0'));

  return `${ano}-${mes}-${dia}`;
};

export const dateInUTCFormat = (dateString: string) => {
  const [ano, mes, dia] = dateString.split('-').map((it) => parseInt(it));
  return new Date(ano, mes - 1, dia);
};

export function removeTimeZone(date: Date) {
  return new Date(date.toISOString().split('T')[0] + 'T00:00:00');
}

export function removeTimeZoneFromString(date: string) {
  if (!date) return '';

  return date.split('T')[0];
}

export const isYear2099 = (date: Date) => date.getFullYear() === 2099;
