import InfoIcon from '@mui/icons-material/Info';
import { IconButton } from '@mui/material';

import LightTooltip from './LightTooltip';

interface Props {
  motive: string;
  className?: string;
}

const AvailabilityTooltipIcon = ({ motive }: Props) => {
  if (!motive) {
    return <></>;
  }
  return (
    <LightTooltip
      title={
        <div
          style={{
            whiteSpace: 'pre-line',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            padding: '8px',
          }}
        >
          <div>{motive}</div>
        </div>
      }
      placement="top"
      arrow
    >
      <IconButton
        sx={{ height: '2rem', width: '2rem' }}
        aria-label="information"
        size="small"
        color="error"
      >
        <InfoIcon fontSize="inherit" />
      </IconButton>
    </LightTooltip>
  );
};

export default AvailabilityTooltipIcon;
