import { Dialog, Transition } from '@headlessui/react';
import { Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { addDays, format } from 'date-fns';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';

import { trackEvent } from '@/utils/trackEvent';

import { queryClient } from '../../App';
import { PermissionMessages } from '../../constants/SGFTUserTypes';
import api from '../../services/apiSgft';
import { actualUserAtom } from '../../state/ActualUser.atom';
import {
    CoverageStructure,
    ModalRequest,
    ModalResponse,
    QualifyingPeriod,
    Scales,
    VacationDataResponse,
    VacationPeriod,
    VacationScales,
    WorkingDays,
} from '../../types/VacationRegister';
import { dateInUTCFormat, removeTimeZone } from '../../utils/formatDate';
import {
    userCanSave,
    userIsRhOrAdmin,
} from '../../utils/handleSavePermissions';
import { Button } from '../atoms/Button';
import CloseButton from '../atoms/CloseButton';
import ControlledCalendar from '../atoms/ControlledCalendar';
import ControlledCheckbox from '../atoms/ControlledCheckbox';
import ControlledSimpleSelect from '../atoms/ControlledSimpleSelect';
import SimpleSelect from '../atoms/SimpleSelect';
import Spinner from '../atoms/Spinner';
import SubtitleText from '../atoms/SubtitleText';
import ModalConfirmation from '../molecules/ConfirmationModal';
import ExpandableSubtitle from '../molecules/ExpandableSubtitle';
import VacationCoverPlan from '../molecules/VacationCoverPlan';

interface Props {
  employeeId: number;
  vacationId: number;
  modalIsOpen: boolean;
  handleClose: () => void;
}

export default function VacationRegisterModal({
  employeeId,
  vacationId,
  handleClose,
  modalIsOpen,
}: Props) {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const actualUser = useRecoilValue(actualUserAtom);
  const canSaveOutOfPolicy = userIsRhOrAdmin();
  const [request, setRequest] = useState<ModalRequest | undefined>(undefined);
  const [periods, setPeriods] = useState<Scales | null>();
  const [calendar, setCalendar] = useState(false);
  const [allowedStartDates, setAllowedStartDates] = useState<
    Record<number, string[]>
  >({});
  const [selectedQualifyingPeriod, setSelectedQualifyingPeriod] = useState<
    number | null
  >(null);
  const [isFetchingVacation, setIsFetchingVacation] = useState(false);
  const [employeeDeallocation, setEmployeeDeallocation] = useState<Array<{
    days: Array<string>;
    coverageTeamName: string;
    coverageEmployeeName: string;
    teamName: string;
    employeeName: string;
  }> | null>(null);
  const isEdit = request?.vacation?.id;
  const minimumDate = addDays(new Date(), 35);

  const fetchVacationData = async (vacationId: number) => {
    setIsFetchingVacation(true);
    try {
      const response = await api.get<VacationDataResponse>(
        `/vacation/data?vacationId=${vacationId}`,
      );
      const { data } = response;
      if (data) {
        const vacationPeriods = data.vacation?.vacationPeriods ?? [];
        const vacationTypeId = Number(data.vacation?.vacationTypeId);
        const newPeriods = completePeriods(
          [...vacationPeriods],
          data.vacationScales,
          vacationTypeId,
        );
        methods.reset({
          employeeId: data?.id,
          totalVacationDays: data?.vacation?.totalVacationDays,
          outOfPolicy: data?.vacation?.outOfPolicy ?? false,
          vacationScale: data?.vacation?.vacationTypeId,
          vacationPeriods: newPeriods,
        });
        setRequest(data);
      }
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
    setIsFetchingVacation(false);
  };

  const completePeriods = (
    vacationPeriods: Array<VacationPeriod>,
    vacationScales: Array<VacationScales>,
    vacationTypeId: number,
  ) => {
    const scale = vacationScales
      .flatMap((item) => item.scales)
      .find((scale) => Number(scale.id) === vacationTypeId);
    if (scale) {
      const daysOfPeriods = [
        scale.firstPeriod,
        scale.secondPeriod,
        scale.thirdPeriod,
      ].filter((val) => val);
      const newPeriods = [null, null, null] as any[];
      daysOfPeriods.forEach((days, index) => {
        const indexOfPeriod = vacationPeriods.findIndex(
          (it) => it.totalDays === days,
        );
        if (indexOfPeriod >= 0) {
          newPeriods[index] = vacationPeriods[indexOfPeriod];
          vacationPeriods.splice(indexOfPeriod, 1);
        } else {
          newPeriods[index] = {
            totalDays: days,
            startDate: null,
            endDate: null,
            vacationCovers: [],
          };
        }
      });
      return newPeriods;
    }
    return vacationPeriods;
  };

  const fetchQualifyingPeriods = async () => {
    try {
      const response = await api.get<QualifyingPeriod[]>(
        `/vacation/qualifyingPeriods?employeeId=${employeeId}`,
      );
      return response.data.map((qualifyingPeriod) => ({
        label: qualifyingPeriod.name,
        value: qualifyingPeriod.value,
      }));
    } catch (e) {
      toast.error('Erro ao carregar os dados do período aquisitivo', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const { data: qualifyingPeriods } = useQuery(
    ['vacationQualifyingPeriods', employeeId],
    fetchQualifyingPeriods,
    {
      retry: false,
      refetchOnMount: false,
    },
  );

  const fetchAvailableTeamStructures = async () => {
    const response = await api.get<CoverageStructure[]>(
      '/vacation/coverage/availableStructures',
      {
        params: {
          employeeId,
        },
      },
    );

    return response.data;
  };

  const { data: structures } = useQuery(
    ['coverageAvailableStructures', employeeId],
    fetchAvailableTeamStructures,
    {
      retry: false,
      refetchOnMount: false,
    },
  );

  const methods = useForm<ModalResponse>();

  useEffect(() => {
    if (qualifyingPeriods && vacationId !== 0) {
      const filterQualifying = qualifyingPeriods.filter(
        (d: { value: number }) => d.value === vacationId,
      ).length;
      if (filterQualifying !== 0) {
        handleSelectQualifyingPeriod(vacationId);
      }
    }
  }, [qualifyingPeriods]);

  const handleSelectQualifyingPeriod = (vacationId: number) => {
    setRequest(undefined);
    setSelectedQualifyingPeriod(vacationId);
    fetchVacationData(vacationId);
    methods.reset({
      outOfPolicy: request?.vacation?.outOfPolicy ?? false,
    });
    setPeriods(null);
  };
  const fetchVacationCover = async (
    startDate: string,
    endDate: string,
  ): Promise<{ startDate: Date; endDate: Date }[]> => {
    try {
      const response = await api.get<WorkingDays>(
        `/vacation/workDays?startDate=${startDate}&endDate=${endDate}&employeeId=${employeeId}`,
      );
      return response.data.workDaysPeriods;
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const totalDaysWatch = methods.watch('totalVacationDays', request?.totalDays);
  const vacationScaleWatch = methods.watch(
    'vacationScale',
    request?.vacationScale,
  );
  const vacationPeriodsWatch = methods.watch(
    'vacationPeriods',
    request?.vacation?.vacationPeriods,
  );
  const outOfPolicyWatch = methods.watch(
    'outOfPolicy',
    request?.vacation ? request?.vacation.outOfPolicy : false,
  );

  useEffect(() => {
    if (vacationScaleWatch) {
      getAllowedDates(isEdit ?? '', vacationScaleWatch, outOfPolicyWatch);
    }
  }, [vacationScaleWatch, outOfPolicyWatch]);

  const handleChangeVacationScale = async () => {
    if (!request) return;

    const vacationTypeId = request?.vacation?.vacationTypeId;
    if (vacationScaleWatch === undefined && vacationTypeId) {
      methods.setValue('vacationScale', vacationTypeId);
    }

    const vacationScale = request.vacationScales
      .find((el) => el.totalDays === totalDaysWatch)
      ?.scales.find((el) => el.id === vacationScaleWatch);

    if (vacationScale) {
      setPeriods(vacationScale);
      const scaleNames = vacationScale.name.split('x');
      const newVacationPeriods = scaleNames.map(async (el, index) => {
        let startDate = methods.getValues(`vacationPeriods.${index}.startDate`);
        const getVacationCovers = async () => {
          if (startDate) {
            startDate = moment.utc(startDate).format('yyyy-MM-DD');
            const endDate = format(
              addDays(new Date(startDate), parseInt(el)),
              'yyyy-MM-dd',
            );
            return await fetchVacationCover(startDate, endDate);
          }
          return [];
        };
        const vacationCoversWorkDays = await getVacationCovers();
        return {
          totalDays: parseInt(el),
          startDate: startDate
            ? dateInUTCFormat(startDate.toLocaleString())
            : '',
          endDate: startDate
            ? dateInUTCFormat(
                format(
                  addDays(new Date(startDate), parseInt(el)),
                  'yyyy-MM-dd',
                ),
              )
            : '',
          salaryAdvance:
            request.vacation?.vacationPeriods[index]?.salaryAdvance ?? false,
          vacationCovers:
            request.vacation?.vacationPeriods[index]?.vacationCovers ?? [],
          vacationCoversWorkDays,
        } as VacationPeriod;
      });

      methods.setValue(
        'vacationPeriods',
        await Promise.all(newVacationPeriods),
      );
      methods.setValue('vacationTypeId', vacationScaleWatch);
    }
  };

  useEffect(() => {
    handleChangeVacationScale();
  }, [request, vacationScaleWatch, methods, totalDaysWatch]);

  async function getAllowedDates(
    vacationId: string,
    vacationTypeId: string,
    outOfPolicy: boolean,
  ) {
    try {
      const response = await api.get(`/vacation/allowedVacationDates`, {
        params: {
          vacationId,
          vacationTypeId,
          outOfPolicy,
        },
      });

      setAllowedStartDates(response.data.allowedStartDates);
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  }

  function getScales(totalDays: number): { value: string; label: string }[] {
    if (request) {
      const vacationScales = request?.vacationScales.filter(
        (el) => el.totalDays === totalDays,
      );

      if (request.isADM || request.isDoctor) {
        const scales = vacationScales![0].scales.map((el) => ({
          value: el.id,
          label: el.name,
        }));
        return scales;
      }

      if (outOfPolicyWatch) {
        const scales = vacationScales![0].scales.map((el) => ({
          value: el.id,
          label: el.name,
        }));

        return scales;
      }

      const scales = vacationScales![0].scales
        .filter((el) => el.outOfPolicy !== outOfPolicyWatch)
        .map((el) => ({
          value: el.id,
          label: el.name,
        }));

      return scales;
    }

    return [{ value: '0', label: '' }];
  }

  function checkIfScaleIsOutOfPolicy() {
    if (request) {
      const vacationScales = request?.vacationScales.filter(
        (el) => el.totalDays === totalDaysWatch,
      );
      const onPolicyScales = vacationScales![0].scales.filter(
        (el) => el.outOfPolicy === true,
      );
      const isVacationOutOfPolicy = onPolicyScales.some(
        (scale) => scale.id === vacationScaleWatch,
      );

      return !isVacationOutOfPolicy;
    }
    return false;
  }

  function verifyStartDateAllowed(
    dates: {
      totalDays: number;
      startDate: string | null;
    }[],
    allowedDates: Record<string, string[]>,
  ): boolean {
    for (const date of dates) {
      const isAllowed = allowedDates[date.totalDays]?.find((dateString) => {
        const day = removeTimeZone(new Date(dateString)).toDateString();
        return day === date.startDate;
      });
      if (!isAllowed && date.startDate !== null) {
        return false;
      }
    }
    return true;
  }

  function getStartDates(vacationPeriods: VacationPeriod[]) {
    const startDates = vacationPeriods.map((period: VacationPeriod) => {
      const date = period.startDate
        ? removeTimeZone(new Date(period.startDate)).toDateString()
        : null;
      const startDate = {
        totalDays: period.totalDays,
        startDate: date,
      };
      return startDate;
    });
    return startDates;
  }

  function checkIfStartDatesAreAllowed(vacationPeriods: VacationPeriod[]) {
    const startDates = getStartDates(vacationPeriods);
    const isStartDateAllowed = verifyStartDateAllowed(
      startDates,
      allowedStartDates,
    );
    return isStartDateAllowed;
  }

  async function fetchInPolicyDates() {
    try {
      const response = await api.get(`/vacation/allowedVacationDates`, {
        params: {
          vacationId: isEdit ?? '',
          vacationTypeId: vacationScaleWatch,
          outOfPolicy: false,
        },
      });
      return response.data.allowedStartDates;
    } catch {
      toast.error('Erro ao salvar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
    }
  }

  async function checkIfStartDateIsOutOfPolicy(
    vacationPeriods: VacationPeriod[],
  ) {
    const startDates = getStartDates(vacationPeriods);
    const onPolicyStartDates = await fetchInPolicyDates();
    const isStartDateOutOfPolicy = !verifyStartDateAllowed(
      startDates,
      onPolicyStartDates,
    );
    return isStartDateOutOfPolicy;
  }

  function getTotalDays(): { value: number; label: string }[] {
    return [
      { value: 30, label: '30d' },
      { value: 20, label: '20d' },
      { value: 24, label: '24d' },
      { value: 18, label: '18d' },
      { value: 12, label: '12d' },
    ];
  }

  const verifyCoverage = async (modalData: ModalResponse) => {
    setIsSubmiting(true);
    const covers = modalData.vacationPeriods.flatMap(
      (period: any) => period.vacationCovers,
    );
    try {
      const response = await api.post('vacation/verify-coverage', {
        vacationCovers: covers,
        employeeId,
      });
      if (response.data.employeeDeallocation.length) {
        setEmployeeDeallocation(response.data.employeeDeallocation);
      } else {
        setEmployeeDeallocation(null);
        await onSubmit();
      }
    } catch (e) {
      toast.error('Erro ao verificar a cobertura', { theme: 'colored' });
    }
    setIsSubmiting(false);
  };

  function handleVacationPeriodSubmissionData(modalData: ModalResponse) {
    const vacationPeriods = modalData.vacationPeriods.map(
      (period: VacationPeriod) => {
        const totalDays = period.totalDays;
        let endDate = period.endDate;

        if (period.startDate) {
          const startDate = new Date(period.startDate);
          endDate = new Date(startDate);
          endDate.setDate(startDate.getDate() + (totalDays - 1));
        } else {
          period.startDate = null;
          endDate = null;
        }
        return { ...period, endDate };
      },
    );
    return vacationPeriods;
  }

  const onSubmit = async () => {
    const modalData = methods.getValues();
    setEmployeeDeallocation(null);
    if (outOfPolicyWatch && !canSaveOutOfPolicy) {
      toast.error('Somente RH pode salvar férias fora da política', {
        theme: 'colored',
        toastId: 'error',
      });
      return;
    }

    const isStartDateAllowed = checkIfStartDatesAreAllowed(
      modalData.vacationPeriods,
    );
    if (!isStartDateAllowed) {
      toast.error('Data de início invalída', {
        theme: 'colored',
        toastId: 'error',
      });
      return;
    }

    const shiftOutOfPolicy = checkIfScaleIsOutOfPolicy();
    const startDateOufOfPolicy = await checkIfStartDateIsOutOfPolicy(
      modalData.vacationPeriods,
    );

    const vacationPeriods = handleVacationPeriodSubmissionData(modalData);
    const { vacationScale, ...modalDataWithoutVacationScale } = modalData;

    const submissionData = {
      ...modalDataWithoutVacationScale,
      vacationPeriods,
      shiftOutOfPolicy,
      startDateOufOfPolicy,
    };

    modalData.vacationPeriods.forEach((period: VacationPeriod) => {
      if (!period.startDate && period.vacationCovers.length > 0) {
        toast.error('Plano de cobertura sem período de férias', {
          theme: 'colored',
          toastId: 'error',
        });
      }
    });
    const endpoint = isEdit
      ? `/vacation/${request?.vacation?.id}`
      : '/vacation';
    const action = isEdit ? 'editadas' : 'cadastradas';
    const method = isEdit ? 'put' : 'post';
    setIsSubmiting(true);
    let invalid = false;
    submissionData.vacationPeriods.forEach((period: any) => {
      period.vacationCovers.forEach((vacationCover: any) => {
        if (!vacationCover.employeeId && !vacationCover.coverageType) {
          invalid = true;
          toast.error('É preciso preencher o colaborador da cobertura', {
            theme: 'colored',
            toastId: 'coverageEmployee',
          });
        } else if (!vacationCover.startDate) {
          invalid = true;
          toast.error('Data de cobertura não pode estar vazia', {
            theme: 'colored',
            toastId: 'coverageDate',
          });
        }
      });
    });
    if (invalid) {
      setIsSubmiting(false);
      return;
    }
    await api[method](endpoint, submissionData)
      .then(
        () => {
          toast.success(`Férias ${action} com sucesso`, {
            theme: 'colored',
            toastId: 'success',
          });
          queryClient.invalidateQueries({ queryKey: ['team-structure'] });
          queryClient.invalidateQueries({
            queryKey: ['vacationData', employeeId],
          });
          trackEvent(
            submissionData.shiftOutOfPolicy ||
              submissionData.startDateOufOfPolicy
              ? 'Ferias fora da politica'
              : 'Ferias dentro da politica',
            'generate_vacation',
            `${submissionData.totalVacationDays} dias`,
          );
          handleClose();
        },
        (error) => {
          const errorMessage =
            error?.response?.status < 500
              ? error.response?.data?.message
              : 'Não conseguimos processar sua solicitação. Tente novamente mais tarde!';
          toast.error(errorMessage, {
            theme: 'colored',
            toastId: 'error',
          });
        },
      )
      .finally(() => {
        setIsSubmiting(false);
      });
  };

  useEffect(() => {
    methods.reset();
  }, [modalIsOpen]);
  const getReferenceDate = (date: string | undefined) => {
    const today = new Date();
    if (date) {
      return new Date(
        Math.max(dateInUTCFormat(date).valueOf(), today.valueOf()),
      );
    }
    return today;
  };

  const updateSalaryAdvanceCheckboxes = (index: number) => {
    if (methods.getValues(`vacationPeriods.${index}.salaryAdvance`) === true) {
      vacationPeriodsWatch.forEach((el, idx) => {
        if (idx !== index) {
          methods.setValue(`vacationPeriods.${idx}.salaryAdvance`, false);
        }
      });
    }
  };

  const shouldDisableSalaryAdvanceCheckbox = (index: number) => {
    const isRhOrAdmin = userIsRhOrAdmin();
    if (isRhOrAdmin) {
      return false;
    }
    const vacationPeriod = vacationPeriodsWatch[index];
    if (vacationPeriod?.startDate) {
      return minimumDate > vacationPeriod.startDate;
    }
  };

  return (
    <Transition appear show={true}>
      <Dialog className="relative z-50" onClose={handleClose}>
        <div className="fixed inset-0 flex w-screen items-center justify-center bg-black/30 p-4">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="custom-scrollbar max-h-[85vh] w-[50rem] flex-col justify-between overflow-auto overflow-y-scroll rounded-lg bg-white">
              <ModalConfirmation
                isOpen={!!employeeDeallocation}
                title="Desalocação de colaborador"
                description={employeeDeallocation?.map(
                  (deallocation, index) => {
                    if (deallocation.teamName === deallocation.coverageTeamName)
                      return (
                        <p className="py-2" key={index}>
                          O/A ferista {deallocation.coverageEmployeeName} irá
                          cobrir o/a colaborador(a) {deallocation.employeeName}{' '}
                          na turma {deallocation.coverageTeamName} no(s) dia(s){' '}
                          {deallocation.days
                            .map((day) => moment(day).format('DD/MM/YYYY'))
                            .join(', ')}
                          .
                        </p>
                      );
                    else
                      return (
                        <p className="py-2" key={index}>
                          O/A colaborador(a) {deallocation.coverageEmployeeName}{' '}
                          será desalocado/a da turma{' '}
                          {deallocation.coverageTeamName} no(s) dia(s){' '}
                          {deallocation.days
                            .map((day) => moment(day).format('DD/MM/YYYY'))
                            .join(', ')}{' '}
                          para cobrir {deallocation.employeeName} na turma{' '}
                          {deallocation.teamName}.
                        </p>
                      );
                  },
                )}
                confirmAction={onSubmit}
                discartAction={() => setEmployeeDeallocation(null)}
                onClose={() => setEmployeeDeallocation(null)}
              />
              <div className="w-full p-5">
                <div className="flex h-auto items-center justify-between">
                  <div className="flex justify-between">
                    <SubtitleText
                      className="text-[20px]"
                      subtitle={`Planejamento de Férias ${
                        request
                          ? request?.isDoctor
                            ? '- Médicos'
                            : request?.isADM
                              ? '- Regime Administrativo'
                              : '- Regime de Turno'
                          : ''
                      }`}
                    />
                  </div>
                  <CloseButton onClick={handleClose} className="mt-[-1rem]" />
                </div>
                <SimpleSelect
                  width="45rem"
                  title="Período aquisitivo"
                  values={qualifyingPeriods ?? []}
                  value={selectedQualifyingPeriod}
                  setState={(value) =>
                    handleSelectQualifyingPeriod(value as number)
                  }
                />
              </div>
              {request && (
                <FormProvider {...methods}>
                  <form
                    className="space-between flex h-[90%] flex-col pt-2 "
                    onSubmit={methods.handleSubmit(verifyCoverage)}
                  >
                    <div className="p-5 pt-0">
                      <div className="mb-2 flex w-[45rem] justify-between">
                        <ControlledSimpleSelect
                          name="employeeId"
                          width="23rem"
                          title={'Nome'}
                          control={methods.control}
                          values={[
                            {
                              value: request?.id,
                              label: request?.name,
                            },
                          ]}
                        />
                        <ControlledSimpleSelect
                          width="9.5rem"
                          title={'Total de férias'}
                          control={methods.control}
                          name="totalVacationDays"
                          values={getTotalDays()}
                        />
                        <ControlledSimpleSelect
                          defaultValue={request?.vacation?.vacationTypeId ?? ''}
                          name="vacationScale"
                          width="9.5rem"
                          control={methods.control}
                          title={'Formato de férias'}
                          values={getScales(totalDaysWatch)}
                        />
                      </div>
                      {!(request.isADM || request.isDoctor) && (
                        <ControlledCheckbox
                          title={'Férias fora da política'}
                          name="outOfPolicy"
                          disabled={!canSaveOutOfPolicy}
                          control={methods.control}
                        />
                      )}
                      {periods && (
                        <div>
                          {periods.name
                            .split('x')
                            .map((el: string, index: number) => {
                              return (
                                <ExpandableSubtitle
                                  key={index}
                                  subtitle={`Período de ${el} dias`}
                                >
                                  <>
                                    <div className="mb-2 flex w-[45rem] justify-between">
                                      <ControlledCalendar
                                        referenceDate={getReferenceDate(
                                          allowedStartDates[parseInt(el)]?.at(
                                            0,
                                          ),
                                        )}
                                        width="22rem"
                                        title={'Data de início'}
                                        control={methods.control}
                                        name={`vacationPeriods.${index}.startDate`}
                                        onChange={async (e) => {
                                          if (e) {
                                            const endDateNumber = addDays(
                                              new Date(e),
                                              parseInt(el) - 1,
                                            );

                                            const endDate = format(
                                              endDateNumber,
                                              'yyyy-MM-dd',
                                            );

                                            methods.setValue(
                                              `vacationPeriods.${index}.endDate`,
                                              dateInUTCFormat(endDate),
                                            );
                                            const startDate = format(
                                              e,
                                              'yyyy-MM-dd',
                                            );
                                            const data =
                                              await fetchVacationCover(
                                                startDate,
                                                endDate,
                                              );
                                            methods.setValue(
                                              `vacationPeriods.${index}.vacationCoversWorkDays`,
                                              data,
                                            );
                                          } else {
                                            methods.setValue(
                                              `vacationPeriods.${index}.endDate`,
                                              new Date(''),
                                            );
                                            methods.setValue(
                                              `vacationPeriods.${index}.vacationCoversWorkDays`,
                                              [],
                                            );
                                          }
                                        }}
                                        setCalendar={setCalendar}
                                        shouldDisableDate={(day: Date) =>
                                          !verifyStartDateAllowed(
                                            [
                                              {
                                                startDate:
                                                  removeTimeZone(
                                                    day,
                                                  ).toDateString(),
                                                totalDays: parseInt(el),
                                              },
                                            ],
                                            allowedStartDates,
                                          )
                                        }
                                      />
                                      <ControlledCalendar
                                        width="22rem"
                                        disabled
                                        title={'Data final'}
                                        name={`vacationPeriods.${index}.endDate`}
                                        control={methods.control}
                                      />
                                    </div>
                                    <div className="mb-5">
                                      <ControlledCheckbox
                                        title={'Adiantamento do 13º'}
                                        name={`vacationPeriods.${index}.salaryAdvance`}
                                        control={methods.control}
                                        onChange={() =>
                                          updateSalaryAdvanceCheckboxes(index)
                                        }
                                        disabled={shouldDisableSalaryAdvanceCheckbox(
                                          index,
                                        )}
                                      />
                                    </div>
                                    {!(request.isADM || request.isDoctor) && (
                                      <VacationCoverPlan
                                        index={index}
                                        employees={request?.coverageEmployees}
                                        vacationCovers={
                                          vacationPeriodsWatch?.[index]
                                            ?.vacationCoversWorkDays
                                        }
                                        structures={structures}
                                      />
                                    )}
                                  </>
                                </ExpandableSubtitle>
                              );
                            })}
                        </div>
                      )}
                    </div>
                    <div className={calendar ? 'h-40' : ''}></div>
                    <div className="flex justify-between p-5">
                      <Button
                        type="button"
                        variant="outline"
                        className="min-w-40 "
                        onClick={handleClose}
                      >
                        Cancelar
                      </Button>
                      <Tooltip
                        title={
                          outOfPolicyWatch && !canSaveOutOfPolicy
                            ? PermissionMessages.OutOfPolicyNoPermission
                            : PermissionMessages.NoPermission
                        }
                        disableHoverListener={
                          outOfPolicyWatch && !canSaveOutOfPolicy
                            ? !(outOfPolicyWatch && !canSaveOutOfPolicy)
                            : userCanSave(
                                request?.managementId,
                                actualUser.management_id,
                              )
                        }
                      >
                        <span>
                          <Button
                            type="submit"
                            isLoading={isSubmiting}
                            className="min-w-40 "
                            disabled={
                              outOfPolicyWatch && !canSaveOutOfPolicy
                                ? outOfPolicyWatch && !canSaveOutOfPolicy
                                : !userCanSave(
                                    request?.managementId,
                                    actualUser.management_id,
                                  )
                            }
                          >
                            Confirmar
                          </Button>
                        </span>
                      </Tooltip>
                    </div>
                  </form>
                </FormProvider>
              )}
              {isFetchingVacation && (
                <div className="flex h-40 w-full items-center">
                  <Spinner size={50} />
                </div>
              )}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
