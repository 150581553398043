import { ColumnDef } from '@tanstack/react-table';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';

import { queryClient } from '@/App';
import EditButton from '@/components/atoms/EditButton';
import TrashButton from '@/components/atoms/TrashButton';
import ModalConfirmation from '@/components/molecules/ConfirmationModal';
import api from '@/services/apiSgft';
import { chartAtom } from '@/state/ChartFilter.atom';
import { FixedAllocationData } from '@/types/FixedAllocation';

import TableCellDate from '../../components/atoms/TableCellDate';
import TableCellText from '../../components/atoms/TableCellText';
import { removeTimeZone } from '../../utils/formatDate';

export const columns: Array<ColumnDef<FixedAllocationData>> = [
  {
    accessorFn: (row) => row.employee.name,
    meta: {
      headerClassName: 'sticky z-10 pl-2 bg-white text-start',
      stickyClassName: 'sticky z-10 bg-inherit text-start px-2',
    },
    header: 'Nome',
    cell: (value) => {
      return (
        <div className="w-40">
          <TableCellText
            text={value.getValue() as string}
            isName
            className="w-40"
          />
        </div>
      );
    },
  },
  {
    accessorFn: (row) => row.employee.thirdParty,
    meta: {
      headerClassName: 'sticky z-10 pl-2 bg-white text-start',
      stickyClassName: 'sticky z-10 bg-inherit text-start px-2',
    },
    header: 'Tipo de Vínculo',
    cell: (value) => {
      return (
        <div className="w-40 uppercase">
          <TableCellText
            text={value.getValue() ? 'Terceiro' : 'Origem Energia'}
          />
        </div>
      );
    },
  },
  {
    accessorFn: (row) => row?.position?.name,
    meta: {
      headerClassName: 'sticky z-10 pl-2 bg-white text-start',
      stickyClassName: 'sticky z-10 bg-inherit text-start px-2',
    },
    header: 'Posição Fixa',
    cell: (value) => {
      return (
        <div className="min-w-[20rem]">
          <TableCellText
            text={value.getValue() as string}
            className="w-full min-w-[20rem]"
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'updatedAt',
    meta: {
      headerClassName: 'sticky z-10 pl-2 bg-white text-start',
      stickyClassName: 'sticky z-10 bg-inherit text-start px-2',
    },
    header: 'Data de Atualização',
    cell: (value) => {
      return (
        <div className="w-28">
          <TableCellDate
            date={
              (value.getValue() as string)
                ? removeTimeZone(new Date(value.getValue() as string))
                : undefined
            }
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'actions',
    meta: {
      headerClassName: 'sticky z-10 pl-2 bg-white text-start',
      stickyClassName: 'sticky z-10 bg-inherit text-start px-2',
    },
    header: '',
    cell: (props) => {
      const navigate = useNavigate();
      const [chartFilters, setCharFilters] = useRecoilState(chartAtom);
      const [isOpen, setIsOpen] = useState(false);

      const goToFixedAllocation = () => {
        const newChartFilter = {
          ...chartFilters,
          employeeFilter: props.row.original.position?.id
            ? props.row.original.employee.id
            : undefined,
          positionFilter: props.row.original?.position?.id,
        };
        setCharFilters(newChartFilter);
        navigate('/home/visualizar-estrutura-organograma');
      };
      const removeFixedAllocation = async () => {
        try {
          await api.delete('position/remove-fixed-position-allocation', {
            data: { id: props.row.original.id },
          });
          toast.success('alocação fixa excluída com sucesso!', {
            theme: 'colored',
            toastId: 'error',
          });
          queryClient.invalidateQueries(['fixed-allocation']);
          queryClient.invalidateQueries(['employees-chart']);
          queryClient.invalidateQueries(['roles-chart']);

          setIsOpen(false);
        } catch (e: any) {
          toast.error(
            'Erro ao excluir alocação fixa. Tente novamente mais tarde!',
            {
              theme: 'colored',
              toastId: 'error',
            },
          );
          throw e;
        }
      };

      return (
        <div className="flex w-8 justify-end">
          <EditButton color="primary" onClick={goToFixedAllocation} />
          <TrashButton color="primary" onClick={() => setIsOpen(true)} />
          <ModalConfirmation
            title={`Deseja excluir alocação fixa?`}
            description={`A alocação do colaborador ${props.row.original.employee.name} será removida!`}
            confirmAction={removeFixedAllocation}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            discartAction={() => setIsOpen(false)}
            containerClassName="w-[30rem]"
          />
        </div>
      );
    },
  },
];
