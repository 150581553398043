interface Props {
  title: string;
  text: string;
  className?: string;
}

const FixedTextField = ({ title, text, className }: Props) => {
  return (
    <div
      aria-labelledby={`${title}-label`}
      className={`mb-1 flex h-[2.5rem]  flex-col justify-between font-graphie text-[10px] font-normal tracking-wider text-[#A1A1A1] ${className}`}
    >
      <div>{title}</div>
      <div
        className={`flex h-[1.5rem] items-center rounded bg-gray-100 px-2 text-[11px] text-[#5A5A5A] ${className}`}
      >
        {text}
      </div>
    </div>
  );
};

export default FixedTextField;
