/* eslint-disable no-unused-vars */
import { Tooltip } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { PermissionMessages } from '@/constants/SGFTUserTypes';
import { extraordinaryAllocationColumns } from '@/constants/tableColumns/TrainingPlanningTableColumns';
import { OnlineTrainingType } from '@/constants/TrainingConstants';
import { TrainingDay } from '@/types/TrainingPlanning';
import { TrainingPlanningEmployee } from '@/types/TrainingPlanningEmployee';
import { TrainingPlanningForm } from '@/types/TrainingPlanningForm';

import api from '../../services/apiSgft';
import { Button } from '../atoms/Button';
import { ControlledJustificationDialog } from '../atoms/ControlledJustificationDialog';
import SearchInput from '../molecules/SearchInput';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog';
import InfiniteTable from './InfiniteTable';

interface ExtraordinaryAllocationModalProps {
  isOpen: boolean;
  training: string;
  trainingDays: TrainingDay[];
  modality: string;
  onClose: () => void;
  trainingPlanning: TrainingPlanningForm;
  setTrainingPlanning: (a: TrainingPlanningForm) => void;
  trainingPlanningId?: string;
  onlineTrainingType?: string;
  isThirdParty?: boolean;
}

const ExtraordinaryAllocationModal: React.FC<
  ExtraordinaryAllocationModalProps
> = ({
  isOpen,
  training,
  trainingDays,
  modality,
  trainingPlanning,
  setTrainingPlanning,
  onClose,
  onlineTrainingType,
  trainingPlanningId,
}) => {
  const methods = useForm();
  const { watch } = methods;

  const [searchedEmployeeName, setSearchedEmployeeName] = useState('');
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const asyncTraining = onlineTrainingType === OnlineTrainingType.Async;
  const canSave: boolean | undefined = !!trainingPlanning.selectedEmployee.some(
    (el) => el.isExtraordinaryAllocation,
  );

  const fetchEmployees = async ({
    pageParam = 1,
  }): Promise<{
    employees: TrainingPlanningEmployee[];
    totalResults: number;
    nextPage: number | null;
  }> => {
    try {
      const response = await api.get(
        `training-plannings/trainings/${training}/extra-allocations`,
        {
          params: {
            size: 6,
            page: pageParam,
            employee: searchedEmployeeName,
            trainingDays: trainingDays || [],
            trainingPlanningId,
            modality,
            alreadySelectedEmployees: trainingPlanning.selectedEmployee.map(
              (el) => el.employee.employeeNumber,
            ),
            isThirdParty: false,
            onlineTrainingType,
          },
        },
      );
      return response.data;
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const onSubmit = () => {
    setIsConfirmationOpen(true);
  };

  const handleCreate = async () => {
    const justification = methods.getValues('justification');
    const index = trainingPlanning.selectedEmployee.findIndex(
      (el) =>
        el.employee.employeeNumber ===
        trainingPlanning.extraordinaryAllocationId,
    );

    if (index !== -1) {
      const updatedEmployee = {
        ...trainingPlanning.selectedEmployee[index],
        extraordinaryAllocationMotive: justification,
      };

      const updatedSelectedEmployee = [
        ...trainingPlanning.selectedEmployee.slice(0, index),
        updatedEmployee,
        ...trainingPlanning.selectedEmployee.slice(index + 1),
      ];

      setTrainingPlanning({
        selectedEmployee: updatedSelectedEmployee,
        isAllEmployeeSelected: trainingPlanning.isAllEmployeeSelected,
        trainingDate: trainingPlanning.trainingDate,
        extraordinaryAllocationId: trainingPlanning.extraordinaryAllocationId,
      });

      methods.reset({
        justification: '',
      });
      setSearchedEmployeeName('');
      setIsConfirmationOpen(false);
      onClose();
    }
  };

  const handleClose = () => {
    const updatedSelectedEmployee = trainingPlanning.selectedEmployee.filter(
      (el) =>
        !el.isExtraordinaryAllocation ||
        (el.isExtraordinaryAllocation &&
          el.extraordinaryAllocationMotive !== ''),
    );

    setTrainingPlanning({
      selectedEmployee: updatedSelectedEmployee,
      extraordinaryAllocationId: '',
      isAllEmployeeSelected: false,
      trainingDate: trainingPlanning.trainingDate,
    });

    setSearchedEmployeeName('');
    methods.reset();
    setIsConfirmationOpen(false);
    onClose();
  };

  const handleInputChange = (name: string) => {
    setSearchedEmployeeName(name);
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (
        name === 'employeeName' &&
        type === 'change' &&
        typeof value?.employeeName === 'string'
      ) {
        handleInputChange(value?.employeeName);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const {
    data: employees,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['training-planning-employee-extraoridinary', searchedEmployeeName],
    fetchEmployees,
    {
      retry: false,
      getNextPageParam: (actualPage) => {
        return actualPage.nextPage;
      },
      enabled: isOpen,
    },
  );

  const employeesData =
    employees?.pages.flatMap((page) => page.employees) ?? [];
  const justification = methods.watch('justification');

  return (
    <div>
      <Dialog open={isOpen} onOpenChange={(open) => !open && handleClose?.()}>
        <DialogContent className="max-h-[85vh] w-[90vw] max-w-[2000px] font-graphie text-[12px] font-normal text-[#4A4A4A]">
          <DialogHeader>
            <DialogTitle className="text-xl font-semibold text-primary">
              Adicionar Alocação Extraordinária
            </DialogTitle>
          </DialogHeader>
          <div className="flex w-full justify-center">
            <div className="mb-4 h-px min-h-px w-full bg-[#193CB980]" />
          </div>
          <div className="custom-scrollbar max-h-[85vh] w-full flex-col justify-between overflow-auto overflow-y-scroll rounded-lg ">
            <div className="flex h-[600px] max-h-[52vh] flex-col items-start gap-2 pt-1">
              <div className="w-full px-4">
                <SearchInput
                  placeholder="Pesquisar por colaborador"
                  autoFocus
                  {...methods.register('employeeName')}
                />
              </div>
              {employeesData && employeesData.length > 0 && (
                <InfiniteTable
                  hasNextPage={hasNextPage}
                  data={employeesData}
                  columns={extraordinaryAllocationColumns(
                    trainingPlanning,
                    setTrainingPlanning,
                  )}
                  fetchNextPage={fetchNextPage}
                  isFetchingNextPage={isFetchingNextPage}
                  tableContainerClassName="min-h-32"
                  columnVisibility={{
                    availability: !asyncTraining,
                    trainings: !asyncTraining,
                    nearestLanding: !asyncTraining,
                    nearestBoarding: !asyncTraining,
                    statusInDay: !asyncTraining,
                  }}
                />
              )}
            </div>
          </div>
          <div className="flex justify-between">
            <DialogClose>
              <Button
                type="button"
                variant="outline"
                className="mb-5 min-w-36"
                onClick={handleClose}
              >
                Cancelar
              </Button>
            </DialogClose>
            <div>
              <Tooltip
                title={PermissionMessages.NoPermission}
                disableHoverListener={canSave}
              >
                <Button
                  type="button"
                  disabled={!canSave}
                  className="mb-5 min-w-36"
                  onClick={onSubmit}
                >
                  Confirmar
                </Button>
              </Tooltip>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <ControlledJustificationDialog
        title={'Confirmação de Alocação Extraordinária'}
        name={'justification'}
        methods={methods}
        onConfirmation={handleCreate}
        disabled={!justification}
        isOpen={isConfirmationOpen}
        description="Para continuar com essa ação de alocação extraordinária, é necessário acrescentar uma justificativa."
        fieldTitle="Justificativa de alteração"
        closeDialog={() => setIsConfirmationOpen(false)}
      />
    </div>
  );
};

export default ExtraordinaryAllocationModal;
