/* eslint-disable no-unused-vars */
export enum Availability {
  Disponivel = 'Disponível',
  Indisponivel = 'Indisponível',
}

export enum Status {
  Folga = 'Folga',
  Trabalhando = 'Trabalhando',
}

export enum TrainingType {
  Regulatorio = 'Regulatório',
  Procedimento = 'Procedimento',
}

export enum Realization {
  Formacao = 'Formação',
  Reciclagem = 'Reciclagem',
}

export enum RedmineStatus {
  SolicitacaoAprovada = 'SOLICITAÇÃO APROVADA',
  SolicitacaoEmAnalise = 'SOLICITAÇÃO EM ANÁLISE',
  SolicitacaoRejeitada = 'SOLICITAÇÃO REJEITADA',
  SolicitacaoCancelada = 'SOLICITAÇÃO CANCELADA',
}
