import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';

import { queryClient } from '@/App';
import api from '@/services/apiSgft';
import { multipleReportsAtom } from '@/state/MultipleReports.atom';
import { reportsPopoverAtom } from '@/state/ReportsPopover.atom';

import { Button } from '../atoms/Button';

const ReportsPopover = () => {
  const [reportsPopover, setReportsPopover] =
    useRecoilState(reportsPopoverAtom);
  const [multipleReports, setMultipleReports] =
    useRecoilState(multipleReportsAtom);

  const submit = async () => {
    try {
      await api.put(`position/update-position-report/${reportsPopover}`, {
        reportToIds: multipleReports.parentsId,
      });
      toast.success('Reporte de posição editado com sucesso!', {
        theme: 'colored',
        toastId: 'success',
      });
      queryClient.invalidateQueries(['employees-chart']);
      queryClient.invalidateQueries(['roles-chart']);
    } catch (error: any) {
      let errorMessage =
        'Erro ao salvar edição de reporte! Tente novamente mais tarde';
      if (error.response.status !== 500) {
        errorMessage = error.response.data.message;
      }
      toast.error(errorMessage, {
        theme: 'colored',
        toastId: 'error',
      });
    }
  };

  return (
    <div className="relative z-10 flex h-full">
      {reportsPopover && (
        <div className="flex h-[12rem] w-[26rem] flex-col items-center justify-around rounded bg-white text-center font-graphie shadow-lg">
          <div className="w-full border-b-[1px] border-b-[#193CB9] p-1 text-[19px] font-semibold text-[#193CB9]">
            Edição de reportes
          </div>
          <div className="w-[85%] text-[14px]">
            Selecione o(s) novo(s) reporte(s) da posição!
          </div>
          <div className="flex w-full justify-between px-4">
            <Button
              type="button"
              className="h-8 min-w-36 border-2 border-[#193CB9] bg-white text-[#193CB9] hover:bg-[#e9eaf1]"
              onClick={() => {
                setMultipleReports(() => ({
                  parentsId: [],
                  positionId: null,
                }));
                setReportsPopover(null);
              }}
            >
              Cancelar
            </Button>
            <Button
              type="button"
              className="h-8 min-w-36"
              disabled={multipleReports.parentsId.length === 0}
              onClick={() => {
                setReportsPopover(null);
                submit();
              }}
            >
              Salvar
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportsPopover;
