import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';

import NoPermissionText from '@/components/atoms/NoPermissionText';

import EmptyIcon from '../../assets/icons/empty.svg';
import { Button } from '../../components/atoms/Button';
import Spinner from '../../components/atoms/Spinner';
import SideFilter from '../../components/molecules/SideFilter';
import SuppliersCards from '../../components/molecules/SuppliersCards';
import SupplierModal from '../../components/organisms/SupplierModal';
import { filters } from '../../constants/filters/SuppliersFilter';
import api from '../../services/apiSgft';
import { suppliersAtom } from '../../state/SuppliersFilter.atom';
import { SupplierRegister } from '../../types/Supplier';
import { userCanSeeTrainingControl } from '../../utils/handleSavePermissions';

const SuppliersView = () => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const filterValues = useRecoilValue(suppliersAtom);
  const [filteredData, setFilteredData] = useState(filterValues);

  const fetchStructures = async (): Promise<SupplierRegister[]> => {
    try {
      const response = await api.get('/supplier', {
        params: {
          suppliers: filteredData.supplier,
          trainings: filteredData.training,
          trainingType: filteredData.trainingType,
        },
      });
      return response.data;
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const { data, isLoading } = useQuery(
    ['suppliers', filteredData],
    fetchStructures,
    {
      retry: false,
    },
  );

  const handleApplyFilter = (isReseting: boolean) => {
    if (isReseting)
      setFilteredData(() => ({
        supplier: [],
        training: [],
        trainingType: [],
      }));
    else {
      setFilteredData(() => ({
        ...filterValues,
      }));
    }
  };
  if (!userCanSeeTrainingControl()) {
    return <NoPermissionText />;
  }
  return (
    <div className="h-full w-full">
      <div className="relative flex h-full max-h-screen w-full flex-col items-start">
        <div className="absolute -top-10 right-20 flex items-center justify-end">
          <Button
            className="mx-5 mt-1 h-7 rounded-[1rem] text-xs font-medium"
            onClick={() => setModalIsOpen(true)}
          >
            Adicionar Fornecedor
          </Button>
        </div>
        <SideFilter
          refetchOnChange
          filters={filters}
          atom={suppliersAtom}
          applyChanges={handleApplyFilter}
        />
        <SupplierModal
          isOpen={modalIsOpen}
          onClose={() => {
            setModalIsOpen(false);
          }}
        />
        {isLoading ? (
          <div className="flex h-[98%] w-full flex-col items-center justify-center rounded-md bg-white">
            <Spinner size={50} />
          </div>
        ) : data && data.length > 0 ? (
          <SuppliersCards cards={data} setModalIsOpen={setModalIsOpen} />
        ) : (
          <div className="flex h-[98%] w-full flex-col items-center justify-center rounded-md bg-white">
            <img src={EmptyIcon} />
            <div className="w-full text-center text-sm">
              <p>Não existem fornecedores cadastrados</p>
              <span className="font-semibold text-blue-800">
                Cadastre um novo fornecedor para visualizar seus dados!
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SuppliersView;
