import { atom } from 'recoil';

import { AllocationEditAtom } from './AllocationEditType.atom';

export const AllocationDataTypeAtom = atom<AllocationEditAtom[]>({
  key: 'allocationData',
  default: [
    {
      id: 0,
      isCurrent: false,
      employee_id: 0,
      end_date: '',
      justification: '',
      management: '',
      start_date: '',
      team: '',
      teamStructure: '',
      team_id: 0,
      isUpdated: false,
      isPast: false,
    },
  ],
});
