import { IconButton } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { MouseEventHandler } from 'react';

interface Props {
  onClick: MouseEventHandler;
}

const ArrowUpButton = ({ onClick }: Props) => {
  return (
    <IconButton
      sx={{ size: '10px', color: '#193CB9' }}
      onClick={onClick}
      aria-label="delete"
    >
      <KeyboardArrowUpIcon />
    </IconButton>
  );
};

export default ArrowUpButton;
