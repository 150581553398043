import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';

import api from '@/services/apiSgft';
import { AllocationEditTypeAtom } from '@/state/AllocationEditType.atom';
import { AllocationTypeAtom } from '@/state/AllocationType.atom';
import { AllocationUpdateDataTypeAtom } from '@/state/UpdateDataType.atom';
import { trackEvent } from '@/utils/trackEvent';

import { removeTimeZone } from '../../utils/formatDate';
import { Button } from '../atoms/Button';
import Line from '../atoms/Line';
import SimpleSelect from '../atoms/SimpleSelect';
import SimpleTextField from '../atoms/SimpleTextField';
import SingleDate from '../atoms/SingleDate';
import SubtitleText from '../atoms/SubtitleText';
import ModalConfirmation from './ConfirmationModal';

interface Props {
  employeeId: number | undefined;
  name: string;
  currentAllocation: any;
  managementList: any;
  data: any;
}

interface Option {
  value: number;
  label: string;
}

const UpdateAllocationCard = ({
  employeeId,
  name,
  currentAllocation,
  managementList,
  data,
}: Props) => {
  const [date, setDate] = useState('');
  const [justification, setJustification] = useState('');
  const [teamId, setTeamId] = useState<string | number>('');
  const [managementId, setManagementId] = useState<string | number>('');
  const [teamStructureId, setTeamStructureId] = useState<string | number>('');
  const [isSavingData, setIsSavingData] = useState(false);
  const [type, setType] = useRecoilState(AllocationTypeAtom);
  const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
  const [editData, setEditData] = useRecoilState(AllocationEditTypeAtom);
  const [, setUpdateData] = useRecoilState(AllocationUpdateDataTypeAtom);

  let managementValues = [];
  let teamStructureValues = [];
  let teams: Option[] = [];
  let management: any;

  const compareString = (a: any, b: any) => {
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  };

  useEffect(() => {
    if (managementList.length > 0 && currentAllocation) {
      const filterManagement = managementList.find(
        (management: any) => management.name === currentAllocation.management,
      );
      if (filterManagement) setManagementId(filterManagement.id);
    }
  }, [managementList]);

  useEffect(() => {
    if (editData.isUpdated && type === 'edit') {
      setTeamId(editData.team_id);
      setDate(editData.start_date);
      const management = managementList.find(
        (management: any) => management.name === editData.management,
      );
      let teamStructureValue = [];
      if (management && management.teamStructures) {
        teamStructureValue = management.teamStructures.find(
          (value: any) => value.name === editData.teamStructure,
        );
        setManagementId(management.id);
        setTeamStructureId(teamStructureValue.id);
        if (editData.justification) setJustification(editData.justification);
      }
    }
    if (type === 'deallocate') {
      setJustification(editData.justification);
      setDate(editData.end_date);
    }
  }, [editData, type]);

  if (managementList)
    managementValues = managementList.map((management: any) => ({
      value: management.id,
      label: management.name,
    }));
  if (managementId !== '' && managementList.length > 0) {
    management = managementList.find((item: any) => item.id === managementId);

    if (management && management.teamStructures.length > 0)
      teamStructureValues = management.teamStructures.map(
        (teamStructureValue: any) => ({
          value: teamStructureValue.id,
          label: teamStructureValue.name,
        }),
      );
    teamStructureValues.sort(compareString);
  }
  if (managementId !== '' && teamStructureId !== '') {
    const filteredTeamStructure = management.teamStructures.find(
      (item: any) => item.id === teamStructureId,
    );
    if (filteredTeamStructure) {
      teams = filteredTeamStructure.team.map((team: any) => {
        return { value: team.id, label: team.name };
      });
    }
  }

  const isAbleToSave =
    (managementId !== '' &&
      teamStructureId !== '' &&
      teamId !== '' &&
      justification !== '' &&
      date !== '') ||
    (type === 'deallocate' && justification !== '' && date !== '');

  const handleSaveData = () => {
    if (isAbleToSave) {
      if (type === 'deallocate') {
        setIsSavingData(true);
        api
          .post(
            `/team-employee/desallocate-employee?employeeId=${employeeId}&endDate=${removeTimeZone(new Date(date))}&justification=${justification}`,
          )
          .then((response: any) => {
            toast.success(response.data.message, {
              theme: 'colored',
              toastId: 'success',
            });
            trackEvent(
              'Desalocacao de Colaborador',
              'update_allocation',
              management ? management.name : '',
            );
            setUpdateData(true);

            handleClose();
          })
          .catch((error) => {
            const errorMessage =
              error?.response?.status < 500
                ? error?.response?.data?.message
                : 'Sua solicitação não foi processada. Tente novamente mais tarde!';
            toast.error(errorMessage, {
              theme: 'colored',
              toastId: 'error',
            });
          })
          .finally(() => {
            setIsSavingData(false);
          });
      } else {
        setIsSavingData(true);
        const method: 'post' | 'put' = type === 'create' ? 'post' : 'put';
        const data =
          type === 'create'
            ? `?employeeId=${employeeId}&teamId=${teamId}&startDate=${removeTimeZone(new Date(date))}&justification=${justification}`
            : `?employeeTeamHistoryId=${editData.id}&teamId=${teamId}&startDate=${removeTimeZone(new Date(date))}&justification=${justification}`;

        api[method](`/team-employee${data}`)
          .then((response: any) => {
            toast.success(response.data.message, {
              theme: 'colored',
              toastId: 'success',
            });
            trackEvent(
              management ? management.name : '',
              'update_allocation',
              teams.length > 0 ? teams[0].label : '',
            );
            setUpdateData(true);
            handleClose();
          })
          .catch((error) => {
            const errorMessage =
              error?.response?.status < 500
                ? error?.response?.data?.message
                : 'Sua solicitação não foi processada. Tente novamente mais tarde!';
            toast.error(errorMessage, {
              theme: 'colored',
              toastId: 'error',
            });
          })
          .finally(() => {
            setIsSavingData(false);
          });
      }
    }
  };

  const handleSaveButton = () => {
    const teamName = teams.find((team: any) => team.value === teamId);
    const teamStructureName = teamStructureValues.find(
      (teamStructure: any) => teamStructure.value === teamStructureId,
    );
    const managementName = managementValues.find(
      (management: any) => management.value === managementId,
    );
    const previousAllocation = findPreviousAllocation();
    if (
      teamName &&
      teamName.label === previousAllocation.team &&
      teamStructureName.label === previousAllocation.teamStructure &&
      managementName.label === previousAllocation.management &&
      (!editData || (editData && editData.id !== previousAllocation.id))
    ) {
      toast.error(
        'A turma selecionada é a mesma turma à qual o funcionário já pertence na data da realocação',
        {
          theme: 'colored',
          toastId: 'error',
        },
      );
    } else {
      if (type === 'edit') {
        handleSaveData();
      } else if (type === 'create' || type === 'deallocate') {
        setIsModalConfirmationOpen(true);
      }
    }
  };

  const handleClose = () => {
    setType('');
    setJustification('');
    setTeamStructureId('');
    setTeamId('');
    setManagementId(
      managementList.find(
        (management: any) => management.name === currentAllocation.management,
      ).id,
    );
    setDate('');
    setEditData({
      id: 0,
      isCurrent: false,
      employee_id: 0,
      end_date: '',
      justification: '',
      management: '',
      start_date: '',
      team: '',
      teamStructure: '',
      team_id: 0,
      isUpdated: false,
    });
    teams = [];
  };

  const findPreviousAllocation = () => {
    let previousAllocation = data.find(
      (allocation: any) =>
        removeTimeZone(new Date(date)).getTime() >=
          removeTimeZone(new Date(allocation.start_date)).getTime() &&
        (allocation.end_date === undefined ||
          allocation.end_date === null ||
          removeTimeZone(new Date(date)).getTime() <=
            removeTimeZone(new Date(allocation.end_date)).getTime()),
    );

    if (
      !previousAllocation &&
      removeTimeZone(new Date(date)).getTime() >=
        removeTimeZone(new Date(data[0].end_date)).getTime()
    ) {
      previousAllocation = data[0];
    }

    return previousAllocation;
  };

  const generateConfirmatiomMessage = () => {
    const teamName = teams.find((team: any) => team.value === teamId);
    const teamStructureName = teamStructureValues.find(
      (teamStructure: any) => teamStructure.value === teamStructureId,
    );
    const managementName = managementValues.find(
      (management: any) => management.value === managementId,
    );

    const previousAllocation = findPreviousAllocation();

    if (previousAllocation) {
      if (managementName.label !== previousAllocation.management) {
        return `Tem certeza que deseja atualizar a alocação do colaborador ${name} da gerência ${previousAllocation.management} / ${previousAllocation.teamStructure} / ${previousAllocation.team} para a gerência ${managementName.label} / ${teamStructureName.label} / ${teamName && teamName.label}?`;
      } else if (teamStructureName.label !== previousAllocation.teamStructure) {
        return `Tem certeza que deseja atualizar a alocação do colaborador ${name} da estrutura ${previousAllocation.teamStructure} / ${previousAllocation.team} para a estrutura ${teamStructureName.label} / ${teamName && teamName.label}?`;
      } else {
        return `Tem certeza que deseja atualizar a alocação do colaborador ${name} da turma ${previousAllocation.team} para a turma ${teamName && teamName.label}?`;
      }
    } else {
      return `Tem certeza que deseja atualizar a alocação do colaborador ${name}?`;
    }
  };

  return (
    <div className="mb-5">
      {type !== '' && (
        <div>
          <SubtitleText
            subtitle={
              type === 'deallocate'
                ? 'Desalocar colaborador da gerência'
                : type === 'create'
                  ? 'Atualizar Realocação'
                  : 'Editar Troca'
            }
          />
          <Line />
          {type === 'deallocate' ? (
            <div className="mb-3 flex w-full justify-between">
              <SimpleTextField
                width="w-[38rem]"
                fontColor="text-[#4a4a4a]"
                fontSize="text-[12px]"
                title="Justificativa"
                value={justification}
                setState={setJustification}
              />
              <SingleDate
                id={1}
                dateValue={date}
                setDate={setDate}
                value="Data da desalocação"
                width="10rem"
              />
            </div>
          ) : (
            <>
              <div className="mb-3 flex w-full justify-between">
                <SimpleSelect
                  width="14rem"
                  title={'Gerência'}
                  values={managementValues}
                  value={managementId}
                  setState={(id) => {
                    setManagementId(id as number);
                    setTeamStructureId('');
                    setTeamId('');
                  }}
                />
                <SimpleSelect
                  width="14rem"
                  title={'Estrutura'}
                  values={managementId !== '' ? teamStructureValues : []}
                  value={teamStructureId}
                  setState={(id) => {
                    setTeamStructureId(id as number);
                    setTeamId('');
                  }}
                />
                <SimpleSelect
                  width="8rem"
                  title={'Turma'}
                  values={teamStructureId !== '' ? teams : []}
                  value={teamId}
                  setState={(id) => setTeamId(id as number)}
                />
                <SingleDate
                  id={1}
                  dateValue={date}
                  setDate={setDate}
                  value="Data de início"
                  width="9rem"
                />
              </div>
              <div className="mb-3 flex w-full justify-between">
                <SimpleTextField
                  width="w-full"
                  fontColor="text-[#4a4a4a]"
                  fontSize="text-[12px]"
                  title="Justificativa"
                  value={justification}
                  setState={setJustification}
                />
              </div>
            </>
          )}

          <div className="flex justify-between p-5">
            <Button
              type="button"
              title="Cancelar"
              variant="link"
              className="min-w-40  font-semibold ring-1"
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              type="button"
              className="min-w-40  underline"
              onClick={handleSaveButton}
              isLoading={isSavingData}
              disabled={!isAbleToSave}
            >
              Salvar
            </Button>
          </div>
        </div>
      )}
      {isModalConfirmationOpen && (
        <ModalConfirmation
          title={''}
          description={
            type === 'deallocate'
              ? `Tem certeza que deseja desalocar esse colaborador?`
              : generateConfirmatiomMessage()
          }
          confirmAction={handleSaveData}
          isOpen={isModalConfirmationOpen}
          onClose={() => setIsModalConfirmationOpen(false)}
          discartAction={() => setIsModalConfirmationOpen(false)}
        />
      )}
    </div>
  );
};

export default UpdateAllocationCard;
