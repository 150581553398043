import { TextField, TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';
import { FieldError } from 'react-hook-form';

import TooltipIconText from './TooltipIconText';

type Props = {
  title: string;
  className?: string;
  width?: string;
  height?: string;
  fieldError?: FieldError;
  errorMessage?: string;
  tooltipText?: string;
  upperCase?: boolean;
} & TextFieldProps;

const UncontrolledInputText = forwardRef<HTMLInputElement, Props>(
  (
    {
      title,
      className,
      width,
      height,
      fieldError,
      errorMessage,
      tooltipText,
      upperCase,
      ...rest
    },
    ref,
  ) => {
    return (
      <div>
        <div className={`flex-colum ${className}`}>
          <div className="flex items-center font-graphie text-[12px] text-[#4A4A4A]">
            {title}
            {rest.required && <span className="text-red">*</span>}
            {tooltipText && <TooltipIconText text={tooltipText} />}
          </div>
          <TextField
            required
            type={rest.type}
            sx={{ width: `${width}` }}
            inputProps={{
              style: {
                padding: '0px 10px',
                height: `${height ?? '2rem'}`,
                fontSize: '12px',
                textTransform: upperCase ? 'uppercase' : undefined,
                background: rest.disabled ? 'white' : undefined,
              },
            }}
            variant="outlined"
            ref={ref}
            {...rest}
          />
          {fieldError && (
            <div className="text-[10px] text-[#dd0000]">{errorMessage}</div>
          )}
        </div>
      </div>
    );
  },
);

UncontrolledInputText.displayName = 'UncontrolledInputText';

export default UncontrolledInputText;
