import { useState } from 'react';

import { columns } from '@/constants/tableColumns/SupplierTrainingTableColumn';
import { cn } from '@/utils/cn';

import { Training } from '../../types/Supplier';
import Table from '../organisms/Table';
import LightTooltip from './LightTooltip';
import Modal from './Modal';

interface Props {
  trainings: Training[];
}

export const ShowSupplierTrainings = ({ trainings }: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div>
      <div className="mb-1 text-[12px] font-bold text-[#5A5A5A]">
        Treinamentos Aplicados
      </div>
      {trainings.length === 0 ? (
        <div className="flex h-28 w-full py-2 text-[10px] font-bold text-[#5A5A5A]">
          O fornecedor não possui nenhum treinamento cadastrado
        </div>
      ) : (
        <div>
          <div className="flex h-28 w-full flex-col">
            {trainings.slice(0, 3).map((item, index) => (
              <div
                key={index}
                className={cn(
                  ' flex h-[1.7rem] w-full items-center p-2',
                  index % 2 === 0 ? 'bg-[#F1F3FA]' : 'bg-white',
                )}
              >
                <LightTooltip
                  title={item.training.name}
                  arrow
                  sx={{
                    paddingBottom: 1,
                  }}
                  placement="top-start"
                >
                  <p className="w-full overflow-hidden text-ellipsis text-nowrap text-[10px] uppercase text-gray-600">
                    {item.training.name}
                  </p>
                </LightTooltip>
              </div>
            ))}
          </div>
          <button
            className="mt-2 flex w-full justify-end text-[10px] font-semibold text-[#193CB9] underline"
            onClick={() => setOpen(true)}
          >
            {`Abrir lista completa (${trainings.length})`}
          </button>
        </div>
      )}
      <Modal
        isOpen={open}
        onClose={() => setOpen(false)}
        className="h-[50vh] w-[40rem]"
        title="Treinamentos Aplicados"
      >
        <div className="ml-2 flex flex-col rounded">
          <Table columns={columns} data={trainings} trBodyClassname="h-8" />
        </div>
      </Modal>
    </div>
  );
};
