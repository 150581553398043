import {
  components,
} from 'react-select';

const ValueContainer = ({ children, ...props }: any) => {
  const { hasValue } = props;
  const [tags, otherChildren] = children;

  return (
    <components.ValueContainer {...props}>
      {!hasValue ? (
        <>{children}</>
      ) : (
        <>
          {tags}
          {otherChildren}
        </>
      )}
    </components.ValueContainer>
  );
};

export default ValueContainer