import { atom } from 'recoil';

export interface FirstAllocation extends Record<number, string> {
  id: number;
  isOpen: string;
}

const FirstAllocationAtom = atom<FirstAllocation>({
  key: 'firstAllocation',
  default: {
    id: 0,
    isOpen: '',
  },
});

export { FirstAllocationAtom };
