import ReactGA from 'react-ga4';

type Action =
  | 'file_download'
  | 'filter_content'
  | 'file_upload'
  | 'login'
  | 'logout'
  | 'generate_team_structure'
  | 'delete_team_structure'
  | 'generate_on_notice'
  | 'generate_on_notice_activation'
  | 'generate_vacation'
  | 'generate_absense'
  | 'generate_extra_trip'
  | 'generate_journey_extension'
  | 'generate_training'
  | 'generate_supplier'
  | 'generate_allocation'
  | 'training_file_sent_to_approval'
  | 'training_file_sent_to_employee_approval'
  | 'update_allocation'
  | 'training_team_transport_request';

export const trackEvent = (category: string, action: Action, label: string) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};
