import axios from 'axios';
import { msalInstance } from '../main';

const baseURL = import.meta.env.VITE_SGFT_URL;
const msalScopes = import.meta.env.VITE_MSAL_SCOPES;

const api = axios.create({
  baseURL,
  headers: {
    'Content-type': 'application/json',
  },
});

api.interceptors.request.use(async (config: any) => {
  const accessTokenRequest = {
    scopes: [msalScopes],
    account: msalInstance.getAllAccounts()[0],
  };

  try {
    const accessToken = (
      await msalInstance.acquireTokenSilent(accessTokenRequest)
    ).accessToken;

    return {
      ...config,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
  } catch (e) {
    return msalInstance.acquireTokenRedirect(accessTokenRequest);
  }
});

export default api;
