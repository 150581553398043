import { FC, HTMLProps, MouseEventHandler } from 'react';
import { twMerge } from 'tailwind-merge';

import LightTooltip from './LightTooltip';

interface TableDataProps extends HTMLProps<HTMLParagraphElement> {
  tableValue: unknown;
  title?: string;
  divClassName?: string;
  onClick?: MouseEventHandler<HTMLParagraphElement>;
  containerClassName?: string;
  className?: string;
  color?: string;
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
    | undefined;
}

const TableData: FC<TableDataProps> = ({
  tableValue,
  title,
  onClick = null,
  divClassName = '',
  containerClassName,
  className,
  color,
  placement,
}) => {
  return (
    <LightTooltip title={title} arrow placement={placement || 'top-start'}>
      <div className={twMerge('flex w-full items-center', containerClassName)}>
        <p
          className={twMerge(
            `justify-center overflow-hidden text-ellipsis whitespace-nowrap text-xs text-gray-600 ${color ? `text-[${color}]` : ''}`,
            className,
          )}
          onClick={onClick || undefined}
        >
          {tableValue as string}
        </p>
        <div className={divClassName} />
      </div>
    </LightTooltip>
  );
};

export default TableData;
