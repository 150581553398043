import ControlledSimpleSelect from '../atoms/ControlledSimpleSelect';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { teamRegisterAtom } from '../../state/TeamRegister.atom';
import {
  ROTATION_MEDICO,
  ROTATION_MOTORISTA,
  ROTATION_PADRAO,
  ROTATION_TURNO_14X14,
  ROTATION_TURNO_7X7,
  STRUCTURE_TYPES,
} from '../../constants/TeamRegister';
import ControlledCalendar from '../atoms/ControlledCalendar';
import NumericInput from '../atoms/NumericInput';
import { teamRegisterPreviewAtom } from '../../state/TeamRegisterPreview.atom';

interface Props {
  methods: any;
  index: number;
}

export const TeamGroupRegister = ({ methods, index }: Props) => {
  const [team, setTeam] = useRecoilState(teamRegisterAtom);
  const [teamPreview, setTeamPreview] = useRecoilState(teamRegisterPreviewAtom);
  const [rotation, setRotation] = useState<{ value: string; label: string }[]>(
    [],
  );

  useEffect(() => {
    if (
      (team.teamsLength != 4 && team.structureType == STRUCTURE_TYPES.TURNO) ||
      team.structureType == STRUCTURE_TYPES.ADM
    ) {
      setRotation(ROTATION_PADRAO);
    } else if (team.structureType === STRUCTURE_TYPES.MEDICO) {
      setRotation(ROTATION_MEDICO);
    } else if (team.structureType === STRUCTURE_TYPES.MOTORISTA) {
      setRotation(ROTATION_MOTORISTA);
    } else if (team.structureType === STRUCTURE_TYPES.SERVICOS) {
      setRotation(ROTATION_PADRAO);
    } else if (team.scale === '7x7') {
      setRotation(ROTATION_TURNO_7X7);
    } else if (team.scale === '14x14') {
      setRotation(ROTATION_TURNO_14X14);
    }
  }, [team]);

  const handleChange = (e: any) => {
    setTeamPreview((prevState) => {
      let paramObj: teamRegisterPreviewAtom = {
        date: [],
        rotation: [],
        minPeople: [],
      };

      if (typeof e.target.value === 'object') {
        const newArray = [...prevState.date];
        newArray[index] = e.target.value;
        paramObj = {
          ...prevState,
          date: newArray,
        };
      } else if (typeof e.target.value === 'string' && !isNaN(e.target.value)) {
        const newArray = [...prevState.minPeople];
        newArray[index] = e.target.value;
        paramObj = {
          ...prevState,
          minPeople: newArray,
        };
      } else {
        const newArray = [...prevState.rotation];
        newArray[index] = e.target.value;
        paramObj = {
          ...prevState,
          rotation: newArray,
        };
      }

      return paramObj;
    });
  };

  return (
    <div className="bg-[#f1f1f8] rounded  p-2 w-full">
      <div className="text-[#000000] mr-[0.5rem] text-[12px] semibold mb-2">
        {`Turma ${index + 1}`}
      </div>
      <div className="flex gap-2 justify-between w-full flex-wrap overflow-hidden py-2">
        <ControlledCalendar
          title="Proximo Embarque"
          control={methods.control}
          {...methods.register(`teamsRotation.${index}.startDate`, {
            onChange: handleChange,
            value: teamPreview.date[index],
          })}
          width="220px"
          disablePast
        />
        <ControlledSimpleSelect
          title="Rotação"
          required
          values={rotation}
          control={methods.control}
          {...methods.register(`teamsRotation.${index}.rotation`, {
            onChange: handleChange,
            value: teamPreview.rotation[index],
          })}
          width="220px"
        />
        <NumericInput
          required
          title={
            <span style={{ whiteSpace: 'nowrap' }}>
              Quantidade mínima de pessoas na turma
            </span>
          }
          {...methods.register(`teamsRotation.${index}.minPeople`, {
            onChange: handleChange,
          })}
          width="220px"
          value={teamPreview.minPeople[index]}
        />
      </div>
    </div>
  );
};
