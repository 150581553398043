import { FaArrowAltCircleRight } from 'react-icons/fa';

import { cn } from '../../utils/cn';
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './ToolTip';

const NewTeamIndicator = ({ newTeam }: { newTeam: string }) => {
  return (
    <TooltipProvider delayDuration={500} skipDelayDuration={200}>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="absolute -left-[17px] z-20 flex px-2">
            <span className={cn('flex rounded-full  bg-white ')}>
              <FaArrowAltCircleRight color="#4b5563" />
            </span>
          </div>
        </TooltipTrigger>
        <TooltipContent
          className="z-50 bg-white"
          align="center"
          sideOffset={1}
          arrowPadding={50}
          side="left"
          sticky="always"
        >
          <TooltipArrow className="fill-white" />
          <p>Realocação: {newTeam}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default NewTeamIndicator;
