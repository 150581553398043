export const subtitle: Record<string, string> = {
  Matriz: 'Matriz',
  Alagoas: 'Polo Alagoas',
  'Tucano Sul': 'Polo Tucano Sul',
  'Posição Intermediária': 'Posição Intermediária',
  'Posição vazia': 'Posição vazia',
  'Divergência no número de colaboradores':
    'Divergência no número de colaboradores',
  ADM: 'Administrativo',
  TURNO: 'Turno',
};

export const subtitleEntries: [string, string][] = Object.entries(subtitle) as [
  string,
  string,
][];
