import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { Pencil } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { queryClient } from '@/App';
import EmptyIcon from '@/assets/icons/empty.svg';
import { columns } from '@/constants/tableColumns/TrainingFileTeamInstructorsTableColumns';
import api from '@/services/apiSgft';
import { TrainingFileTeamDay } from '@/types/TrainingFile';
import { cn } from '@/utils/cn';

import { Button } from '../atoms/Button';
import Modal from '../atoms/Modal';
import Spinner from '../atoms/Spinner';
import Table from '../organisms/Table';
interface TrainingFileTeamInstructorsProps {
  trainingFileId: number;
  trainingPlanningDay: TrainingFileTeamDay;
  className?: string;
}

export const TrainingFileTeamInstructors = ({
  trainingFileId,
  trainingPlanningDay,
  className = '',
}: TrainingFileTeamInstructorsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const instructors = trainingPlanningDay.instructors ?? [];
  const [selectedInstructors, setSelectedInstructors] = useState(
    instructors.map((instructor) => instructor.instructor.id),
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const names = instructors.length
    ? instructors.map((instructor) => instructor.instructor.name).join(', ')
    : '-';

  const fetchInstructors = async () => {
    const response = await api.get(
      `training-files/${trainingFileId}/instructor`,
    );
    return response.data;
  };
  const { data: availableInstructors, isLoading } = useQuery(
    ['training-file-instructors', trainingFileId],
    fetchInstructors,
  );
  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await api.put(
        `training-plannings/traininingPlanningDay/${trainingPlanningDay.id}/instructors/`,
        {
          instructors: selectedInstructors,
        },
      );
      queryClient.invalidateQueries({ queryKey: ['training-files'] });
      queryClient.invalidateQueries({ queryKey: ['training-file-details'] });

      setIsOpen(false);
    } catch {
      toast.error('Erro ao definir os instrutores', {
        toastId: 'error',
        theme: 'colored',
      });
    }
    setIsSubmitting(false);
  };
  const handleSelectInstructors = (id: number) => {
    if (selectedInstructors.includes(id)) {
      setSelectedInstructors((prev) => prev.filter((it) => it !== id));
    } else {
      setSelectedInstructors((prev) => [...prev, id]);
    }
  };
  return (
    <div className="w-full overflow-hidden">
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          title={`Instrutores incluídos na turma - dia ${format(new Date(trainingPlanningDay.date), 'dd/MM/yyyy')}`}
          className="max-w-[50rem]"
        >
          {isLoading ? (
            <Spinner />
          ) : availableInstructors?.length ? (
            <div className="custom-scrollbar max-h-[50vh] overflow-y-auto">
              <Table
                columns={columns(handleSelectInstructors, selectedInstructors)}
                data={availableInstructors}
              />
            </div>
          ) : (
            <div className="flex w-full flex-col items-center justify-center">
              <img src={EmptyIcon} className="w-40" />
              <div className="flex flex-col items-center text-sm">
                <p>{`Nenhum instrutor disponível`}</p>
                <span className="font-semibold text-blue-800">
                  {`Cadastre os instrutores no ficheiro para consegui visualizar.`}
                </span>
              </div>
            </div>
          )}
          <div className="flex w-full justify-between p-6">
            <Button
              variant="outline"
              className="w-32 text-primary ring-1 ring-primary"
              onClick={() => setIsOpen(false)}
            >
              Cancelar
            </Button>
            <Button
              className="w-32 text-white"
              onClick={handleSubmit}
              isLoading={isSubmitting}
              disabled={!availableInstructors?.length}
            >
              Salvar
            </Button>
          </div>
        </Modal>
      )}
      <div
        className={cn(
          'flex w-full items-center gap-2 overflow-hidden py-2',
          className,
        )}
      >
        <button
          className="items center flex w-full  overflow-hidden text-left text-[12px] font-semibold text-primary"
          onClick={() => setIsOpen(true)}
        >
          <p
            className={cn(
              'w-full  max-w-full overflow-hidden text-ellipsis whitespace-nowrap   text-left text-primary',
            )}
          >
            {names}
          </p>
          {instructors?.length ? (
            <span className="text-xs">({instructors?.length})</span>
          ) : (
            <></>
          )}
          <div className="ml-1">
            <Pencil size={14} />
          </div>
        </button>
      </div>
    </div>
  );
};
