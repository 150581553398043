import Line from '../atoms/Line';
import SubtitleText from '../atoms/SubtitleText';

interface Props {
  subtitle: string;
  className?: string;
}

const Subtitle = ({ subtitle, className }: Props) => {
  return (
    <>
      <SubtitleText subtitle={subtitle} className={className} />
      <Line />
    </>
  );
};

export default Subtitle;
