import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
    locationOptions,
    Modality,
    modalityOptions,
} from '../../constants/TrainingConstants';
import { Training } from '../../types/Training';
import ControlledSimpleSelect from '../atoms/ControlledSimpleSelect';
import Input from '../atoms/Input';

export interface FormData {
  allPoles: boolean;
  specificLocations: string[];
  name: string;
  modalities: string[];
  totalWorkload: string;
  onlineWorkload: string;
  inPersonWorkload: string;
  validity: string;
  needsRecycling: string;
  recyclingMonths: string;
  preRequisites: string[];
  equivalences: string[];
}

interface RegulatoryTrainingFormFieldsProps {
  trainingData?: Training;
  regulatoryTrainings: Training[];
  canSave?: boolean;
}

const RegulatoryTrainingFormFields: React.FC<
  RegulatoryTrainingFormFieldsProps
> = ({ trainingData, regulatoryTrainings, canSave }) => {
  const { control, reset, register, watch, setValue } = useFormContext();
  const selectedModalidade: Array<string> = watch('modalities');
  const needsRecycling = watch('needsRecycling');
  const onlineWorkload = watch('onlineWorkload');
  const inPersonWorkload = watch('inPersonWorkload');

  const regulatoryTrainingOptions =
    regulatoryTrainings?.map((training) => ({
      value: training.id.toString(),
      label: training.name,
    })) || [];

  useEffect(() => {
    if (
      selectedModalidade &&
      selectedModalidade.includes(Modality.Semipresencial)
    ) {
      const total =
        Number(formData.onlineWorkload) + Number(formData.inPersonWorkload);
      setFormData(() => ({
        ...formData,
        totalWorkload: String(isNaN(total) ? 0 : total),
      }));
      setValue('totalWorkload', isNaN(total) ? 0 : total);
    } else {
      if (selectedModalidade) {
        setValue('onlineWorkload', 0);
        setValue('inPersonWorkload', 0);
        setFormData((prevFormData: any) => ({
          ...prevFormData,
          onlineWorkload: 0,
          inPersonWorkload: 0,
        }));
      }
    }
  }, [selectedModalidade, onlineWorkload, inPersonWorkload]);

  useEffect(() => {
    reset();

    setValue('locations', trainingData?.locations ?? []);
    setValue('modalities', trainingData?.modalities ?? []);
    setValue('needsRecycling', trainingData?.needsRecycling ?? []);
    setValue('preRequisites', trainingData?.preRequisites ?? []);
    setValue('equivalences', trainingData?.equivalences ?? []);
    setValue('trainingType', trainingData?.trainingType ?? '');
  }, [trainingData]);

  const [formData, setFormData] = useState<FormData>({
    allPoles: false,
    specificLocations: trainingData?.locations || [],
    name: trainingData?.name || '',
    modalities: trainingData?.modalities || [],
    totalWorkload: String(trainingData?.totalWorkload) || '',
    onlineWorkload: String(trainingData?.onlineWorkload) || '',
    inPersonWorkload: String(trainingData?.inPersonWorkload) || '',
    validity: String(trainingData?.validity) || '',
    needsRecycling: trainingData?.needsRecycling || '',
    recyclingMonths: String(trainingData?.recyclingMonths) || '',
    preRequisites: trainingData?.preRequisites || [],
    equivalences: trainingData?.equivalences || [],
  });

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { type, name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]:
        type === 'number' ? Math.min(Number(value), 999).toString() : value,
    }));
  };

  return (
    <div className="p-5 pt-0">
      <div className="mb-4 w-[46rem]">
        <Input
          label="Nome"
          type="text"
          value={formData.name}
          key={'name'}
          {...register('name', { required: true, onChange: handleChange })}
          required={true}
          disabled={!canSave}
          className="uppercase"
        />
      </div>
      <div className="mb-4 flex w-[46rem] justify-between gap-4">
        <ControlledSimpleSelect
          values={locationOptions}
          title="Polos de Aplicação"
          control={control}
          required={true}
          key={'locations'}
          name={'locations'}
          multiple={true}
          width="23rem"
          defaultValue={formData.specificLocations}
          LabelClassName="font-medium text-sm text-primary"
          disabled={!canSave}
        />
        <ControlledSimpleSelect
          values={modalityOptions}
          title="Modalidades"
          control={control}
          required={true}
          multiple={true}
          key={'modalities'}
          name={'modalities'}
          width="22rem"
          defaultValue={formData.modalities}
          LabelClassName="font-medium text-sm text-primary"
          disabled={!canSave}
        />
      </div>
      <div className="mb-4 flex w-[46rem] justify-between gap-4">
        <Input
          label="Carga Horária Total (h)"
          type="number"
          {...register('totalWorkload', {
            required: true,
            onChange: handleChange,
          })}
          value={formData.totalWorkload}
          max={999}
          min={0}
          required={true}
          disabled={
            !canSave ||
            (selectedModalidade &&
              selectedModalidade.includes(Modality.Semipresencial))
          }
          key={'totalWorkload'}
          name={'totalWorkload'}
          className="w-[14rem]"
        />
        <Input
          label="Carga Horária Online (h)"
          type="number"
          {...register('onlineWorkload', {
            required: false,
            onChange: handleChange,
          })}
          value={formData.onlineWorkload}
          max={999}
          min={0}
          required={false}
          name={'onlineWorkload'}
          key={'onlineWorkload'}
          className="w-[14rem]"
          disabled={
            !canSave ||
            (selectedModalidade &&
              !selectedModalidade.includes(Modality.Semipresencial))
          }
        />
        <Input
          label="Carga Horária Presencial (h)"
          type="number"
          {...register('inPersonWorkload', {
            required: false,
            onChange: handleChange,
          })}
          value={formData.inPersonWorkload}
          max={999}
          min={0}
          required={false}
          name={'inPersonWorkload'}
          key={'inPersonWorkload'}
          disabled={
            !canSave ||
            (selectedModalidade &&
              !selectedModalidade.includes(Modality.Semipresencial))
          }
        />
      </div>
      <div className="mb-4 flex w-[46rem] justify-between">
        <div className="w-40">
          <Input
            label="Validade (anos)"
            type="number"
            {...register('validity', {
              required: false,
              onChange: handleChange,
            })}
            value={formData.validity}
            max={999}
            min={0}
            required={false}
            name={'validity'}
            key={'validity'}
            disabled={!canSave}
          />
        </div>
        <div className="w-[15rem]">
          <ControlledSimpleSelect
            values={[
              { value: 'true', label: 'Sim' },
              { value: 'false', label: 'Não' },
            ]}
            title="Necessidade de Reciclagem?"
            control={control}
            required={true}
            key={'needsRecycling'}
            name={'needsRecycling'}
            multiple={false}
            width="15rem"
            defaultValue={formData.needsRecycling}
            LabelClassName="font-medium text-sm text-primary mb-1"
            disabled={!canSave}
          />
        </div>
        <div className="w-[19rem]">
          <Input
            label="Carga Horária Reciclagem (h)"
            type="number"
            {...register('recyclingMonths', {
              required: formData.needsRecycling === 'true',
              onChange: handleChange,
            })}
            value={formData.recyclingMonths}
            required={formData.needsRecycling === 'true'}
            key={'recyclingMonths'}
            disabled={!canSave || !(needsRecycling === 'true')}
          />
        </div>
      </div>
      <div className="mb-4 flex w-[46rem] gap-4">
        <ControlledSimpleSelect
          values={regulatoryTrainingOptions}
          title="Pre-Requisitos"
          control={control}
          required={false}
          key={'preRequisites'}
          name={'preRequisites'}
          multiple={true}
          width="22.5rem"
          className="mb-4"
          defaultValue={formData.preRequisites}
          LabelClassName="font-medium text-sm text-primary"
          disabled={!canSave}
        />

        <ControlledSimpleSelect
          values={regulatoryTrainingOptions}
          title="Equivalências"
          control={control}
          required={false}
          key={'equivalences'}
          name={'equivalences'}
          multiple={true}
          width="22.5rem"
          defaultValue={formData.equivalences}
          LabelClassName="font-medium text-sm text-primary"
          disabled={!canSave}
        />
      </div>
    </div>
  );
};

export default RegulatoryTrainingFormFields;
