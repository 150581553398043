import { IconButton } from '@mui/material';
import { useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { toast } from 'react-toastify';

import { queryClient } from '@/App';
import { BoardDto } from '@/pages/Board';
import api from '@/services/apiSgft';
import { cn } from '@/utils/cn';

import DeleteIcon from '../../assets/icons/delete-icon.svg';
import EditIcon from '../../assets/icons/edit-icon.svg';
import { ConfirmationDialog } from '../atoms/ConfirmationDialog';
import TooltipIconText from '../atoms/TooltipIconText';
import BoardModal from './BoardModal';

interface Props {
  board: BoardDto;
}

interface CoordinationCardProps {
  coordination: {
    id: number;
    name: string;
    active: boolean;
  };
}

interface ManagementCardProps {
  management: {
    id: number;
    name: string;
    active: boolean;
    coordinations: {
      id: number;
      name: string;
      active: boolean;
    }[];
  };
}

const BoardExpandedCard = ({ board }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const removeBoard = async (id: number) => {
    try {
      await api.put(`/board/update-status`, { id, active: false });
      toast.success('Diretoria desativada com sucesso!', {
        theme: 'colored',
        toastId: 'success',
      });
      queryClient.invalidateQueries(['get-all-boards-data']);
    } catch (e) {
      toast.error('Erro ao desativar diretoria!', {
        theme: 'colored',
        toastId: 'error',
      });
    }
    setIsOpenDelete(false);
  };

  const CoordinationCard = ({ coordination }: CoordinationCardProps) => (
    <div
      className={`mb-2 mr-5 flex min-w-[7rem] items-center rounded border border-solid border-[#D7DFFF] ${
        !coordination.active ? 'border-[#d4d4d4] bg-[#e3e3e3]' : ''
      } p-2`}
    >
      {`Coordenação: ${coordination.name}`}
      {!coordination.active && (
        <TooltipIconText text="Coordenação desativada" />
      )}
    </div>
  );

  const ManagementCard = ({ management }: ManagementCardProps) => (
    <div
      className={`mt-1 flex min-h-[5rem] w-full flex-col rounded bg-[#F1F3FA] text-center ${!management.active && 'border border-[#d4d4d4] bg-[#e3e3e3]'} p-2`}
    >
      <div className="flex items-center font-graphie text-[13px] font-semibold text-[#193CB9]">
        {`Gerência: ${management.name}`}
        {!management.active && <TooltipIconText text={'Gerência desativada'} />}
      </div>
      <div className="mt-1 flex flex-wrap text-[12px] text-[#5A5A5A]">
        {management.coordinations.map((coordination) => {
          return (
            <CoordinationCard
              key={coordination.id}
              coordination={coordination}
            />
          );
        })}
      </div>
    </div>
  );

  return (
    <div
      key={board.id}
      className={cn(
        'mb-2 flex min-h-[3rem] w-full flex-col rounded p-2',
        board.active ? 'bg-white' : 'border border-[#d4d4d4] bg-[#c8c8c893]',
      )}
    >
      <div className="flex items-center justify-between font-graphie">
        <div className="flex items-center text-end font-graphie text-[14px] font-semibold text-[#193CB9]">
          {`Diretoria: ${board.name}`}
        </div>
        <div className="flex items-center">
          {board?.company?.name && (
            <div
              className={cn(
                'mr-2 h-[2rem] min-w-[5rem] rounded p-2 text-[12px]',
                board.company.name === 'TAMAC'
                  ? 'bg-[#83abd08a] text-[#222a84]'
                  : 'bg-[#E9F1FF] text-[#1826BD]',
              )}
            >
              {`Empresa: ${board.company.name}`}
            </div>
          )}
          <IconButton
            aria-label="edit"
            size="small"
            onClick={() => setIsOpenEdit(true)}
          >
            <img
              src={EditIcon}
              alt="Ícone de editar"
              className="mr-2 w-5 flex-none"
            />
          </IconButton>
          {board.active ? (
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => setIsOpenDelete(true)}
            >
              <img
                src={DeleteIcon}
                alt="Ícone de deletar"
                className="mr-2 w-[13px] flex-none"
              />
            </IconButton>
          ) : (
            <div className="ml-[-0.25rem] mr-1">
              <TooltipIconText text={'Diretoria desativada'} />
            </div>
          )}
          <IconButton onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? (
              <IoIosArrowUp fontSize={17} />
            ) : (
              <IoIosArrowDown fontSize={17} />
            )}
          </IconButton>
        </div>
      </div>
      <div>
        {isOpen &&
          board.managements.map((management) => {
            return (
              <ManagementCard key={management.id} management={management} />
            );
          })}
      </div>

      {isOpenEdit && (
        <BoardModal
          isOpen={isOpenEdit}
          onClose={() => setIsOpenEdit(false)}
          type={'edit'}
          data={board}
        />
      )}
      {isOpenDelete && (
        <ConfirmationDialog
          title={'Confirmação de Desativação'}
          description={
            'Ao prosseguir com esta ação, a diretoria e todas as suas informações serão desativadas no sistema.'
          }
          setIsOpen={setIsOpenDelete}
          onConfirmation={() => removeBoard(board.id)}
          height="15rem"
          width="30rem"
        />
      )}
    </div>
  );
};

export default BoardExpandedCard;
