import { BaseEdge, EdgeProps, getSmoothStepPath } from '@xyflow/react';
import { FC } from 'react';

interface CustomEdgeData extends Record<string, unknown> {
  spacing: number;
  isIntermediary: boolean;
  employee: {
    employeeNumber: string;
  };
}

interface CustomEdgeProps extends EdgeProps {
  data: CustomEdgeData;
}

const CustomEdge: FC<CustomEdgeProps> = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  id,
  data,
  targetPosition,
  sourcePosition,
}) => {
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    centerY: targetY - data.spacing / 2,
    targetPosition,
    sourcePosition,
  });

  const edgeStyle = {
    stroke: '#BEBEBE',
    strokeWidth: 2,
    strokeDasharray: data.isIntermediary ? '4 2' : 'none',
  };

  return <BaseEdge id={id} path={edgePath} style={edgeStyle} />;
};

export default CustomEdge;
