import { Typography } from '@mui/material';
import { MouseEvent, useState } from 'react';

import LightTooltip from './LightTooltip';

interface Props {
  value: string;
  color?: string;
  fontSize?: string;
  width?: string;
  textAlign?:
    | 'start'
    | 'end'
    | 'left'
    | 'right'
    | 'center'
    | 'justify'
    | 'match-parent';
}

export const TooltipString = ({
  value,
  color,
  fontSize,
  width,
  textAlign,
}: Props) => {
  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  const handleShouldShow = ({ currentTarget }: MouseEvent<Element>) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipEnabled(true);
    }
  };

  return (
    <LightTooltip
      title={value}
      open={tooltipEnabled}
      onClose={() => setTooltipEnabled(false)}
      arrow
      placement="top-end"
    >
      <Typography
        sx={{
          color: color ?? '#5A5A5A',
          fontSize: fontSize ?? '10px',
          width,
          fontFamily: 'Graphie',
          textAlign,
        }}
        onMouseEnter={handleShouldShow}
        textOverflow={'hidden'}
        noWrap
      >
        {value}
      </Typography>
    </LightTooltip>
  );
};
