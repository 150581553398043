import { format } from 'date-fns';
import { atom } from 'recoil';

export interface ViewScheduleFiltersAtom {
  timeRange: {
    startDate: string | undefined;
    endDate: string | undefined;
  };
  workplace: string[];
  schedule: string[];
  sector: string[];
  area: string[];
  management: string[];
  employee: string[];
  teamStructure: string[];
}

const employee = JSON.parse(localStorage.getItem('employee') ?? '{}');
const managementId = employee?.management_id;
const startDate = new Date();
const endDate = new Date();
endDate.setMonth(startDate.getMonth() + 3);
// endDate.setDate(startDate.getDate() + 365);

export const viewScheduleFiltersAtom = atom<ViewScheduleFiltersAtom>({
  key: 'viewSchedule',
  default: {
    timeRange: {
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
    },
    workplace: [],
    sector: [],
    schedule: [],
    area: [],
    management: managementId ? [String(managementId)] : [],
    employee: [],
    teamStructure: [],
  },
});
