import { Dialog, Transition } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';

import api from '../../services/apiSgft';
import { FirstAllocationAtom } from '../../state/FirstAllocation.atom';
import CloseButton from '../atoms/CloseButton';
import SubtitleText from '../atoms/SubtitleText';
import TextComponent from '../atoms/TextComponent';
import FirstAllocationCard from '../molecules/FisrtAllocationCard';

interface Props {
  handleClose: () => void;
  setUpdateData: any;
}

export default function FirstAllocationModal({
  handleClose,
  setUpdateData,
}: Props) {
  const firstAllocation = useRecoilValue(FirstAllocationAtom);
  const [data, setData] = useState<any>();

  const getData = async () => {
    try {
      const response = await api.get(
        `/employees/initialAllocation/${firstAllocation.id}`,
      );
      setData(response.data);
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Transition appear show={true}>
      <Dialog className="relative z-50" onClose={handleClose}>
        <div className="fixed inset-0 flex w-screen items-center justify-center bg-black/30 px-4 pt-4">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="custom-scrollbar max-h-[100vh] w-[52rem] flex-col justify-between overflow-auto overflow-y-scroll rounded-lg bg-white">
              <div className="w-full flex-col justify-between px-5 pb-3 pt-5">
                <div className="flex h-auto items-center justify-between">
                  <div className="flex justify-between">
                    <SubtitleText
                      className="text-[20px]"
                      subtitle={'Primeira Alocação'}
                    />
                  </div>
                  <CloseButton onClick={handleClose} className="mt-[-1rem]" />
                </div>
                <div className="mt-5">
                  <div className="mb-5 flex w-full justify-between">
                    <TextComponent
                      title={'Nome'}
                      width="22rem"
                      value={data ? data.name : ''}
                    />
                    <TextComponent
                      title={'Chapa'}
                      width="12rem"
                      value={data ? data.employeeNumber : ''}
                    />
                    <TextComponent
                      title={'Colaborador Ferista'}
                      width="10rem"
                      value={
                        data ? (data.vacationReliefWorker ? 'SIM' : 'NÃO') : ''
                      }
                    />
                  </div>
                  <FirstAllocationCard
                    management={data ? data.management : ''}
                    allocationDate={data ? data.admissionDate : ''}
                    teamStructures={data ? data.teamStructures : []}
                    handleClose={handleClose}
                    employeeId={firstAllocation.id}
                    setUpdateData={setUpdateData}
                  />
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
