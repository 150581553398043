import Card from '../atoms/Card';

export interface IProps {
  cards: {
    value: number;
    status: string;
    title: string;
    onClick?: any;
    width?: string;
    borderColor?: string;
  }[];
  className?: string;
}

const Cards = ({ cards, className }: IProps) => {
  return (
    <div className={`mb-3 flex justify-start font-graphie ${className}`}>
      {cards.map((card, index) => (
        <Card
          value={card.value}
          status={card.status}
          title={card.title}
          onClick={card?.onClick}
          width={card?.width}
          borderColor={card?.borderColor}
          key={index}
        />
      ))}
    </div>
  );
};

export default Cards;
