import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
} from '@azure/msal-react';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import Login from '../../pages/Login';
import api from '../../services/apiSgft';
import { actualUserAtom } from '../../state/ActualUser.atom';
import { AuthTemplate } from './AuthTemplate';

const RootLayout = () => {
  const isAuthenticated = useIsAuthenticated();
  const [, setActualUser] = useRecoilState(actualUserAtom);
  async function getUserInfo() {
    await api.get('/employees/info').then((response) => {
      setActualUser(response.data);
      localStorage.setItem('employee', JSON.stringify(response.data));
    });
  }
  useEffect(() => {
    if (isAuthenticated) {
      getUserInfo();
    }
  }, [isAuthenticated]);
  return (
    <>
      <AuthenticatedTemplate>
        <AuthTemplate />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Login redirectHome={false} />
      </UnauthenticatedTemplate>
    </>
  );
};

export default RootLayout;
