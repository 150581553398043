import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    fontFamily: 'Graphie',
    marginLeft: '2rem',
    marginBottom: '0px !important',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    marginLeft: '-1rem',
    '&::before': {
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[1],
    },
  },
}));

export default LightTooltip;
