import { GroupBase, StylesConfig } from 'react-select';

import { SelectOption } from '@/constants/TrainingConstants';

export const styles: StylesConfig<
  SelectOption,
  true,
  GroupBase<SelectOption>
> = {
  placeholder: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? '#AAA' : 'black',
    fontWeight: '300',
    fontSize: '16px',
  }),
  valueContainer: (base) => ({
    ...base,
    height: 'auto',
    background: 'transparent',
  }),
  multiValue: (base) => ({
    ...base,
  }),
  control: (provided) => ({
    ...provided,
    background: 'transparent',
    borderRadius: '6px',
    color: 'black',
    fontFamily: 'Graphie, sans-serif',
  }),
  menuList: (provided) => ({
    ...provided,
    minHeight: '30px',
    fontWeight: 'thin',
    color: 'white',
    fontFamily: 'Graphie, sans-serif',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : '#193CB9',
    fontSize: '13px',
    fontWeight: state.isSelected ? 'bold' : 'normal',
    fontFamily: 'Graphie, sans-serif',
  }),
  menuPortal: (provided) => ({
    ...provided,
    fontSize: '13px',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    whiteSpace: 'break-spaces',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'black',
    fontSize: '12px',
    fontWeight: 'normal',
    fontFamily: 'Graphie, sans-serif',
  }),
  input: (provided) => ({
    ...provided,
    color: 'black',
  }),
};
