import { z } from 'zod';

export enum PendencyStatus {
  OPEN = 0,
  CLOSED = 1,
  CANCELLED = 2,
  NO_TREATMENT = 3,
  ALERT = 9,
}

export enum PendencyType {
  EMPLOYEE_NOT_FOUND = 'COLABORADOR NÃO CONSTA EM BASE',
  VACATION_TO_EXPIRE = 'FÉRIAS A VENCER',
  UNCOVERED_VACATION = 'FÉRIAS SEM PLANO DE COBERTURA',
  MODIFIED_ON_NOTICE = 'PERÍODO DE SOBREAVISO ALTERADO',
  TEAM_ON_NOTICE_ERROR = 'TURMA SEM FUNCIONÁRIO DE SOBREAVISO EM UM DOS PRÓXIMOS 30 DIAS',
  NO_ALLOCATION = 'FUNCIONÁRIO SEM ALOCAÇÃO',
  ALLOCATION_REQUEST = 'SOLICITAÇÃO DE REALOCAÇÃO',
  RELOCATION_AWAITING_TURN = 'REALOCAÇÃO AGUARDANDO VIRADA DE COMPETÊNCIA',
  ALLOCATION_DIVERGENCE = 'DIVERGÊNCIA DE ALOCAÇÃO ENTRE FOLHA E SGFT',
}

const PendencyFilterOption = z
  .object({
    id: z
      .union([z.number(), z.string()])
      .transform((id) => String(id))
      .pipe(z.string()),
    name: z.string(),
  })
  .transform(({ id, name }) => ({
    value: id,
    label: name,
  }));

const PendencyFilterSingleOption = z.string().transform((value) => {
  return {
    value,
    label: value,
  };
});

export const PendencyFilterOptionsSchema = z.object({
  types: z.array(PendencyFilterOption).default([]),
  responsibles: z.array(PendencyFilterOption).default([]),
  areas: z.array(PendencyFilterOption).default([]),
  managements: z.array(PendencyFilterOption).default([]),
  workstations: z.array(PendencyFilterOption).default([]),
  scales: z.array(PendencyFilterSingleOption).default([]),
  sectors: z.array(PendencyFilterSingleOption).default([]),
});

export type PendencyFiltersOptions = z.infer<
  typeof PendencyFilterOptionsSchema
>;

export const PendencySchema = z.object({
  pendencyId: z.number(),
  type: z.string(),
  startDate: z.string(),
  endDate: z.union([z.string(), z.null()]),
  status: z.number(),
  employeeId: z.number(),
  employeeName: z.string(),
  employeeNumber: z.string(),
  employeeStatus: z.string().optional(),
  teamStructureId: z.union([z.number(), z.null()]),
  teamStructureName: z.string().optional(),
  team: z.string().optional(),
  scale: z.string().optional(),
  regime: z.string().optional(),
  area: z.string().optional(),
  sector: z.string().optional(),
  workStation: z.string().optional(),
  managementId: z.union([z.number(), z.null()]),
  management: z.string().optional(),
  responsibleName: z.string(),
  responsibleList: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    }),
  ),
  vacationId: z.union([z.number(), z.null()]),
  allocationRequestId: z.union([z.number(), z.null()]),
});

export const PendencyListSchema = z.object({
  pendencyData: z.array(PendencySchema),
  count: z.number(),
  nextPage: z.number().optional(),
});

export type Pendency = z.infer<typeof PendencySchema>;
