import { useFieldArray, useFormContext } from 'react-hook-form';

import { BoardDto } from '@/pages/Board';

import CoordinationRegisterCard from './CoordinationRegisterCard';

interface Props {
  managementIndex: number;
  data?: BoardDto;
}

const CoordinationRegister = ({ managementIndex, data }: Props) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `managements.${managementIndex}.coordinations`,
  });

  const addCoordination = () => {
    append({
      name: '',
    });
  };

  return (
    <div>
      {fields.map((el, coordinationIndex) => {
        return (
          <CoordinationRegisterCard
            key={
              data?.managements[managementIndex]?.coordinations[
                coordinationIndex
              ]?.id ?? undefined
            }
            managementIndex={managementIndex}
            coordinationIndex={coordinationIndex}
            control={control}
            remove={remove}
            data={data}
          />
        );
      })}
      <button
        type="button"
        className={`mt-2 flex justify-start text-[12px] font-semibold ${
          (data && !data.active) ||
          (data?.managements[managementIndex] &&
            !data.managements[managementIndex].active)
            ? 'text-gray-400'
            : 'text-[#193CB9]'
        }`}
        onClick={addCoordination}
        disabled={
          (data && !data.active) ||
          (data?.managements[managementIndex] &&
            !data.managements[managementIndex].active)
        }
      >
        + Adicionar Coordenação
      </button>
    </div>
  );
};
export default CoordinationRegister;
