import { Paper } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { CoverageStructure } from '@/types/VacationRegister';

import SubtitleText from '../atoms/SubtitleText';
import TooltipIcon from '../atoms/TooltipIcon';
import VacationCoverPlanPeriod from './VacationCoverPlanPeriod';

interface Props {
  employees: { id: string; name: string }[];
  structures: CoverageStructure[] | undefined;
  index: number;
  vacationCovers?: { startDate: Date; endDate: Date }[];
}

const VacationCoverPlan = ({
  employees,
  index,
  vacationCovers,
  structures,
}: Props) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `vacationPeriods.${index}.vacationCovers`,
  });

  const addCoverPlan = () => {
    append({
      coverageType: '',
      name: '',
      employeeId: '',
      startDate: '',
      endDate: '',
    });
  };

  return (
    <Paper sx={{ background: '#edeff1', padding: 1, marginBottom: 3 }}>
      <div className="flex items-center">
        <SubtitleText
          className="mr-[0.5rem] "
          subtitle={'Plano de cobertura'}
        />
        <TooltipIcon vacationCovers={vacationCovers} />
      </div>
      {fields.map((el, idx) => {
        return (
          <VacationCoverPlanPeriod
            employees={employees}
            idx={idx}
            index={index}
            key={el.id}
            vacationCovers={vacationCovers}
            remove={remove}
            structures={structures}
          />
        );
      })}
      <button
        type="button"
        className="text-[12px] text-[#193CB9] underline"
        onClick={addCoverPlan}
      >
        Adicionar plano de cobertura
      </button>
    </Paper>
  );
};

export default VacationCoverPlan;
