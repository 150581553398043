import IconButton from '@mui/material/IconButton';
import * as React from 'react';
import { CgSpinner } from 'react-icons/cg';
import { MdOutlineFileDownload } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';

import { trackEvent } from '@/utils/trackEvent';

import api from '../../services/apiSgft';
import {
  ViewScheduleFiltersAtom,
  viewScheduleFiltersAtom,
} from '../../state/ViewScheduleFilter.atom';
import LightTooltip from './LightTooltip';

interface DownloadButtonProps {
  filteredData: ViewScheduleFiltersAtom;
}

export const DownloadButton: React.FC<DownloadButtonProps> = (filteredData) => {
  const filterData = useRecoilValue(viewScheduleFiltersAtom);
  const [loading, setLoading] = React.useState(false);

  async function downloadSchedule() {
    setLoading(true);
    try {
      const response = await api.get(`/extraction/rh-report`, {
        params: {
          startDate: filteredData.filteredData.timeRange.startDate,
          endDate: filteredData.filteredData.timeRange.endDate,
          workStation: filterData.workplace,
          management: filterData.management,
          scale: filterData.schedule,
          sector: filterData.sector,
          area: filterData.area,
          employee: filterData.employee,
          teamStructure: filterData.teamStructure,
        },
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      );
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute(
        'download',
        'Extração de Estruturas de Turma - SGFT.xlsx',
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
      trackEvent('Download de Estutura de Turma', 'file_download', url);
    } catch (e) {
      toast.error('Estrutura sem dados', {
        theme: 'colored',
        toastId: 'error',
      });
      trackEvent(
        'Download de Estutura de Turma',
        'file_download',
        'Erro ao realizar o Download',
      );
      throw e;
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="cursor-pointer ">
      <LightTooltip title="Extrair as escalas de todos os colaboradores">
        <span>
          <IconButton onClick={downloadSchedule} disabled={loading}>
            {loading ? (
              <CgSpinner
                size={30}
                color="#c1c1c1"
                className="h-full animate-spin"
              />
            ) : (
              <MdOutlineFileDownload />
            )}
          </IconButton>
        </span>
      </LightTooltip>
    </div>
  );
};
