import api from '../../services/apiSgft';
import { SuppliersAtom } from '../../state/SuppliersFilter.atom';
import { Filter } from '../../types/Filter';
import { trainingTypeOptions } from '../trainingTypeOptions';

const fetchFilter = async (
  field: string,
  search: string,
  atom?: SuppliersAtom,
) => {
  const response = await api.get(`${field}`, {
    params: {
      size: 10,
      page: 1,
      getAll: true,
      search,
      supplier: atom?.supplier,
      training: atom?.training,
      trainingType: atom?.trainingType,
    },
  });
  return response.data.map((it: { name: string; id: string }) => ({
    label: it.name,
    value: String(it.id),
  }));
};

export const filters: Filter[] = [
  {
    key: 'supplier',
    name: 'Fornecedor',
    options: [],
    isMulti: false,
    asyncFn: (search, atom?: SuppliersAtom) =>
      fetchFilter('supplier/filter', search, atom),
    isDisabled: () => false,
  },
  {
    key: 'trainingType',
    name: 'Tipo de Treinamento',
    options: trainingTypeOptions,
    isMulti: true,
    asyncFn: undefined,
    isDisabled: () => false,
  },
  {
    key: 'training',
    name: 'Treinamento',
    options: [],
    isMulti: false,
    asyncFn: (search, atom?: SuppliersAtom) =>
      fetchFilter('trainings', search, atom),
    isDisabled: () => false,
  },
];
