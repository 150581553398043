import { Typography } from '@mui/material';
import { useState } from 'react';

import LightTooltip from '../atoms/LightTooltip';

type EmployeeTypeInfoProps = {
  isVacationReliefWorker: boolean;
  isFireFighter: boolean;
};

const EmployeeTypeInfo = ({
  isVacationReliefWorker,
  isFireFighter,
}: EmployeeTypeInfoProps) => {
  const [fireFighterTooltipEnabled, setFireFighterTooltipEnabled] =
    useState(false);
  const [vacationReliefTooltipEnabled, setVacationReliefTooltipEnabled] =
    useState(false);

  const handleFireFighterMouseEnter = () => {
    setFireFighterTooltipEnabled(true);
  };

  const handleFireFighterMouseLeave = () => {
    setFireFighterTooltipEnabled(false);
  };

  const handleVacationReliefMouseEnter = () => {
    setVacationReliefTooltipEnabled(true);
  };

  const handleVacationReliefMouseLeave = () => {
    setVacationReliefTooltipEnabled(false);
  };

  return (
    <div className="ml-1 flex w-4 flex-col items-center justify-center gap-1">
      {isFireFighter && (
        <div className="flex h-4 w-4 items-center justify-center rounded-full bg-[#F4503A]">
          <LightTooltip
            title={'Brigadista'}
            open={fireFighterTooltipEnabled}
            onClose={handleFireFighterMouseLeave}
            arrow
            placement="left-start"
          >
            <Typography
              sx={{
                fontWeight: 'bold',
                color: '#ffffff',
                fontFamily: 'Graphie, sans-serif',
                fontSize: '10px',
                textAlign: 'center',
              }}
              onMouseEnter={handleFireFighterMouseEnter}
              onMouseLeave={handleFireFighterMouseLeave}
              noWrap
              className="w-full text-left text-xs font-bold text-white"
            >
              B
            </Typography>
          </LightTooltip>
        </div>
      )}
      {isVacationReliefWorker && (
        <div className="flex h-4 w-4 items-center justify-center rounded-full bg-[#FFCF54]">
          <LightTooltip
            title={'Ferista'}
            open={vacationReliefTooltipEnabled}
            onClose={handleVacationReliefMouseLeave}
            arrow
            placement="right-end"
          >
            <Typography
              sx={{
                fontWeight: 'bold',
                color: '#ffffff',
                fontFamily: 'Graphie, sans-serif',
                fontSize: '10px',
                textAlign: 'center',
              }}
              onMouseEnter={handleVacationReliefMouseEnter}
              onMouseLeave={handleVacationReliefMouseLeave}
              noWrap
              className="w-full text-left text-xs font-bold text-white"
            >
              F
            </Typography>
          </LightTooltip>
        </div>
      )}
    </div>
  );
};

export default EmployeeTypeInfo;
