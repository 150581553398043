import { atom } from 'recoil';

export interface JobTrainingMatrixFilters
  extends Record<string, string[] | number[]> {
  trainingType: string[];
  location: string[];
  jobTitle: string[] | number[];
  training: string[];
  management: string[];
  areaCoordination: string[];
  workstation: string[];
}
const jobTrainingMatrixFiltersAtom = atom<JobTrainingMatrixFilters>({
  key: 'jobTrainingMatrixFilters',
  default: {
    trainingType: [],
    location: [],
    management: [],
    areaCoordination: [],
    workstation: [],
    jobTitle: [],
    training: [],
  },
});

export { jobTrainingMatrixFiltersAtom };
