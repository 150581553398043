import { Dialog, Transition } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';

import {
    OnNoticeData,
    OnNoticeResponse,
    OnNoticeResponseSchema,
} from '@/constants/OnNoticeConstants';

import { queryClient } from '../../App';
import api from '../../services/apiSgft';
import { OnNoticeTypeAtom } from '../../state/OnNoticeType.atom';
import { Button } from '../atoms/Button';
import CloseButton from '../atoms/CloseButton';
import { DatePicker, DatePickerRange } from '../atoms/DatePicker';
import { OnNoticeColumns } from '../atoms/OnNoticeColumns';
import Spinner from '../atoms/Spinner';
import SubtitleText from '../atoms/SubtitleText';
import TextComponent from '../atoms/TextComponent';
import ExpandableSubtitle from '../molecules/ExpandableSubtitle';
import OnNoticeCard from '../molecules/OnNoticeCard';
import Table from './Table';
interface Props {
  employeeId: number;
  modalIsOpen: boolean;
  handleClose: () => void;
}

export default function OnNoticeModal({
  employeeId,
  modalIsOpen,
  handleClose,
}: Props) {
  const [dates, setDates] = useState<DatePickerRange | undefined>({
    startDate: undefined,
    endDate: undefined,
  });
  const [type, setType] = useRecoilState(OnNoticeTypeAtom);
  const [editData, setEditData] = useState<OnNoticeData | null>(null);

  const fetchOnNoticeData = async (
    employeeId: number,
    dates: DatePickerRange | undefined,
  ) => {
    const response = await api.get('/on-notice/data', {
      params: {
        employeeId,
        startDate: dates?.startDate || null,
        endDate: dates?.endDate || null,
      },
    });
    const parsedSchema = OnNoticeResponseSchema.parse(response.data);
    return parsedSchema;
  };

  const { data, isLoading, isError } = useQuery<OnNoticeResponse>(
    ['on-notice', employeeId, dates],
    () => fetchOnNoticeData(employeeId, dates),
    {
      enabled: modalIsOpen,
      onError: () => {
        toast.error('Erro ao carregar os dados', {
          theme: 'colored',
          toastId: 'error',
        });
      },
    },
  );

  const handleDelete = async (data: OnNoticeData) => {
    try {
      await api.delete(`/on-notice?onNoticeId=${data.id}`);
      setType(null);
      queryClient.invalidateQueries(['team-structure']);
      queryClient.invalidateQueries(['on-notice', employeeId]);
    } catch (e) {
      toast.error('Não foi possível deletar o sobreaviso', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const handleEdit = async (data: OnNoticeData) => {
    setType('edit');
    setEditData(data);
  };

  useEffect(() => {
    setType(null);
    setEditData(null);
  }, [modalIsOpen]);

  return (
    <Transition appear show={modalIsOpen}>
      <Dialog className="relative z-50" onClose={handleClose}>
        <div className="fixed inset-0 flex w-screen items-center justify-center bg-black/30 p-4">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="custom-scrollbar max-h-[100vh] w-[50rem] flex-col justify-between overflow-auto overflow-y-scroll rounded-lg bg-white">
              <div className="w-full flex-col justify-between p-5">
                <div className="flex h-auto items-center justify-between">
                  <SubtitleText className="text-[20px]" subtitle="Sobreaviso" />
                  <CloseButton onClick={handleClose} className="mt-[-1rem]" />
                </div>
                {isLoading && (
                  <div className="flex h-40 w-full items-center justify-center">
                    <Spinner size={50} />
                  </div>
                )}
                {isError && (
                  <div className="flex h-40 w-full items-center justify-center">
                    <div className="text-red-500">
                      Não foi possível carregar os dados
                    </div>
                  </div>
                )}
                {data && (
                  <div className="mt-5">
                    <div className="mb-5 flex w-full justify-between">
                      <TextComponent
                        title="Nome"
                        value={data.name}
                        width="22rem"
                      />
                      <TextComponent
                        title="Chapa"
                        value={data.employee_number}
                        width="12rem"
                      />
                      <div className="w-[12rem]">
                        <div className="font-graphie text-[12px] text-[#4A4A4A]">
                          Datas
                        </div>
                        <DatePicker
                          value={dates}
                          onSelect={(dateRange) => setDates(dateRange)}
                          className={{ button: 'h-8 w-[16em]' }}
                          showShortCuts
                        />
                      </div>
                    </div>
                    <ExpandableSubtitle subtitle="Lista de datas">
                      {data.onNotice.length > 0 ? (
                        <div className="mb-5">
                          <Table
                            columns={OnNoticeColumns}
                            data={data.onNotice}
                            isWhiteTable
                            onEditTableRow={handleEdit}
                            onDeleteTableRow={handleDelete}
                            deleteMessage={{
                              title: 'Excluir sobreaviso',
                              description:
                                'Tem certeza que deseja excluir esse sobreaviso?',
                            }}
                          />
                        </div>
                      ) : (
                        <div className="flex justify-center p-10 font-graphie text-[13px] font-bold text-[#747474]">
                          Ainda não existem sobreavisos planejados
                        </div>
                      )}
                      {!type && (
                        <div className="flex justify-end">
                          <Button
                            type="button"
                            variant="link"
                            className="w-[200px] bg-white font-semibold text-[#193CB9] underline hover:bg-[#e9eaf1]"
                            onClick={() => setType('create')}
                          >
                            + Planejar sobreaviso
                          </Button>
                        </div>
                      )}
                    </ExpandableSubtitle>
                    <OnNoticeCard employeeId={employeeId} editData={editData} />
                  </div>
                )}
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
