import { ptBR } from 'date-fns/locale';
import * as React from 'react';
import { DateRange, DayPicker } from 'react-day-picker';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import { cn } from '../../utils/cn';
import { buttonVariants } from './Button';

export interface ShortCut {
  key: string;
  text: string;
  onClick: (onSelect: (dateRange: DateRange | undefined) => void) => void;
}

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  showShortCuts?: boolean;
  shortCuts?: ShortCut[];
  showClearButton?: boolean;
  onSelect: (date: DateRange | undefined) => void;
};

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  showShortCuts = false,
  showClearButton = true,
  shortCuts = [],
  ...props
}: CalendarProps) {
  return (
    <div className="flex bg-white ">
      {((showClearButton && props.mode !== 'single') || showShortCuts) && (
        <div className="my-2 flex h-auto w-40 flex-col justify-between border-r-2 px-2">
          {showShortCuts && (
            <div className="flex h-full flex-1 flex-col items-start justify-start space-y-2 pt-2 text-sm">
              {shortCuts.map((shortCut) => (
                <button
                  key={shortCut.key}
                  className="pointer w-full rounded-md p-2 text-left text-xs text-[#316c88] hover:bg-slate-300/20"
                  onClick={() => shortCut.onClick(props.onSelect)}
                >
                  {shortCut.text}
                </button>
              ))}
            </div>
          )}
          {showClearButton && (
            <div className="flex h-full  w-full items-end justify-center self-end">
              <button
                className="h-7 w-full rounded-md bg-[#5ac8fa] font-normal text-white hover:bg-[#5ac8fa]/60"
                onClick={() =>
                  props.onSelect({
                    from: undefined,
                    to: undefined,
                  })
                }
              >
                Limpar
              </button>
            </div>
          )}
        </div>
      )}

      <DayPicker
        showOutsideDays={false}
        className={cn('p-3', className)}
        locale={ptBR}
        classNames={{
          months:
            'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
          month: 'space-y-4',
          caption: 'flex justify-center pt-1 relative items-center',
          caption_label: 'text-sm font-medium',
          nav: 'space-x-1 flex items-center',
          nav_button: cn(
            buttonVariants({ variant: 'outline' }),
            'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
          ),
          nav_button_previous: 'absolute left-1',
          nav_button_next: 'absolute right-1',
          table: 'w-full border-collapse space-y-1',
          head_row: 'flex',
          head_cell:
            'text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]',
          row: 'flex w-full mt-px',
          cell: 'h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
          day: cn(
            buttonVariants({ variant: 'ghost' }),
            'h-9 w-9 p-0 font-normal aria-selected:opacity-100 rounded-none hover:bg-primary/10',
          ),
          day_selected:
            'bg-[#5ac8fa]/30 text-[#5ac8fa]-foreground hover:bg-[#5ac8fa] hover:text-[#5ac8fa]-foreground focus:bg-[#5ac8fa] focus:text-[#5ac8fa]-foreground',
          day_today: 'bg-accent text-accent-foreground',
          day_outside:
            'day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
          day_disabled: 'text-muted-foreground/',
          day_range_middle:
            'aria-selected:bg-accent aria-selected:text-accent-foreground',
          day_hidden: 'invisible',
          day_range_start:
            'rounded-l-full bg-[#5ac8fa]/90 text-white font-semibold hover:bg-[#5ac8fa]/80',
          day_range_end:
            'day-range-end rounded-r-full bg-[#5ac8fa]/90 text-white font-semibold hover:bg-[#5ac8fa]/80',
          ...classNames,
        }}
        components={{
          IconLeft: () => <FaChevronLeft className="h-4 w-4" />,
          IconRight: () => <FaChevronRight className="h-4 w-4" />,
          Footer: () => {
            if (showClearButton && props.mode === 'single') {
              return (
                <div className="flex h-full  w-full items-end justify-start self-end">
                  <button
                    className="h-7 w-20 rounded-md text-sm font-normal text-primary underline hover:bg-[#5ac8fa]/10"
                    onClick={() => props.onSelect(undefined)}
                  >
                    Limpar
                  </button>
                </div>
              );
            }
            return <></>;
          },
        }}
        {...props}
      />
    </div>
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
