/* eslint-disable no-unused-vars */
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { MouseEvent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Select, { MultiValue, Theme } from 'react-select';

import { ProcedureTraining, Training } from '@/types/Training';
import { isYear2099 } from '@/utils/formatDate';

import {
  Modality,
  ProcedureModality,
  procedureModalityOptions,
  SelectOption,
} from '../../constants/TrainingConstants';
import ControlledSimpleSelect from '../atoms/ControlledSimpleSelect';
import Input from '../atoms/Input';
import LightTooltip from '../atoms/LightTooltip';
import SubtitleText from '../atoms/SubtitleText';
import { styles } from '../ui/selectStyles';
import ValueContainer from '../ui/valueContainer';

export interface FormData {
  code: string;
  name: string;
  revision: string;
  isCritical: string;
  expirationDate: string;
  locations: string[];
  modalities: string[];
  totalWorkload?: string;
  validity: string;
  daysToCompletionDeadline: string;
  department: string;
  applicationLocations: string[];
  inPersonWorkload: string;
  mappingResponsible: string;
  equivalences: string[];
  procedureId: number;
}

interface TrainingFormFieldsProps {
  trainingData?: Training;
  procedureData?: ProcedureTraining;
  managers: any[];
  procedureTrainings: ProcedureTraining[];
  equivalencesProcedureTrainings: Training[];
  canSave?: boolean;
}

const ProcedureTrainingFormFields: React.FC<TrainingFormFieldsProps> = ({
  trainingData,
  procedureData,
  managers,
  procedureTrainings,
  equivalencesProcedureTrainings,
  canSave,
}) => {
  const { control, reset, register, watch, setValue } = useFormContext();
  const [selectedProcedure, setSelectedProcedure] =
    useState<ProcedureTraining>();
  const selectedProcedureId: string | number = watch('name');
  const selectedModality: Array<string> = watch('modalities');
  const selectedMappingResponsible: string = watch('mappingResponsible');
  const selectedEquivalences: Array<string> = watch('equivalences');
  const applicationLocations: Array<string> = watch('applicationLocations');

  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  const handleShouldShow = ({ currentTarget }: MouseEvent<Element>) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipEnabled(true);
    }
  };

  const procedureTrainingOptions =
    procedureTrainings?.map((training) => ({
      value: training.id,
      label: `${training.docTitle?.toLocaleUpperCase()} | Revisão: ${training?.revision}`,
    })) || [];

  const equivalencesProceduretrainingOptions =
    equivalencesProcedureTrainings?.map((training) => ({
      value: training.id?.toString(),
      label: `${training.name?.toLocaleUpperCase()}`,
    })) || [];

  const managerOptions = canSave
    ? managers?.map((manager) => ({
        value: String(manager?.id),
        label: `Nome:${manager?.name?.toString()} | Função:${manager.position?.toString()} | Gerência:${manager.management?.toString()} | Coodernação: ${manager?.areaCoordinator?.toString()}`,
      })) || []
    : [
        {
          value: String(trainingData?.mappingResponsible?.[0].id),
          label: `Nome:${trainingData?.mappingResponsible?.[0]?.name?.toString()} | Função:${trainingData?.mappingResponsible?.[0]?.role?.toString()} | Gerência:${trainingData?.mappingResponsible?.[0]?.management?.toString()} | Coodernação: ${trainingData?.mappingResponsible?.[0]?.areaCoordinator?.toString()}`,
        },
      ];
  const theme: (theme: Theme) => Theme = (theme) => ({
    ...theme,
    borderRadius: 4,
    spacing: {
      ...theme.spacing,
      controlHeight: 20,
    },
    colors: {
      ...theme.colors,
      neutral60: '#E5E5E5',
    },
  });
  useEffect(() => {
    if (selectedProcedureId) {
      const procedure = procedureTrainings?.find(
        (el) => el.id === selectedProcedureId,
      );

      if (procedure) {
        setSelectedProcedure(procedure);
        setValue('code', procedure?.name ?? '');
        setValue('procedureId', procedure?.id || 0);
        setValue('locations', procedure?.location ?? []);
        setValue('revision', procedure?.revision ?? '');
        setValue('isCritical', procedure?.isCritical ? 'Sim' : 'Não');
        setValue(
          'expirationDate',
          procedure?.expirationDate
            ? isYear2099(new Date(procedure.expirationDate))
              ? ''
              : format(new Date(procedure.expirationDate), 'dd/MM/yyyy', {
                  locale: ptBR,
                })
            : '',
        );
        setValue('department', procedure?.department ?? '');
        setValue('applicationLocations', procedure?.applicationLocations ?? []);
      }
    }
  }, [selectedProcedureId, procedureTrainings, setValue]);

  useEffect(() => {
    reset();

    setValue('code', procedureData?.name || '');
    setValue('name', procedureData?.docTitle?.toLocaleUpperCase() || '');
    setValue('revision', trainingData?.procedure?.revision || '');
    setValue('procedureId', trainingData?.procedure?.id || 0);
    setValue(
      'isCritical',
      trainingData ? (trainingData?.isCritical ? 'Sim' : 'Não') : '',
    );
    setValue(
      'expirationDate',
      procedureData?.expirationDate
        ? isYear2099(new Date(procedureData.expirationDate))
          ? ''
          : format(new Date(procedureData.expirationDate), 'dd/MM/yyyy', {
              locale: ptBR,
            })
        : '',
    );
    setValue('locations', trainingData?.locations || []);
    setValue('modalities', trainingData?.modalities || []);
    setValue(
      'totalWorkload',
      trainingData?.totalWorkload ? String(trainingData?.totalWorkload) : '',
    );
    setValue(
      'validity',
      trainingData?.validity ? String(trainingData.validity) : '',
    );
    setValue(
      'daysToCompletionDeadline',
      trainingData?.daysToCompletionDeadline
        ? String(trainingData?.daysToCompletionDeadline)
        : '',
    );
    setValue('department', procedureData?.department || '');
    setValue('applicationLocations', procedureData?.applicationLocations || []);
    setValue('inPersonWorkload', String(trainingData?.inPersonWorkload) || '');
    setValue(
      'mappingResponsible',
      String(trainingData?.mappingResponsible?.[0]?.id ?? '') || '',
    );
    setValue('equivalences', trainingData?.equivalences || []);

    setSelectedProcedure(procedureData);
  }, [trainingData, procedureData]);

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { type, name, value } = e.target;

    setValue(
      name,
      type === 'number' ? Math.min(Number(value), 999)?.toString() : value,
    );
  };

  const defaultProcedureOption = procedureTrainingOptions.find(
    (option) => option.value === selectedProcedure?.id,
  );

  return (
    <div className="p-5 pt-0">
      <div className="flex h-auto items-center justify-between pb-2">
        <div className="flex justify-between">
          <SubtitleText
            className="text-[20px]"
            subtitle={`Informações do Procedimento`}
          />
        </div>
      </div>
      <div className="mb-4 w-full">
        {procedureData ? (
          <Input
            label="Nome"
            type="string"
            {...register('name', {
              required: true,
              onChange: handleChange,
            })}
            required={false}
            disabled={true}
            key={'name'}
            name={'name'}
            className="w-[46rem] font-graphie"
          />
        ) : (
          <div className="flex w-[46rem] flex-col items-start">
            <div
              className={
                'font-graphie text-[12px] text-sm font-medium text-primary'
              }
            >
              Nome
            </div>
            <Select
              isSearchable
              options={procedureTrainingOptions}
              isDisabled={!canSave}
              isMulti={undefined}
              onChange={(newValue: any) => setValue('name', newValue?.value)}
              placeholder=""
              required={true}
              key={'name'}
              name={'name'}
              className={`w-full rounded-lg font-graphie text-sm font-medium uppercase text-primary`}
              theme={theme}
              styles={styles}
              components={{ ValueContainer }}
              noOptionsMessage={() => 'Nenhuma opção encontrada'}
              menuPosition="fixed"
              defaultValue={defaultProcedureOption}
            />
          </div>
        )}
      </div>
      <div className="mb-4 flex w-[46rem] justify-between gap-4">
        <Input
          label="Código"
          type="string"
          {...register('code', {
            required: true,
            onChange: handleChange,
          })}
          required={false}
          disabled={true}
          key={'code'}
          name={'code'}
          className="w-[14rem] font-graphie uppercase"
        />
        <Input
          label="Revisão"
          type="string"
          {...register('revision', {
            required: true,
            onChange: handleChange,
          })}
          required={false}
          disabled={true}
          key={'revision'}
          name={'revision'}
          className="w-[14rem] font-graphie uppercase"
        />
        <Input
          label="Criticidade"
          type="string"
          {...register('isCritical', {
            required: true,
            onChange: handleChange,
          })}
          required={false}
          disabled={true}
          key={'isCritical'}
          name={'isCritical'}
          className="w-full font-graphie uppercase"
        />
      </div>
      <div className="mb-4 flex w-[46rem] justify-between gap-4">
        <Input
          label="Validade do Procedimento"
          type="string"
          {...register('expirationDate', {
            required: false,
            onChange: handleChange,
          })}
          required={false}
          disabled={true}
          key={'expirationDate'}
          name={'expirationDate'}
          className="w-[22rem]"
          containerClassname="w-[22rem] font-graphie"
        />
        <Input
          label="Polos de Aplicação"
          type="string"
          {...register('locations', {
            required: true,
            onChange: handleChange,
          })}
          required={false}
          disabled={true}
          key={'locations'}
          name={'locations'}
          className="w-[22rem] uppercase"
          containerClassname="w-[22rem] font-graphie"
        />
      </div>
      <div className="mb-4 flex w-full justify-between gap-4">
        <LightTooltip
          title={applicationLocations?.join(', ')}
          open={tooltipEnabled}
          onClose={() => setTooltipEnabled(false)}
          arrow
          placement="top-start"
        >
          <Input
            label="Locais de Aplicação"
            type="string"
            {...register('applicationLocations', {
              required: true,
              onChange: handleChange,
            })}
            required={false}
            disabled={true}
            key={'applicationLocations'}
            name={'applicationLocations'}
            className="w-[46rem] uppercase"
            containerClassname="w-[22rem] font-graphie"
            onMouseEnter={handleShouldShow}
          />
        </LightTooltip>
      </div>
      <div className="mb-4 flex w-full justify-between gap-4">
        <Input
          label="Gerência"
          type="string"
          {...register('department', {
            required: true,
            onChange: handleChange,
          })}
          required={false}
          disabled={true}
          key={'department'}
          name={'department'}
          className="w-[46rem] uppercase"
          containerClassname="w-[22rem] font-graphie"
        />
      </div>
      {selectedProcedure && (
        <>
          <div className="flex h-auto items-center justify-between pb-2">
            <div className="flex justify-between">
              <SubtitleText
                className="font-graphie text-[20px]"
                subtitle={`Informações do Treinamento`}
              />
            </div>
          </div>
          <div className="flex w-[46rem] justify-between gap-[2.2rem]">
            <div className="mb-4 flex w-[46rem] justify-between gap-2">
              <ControlledSimpleSelect
                values={procedureModalityOptions}
                title="Modalidades"
                control={control}
                required={true}
                multiple={true}
                key={'modalities'}
                name={'modalities'}
                width="11rem"
                LabelClassName="font-medium text-sm text-primary font-graphie"
                disabled={!canSave}
              />
              <Input
                label="Carga Horária Total (h)"
                type="number"
                {...register('totalWorkload', {
                  required: false,
                  onChange: handleChange,
                })}
                max={999}
                min={0}
                required={false}
                disabled={
                  (!canSave && !trainingData?.isResponsible) ||
                  (selectedModality &&
                    selectedModality.includes(Modality.Semipresencial))
                }
                key={'totalWorkload'}
                name={'totalWorkload'}
                className="w-[10.5rem] font-graphie"
                containerClassname="gap-0 w-full"
              />
            </div>
            <div className="mb-4 flex w-[46rem] justify-between gap-2">
              <Input
                label="Validade (anos)"
                type="number"
                {...register('validity', {
                  required: false,
                  onChange: handleChange,
                })}
                max={999}
                min={0}
                required={false}
                name={'validity'}
                key={'validity'}
                disabled={!canSave}
                className="w-[10.5rem] font-graphie"
                containerClassname="gap-0 w-full"
              />
              <Input
                label="Prazo de Conclusão (dias)"
                type="string"
                {...register('daysToCompletionDeadline', {
                  required: false,
                  onChange: handleChange,
                })}
                required={false}
                className="w-[10.5rem] font-graphie"
                containerClassname="gap-0 w-full"
                name={'daysToCompletionDeadline'}
                key={'daysToCompletionDeadline'}
                disabled={
                  !canSave ||
                  (!selectedModality?.find(
                    (el) => el === ProcedureModality.Online,
                  ) &&
                    !selectedModality?.find(
                      (el) => el === ProcedureModality.SomenteLeitura,
                    ))
                }
              />
            </div>
          </div>
          <div className="flex w-full flex-col justify-between gap-4">
            <div className="flex w-[46rem] flex-col items-start">
              <div
                className={
                  'font-graphie text-[12px] text-sm font-medium text-primary'
                }
              >
                Equivalências
              </div>
              <Select
                isSearchable
                options={equivalencesProceduretrainingOptions}
                isDisabled={!canSave}
                isMulti={true}
                onChange={(newValue: MultiValue<SelectOption>) =>
                  setValue(
                    'equivalences',
                    newValue.map((el) => el.value),
                  )
                }
                placeholder=""
                required={false}
                key={'equivalences'}
                name={'equivalences'}
                className={`w-full rounded-lg font-graphie text-sm font-medium text-primary`}
                theme={theme}
                styles={styles}
                components={{ ValueContainer }}
                noOptionsMessage={() => 'Nenhuma opção encontrada'}
                menuPosition="fixed"
                defaultValue={
                  selectedEquivalences
                    ? equivalencesProceduretrainingOptions.filter((el) =>
                        selectedEquivalences.includes(el.value),
                      )
                    : []
                }
              />
            </div>
            <div className="flex w-[46rem] flex-col items-start">
              <div
                className={
                  'font-graphie text-[12px] text-sm font-medium text-primary'
                }
              >
                Responsável pelo Mapeamento
              </div>
              <Select
                isSearchable
                options={managerOptions}
                isDisabled={!canSave}
                isMulti={undefined}
                onChange={(newValue: any) =>
                  setValue('mappingResponsible', newValue?.value)
                }
                placeholder=""
                required={false}
                key={'mappingResponsible'}
                name={'mappingResponsible'}
                className={`w-full rounded-lg font-medium`}
                theme={theme}
                styles={styles}
                components={{ ValueContainer }}
                noOptionsMessage={() => 'Nenhuma opção encontrada'}
                menuPosition="fixed"
                defaultValue={managerOptions.find(
                  (el) => el.value === selectedMappingResponsible,
                )}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProcedureTrainingFormFields;
