import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import CreateFixedAllocation from '@/components/molecules/CreateFixedAllocation';
import Wrapper from '@/components/molecules/Wrapper';
import Table from '@/components/organisms/Table';
import { columns } from '@/constants/tableColumns/FixedAllocationsColumns';
import api from '@/services/apiSgft';
import { FixedAllocationData } from '@/types/FixedAllocation';

import EmptyIcon from '../../assets/icons/empty.svg';

const FixedAllocation = () => {
  const fetchFixedAllocations = async () => {
    try {
      const response = await api.get(
        '/position/get-all-fixed-position-allocation',
      );
      return response.data;
    } catch (e: any) {
      if (e.response.status !== 500) {
        toast.error(e.response.data.message, {
          theme: 'colored',
          toastId: 'error',
        });
      } else {
        toast.error('Erro ao carregar os dados', {
          theme: 'colored',
          toastId: 'error',
        });
        throw e;
      }
    }
  };

  const { data, isLoading, isError } = useQuery<FixedAllocationData[]>(
    ['fixed-allocation'],
    fetchFixedAllocations,
    {
      retry: false,
    },
  );

  return (
    <div className="flex justify-center">
      <div className="fixed right-20 top-3 flex items-center">
        <CreateFixedAllocation />
      </div>
      <Wrapper isLoading={isLoading} isError={isError}>
        {data && data.length > 0 ? (
          <Table columns={columns} data={data} />
        ) : (
          <div className="flex h-full w-full flex-col items-center justify-center rounded-md bg-white">
            <img src={EmptyIcon} className="w-40" />
            <div className="w-full text-center text-sm">
              <p>Nenhuma alocação fixa foi encontrada!</p>
              <span className="font-semibold text-blue-800">
                Cadastre uma alocação para poder visualizá-la!
              </span>
            </div>
          </div>
        )}
      </Wrapper>
    </div>
  );
};

export default FixedAllocation;
