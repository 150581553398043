import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import EmptyIcon from '@/assets/icons/empty.svg';
import Spinner from '@/components/atoms/Spinner';
import SideFilter from '@/components/molecules/SideFilter';
import { TrainingFileTeamCard } from '@/components/organisms/TrainingFileTeamCard';
import api from '@/services/apiSgft';
import { trainingFilesDetailsFiltersAtom } from '@/state/TrainingFilesDetailsFilter.atom';
import { Filter } from '@/types/Filter';
import { TrainingFileDetails } from '@/types/TrainingFile';

const TrainingFileTeams = () => {
  const [searchParams] = useSearchParams();
  const filterValues = useRecoilValue(trainingFilesDetailsFiltersAtom);
  const [filteredData, setFilteredData] = useState(filterValues);
  let paramTeam = searchParams.get('turma');
  const { id: trainingFileId } = useParams();
  const fetchDetails = async () => {
    const params = {
      startDate: filteredData.timeRange.startDate,
      endDate: filteredData.timeRange.endDate,
      employees: filteredData.employee,
      managements: filteredData.management,
      teams: paramTeam ? [...filteredData.team, paramTeam] : filteredData.team,
      areaBoards: filteredData.areaBoard,
      roles: filteredData.role,
      workStations: filteredData.workStation,
    };
    const response = await api.get(
      `training-plannings/details/${trainingFileId}`,
      {
        params,
      },
    );
    return response.data;
  };

  const { data, isLoading } = useQuery<TrainingFileDetails>(
    ['training-file-details', paramTeam, trainingFileId, filteredData],
    fetchDetails,
  );
  const handleApplyFilter = (isReseting: boolean) => {
    paramTeam = null;
    searchParams.delete('turma');
    if (isReseting) {
      setFilteredData(() => ({
        team: [],
        timeRange: {
          endDate: undefined,
          startDate: undefined,
        },
        employee: [],
        management: [],
        areaBoard: [],
        role: [],
        workStation: [],
      }));
    } else {
      setFilteredData(() => ({
        ...filterValues,
      }));
    }
  };
  const fetchFilter = async (field: string, search: string) => {
    const response = await api.get(`${field}/filter`, {
      params: {
        trainingFileId: Number(trainingFileId),
        search,
        size: 10,
        page: 1,
      },
    });
    const options = response.data.map((it: { name: string; id: string }) => ({
      label: it.name,
      value: String(it.id),
    }));
    return options;
  };
  const filters: Filter[] = [
    {
      key: 'timeRange',
      name: 'Período',
      options: [],
      isMulti: true,
      asyncFn: undefined,
      isDisabled: () => false,
    },
    {
      key: 'team',
      name: 'Turma',
      options: [],
      isMulti: true,
      asyncFn: (search) => fetchFilter('training-plannings/teams', search),
      isDisabled: () => false,
    },
    {
      key: 'management',
      name: 'Gerência',
      options: [],
      isMulti: true,
      asyncFn: (search) => fetchFilter('employees/management', search),
      isDisabled: () => false,
    },

    {
      key: 'areaBoard',
      name: 'Coordenação',
      options: [],
      isMulti: true,
      asyncFn: (search) => fetchFilter('employees/area-board', search),
      isDisabled: () => false,
    },
    {
      key: 'role',
      name: 'Função',
      options: [],
      isMulti: true,
      asyncFn: (search) => fetchFilter('employees/role', search),
      isDisabled: () => false,
    },
    {
      key: 'workStation',
      name: 'Local de Trabalho',
      options: [],
      isMulti: true,
      asyncFn: (search) => fetchFilter('work-station', search),
      isDisabled: () => false,
    },

    {
      key: 'employee',
      name: 'Colaborador',
      options: [],
      isMulti: true,
      asyncFn: (search) => fetchFilter('employees', search),
      isDisabled: () => false,
    },
  ];
  return (
    <>
      <SideFilter
        refetchOnChange
        filters={filters}
        atom={trainingFilesDetailsFiltersAtom}
        applyChanges={handleApplyFilter}
      />
      <div className="flex flex-col gap-2 py-3">
        <div className="flex gap-5">
          <div className="w-48 rounded-md border-2 border-gray-200 bg-white px-4 py-3">
            <p className="text-xs">Treinamentos em folga</p>
            <h4 className="text-3xl font-bold text-primary">
              {data?.dayOffTrainingCount ?? 0}
            </h4>
          </div>
          <div className="w-48 rounded-md border-2 border-gray-200 bg-white px-4 py-3">
            <p className="text-xs">Transportes solicitados</p>
            <h4 className="text-3xl font-bold text-primary">
              {data?.requestedTransportCount ?? 0}
            </h4>
          </div>
          <div className="w-48 rounded-md border-2 border-gray-200 bg-white px-4 py-3">
            <p className="text-xs">Dias de alojamento</p>
            <h4 className="text-3xl font-bold text-primary">
              {data?.extraAccommodationCount ?? 0}
            </h4>
          </div>
          <div className="w-48 rounded-md border-2 border-gray-200 bg-white px-4 py-3">
            <p className="text-xs">Refeições extra</p>
            <h4 className="text-3xl font-bold text-primary">
              {data?.extraMealCount ?? 0}
            </h4>
          </div>
        </div>
        <div>
          {isLoading ? (
            <div
              className="flex h-full w-full items-center justify-center"
              key="loading-1"
            >
              <Spinner size={50} />
            </div>
          ) : data?.teams.length ? (
            <div className="flex flex-col gap-4">
              {data?.teams.map((planningTeam) => (
                <TrainingFileTeamCard
                  trainingFileTeam={planningTeam}
                  key={planningTeam.id}
                  defaultOpen={!!paramTeam}
                />
              ))}
            </div>
          ) : (
            <div className="my-4 flex h-full min-h-[50vh] w-full flex-col items-center justify-center rounded-md bg-white">
              <img src={EmptyIcon} className="w-40" />
              <div className="w-full text-center text-sm">
                <p>Nenhuma turma encontrada com esses parâmetros</p>
                <span className="font-semibold text-blue-800">
                  Cadastre um ou altere os valores dos filtros para visualizar!
                </span>
              </div>
            </div>
          )}
          {}
        </div>
      </div>
    </>
  );
};

export default TrainingFileTeams;
