interface Props {
  title: string;
}

const Title = ({ title }: Props) => {
  return (
    <div className="bg-[#193CB9] text-white font-semibold text-[19px] flex items-center h-12 rounded-[15px] px-3 mb-2">
      {title}
    </div>
  );
};

export default Title;
