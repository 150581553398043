import { CardContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MouseEvent, useState } from 'react';

import LightTooltip from './LightTooltip';

const CardContentNoPadding = styled(CardContent)(`
  padding: 0;
  &:last-child {
    padding-bottom: 0;
  }
`);
export interface IProps {
  value: number;
  status: string;
  title: string;
  onClick?: any;
  width?: string;
  borderColor?: string;
}

const formatValue = (value: number) => {
  if (value < 10) {
    return `0${value}`;
  }
  return `${value}`;
};

const Card = ({
  value,
  status,
  title,
  onClick,
  width,
  borderColor,
}: IProps) => {
  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  const handleShouldShow = ({ currentTarget }: MouseEvent<Element>) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipEnabled(true);
    }
  };

  return (
    <div className="mr-[10px] font-graphie">
      <CardContentNoPadding
        sx={{
          background: '#FFFFFF',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          fontFamily: 'inherit',
          width: width || '12rem',
          whiteSpace: 'nowrap',
          padding: '10px',
          borderColor: borderColor || 'black',
          borderWidth: borderColor ? '1px' : 'none',
          borderStyle: borderColor ? 'solid' : 'solid',
          cursor: onClick ? 'pointer' : 'auto',
        }}
        onClick={onClick ?? onClick}
      >
        <LightTooltip
          title={title}
          open={tooltipEnabled}
          onClose={() => setTooltipEnabled(false)}
          arrow
          placement="top-end"
        >
          <Typography
            sx={{
              color: '#4A4A4A',
              width: width || '10rem',
              fontSize: '14px',
              fontWeight: 500,
              fontFamily: 'inherit',
            }}
            onMouseEnter={handleShouldShow}
            textOverflow={'hidden'}
            noWrap
          >
            {title}
          </Typography>
        </LightTooltip>
        <Typography
          sx={{
            fontSize: '22px',
            fontWeight: '700',
            font: 'graphie',
            marginBottom: '10px',
          }}
          color={status}
        >
          {formatValue(value)}
        </Typography>
      </CardContentNoPadding>
    </div>
  );
};

export default Card;
