import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { queryClient } from '@/App';
import api from '@/services/apiSgft';

import { Button } from '../atoms/Button';
import ControlledSimpleSelect from '../atoms/ControlledSimpleSelect';
import Modal from '../atoms/Modal';
import SearchSelect from '../atoms/SearchSelect';

interface FixedAllocationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface Employee {
  company: string;
  employeeNumber: string;
  id: number;
  name: string;
  thirdParty: boolean;
}
interface Names {
  name: string;
  employees: Employee[];
}

const FixedAllocationModal: React.FC<FixedAllocationModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [name, setName] = useState('');
  const methods = useForm({
    defaultValues: {
      bondType: '',
      employeeName: '',
      employeeId: '',
    },
  });

  const bondTypeWatch = methods.watch(`bondType`);
  const employeeNameWatch = methods.watch(`employeeName`);

  const onSubmit = async (data: any) => {
    setIsSubmiting(true);

    try {
      await api.post('position/register-as-fixed-position-allocation', {
        employeeId: data.employeeId,
      });
      toast.success('Alocação fixa criada com sucesso!', {
        theme: 'colored',
        toastId: 'success',
      });
    } catch (error: any) {
      toast.error(
        `${'Erro ao criar alocação fixa, tente novamente mais tarde!'}`,
        {
          theme: 'colored',
          toastId: 'error',
        },
      );
      throw error;
    } finally {
      setIsSubmiting(false);
      closeAndClear();
    }
  };

  const closeAndClear = () => {
    methods.reset();
    onClose();
    queryClient.invalidateQueries(['fixed-allocation']);
  };

  const fetchEmployees = async () => {
    const response = await api.get(
      'position/get-all-fixed-position-allocation-employee-options',
      {
        params: {
          search: name.toUpperCase(),
          thirdParty: bondTypeWatch === 'thirdParty',
        },
      },
    );
    return response.data;
  };
  const { data } = useQuery<Names[]>(
    ['get-all-employees', bondTypeWatch, name],
    fetchEmployees,
    {
      retry: false,
    },
  );

  const employeesIds = employeeNameWatch
    ? data
        ?.find((el) => el.name === employeeNameWatch)
        ?.employees.map((el: Employee) => ({
          value: el.id,
          label: el.employeeNumber,
        }))
    : [];

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeAndClear}
      title={'Cadastro de colaboradores com alocação fixa'}
      className="w-[45rem] max-w-[85vw]"
    >
      <FormProvider {...methods}>
        <form
          className="space-between flex h-[90%] flex-col px-5 py-2"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className="mb-4 flex w-[43rem] items-end justify-between">
            <ControlledSimpleSelect
              title={'Vínculo'}
              required
              control={methods.control}
              name={`bondType`}
              width="13.5rem"
              values={[
                { value: 'thirdParty', label: 'Terceiros' },
                { value: 'origem', label: 'Origem Energia' },
              ]}
            />
            <SearchSelect
              {...methods.register('employeeName')}
              control={methods.control}
              title="Nome"
              options={
                data?.map((el) => {
                  return {
                    label: el.name,
                    value: el.name,
                  };
                }) || []
              }
              required
              setSearch={(e) => setName(e)}
              disabled={!bondTypeWatch}
            />
            <ControlledSimpleSelect
              title={`${bondTypeWatch === 'thirdParty' ? 'Número do Suricata' : 'Chapa'}`}
              required
              control={methods.control}
              name={`employeeId`}
              width="13.5rem"
              values={employeesIds}
              disabled={!employeeNameWatch}
            />
          </div>
          <div className="my-2 mt-[2rem] flex w-full justify-between">
            <Button
              type="button"
              className="h-8 min-w-36 border-2 border-[#193CB9] bg-white text-[#193CB9] hover:bg-[#e9eaf1]"
              onClick={closeAndClear}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              className=" h-8 min-w-36"
              isLoading={isSubmiting}
            >
              Salvar
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default FixedAllocationModal;
