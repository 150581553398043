import { Grid } from '@mui/material';

import { SupplierRegister, Training } from '../../types/Supplier';
import SupplierCard from '../atoms/SupplierCard';

export interface IProps {
  cards: SupplierRegister[];
  setModalIsOpen: (value: boolean) => void;
}

const SuppliersCards = ({ cards, setModalIsOpen }: IProps) => {
  const handleTrainings = (trainings: Training[]) => {
    return trainings.map((training) => {
      if (training.training.name.includes(' (REVISÃO')) {
        return {
          ...training,
          training: {
            ...training.training,
            name: training.training.name.split(' (REVISÃO')[0],
          },
        };
      }
      return training;
    });
  };

  return (
    <div className="w-full py-[1.5rem]">
      <Grid container spacing={3}>
        {cards.map((el, index) => (
          <Grid
            key={index}
            item
            xs={4}
            display="flex"
            flexWrap={'wrap'}
            justifyContent={'center'}
          >
            <SupplierCard
              id={el.id}
              active={el.active}
              name={el.name}
              email={el.email}
              trainings={handleTrainings(el.trainings)}
              trainingTypes={el.trainingTypes}
              onlineMaxDays={el.onlineMaxDays}
              inPersonMaxDays={el.inPersonMaxDays}
              setModalIsOpen={setModalIsOpen}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default SuppliersCards;
