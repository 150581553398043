import { atom } from 'recoil';

export interface MultipleReports
  extends Record<string, number[] | number | null> {
  parentsId: number[];
  positionId: number | null;
}

const multipleReportsAtom = atom<MultipleReports>({
  key: 'multipleReports',
  default: {
    parentsId: [],
    positionId: null,
  },
});

export { multipleReportsAtom };
