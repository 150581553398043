import { ColumnDef } from '@tanstack/react-table';

import TableCellDate from '@/components/atoms/TableCellDate';
import TableData from '@/components/atoms/TableData';
import { cn } from '@/utils/cn';
import { removeTimeZone } from '@/utils/formatDate';

import { Availability } from '../TrainingPlanning';

interface TableColumns {
  date: Date;
  availability: {
    availability: Availability;
    motive: string;
  };
}
export const availabilityColumns: Array<ColumnDef<TableColumns>> = [
  {
    accessorKey: 'date',
    header: 'Data',
    meta: {
      headerClassName: 'text-left',
    },
    cell: (props) => {
      const date = removeTimeZone(new Date(props.getValue() as Date));
      return (
        <div className="w-32">
          <TableCellDate date={date} />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'availability',
    header: 'Disponibilidade',
    meta: {
      headerClassName: 'text-left',
    },
    cell: (props) => {
      const availability = (
        props.getValue() as { availability: string; motive: string }
      ).availability;
      return (
        <div className="w-32">
          <TableData
            tableValue={availability}
            className={cn(
              availability === Availability.Disponivel
                ? 'text-[#10AE88]'
                : 'text-[#F4503A]',
            )}
          />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'availability',
    header: 'Motivo',
    meta: {
      headerClassName: 'text-left',
    },
    cell: (props) => {
      return (
        <div className="w-[15rem]">
          <TableData
            tableValue={
              (props.getValue() as { availability: string; motive: string })
                .motive ?? '-'
            }
          />
        </div>
      );
    },
    enableSorting: false,
  },
];
