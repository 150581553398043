import React from 'react';

import { cn } from '@/utils/cn';

import TableCellText from './TableCellText';

interface TatableCellInterface {
  children: React.ReactNode;
  text: string;
  isOpen: boolean;
  containerClassName?: string;
  tableCellClassName?: string;
}

export const TatableCell = ({
  children,
  isOpen,
  text,
  containerClassName = '',
  tableCellClassName = '',
}: TatableCellInterface) => {
  return (
    <div className={cn('flex w-full flex-col', containerClassName)}>
      <TableCellText
        text={text}
        className={cn('flex h-12 items-center', tableCellClassName)}
      />
      {isOpen ? (
        <div className="flex flex-col items-center gap-2  py-2 text-xs">
          {children}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
