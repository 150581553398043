import { isSameDay, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { UseFieldArrayRemove, useFormContext } from 'react-hook-form';

import api from '../../services/apiSgft';
import { Coverage, CoverageStructure } from '../../types/VacationRegister';
import { formatDate, removeTimeZone } from '../../utils/formatDate';
import { getDateRange } from '../../utils/getDateRange';
import ControlledSimpleSelect, {
  Values,
} from '../atoms/ControlledSimpleSelect';
import { DatePicker } from '../atoms/DatePicker';
import TrashButton from '../atoms/TrashButton';

interface Props {
  employees: { id: string; name: string }[];
  structures: CoverageStructure[] | undefined;
  index: number;
  idx: number;
  vacationCovers?: { startDate: Date; endDate: Date }[];
  remove: UseFieldArrayRemove;
}

const VacationCoverPlanPeriod = ({
  index,
  employees,
  idx,
  vacationCovers,
  remove,
  structures,
}: Props) => {
  const [employeeValues, setEmployeeValues] = useState<Values[]>([]);
  const [notAllowedPeriods, setNotAllowedPeriods] = useState<
    {
      startDate: Date;
      endDate: Date;
    }[]
  >([]);
  const { control, getValues, setValue, watch } = useFormContext();

  let names = employees.map((el) => {
    return { value: el.id, label: el.name };
  });

  names = names.concat([
    { value: Coverage.NO_NEED, label: 'NÃO NECESSITA DE COBERTURA' },
    { value: Coverage.THIRD_PARTY, label: 'COBERTURA POR TERCEIROS' },
    {
      value: Coverage.FROM_ANOTHER_AREA,
      label: 'COBERTURA POR OUTRA ESTRUTURA',
    },
    { value: Coverage.VIA_CLASS_CHANGE, label: 'COBERTURA VIA FERISTA' },
  ]);

  const outOfTeamCoverage = [
    Coverage.NO_NEED,
    Coverage.THIRD_PARTY,
    Coverage.FROM_ANOTHER_AREA,
    Coverage.VIA_CLASS_CHANGE,
  ];
  const periodStartDate = new Date(
    getValues(`vacationPeriods.${index}.startDate`),
  );
  const periodEndDate = new Date(periodStartDate);
  const totalDays = getValues(`vacationPeriods.${index}.totalDays`);
  periodEndDate.setDate(periodStartDate.getDate() + (totalDays - 1));
  const fetchAvailableCoverDays = async (coverageEmployeeId: number | null) => {
    const fullPeriod = getDateRange(periodStartDate, periodEndDate);
    let daysToBeCovered: Array<Date> = [];
    if (!coverageEmployeeId) {
      daysToBeCovered =
        vacationCovers?.flatMap((vacationCover) => {
          return getDateRange(
            removeTimeZone(new Date(vacationCover.startDate)),
            removeTimeZone(new Date(vacationCover.endDate)),
          );
        }) ?? [];
    } else {
      const employeeId = getValues('employeeId');
      const response = await api.get('/vacation/coverage/availableDates', {
        params: {
          employeeId,
          coverageEmployeeId,
          startDate: periodStartDate,
          endDate: periodEndDate,
          isFromAnotherStructure: true,
        },
      });
      daysToBeCovered = response.data.map((date: string) => parseISO(date));
    }
    const newNotAllowedPeriods: Array<{
      startDate: Date;
      endDate: Date;
    }> = [];
    fullPeriod.forEach((day) => {
      if (
        !daysToBeCovered.some((dayDoBeCovered) =>
          isSameDay(day, dayDoBeCovered),
        )
      ) {
        newNotAllowedPeriods.push({
          startDate: day,
          endDate: day,
        });
      }
    });
    setNotAllowedPeriods(newNotAllowedPeriods);
  };

  const structuresValues = structures
    ? structures.map((structure) => {
        return {
          value: structure.id,
          label: structure.name,
        };
      })
    : undefined;

  const handleStructureSelectValue = () => {
    const structureEmployeeSelectValues = structures
      ? structures
          .filter((structure) => structure.id === watchStructureName)
          .flatMap((structure) =>
            structure.employees.map((employee) => ({
              value: employee.id,
              label: employee.name,
            })),
          )
      : [];

    setEmployeeValues(structureEmployeeSelectValues);
  };

  const fieldName = `vacationPeriods.${index}.vacationCovers.${idx}.name`;

  const structureName = `vacationPeriods.${index}.vacationCovers.${idx}.structure.id`;

  let startDate = null;
  let endDate = null;
  const watchedStartDate = watch(
    `vacationPeriods.${index}.vacationCovers.${idx}.startDate`,
  );
  const watchedEndDate = watch(
    `vacationPeriods.${index}.vacationCovers.${idx}.endDate`,
  );

  if (watchedStartDate) {
    startDate = watchedStartDate;

    endDate = watchedEndDate;
  }

  const watchedPeriodStartDate = watch(`vacationPeriods.${index}.startDate`);
  const watchedPeriodEndDate = watch(`vacationPeriods.${index}.endDate`);

  const watchFieldName = watch(
    `vacationPeriods.${index}.vacationCovers.${idx}.coverageType`,
  );

  const watchStructureName = watch(
    `vacationPeriods.${index}.vacationCovers.${idx}.structure.id`,
  );

  useEffect(() => {
    if (watchedPeriodStartDate && watchedPeriodEndDate) {
      const employeeId = getValues(
        `vacationPeriods.${index}.vacationCovers.${idx}.employeeId`,
      );
      fetchAvailableCoverDays(employeeId);
    }
  }, [watchedPeriodStartDate, watchedPeriodEndDate]);

  useEffect(() => {
    handleStructureSelectValue();
  }, [watchStructureName]);

  // Use a single useEffect to set the initial value based on existing data
  useEffect(() => {
    const employeeId = getValues(
      `vacationPeriods.${index}.vacationCovers.${idx}.employeeId`,
    );
    const coverageType = getValues(
      `vacationPeriods.${index}.vacationCovers.${idx}.coverageType`,
    );

    // Determine the initial value and fetch cover days as necessary
    const initialValue =
      coverageType === Coverage.FROM_ANOTHER_AREA
        ? coverageType
        : employeeId || coverageType || '';
    if (employeeId) {
      fetchAvailableCoverDays(employeeId);
    } else {
      fetchAvailableCoverDays(null);
    }

    // Update the form with the initial value
    setValue(fieldName, initialValue);
  }, []);

  return (
    <div className="mb-5 flex flex-col gap-2">
      <div className="flex items-end justify-between gap-5">
        <ControlledSimpleSelect
          width="19.5rem"
          title={'Nome'}
          onChange={(e) => {
            const newValue = String(e.target.value);
            const isCoverageType = outOfTeamCoverage.includes(newValue);

            setValue(fieldName, newValue);

            if (isCoverageType) {
              setValue(
                `vacationPeriods.${index}.vacationCovers.${idx}.coverageType`,
                e.target.value,
              );
              setValue(
                `vacationPeriods.${index}.vacationCovers.${idx}.employeeId`,
                undefined,
              );
              fetchAvailableCoverDays(null);
            } else {
              setValue(
                `vacationPeriods.${index}.vacationCovers.${idx}.coverageType`,
                Coverage.STANDARD,
              );
              setValue(
                `vacationPeriods.${index}.vacationCovers.${idx}.employeeId`,
                e.target.value,
              );
              fetchAvailableCoverDays(Number(newValue));
            }
          }}
          values={names}
          name={fieldName}
          control={control}
          changeItemColor
          changeItems={outOfTeamCoverage}
        />
        <div className="w-[19.5rem]">
          <DatePicker
            value={{
              endDate: endDate
                ? formatDate(removeTimeZone(new Date(endDate)))
                : undefined,
              startDate: startDate
                ? formatDate(removeTimeZone(new Date(startDate)))
                : undefined,
            }}
            startFrom={periodStartDate}
            minDate={periodStartDate}
            maxDate={periodEndDate}
            disabledDates={notAllowedPeriods}
            onSelect={(val) => {
              const startDate = val?.startDate;
              const endDate = val?.endDate;
              setValue(
                `vacationPeriods.${index}.vacationCovers.${idx}.startDate`,
                startDate,
              );
              setValue(
                `vacationPeriods.${index}.vacationCovers.${idx}.endDate`,
                startDate && !endDate ? startDate : endDate,
              );
            }}
          />
        </div>
        <TrashButton onClick={() => remove(idx)} />
      </div>
      {watchFieldName === Coverage.FROM_ANOTHER_AREA && (
        <ControlledSimpleSelect
          width="19.5rem"
          title="Estrutura"
          values={structuresValues}
          name={structureName}
          control={control}
        />
      )}
      {watchFieldName === Coverage.FROM_ANOTHER_AREA &&
        employeeValues.length > 0 && (
          <ControlledSimpleSelect
            width="19.5rem"
            title="Colaborador"
            values={employeeValues}
            onChange={(e) => {
              const newValue = String(e.target.value);

              setValue(
                `vacationPeriods.${index}.vacationCovers.${idx}.employeeId`,
                e.target.value,
              );
              fetchAvailableCoverDays(Number(newValue));
            }}
            name={`vacationPeriods.${index}.vacationCovers.${idx}.employeeId`}
            control={control}
          />
        )}
    </div>
  );
};
export default VacationCoverPlanPeriod;
