import { useEffect, useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useRecoilState } from 'recoil';

import { chartMarginAtom } from '@/state/ChartMargin.atom';

import LineVector from '../../assets/icons/line-vector.svg';
import { Button } from './Button';

interface Props {
  board?: string;
  management?: string;
  coordination?: string;
  className?: string;
  toPrint?: boolean;
}

const ChartBreadcrumbs = ({
  board,
  management,
  coordination,
  className,
  toPrint,
}: Props) => {
  const [_, setChartMargin] = useRecoilState(chartMarginAtom);
  management = management === 'N/A' ? undefined : management;
  coordination = coordination === 'N/A' ? undefined : coordination;

  const boardStyle =
    management || coordination
      ? 'text-[#A1A1A1]'
      : 'text-[#193DB5] font-medium';
  const managementStyle = coordination
    ? 'text-[#A1A1A1]'
    : 'text-[#193DB5] font-medium';
  const [open, setOpen] = useState(toPrint);
  const boxSize = management ? (coordination ? 'h-28' : 'h-20') : 'h-8';
  const printBoxSize = management
    ? coordination
      ? 'h-[8.5rem]'
      : 'h-[5.5rem]'
    : 'h-8';

  useEffect(() => {
    const marginSize = open
      ? management
        ? coordination
          ? 'large'
          : 'medium'
        : 'small'
      : 'base';
    setChartMargin(marginSize);
  }, [coordination, management, open, setChartMargin]);

  return (
    <div className="relative z-10 flex flex-col">
      <Button
        variant={'secondary'}
        className={`h-12 w-[20rem] justify-around rounded-[0.5rem] p-1 text-[15px] font-medium text-[#193DB5] shadow-md ${toPrint && 'h-12 w-[26rem] text-[18px]'}`}
        onClick={open ? () => setOpen(false) : () => setOpen(true)}
      >
        Controle de Hierarquia
        {open ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </Button>
      {open && (
        <div
          className={`mt-1 ${boxSize} w-[20rem] rounded-[0.5rem] bg-white p-2 font-graphie text-[12px] shadow-md ${toPrint && `${printBoxSize} w-[26rem]`} ${className}`}
        >
          {board && (
            <div className={`${boardStyle}`}>{`Diretoria: ${board}`}</div>
          )}
          {management && (
            <div className="ml-2 flex">
              <img
                alt="Ícone vetor de semicírculo"
                src={LineVector}
                className="mr-1 w-[10px] flex-none"
              />
              <div
                className={`${managementStyle} mt-2`}
              >{`Gerência: ${management}`}</div>
            </div>
          )}
          {coordination && (
            <div className="ml-4 flex">
              <img
                alt="Ícone vetor de semicírculo"
                src={LineVector}
                className="mr-1 w-[10px] flex-none"
              />
              <div className="mt-2 font-medium text-[#193DB5]">{`Coordenação: ${coordination}`}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ChartBreadcrumbs;
