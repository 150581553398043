import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { queryClient } from '@/App';
import { actualUserAtom } from '@/state/ActualUser.atom';
import { FirstAllocationAtom } from '@/state/FirstAllocation.atom';
import {
  userCanSave,
  userCanSaveAllocation,
} from '@/utils/handleSavePermissions';

import SimpleMenu from '../atoms/Menu';
import AllocationModal from '../organisms/AllocationModal';
import { AliasModal } from './AliasModal';

interface ViewMenuProps {
  employeeId: number;
  managementId: number;
  isFirstAllocation: boolean;
  id: number;
  admissionDate: string;
  inactiveStatus?: boolean;
  employeeName: string;
  employeeAlias?: string;
  employeePole: string;
  employeePosition: string;
  employeeRegime: string;
  employeeEmail: string;
  employeeNumber: string;
}
type ModalType = 'alocações' | 'alocação-inicial' | 'alias' | null;
const ViewMenu = ({
  employeeId,
  isFirstAllocation,
  managementId,
  id,
  admissionDate,
  inactiveStatus = false,
  employeeName,
  employeeAlias,
  employeePole,
  employeePosition,
  employeeRegime,
  employeeEmail,
  employeeNumber,
}: ViewMenuProps) => {
  const [searchParams] = useSearchParams();
  const modalType = searchParams.get('tipo') as ModalType;
  const [, setFirstAllocation] = useRecoilState(FirstAllocationAtom);

  const [isAllocationOpen, setIsAllocationOpen] = useState(
    Number(searchParams.get('colaborador')) === employeeId &&
      modalType === 'alocações',
  );

  const [isAliasOpen, setIsAliasOpen] = useState(false);

  const isDisabledReallocation: boolean =
    isFirstAllocation || !userCanSaveAllocation();
  const actualUser = useRecoilValue(actualUserAtom);
  const isDisabledInitialAllocation: boolean =
    !isFirstAllocation || !userCanSave(managementId, actualUser?.management_id);

  const options = [
    {
      name: 'Alocação Inicial',
      onClick: () => {
        setFirstAllocation({
          isOpen: 'open',
          id,
        });
      },
      disabled: isDisabledInitialAllocation,
    },
    {
      name: 'Realocação',
      onClick: () => {
        setIsAllocationOpen(true);
      },
      disabled: isDisabledReallocation,
    },
    {
      name: 'Cadastrar nome para exibição',
      onClick: () => {
        setIsAliasOpen(true);
      },
      disabled: false,
    },
  ];

  return (
    <>
      {isAllocationOpen && (
        <AllocationModal
          employeeId={employeeId}
          modalIsOpen={isAllocationOpen}
          handleClose={() => {
            setIsAllocationOpen(false);
            queryClient.invalidateQueries(['all-employees']);
          }}
          admissionDate={admissionDate}
        />
      )}
      {isAliasOpen && (
        <AliasModal
          isOpen={isAliasOpen}
          setIsOpen={setIsAliasOpen}
          employeeId={employeeId}
          employeeName={employeeName}
          employeeAlias={employeeAlias}
          employeePosition={employeePosition}
          employeeRegime={employeeRegime}
          employeeEmail={employeeEmail}
          employeeNumber={employeeNumber}
          employeePole={employeePole}
        />
      )}
      <SimpleMenu options={options} />
    </>
  );
};
export default ViewMenu;
