import { ColumnDef } from '@tanstack/react-table';

import TableCellDate from '@/components/atoms/TableCellDate';
import TableData from '@/components/atoms/TableData';

import { Status } from '../TrainingPlanning';

interface TableColumns {
  date: Date;
  status: string;
}

export const statusColumns: Array<ColumnDef<TableColumns>> = [
  {
    accessorKey: 'date',
    header: 'Data',
    meta: {
      headerClassName: 'text-left',
    },
    cell: (props) => {
      return (
        <div className="w-32">
          <TableCellDate date={new Date(props.getValue() as string)} />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'status',
    header: 'Situação',
    meta: {
      headerClassName: 'text-left',
    },
    cell: (props) => {
      const status = props.getValue() as Status;
      return (
        <div className="w-32">
          <TableData tableValue={status} />
        </div>
      );
    },
    enableSorting: false,
  },
];
