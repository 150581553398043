import { Dialog, Transition } from '@headlessui/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ptBR } from '@mui/x-date-pickers/locales';
import br from 'date-fns/locale/pt-BR';
import { useEffect, useState } from 'react';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';

import { AllocationDataTypeAtom } from '@/state/AllocationDataType.atom';
import { AllocationUpdateDataTypeAtom } from '@/state/UpdateDataType.atom';
import { removeTimeZone } from '@/utils/formatDate';

import api from '../../services/apiSgft';
import { AllocationTypeAtom } from '../../state/AllocationType.atom';
import { getFirstAndLastName } from '../../utils/getFirstAndLastName';
import { AllocationColumns } from '../atoms/AllocationColumns';
import { Button } from '../atoms/Button';
import CloseButton from '../atoms/CloseButton';
import Spinner from '../atoms/Spinner';
import SubtitleText from '../atoms/SubtitleText';
import TextComponent from '../atoms/TextComponent';
import ExpandableSubtitle from '../molecules/ExpandableSubtitle';
import UpdateAllocationCard from '../molecules/UpdateAllocationCard';
import Table from './Table';

interface Props {
  employeeId: number | undefined;
  handleClose: () => void;
  modalIsOpen: boolean;
  admissionDate?: string;
}

export default function AllocationModal({
  employeeId,
  handleClose,
  modalIsOpen,
  admissionDate,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useRecoilState(AllocationTypeAtom);
  const [year, setYear] = useState(new Date());
  const [data, setData] = useRecoilState(AllocationDataTypeAtom);
  const [employee, setEmployee] = useState<any>();
  const [updateData, setUpdateData] = useRecoilState(
    AllocationUpdateDataTypeAtom,
  );
  const [currentAllocation, setCurrentAllocation] = useState();
  const [managementList, setManagementList] = useState([]);
  const fetchData = async () => {
    if (employeeId) {
      setIsLoading(true);
      try {
        const response = await api.get('/team-employee', {
          params: {
            employeeId,
            year: removeTimeZone(new Date(year)).getFullYear(),
          },
        });
        setEmployee(response.data.employee);
        setUpdateData(false);
        findCurrentAllocation(response.data.employeeTeamHistories);
        filterEmployeeTeamHistories(
          response.data.employeeTeamHistories,
          response.data.employee.name,
        );
        setManagementList(response.data.managementsList);
      } catch (e: any) {
        if (e.response && e.response.status !== 404) {
          toast.error('Erro ao carregar os dados', {
            theme: 'colored',
            toastId: 'error',
          });
          throw e;
        } else setData([]);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setType('');
  }, [modalIsOpen]);

  useEffect(() => {
    fetchData();
  }, [updateData, year, employeeId]);

  const saveYear = (date: any) => {
    setYear(date);
  };

  const isCurrent = (startDate: Date, endDate: Date | null) => {
    const currentDate = new Date();
    if (
      removeTimeZone(new Date(currentDate)).getTime() >=
        removeTimeZone(new Date(startDate)).getTime() &&
      (endDate === null ||
        removeTimeZone(new Date(currentDate)).getTime() <=
          removeTimeZone(new Date(endDate)).getTime())
    ) {
      return true;
    }
    return false;
  };

  const isPast = (startDate: Date, endDate: Date) => {
    const currentDate = new Date();
    if (
      endDate !== null ||
      (removeTimeZone(new Date(currentDate)).getTime() >=
        removeTimeZone(new Date(startDate)).getTime() &&
        removeTimeZone(new Date(currentDate)).getTime() >=
          removeTimeZone(new Date(endDate)).getTime())
    ) {
      return true;
    }
    return false;
  };

  const findCurrentAllocation = (employeeTeamHistories: any) => {
    employeeTeamHistories.forEach((allocation: any) => {
      if (isCurrent(allocation.start_date, allocation.end_date)) {
        setCurrentAllocation(allocation);
      }
    });
  };

  const filterEmployeeTeamHistories = (
    employeeTeamHistories: any,
    employeeName: string,
  ) => {
    const historyData = employeeTeamHistories.map((allocation: any) => ({
      ...allocation,
      isCurrent: isCurrent(allocation.start_date, allocation.end_date),
      isPast: isPast(allocation.start_date, allocation.end_date),
      employeeName,
    }));
    if (admissionDate) {
      historyData[historyData.length - 1].start_date = admissionDate;
    }
    setData(historyData);
  };

  return (
    <Transition appear show={true}>
      <Dialog className="relative z-50" onClose={handleClose} open={true}>
        <div className="fixed inset-0 flex w-screen items-center justify-center bg-black/30 p-4">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="custom-scrollbar max-h-[100vh] w-[52rem] flex-col justify-between overflow-auto overflow-y-scroll rounded-lg bg-white">
              <div className="w-full flex-col justify-between p-5">
                <div className="flex h-auto items-center justify-between">
                  <div className="flex justify-between">
                    <SubtitleText
                      className="text-[20px]"
                      subtitle={'Realocações'}
                    />
                  </div>
                  <CloseButton onClick={handleClose} className="mt-[-1rem]" />
                </div>
                <div className="mt-5">
                  <div className="mb-5 flex w-full justify-between">
                    <TextComponent
                      title={'Nome'}
                      width="12rem"
                      value={employee ? getFirstAndLastName(employee.name) : ''}
                    />
                    <TextComponent
                      title={'Chapa'}
                      width="12rem"
                      value={employee ? employee.employee_number : ''}
                    />
                    <TextComponent
                      title={'Colaborador Ferista'}
                      width="12rem"
                      value={
                        employee
                          ? employee.vacationReliefWorker
                            ? 'SIM'
                            : 'NÃO'
                          : ''
                      }
                    />
                    <div>
                      <div className="text-[12px] text-[#4a4a4a]">Ano</div>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        localeText={
                          ptBR.components.MuiLocalizationProvider.defaultProps
                            .localeText
                        }
                        adapterLocale={br}
                      >
                        <DatePicker
                          key={11}
                          views={['year']}
                          onChange={(date) => saveYear(date)}
                          value={year}
                          slotProps={{ textField: { size: 'small' } }}
                          sx={{
                            width: '8rem',
                            '& .MuiInputBase-root': {
                              height: '2rem',
                              fontSize: '12px',
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <>
                    <ExpandableSubtitle subtitle={'Histórico de realocações'}>
                      {isLoading && (
                        <div className="flex h-40 w-full items-center">
                          <Spinner size={50} />
                        </div>
                      )}
                      {data.length > 0 ? (
                        <div className="mb-5">
                          <Table
                            columns={AllocationColumns}
                            data={data}
                            isWhiteTable
                          />
                        </div>
                      ) : (
                        <div className="flex justify-center p-10 font-graphie text-[13px] font-bold text-[#747474]">
                          Ainda não existem alocações
                        </div>
                      )}
                      {type === '' && (
                        <div className="flex justify-between">
                          <div className="flex cursor-pointer items-center justify-start rounded-md hover:bg-[#e9eaf1]">
                            <FiMinusCircle
                              style={{
                                fontSize: '1rem',
                                color: '#193cb9',
                                marginLeft: '0.5rem',
                              }}
                            />
                            <Button
                              type="button"
                              variant="link"
                              className="w-[160px] font-semibold text-[#193CB9] underline"
                              onClick={() => setType('deallocate')}
                            >
                              Desalocar colaborador
                            </Button>
                          </div>
                          <div className="flex cursor-pointer items-center justify-start rounded-md hover:bg-[#e9eaf1]">
                            <FiPlusCircle
                              style={{
                                fontSize: '1rem',
                                color: '#193cb9',
                                marginLeft: '0.5rem',
                              }}
                            />
                            <Button
                              type="button"
                              variant="link"
                              className="w-[150px] font-semibold text-[#193CB9] underline"
                              onClick={() => setType('create')}
                            >
                              Atualizar realocação
                            </Button>
                          </div>
                        </div>
                      )}
                    </ExpandableSubtitle>
                    <UpdateAllocationCard
                      employeeId={employeeId}
                      name={employee ? getFirstAndLastName(employee.name) : ''}
                      currentAllocation={currentAllocation}
                      managementList={managementList}
                      data={data}
                    />
                  </>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
