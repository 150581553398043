import { TextField } from '@mui/material';
import { ReactNode } from 'react';
import { FieldError } from 'react-hook-form';

import { Button } from '../atoms/Button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';

interface ControlledJustificationDialogProps {
  title: string;
  name: string;
  methods: any;
  onConfirmation: () => void;
  description: string;
  fieldTitle: string;
  children?: ReactNode;
  fieldError?: FieldError | undefined;
  errorMessage?: string;
  disabled?: boolean;
  isOpen?: boolean;
  closeDialog?: () => void;
}

export const ControlledJustificationDialog = ({
  title,
  name,
  onConfirmation,
  description,
  fieldTitle,
  methods,
  children,
  fieldError,
  errorMessage,
  disabled,
  isOpen,
  closeDialog,
}: ControlledJustificationDialogProps) => {
  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && closeDialog?.()}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="font-graphie text-[12px] font-normal text-[#4A4A4A]">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
        <TextField
          multiline
          rows={2}
          title={fieldTitle}
          fullWidth
          fieldError={fieldError}
          errorMessage={errorMessage}
          {...methods.register(name)}
          InputProps={{
            style: { fontSize: '14px' },
          }}
        />
        <div className="flex justify-between">
          <DialogClose>
            <Button
              type="button"
              variant="outline"
              className="mb-5 min-w-36"
              onClick={closeDialog}
            >
              Cancelar
            </Button>
          </DialogClose>
          <DialogClose>
            <Button
              type="button"
              disabled={disabled}
              className="mb-5 min-w-36"
              onClick={onConfirmation}
            >
              Confirmar
            </Button>
          </DialogClose>
        </div>
      </DialogContent>
    </Dialog>
  );
};
