import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BiInfoCircle } from 'react-icons/bi';
import { toast } from 'react-toastify';
import { useDebounce } from 'use-debounce';
import { z } from 'zod';

import { queryClient } from '@/App';
import EmptyIcon from '@/assets/icons/empty.svg';
import { FormSchema } from '@/constants/formSchema/TrainingFileFormSchema';
import { trainingTypeOptions } from '@/constants/trainingTypeOptions';
import api from '@/services/apiSgft';
import { TrainingFile } from '@/types/TrainingFile';
import { cn } from '@/utils/cn';
import { removeTimeZone } from '@/utils/removeTimezone';

import { Button } from '../atoms/Button';
import ControlledSimpleSelect from '../atoms/ControlledSimpleSelect';
import { DatePicker, DatePickerRange } from '../atoms/DatePicker';
import LightTooltip from '../atoms/LightTooltip';
import Line from '../atoms/Line';
import Modal from '../atoms/Modal';
import Spinner from '../atoms/Spinner';
import SubtitleText from '../atoms/SubtitleText';
import { Checkbox } from '../ui/checkbox';
import { FormControl, FormField, FormItem, FormMessage } from '../ui/form';
import { Label } from '../ui/label';
import { RadioGroup, RadioGroupItem } from '../ui/radio-group';
interface TrainingFileModalProps {
  trainingFile?: TrainingFile;
  isOpen: boolean;
  onClose: () => void;
}
const TrainingFileModal: React.FC<TrainingFileModalProps> = ({
  isOpen,
  onClose,
  trainingFile,
}) => {
  const [trainingPeriod, setTrainingPeriod] = useState<
    DatePickerRange | undefined
  >(
    trainingFile
      ? {
          startDate: removeTimeZone(trainingFile.startDate),
          endDate: removeTimeZone(trainingFile.endDate),
        }
      : undefined,
  );

  const [isSubmiting, setIsSubmiting] = useState(false);
  const methods = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      trainings: trainingFile?.trainings ?? [],
      name: trainingFile?.name ?? '',
      trainingType: trainingFile?.trainingType,
      supplierId: trainingFile?.supplier?.id,
    },
  });
  const [trainingInput, setTrainingInput] = useState('');
  const [trainingInputDebounced] = useDebounce(trainingInput, 500);
  const [supplierInput, setSupplierInput] = useState('');
  const [supplierInputDebounced] = useDebounce(supplierInput, 500);
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
  } = methods;
  const supplierWatch = watch('supplierId');
  const trainingsWatch = watch('trainings');
  const trainingTypeWatch = watch('trainingType');

  const submitTrainingFile = async (data: z.infer<typeof FormSchema>) => {
    if (!trainingPeriod?.startDate || !trainingPeriod?.endDate) {
      toast.error('Cadastre o período do ficheiro', {
        theme: 'colored',
      });
      return;
    }
    setIsSubmiting(true);
    const body = {
      ...data,
      startDate: trainingPeriod?.startDate,
      endDate: trainingPeriod?.endDate,
    };
    try {
      if (trainingFile?.id) {
        await api.put(`training-files/${trainingFile.id}`, body);
      } else {
        await api.post(`training-files/`, body);
      }
      toast.success(
        `Sucesso ao ${trainingFile?.id ? 'editar' : 'criar'} ficheiro`,
        {
          theme: 'colored',
        },
      );
      setIsSubmiting(false);
      onClose();
      queryClient.invalidateQueries(['training-files']);
    } catch (error: any) {
      setIsSubmiting(false);
      let errorMessage = `Error ao ${trainingFile?.id ? 'editar' : 'criar'} ficheiro`;
      if (error?.response?.data?.statusCode < 500) {
        errorMessage = error.response.data.message;
      }
      toast.error(errorMessage, {
        theme: 'colored',
      });
    }
  };
  const fetchTrainings = async () => {
    const supplierFilter = supplierWatch ? [supplierWatch] : [];
    if (
      !trainingTypeWatch ||
      (trainingTypeWatch === 'Desenvolvimento' && !supplierWatch)
    ) {
      return [];
    }
    const response = await api.get('trainings/offered-trainings/', {
      params: {
        suppliers: supplierFilter,
        search: trainingInputDebounced,
        trainingType: trainingTypeWatch ? [trainingTypeWatch] : [],
      },
    });
    return response.data;
  };
  const fetchSuppliers = async () => {
    const trainingsFilter = trainingsWatch ?? [];
    if (!trainingTypeWatch) {
      return [];
    }

    const response = await api.get('supplier/filter', {
      params: {
        trainings: trainingsFilter,
        search: supplierInputDebounced,
        trainingType: trainingTypeWatch ? [trainingTypeWatch] : [],
      },
    });
    return response.data;
  };
  const { data: allSuppliers, isLoading: loadingSuppliers } = useQuery<
    { name: string; id: number; active: boolean }[]
  >(
    [
      'all-suppliers',
      trainingsWatch,
      supplierInputDebounced,
      trainingTypeWatch,
    ],
    fetchSuppliers,
  );

  const { data: allTrainings, isLoading: loadingTrainings } = useQuery<
    {
      name: string;
      id: number;
      invalidCount: number;
      isInvalidProcedure: boolean;
    }[]
  >(
    ['all-trainings', supplierWatch, trainingInputDebounced, trainingTypeWatch],
    fetchTrainings,
  );
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="w-[60rem] min-w-[60vw] max-w-[90vw]"
      title={
        trainingFile
          ? `Editar Ficheiro - ${trainingFile.trainingFileNumber}`
          : 'Cadastrar Ficheiro'
      }
    >
      <div className=" flex-col justify-between pb-4">
        <div className="flex h-full w-full px-6">
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit((data) => submitTrainingFile(data))}
              className="flex h-full w-full flex-col justify-between gap-4"
            >
              <div className="flex  gap-6">
                <div className="flex flex-col justify-between gap-1">
                  <span className="text-xs text-gray-600">
                    Nome do Ficheiro <span className="text-red">*</span>
                  </span>
                  <input
                    {...register('name')}
                    className={cn(
                      'h-full w-[20rem] rounded-sm px-2 text-sm text-gray-700 ring-1 ring-gray-300 ',
                    )}
                    type="text"
                  />
                </div>
                <div>
                  <ControlledSimpleSelect
                    width="15rem"
                    title="Tipo de Treinamento"
                    error={!!errors?.trainingType}
                    values={trainingTypeOptions}
                    name="trainingType"
                    control={control}
                    required
                    className={cn(
                      'outline-none',
                      errors?.trainingType && ' ring-red',
                    )}
                  />
                </div>
              </div>
              <div className="mb-2">
                <SubtitleText
                  subtitle="Treinamentos e fornecedores"
                  className="font-semibold"
                />
                <Line />
                <div className="flex justify-between gap-2">
                  <div className="w-full ">
                    <h4 className="font-semibold text-gray-700">
                      Fornecedores
                      <span className="text-red">*</span>
                    </h4>
                    <input
                      placeholder="Pesquisar por fornecedores"
                      className="w-[95%] rounded-md bg-[#F4F7FE] px-2 py-2 text-xs text-gray-700"
                      onChange={(event) => setSupplierInput(event.target.value)}
                      value={supplierInput}
                    />
                    <div className="custom-scrollbar my-2 max-h-60  overflow-auto">
                      <FormField
                        control={control}
                        name="supplierId"
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <RadioGroup
                                onValueChange={field.onChange}
                                defaultValue={field?.value?.toString()}
                                className="flex  flex-col gap-0"
                              >
                                {loadingSuppliers ? (
                                  <div className="flex h-32 items-center justify-center">
                                    <Spinner size={40} />
                                  </div>
                                ) : allSuppliers?.length ? (
                                  allSuppliers?.map((supplier) => {
                                    return (
                                      <div
                                        className="flex h-12 w-full items-center justify-between  border-b-[1px] border-gray-400 px-3 last:border-b-0"
                                        key={supplier.id}
                                      >
                                        <Label
                                          htmlFor={supplier.id.toString()}
                                          className={cn(
                                            !supplier.active && 'text-gray-300',
                                          )}
                                        >
                                          {supplier.name}
                                        </Label>

                                        <RadioGroupItem
                                          value={supplier.id.toString()}
                                          id={supplier.id.toString()}
                                          className="border-primary text-primary"
                                          disabled={!supplier.active}
                                        />
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div className="flex h-48 w-full flex-col items-center justify-center rounded-md bg-white pb-2">
                                    <img src={EmptyIcon} className="w-32" />
                                    <div className="flex w-full flex-col items-center text-center text-xs">
                                      <span className="">
                                        Nenhum fornecedor encontrado
                                      </span>
                                      {!trainingTypeWatch && (
                                        <span className="font-semibold text-primary">
                                          Selecione o tipo de treinamento para
                                          visualizar
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </RadioGroup>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                  </div>
                  <div className="w-full overflow-hidden pl-2">
                    <h4 className="font-semibold text-gray-700">
                      Treinamentos disponíveis
                      <span className="text-red">*</span>
                    </h4>
                    <input
                      placeholder="Pesquisar por treinamentos"
                      className="w-[95%] rounded-md bg-[#F4F7FE] px-2 py-2 text-xs text-gray-700"
                      onChange={(event) => setTrainingInput(event.target.value)}
                      value={trainingInput}
                    />
                    <div className="custom-scrollbar my-2 max-h-60  w-full gap-0 overflow-auto">
                      <FormField
                        control={control}
                        name="trainings"
                        render={() => (
                          <FormItem className="">
                            {loadingTrainings ? (
                              <div className="flex h-32 items-center justify-center">
                                <Spinner size={40} />
                              </div>
                            ) : allTrainings?.length ? (
                              allTrainings?.map((training) => {
                                return (
                                  <FormField
                                    key={training.id}
                                    control={control}
                                    name="trainings"
                                    render={({ field }) => {
                                      return (
                                        <FormItem
                                          key={training.id}
                                          className="mt-0 flex h-12  space-y-0 overflow-hidden border-b-[1px] border-gray-400 px-3 last:border-b-0"
                                        >
                                          <FormControl>
                                            <div
                                              className="flex w-full items-center justify-between space-x-2"
                                              key={training.id}
                                            >
                                              <Label
                                                htmlFor={training?.id?.toString()}
                                                className="overflow-hidden text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                              >
                                                <div className="overflow-hidde flex flex-col">
                                                  <p
                                                    title={training.name}
                                                    className="max-w-full overflow-hidden text-ellipsis whitespace-nowrap text-sm text-gray-800"
                                                  >
                                                    {training.name}
                                                  </p>{' '}
                                                  <p className="flex items-center text-xs font-normal text-gray-500">
                                                    Pendência no treinamento
                                                    <LightTooltip title="Quantidade de treinamentos com situação de vencimento igual a vencido, inválido ou pendente">
                                                      <button className="ml-1">
                                                        {' '}
                                                        <BiInfoCircle />
                                                      </button>
                                                    </LightTooltip>
                                                    :
                                                    <span className="ml-2 text-primary">
                                                      {training.invalidCount}
                                                    </span>
                                                  </p>
                                                </div>
                                              </Label>
                                              <Checkbox
                                                disabled={
                                                  training.isInvalidProcedure
                                                }
                                                checked={field.value?.includes(
                                                  Number(training.id),
                                                )}
                                                onCheckedChange={(checked) => {
                                                  return checked
                                                    ? field.onChange([
                                                        ...field.value,
                                                        Number(training.id),
                                                      ])
                                                    : field.onChange(
                                                        field.value?.filter(
                                                          (value) =>
                                                            value !==
                                                            Number(training.id),
                                                        ),
                                                      );
                                                }}
                                              />
                                            </div>
                                          </FormControl>
                                        </FormItem>
                                      );
                                    }}
                                  />
                                );
                              })
                            ) : (
                              <div className="flex h-48 w-full flex-col items-center justify-center rounded-md bg-white pb-2">
                                <img src={EmptyIcon} className="w-32" />
                                <div className="flex w-full flex-col items-center text-center text-xs">
                                  <span className="">
                                    Nenhum treinamento encontrado
                                  </span>
                                  {!trainingTypeWatch ? (
                                    <span className="font-semibold text-primary">
                                      Selecione o tipo de treinamento para
                                      visualizar
                                    </span>
                                  ) : !supplierWatch ? (
                                    <span className="font-semibold text-primary">
                                      Selecione o tipo de treinamento para
                                      visualizar
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            )}
                          </FormItem>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <SubtitleText subtitle="Informações" />
                <Line />
                <div className="w-full">
                  <p className="font-graphie text-[12px] text-[#4A4A4A]">
                    Periodo de Planejamento
                    <span className="text-red">*</span>
                  </p>
                  <DatePicker
                    value={trainingPeriod}
                    onSelect={(dateRange) => setTrainingPeriod(dateRange)}
                    className={{ button: 'h-8 w-full' }}
                    showShortCuts
                  />
                </div>
              </div>
              <div className="flex w-full items-center justify-between">
                <Button
                  type="button"
                  className="h-8 min-w-36 border-2 border-[#193CB9] bg-white text-[#193CB9] hover:bg-[#e9eaf1]"
                  onClick={onClose}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  className=" h-8 min-w-36"
                  isLoading={isSubmiting}
                  disabled={!supplierWatch}
                >
                  Salvar
                </Button>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </Modal>
  );
};

export default TrainingFileModal;
