import { ColumnDef } from '@tanstack/react-table';

import { OnNoticeData } from '@/constants/OnNoticeConstants';

import TableCellDate from '../../components/atoms/TableCellDate';
import { removeTimeZone } from '../../utils/formatDate';

export const OnNoticeColumns: Array<ColumnDef<OnNoticeData>> = [
  {
    accessorKey: 'startDate',
    header: 'A partir de',
    cell: (props) => {
      return (
        <div className="w-24">
          <TableCellDate
            date={
              (props.getValue() as string) && props.getValue() !== '-'
                ? removeTimeZone(new Date(props.getValue() as string))
                : undefined
            }
          />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'endDate',
    header: 'Até',
    cell: (props) => {
      return (
        <div className="w-24">
          <TableCellDate
            date={
              (props.getValue() as string) && props.getValue() !== '-'
                ? removeTimeZone(new Date(props.getValue() as string))
                : undefined
            }
          />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'gap',
    header: '',
    cell: () => {
      return <div className="w-[20rem]"></div>;
    },
    enableSorting: false,
  },
];
