import EmptyIcon from '../../assets/icons/empty.svg';

const NoPermissionText = () => {
  return (
    <div className="flex w-full flex-col items-center justify-center py-10">
      <img src={EmptyIcon} className="w-40" />
      <div className="flex flex-col items-center text-sm">
        <p>{`Você não tem permissão para ver essa tela!`}</p>
        <span className="font-semibold text-blue-800">
          {`Caso ache que isso seja um erro, favor entre em contato com a equipe de suporte.`}
        </span>
      </div>
    </div>
  );
};

export default NoPermissionText;
