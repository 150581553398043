import React from 'react';

import ErrorIcon from '../../assets/icons/error.svg';
import Spinner from '../atoms/Spinner';

interface OrganizationalChartProps {
  children: React.ReactNode;
  isLoading: boolean;
  isError: boolean;
}

const OrganizationalChart: React.FC<OrganizationalChartProps> = ({
  children,
  isLoading,
  isError,
}: OrganizationalChartProps) => {
  return (
    <div className="h-[90vh] w-[94vw]">
      {isLoading ? (
        <div className="my-4 flex h-[95%] w-full items-center rounded-md">
          <Spinner size={40} />
        </div>
      ) : isError ? (
        <div className="h-[95%] w-full px-[1%]">
          <div className="flex h-full w-full flex-col items-center justify-center rounded-md">
            <img src={ErrorIcon} alt="Ícone de erro" className="w-24" />
            <div className="flex flex-col items-center text-center">
              Não foi possível carregar os dados
              <span className="font-semibold text-blue-800">
                tente novamente mais tarde!
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-4 flex h-full w-full flex-col gap-4">
          {children}
        </div>
      )}
    </div>
  );
};

export default OrganizationalChart;
