import { ColumnDef } from '@tanstack/react-table';
import TableCellDate from '../../components/atoms/TableCellDate';
import TableCellText from '../../components/atoms/TableCellText';
import { ExtraTrip } from '../../types/ExtraTrip';
import { removeTimeZone } from '../../utils/formatDate';
import {
    getRedmineStatus,
    getRedmineStatusColor,
} from '../../utils/redmineStatus';
export const columns: Array<ColumnDef<ExtraTrip>> = [
  {
    accessorKey: 'extraTripType',
    header: 'Motivo',
    cell: (props) => {
      return (
        <div className="w-40">
          <TableCellText text={(props.getValue() as { name: string }).name} />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'workStation',
    header: 'Local',
    cell: (props) => {
      return (
        <div className="w-24">
          <TableCellText text={(props.getValue() as { name: string }).name} />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'startDate',
    header: 'Data de início',
    cell: (props) => {
      return (
        <div className="w-16">
          <TableCellDate
            date={
              (props.getValue() as string) && props.getValue() != '-'
                ? removeTimeZone(new Date(props.getValue() as string))
                : undefined
            }
          />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'endDate',
    header: 'Data Final',
    cell: (props) => {
      return (
        <div className="w-16">
          <TableCellDate
            date={
              (props.getValue() as string) && props.getValue() != '-'
                ? removeTimeZone(new Date(props.getValue() as string))
                : undefined
            }
          />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'entryTime',
    header: 'Entrada',
    cell: (props) => {
      return (
        <div className="w-12">
          <TableCellText text={props.getValue() as string} />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'leavingTime',
    header: 'Saída',
    cell: (props) => {
      return (
        <div className="w-12">
          <TableCellText text={props.getValue() as string} />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'issueStatus',
    header: 'Status',
    cell: (props) => {
      const status = props.getValue() as string;
      return (
        <div className="min-w-16">
          <TableCellText
            text={getRedmineStatus(status)}
            color={getRedmineStatusColor(status)}
          />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'description',
    header: 'Descrição',
    cell: (props) => {
      return (
        <div className="min-w-40 max-w-[14rem]">
          <TableCellText text={props.getValue() as string} />
        </div>
      );
    },
    enableSorting: false,
  },
];
