import { MouseEvent, useState } from 'react';

import LightTooltip from './LightTooltip';

interface Props {
  text: string;
  isName?: boolean;
  width?: string;
  ifEmptyString?: string;
}

const TableCellAllocation = ({ text, isName, width, ifEmptyString }: Props) => {
  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  const handleShouldShow = ({ currentTarget }: MouseEvent<Element>) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth || isName) {
      setTooltipEnabled(true);
    }
  };

  return (
    <LightTooltip
      title={text}
      open={tooltipEnabled}
      onClose={() => setTooltipEnabled(false)}
      arrow
      placement="top-end"
    >
      {text && text !== '-' ? (
        <div
          className={`flex w-[${width}] w-full justify-start whitespace-nowrap`}
        >
          <p
            className="overflow-hidden text-ellipsis text-xs text-gray-600"
            onMouseEnter={handleShouldShow}
          >
            {text as string}
          </p>
        </div>
      ) : (
        <div className={` w-[${width}] text-center text-[11px] text-[#4A4A4A]`}>
          {ifEmptyString}
        </div>
      )}
    </LightTooltip>
  );
};

export default TableCellAllocation;
