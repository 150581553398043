import { IconButton } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { useState } from 'react';
import { BsPencilSquare } from 'react-icons/bs';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { employeeFiltersAtom } from '@/state/EmployeeFilter.atom';

import EditableTableCell from '../../components/atoms/EditableTableCell';
import TableCellDate from '../../components/atoms/TableCellDate';
import TableCellText from '../../components/atoms/TableCellText';
import api from '../../services/apiSgft';
import { actualUserAtom } from '../../state/ActualUser.atom';
import { viewScheduleFiltersAtom } from '../../state/ViewScheduleFilter.atom';
import { removeTimeZone } from '../../utils/formatDate';
import { getFirstAndLastName } from '../../utils/getFirstAndLastName';
import { userCanSave } from '../../utils/handleSavePermissions';
import { EmployeeStatus } from '../EmployeeConstants';
import { Pendency, PendencyStatus, PendencyType } from '../PendencyConstants';

export const columns: Array<ColumnDef<Pendency>> = [
  {
    id: 'export',
    header: '',
    cell: (value) => {
      const setFilterValues = useSetRecoilState(viewScheduleFiltersAtom);
      const [_, setFilterEmployee] = useRecoilState(employeeFiltersAtom);
      const navigate = useNavigate();
      const data = value.row.original;

      const isEmployeeNotFound = data.type === PendencyType.EMPLOYEE_NOT_FOUND;
      const isInactive =
        data.employeeStatus === EmployeeStatus.INSS ||
        data.employeeStatus === EmployeeStatus.MATERNITY_LEAVE;

      const getUrlForPendencyType = () => {
        const {
          employeeId,
          teamStructureId,
          type,
          vacationId,
          employeeNumber,
          allocationRequestId,
        } = data;
        const scaleAndVacation = `/home/planejamento-de-escalas-e-ferias?colaborador=${employeeId}&estrutura=${teamStructureId}`;
        const allocationPath = allocationRequestId
          ? `/home/solicitar-realocacao/${allocationRequestId}?chapa=${employeeNumber}`
          : '/home/realocacoes';

        switch (type) {
          case PendencyType.UNCOVERED_VACATION:
          case PendencyType.VACATION_TO_EXPIRE:
            return `${scaleAndVacation}&tipo=ferias&ferias=${vacationId}`;

          case PendencyType.NO_ALLOCATION:
            return `/home/colaboradores?tipo=alocação-inicial&colaborador=${employeeId}`;

          case PendencyType.MODIFIED_ON_NOTICE:
            return `${scaleAndVacation}&tipo=sobreaviso`;

          case PendencyType.ALLOCATION_REQUEST:
          case PendencyType.RELOCATION_AWAITING_TURN:
          case PendencyType.ALLOCATION_DIVERGENCE:
            return allocationPath;

          default:
            return scaleAndVacation;
        }
      };

      const handleSubmit = async () => {
        try {
          const startDate = new Date(data.startDate);
          startDate.setDate(startDate.getDate() - 5);
          const endDate = new Date(data.startDate);
          endDate.setDate(endDate.getDate() + 84);
          setFilterValues({
            timeRange: {
              startDate: startDate.toISOString().split('T')[0],
              endDate: endDate.toISOString().split('T')[0],
            },
            workplace: [],
            sector: [],
            schedule: [],
            area: [],
            management: data.managementId ? [data.managementId.toString()] : [],
            employee: [],
            teamStructure: data.teamStructureId
              ? [data.teamStructureId.toString()]
              : [],
          });

          setFilterEmployee(() => ({
            management: data.managementId ? [data.managementId.toString()] : [],
            workplace: [],
            teamStructure: data.teamStructureId
              ? [data.teamStructureId.toString()]
              : [],
            employee: data.employeeNumber,
          }));

          const hiperLink = getUrlForPendencyType();

          navigate(hiperLink);
        } catch (e) {
          toast.error('Erro ao ir para pendência', {
            theme: 'colored',
            toastId: 'error',
          });
          throw e;
        }
      };

      return (
        <div className="w-16">
          <IconButton
            sx={{
              margin: 1,
              height: '1.8rem',
              width: '1.8rem',
              background: '#1F3FA8',
              borderRadius: '100%',
              '&:hover': {
                background: '#4c4c73',
              },
              '&:disabled': {
                background: '#9ca3af',
              },
            }}
            aria-label="export"
            onClick={handleSubmit}
            disabled={isEmployeeNotFound || isInactive}
          >
            <BsPencilSquare color="white" height={'7px'} width={'7px'} />
          </IconButton>
        </div>
      );
    },
  },
  {
    accessorKey: 'pendencyId',
    header: '#',
    cell: (value) => {
      return (
        <div className="w-20">
          <TableCellText text={value.getValue() as string} width="full" />
        </div>
      );
    },
  },
  {
    accessorKey: 'employeeName',
    header: 'Colaborador',
    cell: (value) => {
      return (
        <div className="w-40">
          <TableCellText
            text={value.getValue() as string}
            isName
            width="full"
          />
        </div>
      );
    },
  },
  {
    id: 'type',
    accessorKey: 'type',
    header: 'Pendência',
    cell: (value) => {
      return (
        <div className="w-[15rem]">
          <TableCellText text={value.getValue() as string} width="full" />
        </div>
      );
    },
  },
  {
    accessorKey: 'teamStructureName',
    header: 'Estrutura de Turma',
    cell: (value) => {
      const name = value.getValue ? value.getValue() : undefined;
      return (
        <div className="w-40">
          <TableCellText
            text={name ? (name as string).toUpperCase() : ''}
            width="full"
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'team',
    header: 'Turma',
    cell: (value) => {
      return (
        <div className="w-24">
          <TableCellText text={value.getValue() as string} width="full" />
        </div>
      );
    },
  },
  {
    accessorKey: 'startDate',
    header: 'Registro',
    cell: (value) => {
      return (
        <div className="w-28">
          <TableCellDate
            date={
              (value.getValue() as string) && value.getValue() !== '-'
                ? removeTimeZone(new Date(value.getValue() as string))
                : undefined
            }
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'endDate',
    header: 'Conclusão',
    cell: (value) => {
      return (
        <div className="w-28">
          <TableCellDate
            date={
              (value.getValue() as string) && value.getValue() !== '-'
                ? removeTimeZone(new Date(value.getValue() as string))
                : undefined
            }
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'responsibleName',
    header: 'Responsável',
    cell: (value) => {
      const [name, setName] = useState(value.getValue());
      const actualUser = useRecoilValue(actualUserAtom);

      const options = value.row.original.responsibleList.map((item) => {
        return {
          name: getFirstAndLastName(item.name),
          onClick: async () => {
            updateResponsible(item.name, item.id);
          },
          disabled: !(value.row.original.responsibleList.length > 0),
        };
      });

      async function updateResponsible(name: string, id: number) {
        try {
          await api.patch(`/pendencies/update/responsible`, {
            responsibleId: Number(id),
            pendencyId: Number(value.row.original.pendencyId),
          });
          setName(name);
          toast.success(
            `Responsável da pendência #${value.row.original.pendencyId} alterado para ${name} com sucesso!`,
            {
              theme: 'colored',
            },
          );
        } catch (e) {
          toast.error('Erro ao fazer troca de responsável', {
            theme: 'colored',
            toastId: 'error',
          });
          throw e;
        }
      }
      const employee = JSON.parse(localStorage.getItem('employee') ?? '{}');
      const managementId =
        actualUser.management_id !== 0
          ? actualUser.management_id
          : employee?.management_id;
      const managementIdFromPendency = value.row.original.managementId;
      const canSave = userCanSave(
        managementIdFromPendency,
        Number(managementId),
      );

      return (
        <EditableTableCell
          value={name as string}
          options={options}
          ifValueIsEmpty="SEM RESPONSÁVEL"
          isName
          disabled={
            !(value.row.original.responsibleList.length > 0) || !canSave
          }
          canSave={canSave}
        />
      );
    },
  },
  {
    accessorKey: 'management',
    header: 'Gerência',
    cell: (value) => {
      return (
        <div className="w-40">
          <TableCellText text={value.getValue() as string} width="full" />
        </div>
      );
    },
  },
  {
    accessorKey: 'area',
    header: 'Área',
    cell: (value) => {
      return (
        <div className="w-28">
          <TableCellText text={value.getValue() as string} width="full" />
        </div>
      );
    },
  },
  {
    accessorKey: 'regime',
    header: 'Regime',
    cell: (value) => {
      return (
        <div className="w-28">
          <TableCellText text={value.getValue() as string} width="full" />
        </div>
      );
    },
  },
  {
    accessorKey: 'scale',
    header: 'Escala',
    cell: (value) => {
      return (
        <div className="w-28">
          <TableCellText text={value.getValue() as string} width="full" />
        </div>
      );
    },
  },
  {
    accessorKey: 'sector',
    header: 'Setor',
    cell: (value) => {
      return (
        <div className="w-28">
          <TableCellText text={value.getValue() as string} width="full" />
        </div>
      );
    },
  },
  {
    accessorKey: 'workStation',
    header: 'Local de Trabalho',
    cell: (value) => {
      return (
        <div className="w-32">
          <TableCellText text={value.getValue() as string} width="full" />
        </div>
      );
    },
  },
];

const typeColumnIndex = columns.findIndex((column) => column.id === 'type');

const statusColumn: ColumnDef<Pendency> = {
  accessorKey: 'status',
  header: 'Status',
  cell: ({ row }) => {
    const status =
      row.original.status === PendencyStatus.NO_TREATMENT
        ? 'Sem Tratativa'
        : 'Concluída';
    return (
      <div className="w-28">
        <TableCellText text={status} width="full" />
      </div>
    );
  },
};

const closedColumns = columns.map((column) => ({ ...column }));

closedColumns.splice(typeColumnIndex + 1, 0, statusColumn);

export { closedColumns };
