import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@radix-ui/react-tooltip';

import { statusColumns } from '@/constants/tableColumns/StatusTableColumns';
import { Status } from '@/constants/TrainingPlanning';
import { StatusTrainingData } from '@/types/StatusTrainingData';
import { cn } from '@/utils/cn';

import TableData from '../atoms/TableData';
import Table from '../organisms/Table';

interface StatusTableCellProps {
  statusData: StatusTrainingData;
}

export const StatusTableCell = ({ statusData }: StatusTableCellProps) => {
  const workingEveryDay = statusData.every(
    (it) => it.status === Status.Trabalhando,
  );
  const offEveryDay = statusData.every((it) => it.status === Status.Folga);

  const statusText = workingEveryDay
    ? Status.Trabalhando
    : offEveryDay
      ? Status.Folga
      : `${Status.Trabalhando}/${Status.Folga}`;
  return (
    <TooltipProvider skipDelayDuration={100} delayDuration={200}>
      <Tooltip>
        <TooltipTrigger>
          <TableData
            tableValue={statusText}
            className={cn(
              'flex h-6 w-28 items-center rounded-sm px-3 text-left ',
              !workingEveryDay && !offEveryDay
                ? 'cursor-pointer'
                : 'cursor-auto',
            )}
          />
        </TooltipTrigger>
        {!workingEveryDay && !offEveryDay && (
          <TooltipContent className="z-[1000]">
            <TooltipArrow />

            <div className="z-[1000] rounded-md border-2  bg-white p-4 shadow-md">
              <div className="w-full text-start text-lg font-semibold text-[#193CB9]">
                Disponibilidade
              </div>
              <Table
                columns={statusColumns}
                data={statusData}
                containerClassname="text-center w-full"
                trBodyClassname="even:bg-white"
              />
            </div>
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );
};
