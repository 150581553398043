import HomeCards from '../../components/molecules/HomeCards';

const HomeData = () => {
  const cards = [
    {
      title: 'Estrutura e Organograma',
      description: 'Visualização da estrutura e do organograma da Origem',
      img: '/chart.png',
      redirect: [
        { to: '/home/visualizar-estrutura-organograma', title: 'Organograma' },
      ],
      disabled: true,
    },
    {
      title: 'Funções e Requisitos',
      description:
        'Cadastro e edição de informações sobre funções e requisitos',
      img: '/compass.png',
      redirect: [
        {
          to: '/home/functions-and-requiriments',
          title: 'Funções e Requisitos',
        },
      ],
      disabled: true,
    },
    {
      title: 'Planejamento de Escalas e Férias',
      description: 'Controle e gestão de escalas',
      img: '/calendar.png',
      redirect: [
        {
          to: '/home/planejamento-de-escalas-e-ferias',
          title: 'Planejamento de Escalas e Férias',
        },
      ],
      disabled: false,
    },
    {
      title: 'Gestão de Treinamentos',
      description: 'Controle e planejamento dos treinamentos',
      img: '/laptop.png',
      redirect: [
        {
          to: '/home/matriz-treinamento',
          title: 'Gestão de Treinamentos',
        },
      ],
      disabled: false,
    },
  ];

  return (
    <div className="flex h-[1500px] w-full items-center justify-center px-[5%] pb-8 pt-6 md:h-full md:pb-0 md:pt-0">
      <div className="flex-col space-y-4 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-4 md:space-y-0">
        {cards.map((card) => (
          <HomeCards
            key={card.title}
            title={card.title}
            description={card.description}
            img={card.img}
            redirect={card?.redirect}
            className="h-[250px] max-w-[350px] md:max-w-[600px]"
            disabled={card.disabled || !card?.redirect}
          />
        ))}
      </div>
    </div>
  );
};

export default HomeData;
