import { Dialog, Transition } from '@headlessui/react';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';

import { trackEvent } from '@/utils/trackEvent';

import { columns } from '../../constants/tableColumns/ExtraTripsModalColumns';
import api from '../../services/apiSgft';
import { ExtraTrip } from '../../types/ExtraTrip';
import { Option } from '../../types/Filter';
import { Button } from '../atoms/Button';
import CloseButton from '../atoms/CloseButton';
import { DatePicker, DatePickerRange } from '../atoms/DatePicker';
import SimpleSelect from '../atoms/SimpleSelect';
import Spinner from '../atoms/Spinner';
import SubtitleText from '../atoms/SubtitleText';
import ExpandableSubtitle from '../molecules/ExpandableSubtitle';
import ExtraTripsCard from '../molecules/ExtraTripsCard';
import Table from './Table';

interface Props {
  employeeId: number;
  modalIsOpen: boolean;
  handleClose: () => void;
}
const defaultStartDate = new Date();
const defaultEndDate = new Date();
defaultStartDate.setDate(1);
defaultStartDate.setMonth(0);
defaultEndDate.setDate(31);
defaultEndDate.setMonth(11);

export default function ExtraTripsModal({ handleClose, employeeId }: Props) {
  const [isFetching, setIsFetching] = useState(false);
  const [selectedExtraTrip, setSelectedExtraTrip] = useState<ExtraTrip | null>(
    null,
  );
  const [typeFilter, setTypeFilter] = useState<number | null>(null);
  const [showExtraTripForm, setShowExtraTripForm] = useState(false);
  const [extraTrips, setExtraTrips] = useState<ExtraTrip[]>([]);
  const [extraTripTypes, setExtraTripTypes] = useState<Option[]>([]);
  const [employee, setEmployee] = useState<Option>({
    label: '',
    value: employeeId,
  });
  const [workStations, setWorkStations] = useState<Option[]>([]);

  const [datesFilter, setDatesFilter] = useState<DatePickerRange | undefined>({
    startDate: format(defaultStartDate, 'yyyy-MM-dd'),
    endDate: format(defaultEndDate, 'yyyy-MM-dd'),
  });

  const fetchExtraTrips = async () => {
    setIsFetching(true);
    const response = await api.get('extra-trips/getData', {
      params: {
        employeeId,
        extraTripTypeId: typeFilter,
        startDate: datesFilter?.startDate,
        endDate: datesFilter?.endDate,
      },
    });
    setEmployee(() => ({
      label: response.data.employee.name,
      value: response.data.employee.id,
    }));
    setExtraTripTypes(() => {
      return response.data.extraTripTypes.map((extraTrip: any) => ({
        label: extraTrip.name,
        value: extraTrip.id,
      }));
    });
    setExtraTrips(response.data.extraTrips);
    setWorkStations(() => {
      return response.data.workStations.map((workStation: any) => ({
        label: workStation.name,
        value: workStation.id,
      }));
    });
    setIsFetching(false);
  };
  useEffect(() => {
    fetchExtraTrips();
  }, [typeFilter, datesFilter]);

  const handleShowExtraTripForm = (extraTrip?: ExtraTrip) => {
    setSelectedExtraTrip(extraTrip ?? null);
    trackEvent(
      extraTrip?.workStation.name || 'Sem Estacao',
      'generate_extra_trip',
      extraTrip?.extraTripType.name || 'Sem Tipo',
    );
    setShowExtraTripForm(true);
  };
  const handleCloseExtraTripForm = () => {
    setSelectedExtraTrip(null);
    setShowExtraTripForm(false);
  };
  const onChangeData = () => {
    handleCloseExtraTripForm();
    fetchExtraTrips();
  };

  const handleDeleteExtraTrip = async (extraTripId: number) => {
    await api.delete('extra-trips', {
      params: {
        extraTripId,
      },
    });
    fetchExtraTrips();
  };
  const extraTripTypesFilter = [
    {
      label: 'TODOS',
      value: null,
    },
    ...extraTripTypes,
  ];
  return (
    <Transition appear show={true}>
      <Dialog className="relative z-50" onClose={handleClose}>
        <div className="fixed inset-0 flex w-screen items-center justify-center bg-black/30 p-4">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="custom-scrollbar max-h-[85vh] w-[60rem] flex-col justify-between overflow-auto overflow-y-scroll rounded-lg bg-white">
              <div className="w-full flex-col justify-between p-5">
                <div className="flex h-auto items-center justify-between">
                  <div className="flex w-full justify-between">
                    <SubtitleText
                      className="text-[20px]"
                      subtitle={'Idas Extras ao Polo'}
                    />
                  </div>
                  <CloseButton onClick={handleClose} className="mt-[-1rem]" />
                </div>
                <div className="mb-5 flex w-[52rem] justify-between">
                  <SimpleSelect
                    title={'Nome'}
                    width="20rem"
                    values={employee ? [employee] : []}
                    value={employee?.value}
                  />
                  <SimpleSelect
                    title={'Motivo'}
                    width="15rem"
                    values={extraTripTypesFilter}
                    value={typeFilter}
                    setState={(value) => setTypeFilter(value as number)}
                  />
                  <div className="w-[12em]">
                    <div className="font-graphie text-[12px] text-[#4A4A4A]">
                      Datas
                    </div>
                    <DatePicker
                      value={datesFilter}
                      onSelect={(dateRange) => setDatesFilter(dateRange)}
                      className={{ button: 'h-8 w-[16rem]' }}
                      showShortCuts
                    />
                  </div>
                </div>
                <ExpandableSubtitle subtitle={'Idas Extras Planejadas'}>
                  {isFetching ? (
                    <Spinner size={40} />
                  ) : extraTrips.length > 0 ? (
                    <div className="mb-5">
                      <Table
                        columns={columns}
                        data={extraTrips}
                        onEditTableRow={(extraTrip: ExtraTrip) =>
                          handleShowExtraTripForm(extraTrip)
                        }
                        onDeleteTableRow={(extraTrip: ExtraTrip) =>
                          handleDeleteExtraTrip(extraTrip.id)
                        }
                        deleteMessage={{
                          title: 'Excluir ida extra',
                          description: `Tem certeza que deseja excluir essa ida extra do colaborador ${employee.label}?`,
                        }}
                      />
                    </div>
                  ) : (
                    <div className="flex justify-center p-10 font-graphie text-[13px] font-bold text-[#747474]">
                      Ainda não existem idas extras planejadas
                    </div>
                  )}
                  <div className="flex justify-end">
                    <Button
                      type="button"
                      variant="link"
                      className="min-w-40 font-semibold text-[#193CB9] underline hover:bg-[#e9eaf1]"
                      onClick={() => handleShowExtraTripForm()}
                    >
                      + Adicionar ida extra
                    </Button>
                  </div>
                </ExpandableSubtitle>
                {showExtraTripForm && (
                  <ExtraTripsCard
                    employeeId={employeeId}
                    extraTrip={selectedExtraTrip}
                    extraTripTypes={extraTripTypes}
                    workStations={workStations}
                    onCloseForm={handleCloseExtraTripForm}
                    onChangeData={onChangeData}
                  />
                )}
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
