import { DialogActions, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { historyStatusOptions } from '@/constants/TrainingConstants';
import { TrainingControlHistory } from '@/types/TrainingControlHistory';
import { removeTimeZone } from '@/utils/formatDate';

import { queryClient } from '../../App';
import api from '../../services/apiSgft';
import { Button } from '../atoms/Button';
import CloseButton from '../atoms/CloseButton';
import ControlledCalendar from '../atoms/ControlledCalendar';
import ControlledSimpleSelect from '../atoms/ControlledSimpleSelect';
import Line from '../atoms/Line';
import SubtitleText from '../atoms/SubtitleText';
import UncontrolledInputText from '../atoms/UncontrolledInputText';

interface EditTrainingModalProps {
  isOpen: boolean;
  onClose: () => void;
  id: number;
  row: TrainingControlHistory;
}

const EditTrainingModal: React.FC<EditTrainingModalProps> = ({
  isOpen,
  onClose,
  id,
  row,
}) => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [hasDirtyFields, setHasDirtyFields] = useState(false);

  const methods = useForm();

  useEffect(() => {
    methods.reset({
      trainingId: row.training.id,
      registerDate:
        row.registerDate !== '-'
          ? removeTimeZone(new Date(row.registerDate))
          : undefined,
      completionDate:
        row.completionDate !== '-'
          ? removeTimeZone(new Date(row.completionDate))
          : undefined,
      completionStatus: row.completionStatus || '',
      justification: row.modificationJustification || '',
    });
  }, [isOpen]);

  const onSubmit = async (data: any) => {
    setIsSubmiting(true);
    try {
      await api.patch(
        `training-control/training-control-history/${id}`,
        getDirtyFields(data),
      );
      setIsSubmiting(false);
      toast.success(`Histórico editado com sucesso!`, {
        theme: 'colored',
        toastId: 'success',
      });
    } catch (error: any) {
      toast.error(
        error.response.data.statusCode < 500
          ? error.response.data.message
          : 'Erro ao editar histórico',
        {
          theme: 'colored',
          toastId: 'error',
        },
      );
      throw error;
    } finally {
      setIsSubmiting(false);
      closeAndClear();
    }
  };

  useEffect(() => {
    setHasDirtyFields(
      methods.getFieldState('registerDate').isDirty ||
        methods.getFieldState('completionDate').isDirty ||
        methods.getFieldState('completionStatus').isDirty,
    );
  }, [methods.watch()]);

  const getDirtyFields = (data: any) => {
    return {
      registerDate: methods.getFieldState('registerDate').isDirty
        ? data.registerDate
        : undefined,
      completionDate: methods.getFieldState('completionDate').isDirty
        ? data.completionDate
        : undefined,
      completionStatus: methods.getFieldState('completionStatus').isDirty
        ? data.completionStatus
        : undefined,
      justification: data.justification,
    };
  };

  const closeAndClear = () => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey[0] === 'employee-trainings',
    });
    methods.reset();
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={closeAndClear}>
      <div className="fixed inset-0 flex w-screen items-center justify-center bg-black/30 p-4">
        <div className="max-h-[85vh] w-[50rem] flex-col justify-between overflow-auto overflow-y-scroll rounded-lg bg-white scrollbar-thin scrollbar-thumb-[#D9D9D9] scrollbar-thumb-rounded-full">
          <div className="flex h-auto items-center justify-between px-4 pb-1 pt-3">
            <SubtitleText
              className="text-[18px]"
              subtitle={'Editar informações'}
            />
            <CloseButton onClick={closeAndClear} className="mt-[-0.5rem]" />
          </div>
          <FormProvider {...methods}>
            <form
              className="space-between flex h-[90%] flex-col px-5 py-2"
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <div className="mb-4 flex w-full">
                <ControlledSimpleSelect
                  title={'Treinamento'}
                  control={methods.control}
                  name={'trainingId'}
                  width="47rem"
                  values={[
                    { value: row.training.id, label: row.training.name },
                  ]}
                />
              </div>
              <SubtitleText subtitle={'Editar informações de treinamento'} />
              <Line />
              <div className="mb-4 flex justify-between">
                <ControlledCalendar
                  title={'Data de inscrição'}
                  name={'registerDate'}
                  control={methods.control}
                  width="15rem"
                />
                <ControlledCalendar
                  title={'Data de conclusão'}
                  name={'completionDate'}
                  control={methods.control}
                  width="15rem"
                />
                <ControlledSimpleSelect
                  title={'Situação de treinamento'}
                  control={methods.control}
                  name={'completionStatus'}
                  width="15rem"
                  values={historyStatusOptions}
                />
              </div>
              <div className="flex justify-between">
                <UncontrolledInputText
                  required
                  title={'Justificativa de alteração'}
                  width="47rem"
                  {...methods.register('justification')}
                />
              </div>
              <DialogActions
                sx={{
                  width: 'full',
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingBottom: '25px',
                  marginX: '10px',
                  marginTop: '3rem',
                }}
              >
                <Button
                  type="button"
                  className="h-8 min-w-36 border-2 border-[#193CB9] bg-white text-[#193CB9] hover:bg-[#e9eaf1]"
                  onClick={closeAndClear}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  className=" h-8 min-w-36"
                  isLoading={isSubmiting}
                  disabled={!hasDirtyFields}
                >
                  Salvar
                </Button>
              </DialogActions>
            </form>
          </FormProvider>
        </div>
      </div>
    </Modal>
  );
};

export default EditTrainingModal;
