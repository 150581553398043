interface Props {
  onlineMaxDays?: number;
  inPersonMaxDays?: number;
}

export const DueCards = ({ onlineMaxDays, inPersonMaxDays }: Props) => {
  return (
    <div
      className={`flex justify-between mt-1`}
    >
      {onlineMaxDays && (
        <div className="font-inherit p-2">
          <div className="text-[11.5px] font-bold text-[#5A5A5A]">
            Prazo para conclusão (online)
          </div>
          <div className="text-[10.5px] text-[#A1A1A1] mb-1">
            *Após liberação do acesso
          </div>
          <div className="text-[#5A5A5A] text-[11px]">{onlineMaxDays} dias</div>
        </div>
      )}
      {inPersonMaxDays && (
        <div className="font-inherit p-2">
          <div className="text-[11.5px] font-bold text-[#5A5A5A]">
            Prazo para conclusão (presencial)
          </div>
          <div className="text-[10.5px] text-[#A1A1A1] mb-1">
            *Após conclusão online
          </div>
          <div className="text-[#5A5A5A] text-[11px]">
            {inPersonMaxDays} dias
          </div>
        </div>
      )}
    </div>
  );
};
