import { TooltipContent, TooltipTrigger } from '@radix-ui/react-tooltip';
import { format } from 'date-fns';
import { Info } from 'lucide-react';
import { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { queryClient } from '@/App';
import EmptyIcon from '@/assets/icons/empty.svg';
import {
  columns,
  thirdPartycolumns,
} from '@/constants/tableColumns/TrainingFileTeamEmployeesColumns';
import { Modality, OnlineTrainingType } from '@/constants/TrainingConstants';
import { RedmineStatus } from '@/constants/TrainingPlanning';
import api from '@/services/apiSgft';
import { TrainingFileTeam } from '@/types/TrainingFile';
import { dateInUTCFormat } from '@/utils/formatDate';
import { trackEvent } from '@/utils/trackEvent';

import LightTooltip from '../atoms/LightTooltip';
import SimpleMenu from '../atoms/Menu';
import Spinner from '../atoms/Spinner';
import { Tooltip, TooltipProvider } from '../atoms/ToolTip';
import ModalConfirmation from '../molecules/ConfirmationModal';
import Table from './Table';

interface TrainingFileTeamCardProps {
  trainingFileTeam: TrainingFileTeam;
  defaultOpen?: boolean;
}
export const TrainingFileTeamCard = ({
  trainingFileTeam,
  defaultOpen = false,
}: TrainingFileTeamCardProps) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [disableSendToEmployeeApproval, setDisableSendToEmployeeApproval] =
    useState(trainingFileTeam.requestedTransport);
  const [disableTransportRequest, setDisableTransportRequest] = useState(false);
  const hasSomeApproved = trainingFileTeam.employees.some(
    (emp) => emp.redmineManagerStatus === RedmineStatus.SolicitacaoAprovada,
  );
  const hasTransportNeed = trainingFileTeam.employees.some((employee) =>
    employee.trainingPlanningEmployeeLinkDays.some(
      (linkDay) => linkDay.needTransport === true,
    ),
  );
  const hasPresenceConfirmation = trainingFileTeam.employees.some(
    (employee) => employee.confirmedPresence === true,
  );
  const [isTransportRequestLoading, setIsTransportRequestLoading] =
    useState(false);

  const navigate = useNavigate();
  const handleToggleCard = () => {
    setIsOpen((prev) => !prev);
  };
  const handleOpenDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };
  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };
  const handleDeleteModal = async () => {
    try {
      await api.delete(`/training-plannings/${trainingFileTeam.id}`);
      toast.success('Turma excluída com sucesso', { theme: 'colored' });
      queryClient.invalidateQueries(['training-file-details']);
    } catch {
      toast.error('Erro ao excluir ficheiro', { theme: 'colored' });
    }
  };
  const handleSendToEmployeeApproval = async () => {
    try {
      setDisableSendToEmployeeApproval(true);
      await api.post(
        `/training-plannings/${trainingFileTeam.id}/send-to-employee-approval`,
      );

      trackEvent(
        'Enviado para convocação',
        'training_file_sent_to_employee_approval',
        String(trainingFileTeam.id),
      );
      toast.success('Convocações enviadas aos colaboradores!', {
        theme: 'colored',
        toastId: 'success',
      });
    } catch (e) {
      toast.error('Erro ao enviar para aprovação', {
        theme: 'colored',
        toastId: 'error',
      });
      trackEvent(
        'Erro ao enviar para convocação',
        'training_file_sent_to_employee_approval',
        String(trainingFileTeam.id),
      );
      setDisableSendToEmployeeApproval(false);
    }
  };
  const TransportRequest = async () => {
    setIsTransportRequestLoading(true);
    try {
      setDisableTransportRequest(true);
      await api.post('/training-plannings/request-transports', {
        trainingFileId: trainingFileTeam.trainingFileId,
        teamId: trainingFileTeam.id,
      });

      trackEvent(
        'Transportes solicitados',
        'training_team_transport_request',
        String(trainingFileTeam.id),
      );
      toast.success('Transporte(s) solicitado(s) com sucesso!', {
        theme: 'colored',
        toastId: 'success',
      });
    } catch (e) {
      toast.error('Erro ao gerar transporte(s)', {
        theme: 'colored',
        toastId: 'error',
      });
      trackEvent(
        'Erro ao gerar transporte(s)',
        'training_team_transport_request',
        String(trainingFileTeam.id),
      );
      setDisableTransportRequest(false);
    } finally {
      setIsTransportRequestLoading(false);
      queryClient.invalidateQueries(['training-file-details']);
    }
  };

  const isTransportNotNeeded = !hasTransportNeed;
  const isPresenceNotConfirmed = !hasPresenceConfirmation;
  const isTransportRequestDisabled = disableTransportRequest;
  const isTransportAlreadyRequested = trainingFileTeam.requestedTransport;
  const isNotPresential = trainingFileTeam.partModality !== Modality.Presencial;
  const requestTransportDisabled =
    isTransportNotNeeded ||
    isPresenceNotConfirmed ||
    isTransportRequestDisabled ||
    isTransportAlreadyRequested ||
    isNotPresential;

  const options = [
    {
      name: 'Editar turma',
      onClick: () =>
        navigate(
          `/home/ficheiros/${trainingFileTeam.trainingFileId}/planejamento-de-turma/${trainingFileTeam.id}`,
        ),
      disabled: false,
    },
    {
      name: 'Excluir turma',
      onClick: () => handleOpenDeleteModal(),
      disabled: false,
    },
    {
      name: 'Enviar convocações',
      onClick: () => handleSendToEmployeeApproval(),
      disabled:
        (!hasSomeApproved &&
          !(
            trainingFileTeam.onlineTrainingType === OnlineTrainingType.Async
          )) ||
        disableSendToEmployeeApproval ||
        trainingFileTeam.sentToApproval,
    },
    {
      name: 'Solicitar transporte',
      onClick: () => TransportRequest(),
      disabled: requestTransportDisabled,
    },
  ];
  const onlineTraining = trainingFileTeam.partModality === Modality.Online;
  const readOnlyTraining =
    trainingFileTeam.modality === Modality.SomenteLeitura;
  const asyncTraining =
    (onlineTraining || readOnlyTraining) &&
    trainingFileTeam.onlineTrainingType === OnlineTrainingType.Async;
  return (
    <div className="custom-scrollbar flex min-w-full flex-col overflow-x-auto rounded-md bg-white">
      {isDeleteModalOpen && (
        <ModalConfirmation
          confirmAction={handleDeleteModal}
          title="Excluir Turma"
          isOpen={isDeleteModalOpen}
          onClose={handleCloseDeleteModal}
          description={`Tem certeza que deseja excluir a turma: ${trainingFileTeam.name}?`}
        />
      )}

      <div className="flex min-h-16 w-full justify-between gap-2 p-2 text-xs text-gray-600">
        <button onClick={handleToggleCard}>
          <FaChevronDown
            className={`ml-auto fill-primary transition-all ${
              isOpen && 'rotate-180'
            }`}
          />
        </button>
        <div className="flex-start flex w-full items-center gap-1">
          <div className="flex w-72 items-center justify-between gap-2">
            <div className="flex h-8 items-center gap-2 overflow-hidden whitespace-nowrap rounded-lg bg-[#CFDEFF] px-2 py-1 text-primary">
              Turma:
              <LightTooltip
                title={trainingFileTeam.name}
                arrow
                placement="top-end"
              >
                <span className="overflow-hidden py-2 font-bold uppercase">
                  {trainingFileTeam.name}
                </span>
              </LightTooltip>
            </div>
            <SimpleMenu options={options} upDotsButton />
          </div>

          {!asyncTraining && (
            <>
              {trainingFileTeam.planningDays.map((day, index) => {
                const dateText = format(
                  new Date(dateInUTCFormat(day.date)),
                  'dd/MM/yyyy',
                );
                return (
                  <TooltipProvider key={day.id}>
                    <Tooltip>
                      <TooltipTrigger>
                        <div className="flex items-center gap-1 whitespace-nowrap rounded-lg px-2 py-1 ">
                          Dia {index + 1}:
                          <span className="font-bold">{dateText}</span>
                          <Info size={10} />
                        </div>
                      </TooltipTrigger>
                      <TooltipContent className="z-[10000] text-gray-800">
                        <div className="min-w-80 rounded-md bg-white p-3 shadow-md">
                          <span className="my-3 text-sm font-bold text-primary">
                            Dia {index + 1}
                          </span>
                          <div className="grid grid-cols-2 gap-y-2">
                            <div className="col-span-2">
                              Data:
                              <span className="ml-1 font-semibold">
                                {dateText}
                              </span>
                            </div>
                            <div>
                              Início:
                              <span className="ml-1 font-semibold">
                                {day.startTime}
                              </span>
                            </div>
                            <div>
                              Final:
                              <span className="ml-1 font-semibold">
                                {day.endTime}
                              </span>
                            </div>
                            {trainingFileTeam.partModality ===
                            Modality.Online ? (
                              <div>
                                Local:
                                <span className="ml-1 font-semibold">
                                  {day.onlineLocation}
                                </span>
                              </div>
                            ) : (
                              <>
                                <div>
                                  Local:
                                  <span className="ml-1 font-semibold">
                                    {day.workStation}
                                  </span>
                                </div>
                                <div>
                                  Sala:
                                  <span className="ml-1 font-semibold">
                                    {day.roomName}
                                  </span>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                );
              })}
            </>
          )}

          <div className="flex items-center gap-2 whitespace-nowrap rounded-lg px-2 py-1">
            Modalidade:
            <span className="font-bold">{trainingFileTeam.partModality}</span>
          </div>
          {(onlineTraining || readOnlyTraining) && (
            <div className="flex items-center gap-2 whitespace-nowrap rounded-lg px-2 py-1">
              Realização:
              <span className="font-bold">
                {trainingFileTeam.onlineTrainingType}
              </span>
            </div>
          )}
        </div>
      </div>
      {isOpen && (
        <div className="overflow-hidden bg-white">
          {isTransportRequestLoading ? (
            <div className="flex min-h-40 items-center justify-center py-4">
              <Spinner />
            </div>
          ) : (
            <>
              {trainingFileTeam.employees.length > 0 ? (
                <>
                  {trainingFileTeam.employees.filter((el) => !el.isThirdParty)
                    .length > 0 && (
                    <div className="mx-4 min-h-24 w-full bg-white py-2">
                      <h2 className="text-[0.800rem] font-bold text-primary md:text-2xl">
                        Colaboradores Origem
                      </h2>
                      <div className="custom-scrollbar z-50 min-h-24 w-full overflow-x-auto bg-white py-2">
                        <Table
                          columns={columns(
                            onlineTraining || readOnlyTraining,
                            asyncTraining,
                            trainingFileTeam.modality as Modality,
                          )}
                          data={trainingFileTeam.employees
                            .filter((el) => !el.isThirdParty)
                            .map((employee) => ({
                              ...employee,
                              trainingPlanningEmployeeLinkDays:
                                employee.trainingPlanningEmployeeLinkDays.map(
                                  (planningDay) => ({
                                    ...planningDay,
                                    date: dateInUTCFormat(
                                      planningDay.date,
                                    ).toISOString(),
                                  }),
                                ),
                            }))}
                          tBodyClassname="border-none "
                          trBodyClassname="border-none"
                          tdHeaderClassname="bg-transparent"
                          trHeaderClassname="bg-transparent my-4 rounded-md"
                          tdClassname="px-0 align-top"
                          containerClassname="border-separate border-spacing-y-4"
                          columnVisibility={{
                            statusInDay: !asyncTraining,
                            extraMeal: !(onlineTraining || readOnlyTraining),
                            needTransport: !(
                              onlineTraining || readOnlyTraining
                            ),
                            extraAccommodation: !(
                              onlineTraining || readOnlyTraining
                            ),
                            startTime: !(onlineTraining || readOnlyTraining),
                            endTime: !(onlineTraining || readOnlyTraining),
                            extraAccommodationEntryDate: !(
                              onlineTraining || readOnlyTraining
                            ),
                            extraAccommodationExitDate: !(
                              onlineTraining || readOnlyTraining
                            ),
                            redmineManagerStatus: !asyncTraining,
                            confirmedPresence: !asyncTraining,
                            date: !asyncTraining,
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {trainingFileTeam.employees.filter((el) => el.isThirdParty)
                    .length > 0 && (
                    <div className="z-50 mx-4 min-h-24 w-full bg-white py-2">
                      <h2 className="text-[0.800rem] font-bold text-primary md:text-2xl">
                        Terceiros
                      </h2>
                      <div className="custom-scrollbar z-50 min-h-24 w-full overflow-x-auto bg-white py-2">
                        <Table
                          columns={thirdPartycolumns(
                            onlineTraining || readOnlyTraining,
                            asyncTraining,
                            trainingFileTeam.modality as Modality,
                          )}
                          data={trainingFileTeam.employees
                            ?.filter((el) => el.isThirdParty)
                            .map((employee) => ({
                              ...employee,
                              trainingPlanningEmployeeLinkDays:
                                employee.trainingPlanningEmployeeLinkDays?.map(
                                  (planningDay) => ({
                                    ...planningDay,
                                    date: dateInUTCFormat(
                                      planningDay.date,
                                    ).toISOString(),
                                  }),
                                ),
                            }))}
                          tBodyClassname="border-none "
                          trBodyClassname="border-none"
                          tdHeaderClassname="bg-transparent"
                          trHeaderClassname="bg-transparent my-4 rounded-md"
                          tdClassname="px-0 align-top"
                          containerClassname="border-separate border-spacing-y-4"
                          columnVisibility={{
                            statusInDay: !asyncTraining,
                            extraMeal: !(onlineTraining || readOnlyTraining),
                            needTransport: !(
                              onlineTraining || readOnlyTraining
                            ),
                            extraAccommodation: !(
                              onlineTraining || readOnlyTraining
                            ),
                            startTime: !(onlineTraining || readOnlyTraining),
                            endTime: !(onlineTraining || readOnlyTraining),
                            extraAccommodationEntryDate: !(
                              onlineTraining || readOnlyTraining
                            ),
                            extraAccommodationExitDate: !(
                              onlineTraining || readOnlyTraining
                            ),
                            redmineManagerStatus: !asyncTraining,
                            confirmedPresence: !asyncTraining,
                          }}
                        />
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="flex min-h-40 w-full flex-col items-center justify-center rounded-md bg-white pb-2">
                  <img src={EmptyIcon} className="w-32" />
                  <div className="w-full text-center text-xs">
                    <span className="font-semibold text-blue-800">
                      Nenhuma colaborador alocado nessa turma!
                    </span>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};
