import { FC } from 'react';

import { StatusTrainingControlHistory } from '@/constants/TrainingConstants';

import LightTooltip from './LightTooltip';

interface Props {
  title: string;
}

const TableCellTrainingStatus: FC<Props> = ({ title }) => {
  const textColor = [
    StatusTrainingControlHistory.Concluido,
    StatusTrainingControlHistory.Convalidado,
    StatusTrainingControlHistory.ConcluidoPorEquivalencia,
    StatusTrainingControlHistory.Finalizado,
  ].includes(title as StatusTrainingControlHistory)
    ? 'text-[#10AE88]'
    : title === StatusTrainingControlHistory.Invalidado
      ? 'text-[#FFA654]'
      : 'text-[#F4503A]';

  return (
    <LightTooltip title={title} arrow placement="top-start">
      <div
        className={`flex w-32 justify-center text-center font-graphie text-[12px] ${textColor}`}
      >
        {title.toLocaleUpperCase()}
      </div>
    </LightTooltip>
  );
};

export default TableCellTrainingStatus;
