import DeleteIcon from '@mui/icons-material/Delete';
import { ButtonHTMLAttributes } from 'react';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: 'primary' | 'action';
  displayValue?: string;
}

const TrashButton = ({ onClick, color, displayValue, ...rest }: Props) => {
  const buttonStyle = displayValue ? { display: displayValue } : {};

  return (
    <button
      type="button"
      onClick={onClick}
      aria-label="delete"
      {...rest}
      style={buttonStyle}
      className="p-2 disabled:cursor-not-allowed disabled:opacity-35"
    >
      <DeleteIcon fontSize="small" color={color || 'inherit'} />
    </button>
  );
};

export default TrashButton;
