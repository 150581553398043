import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useResetRecoilState } from 'recoil';
import { z } from 'zod';

import NoPermissionText from '@/components/atoms/NoPermissionText';
import { userCanSeeTeamStructureForms } from '@/utils/handleSavePermissions';

import { RegisterAndEditTeam } from '../../components/templates/RegisterAndEditTeam';
import { editFormSchema } from '../../constants/TeamRegister';
import api from '../../services/apiSgft';
import { teamRegisterAtom } from '../../state/TeamRegister.atom';
import { TeamEdit } from '../../types/TeamRegister';

type formData = z.infer<typeof editFormSchema>;

const TeamsRegister: React.FC = () => {
  const { state } = useLocation();
  const structureId = state.structureId;
  const navigate = useNavigate();
  const methods = useForm<formData>({
    resolver: zodResolver(editFormSchema),
  });
  const reset = useResetRecoilState(teamRegisterAtom);

  useEffect(() => {
    async function fetchStructure(structureId: number) {
      const response = await api.get<formData>(`team-structure`, {
        params: { id: structureId },
      });
      const responseData = response.data;

      methods.setValue('name', responseData.name);
      methods.setValue('management', responseData.management);
      methods.setValue('area', responseData.area);
      methods.setValue('sector', responseData.sector);
      methods.setValue('workstation', responseData.workstation);
      methods.setValue('onNotice', responseData.onNotice ? 'SIM' : 'NÃO');
      methods.setValue(
        'mandatoryOnNotice',
        responseData.mandatoryOnNotice ? 'SIM' : 'NÃO',
      );
      methods.setValue('confinement', responseData.confinement ? 'SIM' : 'NÃO');
    }

    fetchStructure(structureId);
  }, []);

  const onSubmit = async (data: TeamEdit) => {
    if (data.area === -1 && !data.otherArea) {
      toast.info('É necessário preencher a área', {
        theme: 'colored',
        toastId: 'info',
      });
      return;
    }
    if (data.sector === '-1' && !data.otherSector) {
      toast.info('É necessário preencher o setor', {
        theme: 'colored',
        toastId: 'info',
      });
      return;
    }
    try {
      await api.put(`team-structure/${structureId}`, getDirtyFields(data));
      toast.success('Estrutura de turma salva com sucesso', {
        theme: 'colored',
        toastId: 'success',
      });
      resetFields();
    } catch (e) {
      toast.error('Erro ao salvar estrutura de turma', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const getDirtyFields = (data: TeamEdit) => {
    const dirtyFields = {
      name: methods.getFieldState('name').isDirty ? data.name : undefined,
      management: methods.getFieldState('management').isDirty
        ? data.management
        : undefined,
      area: methods.getFieldState('area').isDirty ? data.area : undefined,
      otherArea: methods.getFieldState('otherArea').isDirty
        ? data.otherArea?.toUpperCase()
        : undefined,
      sector: methods.getFieldState('sector').isDirty ? data.sector : undefined,
      otherSector: methods.getFieldState('otherSector').isDirty
        ? data.otherSector?.toUpperCase()
        : undefined,
      workstation: methods.getFieldState('workstation').isDirty
        ? data.workstation
        : undefined,
      onNotice: methods.getFieldState('onNotice').isDirty
        ? data.onNotice === 'SIM'
        : undefined,
      mandatoryOnNotice: methods.getFieldState('mandatoryOnNotice').isDirty
        ? data.mandatoryOnNotice === 'SIM'
        : undefined,
      confinement: methods.getFieldState('confinement').isDirty
        ? data.confinement === 'SIM'
        : undefined,
    };

    return dirtyFields;
  };

  const resetFields = () => {
    reset();
    methods.reset();
    navigate(-1);
  };

  if (!userCanSeeTeamStructureForms()) return <NoPermissionText />;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <RegisterAndEditTeam type="edit" onAbort={resetFields} />
      </form>
    </FormProvider>
  );
};

export default TeamsRegister;
