import { useRecoilState } from "recoil";

import { supplierRegisterAtom } from "../../state/SupplierRegister.atom";
import { Training } from "../../types/Supplier";

interface Props {
  id: number;
  isActive: boolean;
  name: string;
  email?: string;
  trainings: Training[];
  trainingTypes: string[];
  onlineMaxDays?: number;
  inPersonMaxDays?: number;
  setModalIsOpen: (value: boolean) => void;
}

export const SupplierGeneralInfo = ({
  id,
  isActive,
  name,
  email,
  trainings,
  trainingTypes,
  onlineMaxDays,
  inPersonMaxDays,
  setModalIsOpen,
}: Props) => {
  const [, setSupplierRegister] = useRecoilState(supplierRegisterAtom);

  return (
    <div className="mb-4 flex items-center justify-start w-full">
      <div>
        <div
          className={`my-[6px] flex h-[1.1rem] w-20 items-center justify-center rounded text-[11px] tracking-widest text-white ${isActive ? "bg-[#1FBCDC]" : "bg-[#FFA654]"}`}
        >
          {isActive ? "Ativo" : "Inativo"}
        </div>

        <button
          className="mb-[2px] mt-4 font-graphie text-[18px] font-bold text-[#5A5A5A]"
          onClick={() => {
            setSupplierRegister({
              id,
              name,
              email,
              onlineMaxDays,
              inPersonMaxDays,
              active: isActive,
              trainings,
              trainingTypes,
            });
            setModalIsOpen(true);
          }}
        >
          {name}
        </button>
        <div className="font-graphie text-[12px] text-[#A6A6A6] mb-2">
          {email ?? ""}
        </div>
      </div>
    </div>
  );
};
