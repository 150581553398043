import { z } from 'zod';
export const FormSchema = z.object({
  name: z
    .string({ required_error: 'Nome é obrigatório' })
    .min(1, { message: 'Nome é obrigatório' }),
  trainings: z.array(z.number()).default([]),
  supplierId: z.coerce.number({
    required_error: 'Fornecedor é obrigatório',
  }),
  trainingType: z.string({
    required_error: 'Tipo de Treinamento é obrigatório',
  }),
});
