import { MouseEvent, useState } from 'react';

import { redStatus, yellowStatus } from '../../constants/TrainingConstants';
import { ExpirationStatus } from '../../types/TrainingControlTypes';
import LightTooltip from './LightTooltip';

interface Props {
  status: ExpirationStatus | '-';
  upperCase?: boolean;
}

const TableCellStatus = ({ status, upperCase }: Props) => {
  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  const handleShouldShow = ({ currentTarget }: MouseEvent<Element>) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipEnabled(true);
    }
  };

  let bgColor = 'bg-[#10AE88]';
  const textColor = 'text-white';

  if (redStatus.includes(status)) {
    bgColor = 'bg-[#F4503A]';
  } else if (yellowStatus.includes(status)) {
    bgColor = 'bg-[#DDB31D]';
  }

  return (
    <LightTooltip
      title={status}
      open={tooltipEnabled}
      onClose={() => setTooltipEnabled(false)}
      arrow
      placement="top-end"
    >
      {status !== '-' ? (
        <div
          onMouseEnter={handleShouldShow}
          className={`text-[11px] ${textColor} ml-2 min-w-12 text-nowrap rounded p-[5px] tracking-widest ${bgColor}`}
        >
          {upperCase ? status.toUpperCase() : status}
        </div>
      ) : (
        <div className="w-28 text-center"></div>
      )}
    </LightTooltip>
  );
};

export default TableCellStatus;
