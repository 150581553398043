import { Paper } from '@mui/material';
import { useState } from 'react';
import { Control, FieldValues, UseFieldArrayRemove } from 'react-hook-form';
import { toast } from 'react-toastify';

import { queryClient } from '@/App';
import { BoardDto } from '@/pages/Board';
import api from '@/services/apiSgft';

import { ConfirmationDialog } from '../atoms/ConfirmationDialog';
import TrashButton from '../atoms/TrashButton';
import UncontrolledInputText from '../atoms/UncontrolledInputText';

interface Props {
  managementIndex: number;
  coordinationIndex: number;
  control: Control<FieldValues, any>;
  remove: UseFieldArrayRemove;
  data?: BoardDto;
}

const CoordinationRegisterCard = ({
  managementIndex,
  coordinationIndex,
  control,
  remove,
  data,
}: Props) => {
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);

  const onRemove = async (id?: number) => {
    if (!id) return;
    try {
      await api.delete(`coordination/${id}`);
      toast.success(`Coordenação desativada com sucesso!`, {
        theme: 'colored',
        toastId: 'success',
      });
      queryClient.invalidateQueries(['get-all-boards-data']);
    } catch (e) {
      toast.error(`Erro ao desativar coordenação!`, {
        theme: 'colored',
        toastId: 'error',
      });
    }
    setIsOpenDelete(false);
  };

  const removeCoordination = () => {
    if (
      data?.managements[managementIndex]?.coordinations[coordinationIndex]?.id
    ) {
      setIsOpenDelete(true);
    } else {
      remove(managementIndex);
    }
  };

  return (
    <div>
      <Paper
        sx={{
          background:
            (data && !data.active) ||
            (data?.managements[managementIndex]?.coordinations[
              coordinationIndex
            ] &&
              !data?.managements[managementIndex]?.coordinations[
                coordinationIndex
              ]?.active)
              ? '#f2f2f2'
              : '#86b2ff21',
          padding: 1,
          marginY: 1,
          display: 'flex',
          justifyContent: 'between',
          alignItems: 'end',
        }}
      >
        <UncontrolledInputText
          title="Coordenação"
          width="30rem"
          {...control.register(
            `managements.${managementIndex}.coordinations.${coordinationIndex}.name`,
          )}
          disabled={
            (data && !data.active) ||
            (data?.managements[managementIndex]?.coordinations[
              coordinationIndex
            ] &&
              !data?.managements[managementIndex]?.coordinations[
                coordinationIndex
              ]?.active)
          }
          upperCase
        />
        <TrashButton
          color={'action'}
          onClick={removeCoordination}
          disabled={
            (data && !data.active) ||
            (data?.managements[managementIndex]?.coordinations[
              coordinationIndex
            ] &&
              !data?.managements[managementIndex]?.coordinations[
                coordinationIndex
              ]?.active)
          }
        />
      </Paper>
      {isOpenDelete && (
        <ConfirmationDialog
          title={'Confirmação de Desativação'}
          description={
            'Ao prosseguir com esta ação, a coordenação e todas as suas informações serão desativadas no sistema.'
          }
          setIsOpen={setIsOpenDelete}
          onConfirmation={() =>
            onRemove(
              data?.managements[managementIndex]?.coordinations[
                coordinationIndex
              ]?.id,
            )
          }
          height="15rem"
          width="30rem"
        />
      )}
    </div>
  );
};
export default CoordinationRegisterCard;
