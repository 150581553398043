import { useState, useCallback, useEffect, useRef } from 'react';
import { sidebarIsOpenAtom } from '../state/sidebarIsOpen.atom';
import { useRecoilValue } from 'recoil';

const useElementWidth = () => {
  const sidebarIsOpen = useRecoilValue(sidebarIsOpenAtom);

  const [availableWidth, setAvailableWidth] = useState(0);

  const measuredRef = useRef<HTMLDivElement>(null);

  const handleResize = useCallback(() => {
    setTimeout(() => {
      if (measuredRef.current) {
        setAvailableWidth(measuredRef.current.getBoundingClientRect().width);
      }
    }, 500);
  }, [measuredRef, sidebarIsOpen]);

  useEffect(() => {
    handleResize(); // Chama handleResize ao montar o componente para definir a largura inicial

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize, sidebarIsOpen]);
  return { availableWidth, measuredRef };
};
export default useElementWidth;
