import { HiOutlineBan } from 'react-icons/hi';

import LightTooltip from './LightTooltip';

interface MenuProps {
  message: string;
  className: string;
}
const NotAllowedTooltip = ({ message, className }: MenuProps) => {
  return (
    <LightTooltip title={message}>
      <span>
        <HiOutlineBan fontSize="18px" color="#9ca3af" className={className} />
      </span>
    </LightTooltip>
  );
};
export default NotAllowedTooltip;
