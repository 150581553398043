import { atom } from 'recoil';

export interface SuppliersAtom extends Record<string, string[]> {
  supplier: string[];
  training: string[];
  trainingType: string[];
}

export const suppliersAtom = atom<SuppliersAtom>({
  key: 'suppliers',
  default: {
    supplier: [],
    training: [],
    trainingType: [],
  },
});
