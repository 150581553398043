import { Grid } from '@mui/material';
import { AxiosError } from 'axios';
import { parseISO } from 'date-fns';
import { useState } from 'react';
import {
    FieldValues,
    FormProvider,
    useForm,
    UseFormReturn,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { trackEvent } from '@/utils/trackEvent';

import EmptyIcon from '../../assets/icons/empty.svg';
import { Button } from '../../components/atoms/Button';
import Title from '../../components/atoms/Title';
import { TeamGroupRegister } from '../../components/molecules/TeamGroupRegister';
import Schedule from '../../components/organisms/Schedule';
import Table from '../../components/organisms/Table';
import { columns } from '../../constants/tableColumns/TeamRegisterScheduleTableColumns';
import api from '../../services/apiSgft';
import { teamRegisterAtom } from '../../state/TeamRegister.atom';
import { teamRegisterPreviewAtom } from '../../state/TeamRegisterPreview.atom';
import { TeamRegisterSchedule } from '../../types/TeamRegisterSchedule';

const TeamsRegisterPreview: React.FC = () => {
  const team = useRecoilValue(teamRegisterAtom);
  const [_, setTeamPreview] = useRecoilState(teamRegisterPreviewAtom);
  const [table, setTable] = useState<TeamRegisterSchedule[]>([]);
  const navigate = useNavigate();

  const List = (loop: number) => {
    const rows = [];
    for (let i = 0; i < loop; i++) {
      rows.push(i);
    }
    return rows;
  };

  const methods = useForm({});

  const goToBackPage = (methods: UseFormReturn<FieldValues, any, any>) => {
    setTeamPreview({
      date: methods
        .getValues('teamsRotation')
        .map((item: teamRegisterPreviewAtom) => item.startDate),
      minPeople: methods
        .getValues('teamsRotation')
        .map((item: teamRegisterPreviewAtom) => item.minPeople),
      rotation: methods
        .getValues('teamsRotation')
        .map((item: teamRegisterPreviewAtom) => item.rotation),
    });
    navigate(-1);
  };

  function checkFieldsPreview(teamsRotationList: Object[]): boolean {
    return teamsRotationList.every((obj) =>
      Object.values(obj).every((value) => value !== undefined),
    );
  }

  function checkFieldsCreate(teamsRotationList: Object[]): boolean {
    return teamsRotationList.every((obj) =>
      Object.values(obj).every((value) => value !== undefined && value !== ''),
    );
  }

  const getPreview = async (): Promise<any> => {
    if (!checkFieldsPreview(methods.getValues('teamsRotation'))) {
      toast.warn('Preencha todos os campos do formulário!');
      return;
    }

    try {
      const response = await api.post('/team-structure/getPreviewSchedule', {
        name: team.name,
        management: team.management,
        area: team.area,
        sector: team.sector,
        workstation: team.workstation,
        structureType: team.structureType,
        scale: team.scale,
        shift: 'N/A',
        teamsLength: team.teamsLength,
        teamsRotation: methods.getValues('teamsRotation'),
        onNotice: team.onNotice === 'SIM',
        mandatoryOnNotice: team.mandatoryOnNotice === 'SIM',
        confinement: team.confinement === 'SIM',
      });
      const table = response.data.map((el: any, index: number) => {
        return {
          team: `T${index + 1}`,
          schedule: el,
        };
      });
      setTable(table);
    } catch (e) {
      toast.error('Erro ao gerar preview de escalas', {
        theme: 'colored',
        toastId: 'error',
      });
    }
  };

  const reset = useResetRecoilState(teamRegisterAtom);

  const onSubmit = async (data: any): Promise<any> => {
    if (!checkFieldsCreate(methods.getValues('teamsRotation'))) {
      toast.warn('Preencha todos os campos do formulário!');
      return;
    }
    try {
      await api.post('/team-structure/create', {
        name: team.name,
        management: team.management,
        area: team.area,
        otherArea: team.otherArea,
        sector: team.sector,
        otherSector: team.otherSector,
        workstation: team.workstation,
        structureType: team.structureType,
        scale: team.scale,
        shift: 'N/A',
        startDate: team.startDate,
        teamsLength: team.teamsLength,
        teamsRotation: methods.getValues('teamsRotation'),
        onNotice: team.onNotice === 'SIM',
        mandatoryOnNotice: team.mandatoryOnNotice === 'SIM',
        confinement: team.confinement === 'SIM',
      });
      trackEvent(team.structureType, 'generate_team_structure', team.scale);
      toast.success('Estrutura de turma criada com sucesso', {
        theme: 'colored',
        toastId: 'sucess',
      });
      reset();
      methods.reset();
      navigate('/home/planejamento-de-escalas-e-ferias');
    } catch (e) {
      if (
        e instanceof AxiosError &&
        typeof e.response?.data.message !== 'undefined'
      ) {
        const error = e.response.data.message.includes('Erro na criação:')
          ? e.response.data.message
          : 'Erro ao criar estrutura de turma';
        toast.error(error, {
          theme: 'colored',
          toastId: 'error',
        });
      } else {
        toast.error('Erro ao criar estrutura de turma', {
          theme: 'colored',
          toastId: 'error',
        });
      }
    }
  };

  return (
    <div className="mt-1 flex h-full w-full max-w-[110rem] justify-center self-center">
      <div className="w-full flex-col">
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="preview"
            noValidate
          >
            <Title title="Preview de Turmas" />
            <div className="mt-3 flex min-h-[80vh]  flex-col justify-between rounded-[15px] bg-white px-3 py-5">
              <div>
                {table.length > 0 ? (
                  <div className="mb-4 mt-4 flex w-full rounded-md border-[1px] border-solid p-3">
                    <div className="min-w-[4.4rem]">
                      <Table data={table} columns={columns} />
                    </div>
                    <div className="w-[95%]">
                      <Schedule
                        data={table}
                        startDate={parseISO(Object.keys(table[0].schedule)[0])}
                        endDate={parseISO(
                          Object.keys(table[0].schedule)[
                            Object.keys(table[0].schedule).length - 1
                          ],
                        )}
                        stickyHeader={false}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="flex w-full flex-col items-center justify-center py-5">
                    <img src={EmptyIcon} className="w-40" />
                    <div className="flex flex-col items-center text-sm">
                      <span className="mb-[1rem] mt-[1rem] font-semibold text-blue-800">
                        Por favor, preencha os dados abaixo para visualizar as
                        escalas das turmas!
                      </span>
                    </div>
                  </div>
                )}
                <Grid
                  marginBottom={'1rem'}
                  container
                  marginTop={'2rem'}
                  width={'full'}
                  spacing={1}
                  justifyContent={'center'}
                >
                  {team.teamsLength > 0 &&
                    List(team.teamsLength).map((el, index) => (
                      <Grid
                        key={index}
                        item
                        xs={team.teamsLength == 1 ? 12 : 6}
                        display="flex"
                        justifyContent={'center'}
                      >
                        <TeamGroupRegister
                          key={index}
                          methods={methods}
                          index={index}
                        />
                      </Grid>
                    ))}
                </Grid>
              </div>
              <div className="mb-4 mt-5 flex justify-between">
                <Button
                  type="button"
                  className="mr-5 min-w-36 border-2 border-[#193CB9] bg-white text-[#193CB9] hover:bg-[#e9eaf1]"
                  onClick={() => {
                    goToBackPage(methods);
                  }}
                >
                  Voltar
                </Button>
                <div className="flex items-center">
                  <Button
                    type="button"
                    onClick={() => getPreview()}
                    disabled={!team.teamsLength}
                    className="mr-5 min-w-36 border-2 border-[#193CB9] bg-white text-[#193CB9] hover:bg-[#e9eaf1]"
                  >
                    Pré-visualização
                  </Button>
                  <Button
                    type="submit"
                    disabled={!team.teamsLength}
                    className="min-w-36"
                  >
                    Criar Estrutura
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default TeamsRegisterPreview;
