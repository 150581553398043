import React from 'react';

import { trainingColumns } from '@/constants/tableColumns/TrainingPlanningTableColumns';

import { Team } from '../../types/ViewSchedule';
import { cn } from '../../utils/cn';
import { getColorByTeamName } from '../../utils/getColorByTeamName';
import { getSubtitleByTeamName } from '../../utils/getSubtitleByTeamName';
import Table from '../organisms/Table';
import NewTeamIndicator from './NewTeamIndicator';
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from './ToolTip';

interface ScheduleTeamProps {
  scheduleTeamName: Team;
  isFirstOfGroup: boolean;
  isLastOfGroup: boolean;
  borderRed?: boolean | undefined;
  message?: string;
  newTeam: string | undefined;
  workShiftExtensionMessage?: string | undefined;
  onNoticeActivation: boolean | undefined;
  onNoticeActivationMessage: string | undefined;
  trainingTimes?: Array<{
    startTime: string;
    endTime: string;
    name: string;
    date: string;
    location: string;
  }>;
}

const ScheduleTeam: React.FC<ScheduleTeamProps> = React.memo(
  ({
    scheduleTeamName,
    isFirstOfGroup,
    isLastOfGroup,
    borderRed,
    message,
    newTeam,
    workShiftExtensionMessage,
    onNoticeActivation,
    onNoticeActivationMessage,
    trainingTimes,
  }) => {
    const dailyTrainings =
      trainingTimes?.map((training) => ({
        startHour: training.startTime,
        name: training.name,
        location: training.location,
        endHour: training.endTime,
        date: training.date,
      })) ?? [];
    return (
      <div
        className={cn(
          'relative z-10 flex h-6 cursor-pointer select-none items-center justify-center text-sm font-normal',
          getColorByTeamName(scheduleTeamName),
          borderRed && 'border-2 border-red',
          isFirstOfGroup && 'ml-[2px] rounded-l-sm',
          isLastOfGroup && 'mr-[2px] rounded-r-sm',
          scheduleTeamName === 'N/A' && 'bg-transparent text-gray-400',
        )}
      >
        <TooltipProvider delayDuration={500} skipDelayDuration={200}>
          <Tooltip>
            <TooltipTrigger asChild>
              <div
                className={cn(
                  'flex w-full justify-center text-white',
                  (scheduleTeamName === 'N/A' || scheduleTeamName === '-') &&
                    'bg-transparent text-gray-400',
                )}
              >
                {scheduleTeamName}
                {workShiftExtensionMessage !== undefined &&
                  !onNoticeActivation &&
                  '*'}
                {message?.includes('sobreaviso') && (
                  <div className="on-notice-div">
                    SA{onNoticeActivation && '*'}
                  </div>
                )}
              </div>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent
                className=" bg-white"
                align="center"
                sideOffset={1}
                arrowPadding={50}
                side="left"
              >
                <TooltipArrow className="fill-white" />
                {scheduleTeamName === 'TR' ? (
                  <div className="min-w-[30rem] ">
                    <span className="text-lg font-semibold text-[#193CB9]">
                      Treinamentos do dia
                    </span>
                    <div className="custom-scrollbar max-h-[50vh] overflow-y-auto">
                      <Table
                        columns={trainingColumns}
                        data={dailyTrainings}
                        containerClassname="text-center w-full"
                        trBodyClassname="even:bg-white"
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <p>{getSubtitleByTeamName(scheduleTeamName, message)}</p>
                    {workShiftExtensionMessage !== undefined && (
                      <p>{workShiftExtensionMessage}</p>
                    )}
                    {onNoticeActivation && <p>{onNoticeActivationMessage}</p>}
                  </>
                )}
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        </TooltipProvider>
        {newTeam && <NewTeamIndicator newTeam={newTeam} />}
      </div>
    );
  },
);
ScheduleTeam.displayName = 'ScheduleTeam';

export default ScheduleTeam;
