import { atom } from 'recoil';

export interface teamRegisterPreviewAtom extends Record<string, Date[] | string[] | undefined | number[]> {
  date: Date[];
  rotation: string[];
  minPeople: number[];
}

const teamRegisterPreviewAtom = atom<teamRegisterPreviewAtom>({
  key: 'teamRegisterPreview',
  default: {
    date: [],
    rotation: [],
    minPeople: [],
  },
});

export { teamRegisterPreviewAtom };
