import { z } from 'zod';
export const FormSchema = z.object({
  name: z.string(),
  employeeNumber: z.string(),
  extraAccommodations: z.array(
    z.object({
      entryDate: z.date().nullable(),
      exitDate: z.date().nullable(),
    }),
  ),
  route: z.string().optional().nullable(),
  phoneNumber: z.string().optional().nullable(),
  confirmedPresence: z.boolean(),
  absenceJustification: z.string().optional().nullable(),

  planningDays: z.array(
    z.object({
      id: z.number(),
      date: z.date(),
      extraMeal: z.boolean(),
      needTransport: z.boolean(),
      route: z.string().optional().nullable(),
      requestiaId: z.string().optional().nullable(),
      address: z.string().optional().nullable(),
    }),
  ),
});
