import { ColumnDef } from '@tanstack/react-table';
import React from 'react';

import ViewMenu from '@/components/molecules/ViewMenu';

import TableData from '../../components/atoms/TableData';
import { getFirstAndLastName } from '../../utils/getFirstAndLastName';
import { EmployeeStatus } from '../EmployeeConstants';

export const columns: Array<ColumnDef<any>> = [
  {
    accessorKey: 'name',
    header: 'Nome',
    meta: {
      headerClassName:
        'sticky left-0 text-white bg-[#193CB9] rounded-tl-xl z-10',
      stickyClassName: 'sticky left-0 bg-inherit z-10',
    },
    cell: (props) => {
      // const navigate = useNavigate();

      const status = props.row.original.status;
      const shouldDisable =
        status === EmployeeStatus.INSS ||
        status === EmployeeStatus.MATERNITY_LEAVE;

      return (
        <div className="flex w-full items-center">
          <TableData
            tableValue={
              props.row.original.alias ??
              getFirstAndLastName(props.getValue() as string)
            }
            className="w-44 whitespace-nowrap px-3 font-semibold"
            title={props.getValue() as string}
            // onClick={() =>
            //   navigate(`/home/colaborador/${props.row.original.id.toString()}`)
            // }
          />
          <ViewMenu
            employeeId={props.row.original.id}
            isFirstAllocation={props.row.original.neverAllocated}
            id={props.row.original.id as number}
            managementId={props.row.original.managementId as number}
            admissionDate={props.row.original.admissionDate}
            inactiveStatus={shouldDisable}
            employeeName={props.getValue() as string}
            employeeAlias={props.row.original.alias ?? undefined}
            employeePosition={props.row.original.position}
            employeeRegime={props.row.original.regime}
            employeeEmail={props.row.original.email}
            employeeNumber={props.row.original.employeeNumber}
            employeePole={props.row.original.pole}
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'employeeNumber',
    header: 'Chapa',
    meta: {
      headerClassName: 'sticky left-[210px] text-white bg-[#193CB9] z-10',
      stickyClassName: 'sticky left-[210px] bg-inherit z-10',
    },
    cell: (props) => {
      // const navigate = useNavigate();
      return (
        <TableData
          tableValue={props.getValue()}
          className="overflow-hidden text-ellipsis whitespace-nowrap px-3"
          title={props.getValue() as string}
          // onClick={() =>
          //   navigate(`/home/colaborador/${props.row.original.id.toString()}`)
          // }
        />
      );
    },
  },
  {
    accessorKey: 'position',
    header: 'Cargo',
    meta: {
      headerClassName: 'text-white bg-[#193CB9]',
    },
    cell: (props) => (
      <TableData
        tableValue={props.getValue()}
        className="w-44 overflow-hidden text-ellipsis  whitespace-nowrap px-3"
        title={props.getValue() as string}
      />
    ),
  },
  {
    accessorKey: 'workStation',
    header: 'Local de Trabalho',
    meta: {
      headerClassName: 'text-white bg-[#193CB9]',
    },
    cell: (props) => (
      <TableData
        tableValue={props.getValue()}
        className="overflow-hidden text-ellipsis whitespace-nowrap  px-3"
        title={props.getValue() as string}
      />
    ),
  },
  {
    accessorKey: 'state',
    header: 'Estado',
    meta: {
      headerClassName: 'text-white bg-[#193CB9]',
    },
    cell: (props) => (
      <TableData
        tableValue={props.getValue()}
        className="overflow-hidden text-ellipsis whitespace-nowrap px-3"
        title={props.getValue() as string}
      />
    ),
  },

  {
    accessorKey: 'management',
    header: 'Gerência',
    meta: {
      headerClassName: 'text-white bg-[#193CB9]',
    },
    cell: (props) => (
      <TableData
        tableValue={props.getValue()}
        className="w-40 overflow-hidden text-ellipsis  whitespace-nowrap px-3"
        title={props.getValue() as string}
      />
    ),
  },
  {
    accessorKey: 'manager',
    header: 'Gerente',
    meta: {
      headerClassName: 'text-white bg-[#193CB9]',
    },
    cell: (props) => (
      <TableData
        tableValue={props.getValue()}
        className="w-44 overflow-hidden text-ellipsis  whitespace-nowrap px-3"
        title={props.getValue() as string}
      />
    ),
  },
  {
    accessorKey: 'regime',
    header: 'Regime',
    meta: {
      headerClassName: 'text-white bg-[#193CB9] rounded-tr-xl',
    },
    cell: (props) => (
      <TableData
        tableValue={props.getValue()}
        className="overflow-hidden text-ellipsis whitespace-nowrap  px-3"
        title={props.getValue() as string}
      />
    ),
  },
  {
    accessorKey: 'space',
    header: '',
    meta: {
      headerClassName: 'px-0',
    },
    cell: () => <></>,
  },
  {
    accessorKey: 'managerSGFT',
    header: 'Gerência',
    meta: {
      headerClassName: 'text-white bg-[#1FBCDC] rounded-tl-xl',
    },
    cell: (props) => (
      <TableData
        tableValue={props.getValue() || '-'}
        className="overflow-hidden text-ellipsis whitespace-nowrap  px-3"
        title={props.getValue() as string}
      />
    ),
  },
  {
    accessorKey: 'structure',
    header: 'Estrutura',
    meta: {
      headerClassName: 'text-white bg-[#1FBCDC]',
    },
    cell: (props) => (
      <TableData
        tableValue={props.getValue() || '-'}
        className="overflow-hidden text-ellipsis whitespace-nowrap  px-3"
        title={props.getValue() as string}
      />
    ),
  },
  {
    accessorKey: 'team',
    header: 'Turma',
    meta: {
      headerClassName: 'text-white bg-[#1FBCDC]',
    },
    cell: (props) => (
      <TableData
        tableValue={props.getValue() || '-'}
        className="overflow-hidden text-ellipsis whitespace-nowrap  px-3"
        title={props.getValue() as string}
      />
    ),
  },
  {
    accessorKey: 'vacation_relief_worker',
    header: 'Ferista',
    meta: {
      headerClassName: 'text-white bg-[#1FBCDC]',
    },
    cell: (props) => (
      <TableData
        tableValue={props.getValue() ? 'Sim' : 'Não'}
        className="overflow-hidden text-ellipsis whitespace-nowrap  px-3"
        title={props.getValue() ? 'Sim' : 'Não'}
      />
    ),
  },
  {
    accessorKey: 'fire_warden',
    header: 'Brigadista',
    meta: {
      headerClassName: 'text-white bg-[#1FBCDC] rounded-tr-xl',
    },
    cell: (props) => (
      <TableData
        tableValue={props.getValue() ? 'Sim' : 'Não'}
        className="overflow-hidden text-ellipsis whitespace-nowrap px-3"
        title={props.getValue() ? 'Sim' : 'Não'}
      />
    ),
  },
];
