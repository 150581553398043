import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';

import api from '@/services/apiSgft';
import { AllocationDataTypeAtom } from '@/state/AllocationDataType.atom';
import { AllocationEditTypeAtom } from '@/state/AllocationEditType.atom';
import { AllocationTypeAtom } from '@/state/AllocationType.atom';
import { AllocationUpdateDataTypeAtom } from '@/state/UpdateDataType.atom';

import AllocationMenu from '../atoms/AllocationMenu';
import ModalConfirmation from './ConfirmationModal';

interface Props {
  allocation: any;
}

const AllocationButtons = ({ allocation }: Props) => {
  const [, setType] = useRecoilState(AllocationTypeAtom);
  const [, setEditData] = useRecoilState(AllocationEditTypeAtom);
  const [updateData, setUpdateData] = useRecoilState(
    AllocationUpdateDataTypeAtom,
  );
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const data = useRecoilValue(AllocationDataTypeAtom);
  const [allowEditAllocation, setAllowEditAllocation] = useState(true);
  const [allowEditDeallocation, setAllowEditDeallocation] = useState(false);
  const [allowDeleteAllocation, setAllowDeleteAllocation] = useState(true);
  const [allowDeleteDeallocation, setAllowDeleteDeallocation] = useState(false);
  const [deleteType, setDeleteType] = useState('');

  useEffect(() => {
    if (data) {
      if (data[data.length - 1].id === allocation.id) {
        setAllowEditAllocation(false);
        setAllowDeleteAllocation(false);
      } else {
        setAllowEditAllocation(true);
        setAllowDeleteAllocation(true);
      }

      if (data[0].id === allocation.id && allocation.end_date !== null) {
        setAllowEditDeallocation(true);
        setAllowDeleteDeallocation(true);
      } else {
        setAllowEditDeallocation(false);
        setAllowDeleteDeallocation(false);
      }
    }
  }, [data, updateData]);

  const handleDelete = async () => {
    try {
      await api.delete(
        `/team-employee?employeeTeamHistoryId=${Number(allocation.id)}`,
      );
      setUpdateData(true);
      setIsConfirmationModalOpen(false);
      setType('');
      setDeleteType('');
    } catch (e) {
      toast.error('Não foi possível deletar a alocação', {
        theme: 'colored',
        toastId: 'error',
      });
      setIsConfirmationModalOpen(false);
      throw e;
    }
  };

  const handleEdit = async () => {
    setType('edit');
    setEditData({ ...allocation, isUpdated: true });
  };

  const handleEditDeallocation = async () => {
    setType('deallocate');
    setEditData(allocation);
  };

  const handleCloseDeleteModal = () => {
    setIsConfirmationModalOpen(false);
    setDeleteType('');
  };

  const handleDeleteDeallocation = async () => {
    try {
      await api.delete(
        `/team-employee/delete-desallocation?employeeTeamHistoryId=${Number(allocation.id)}`,
      );
      setUpdateData(true);
      setIsConfirmationModalOpen(false);
      setType('');
      setDeleteType('');
    } catch (e) {
      toast.error('Não foi possível deletar a desalocação', {
        theme: 'colored',
        toastId: 'error',
      });
      setIsConfirmationModalOpen(false);
      throw e;
    }
  };

  const handleConfirmAction = () => {
    if (deleteType === 'deallocation') handleDeleteDeallocation();
    else handleDelete();
  };

  const editOptions = [
    {
      name: 'Editar Realocação',
      onClick: () => {
        handleEdit();
      },
      disabled: !allowEditAllocation,
    },
    {
      name: 'Editar Desalocação',
      onClick: () => {
        handleEditDeallocation();
      },
      disabled: !allowEditDeallocation,
    },
  ];

  const deleteOptions = [
    {
      name: 'Remover Realocação',
      onClick: () => {
        setDeleteType('reallocation');
        setIsConfirmationModalOpen(true);
      },
      disabled: !allowDeleteAllocation,
    },
    {
      name: 'Remover Desalocação',
      onClick: () => {
        setDeleteType('deallocation');
        setIsConfirmationModalOpen(true);
      },
      disabled: !allowDeleteDeallocation,
    },
  ];

  return (
    <div className="flex h-[35px] items-center justify-center">
      <div>
        <AllocationMenu options={editOptions} editButton={true} />
      </div>
      <div className="pl-2">
        <AllocationMenu options={deleteOptions} deleteButton={true} />
      </div>
      <ModalConfirmation
        title={''}
        description={
          deleteType === 'deallocation'
            ? `Tem certeza que deseja cancelar a desalocação do ${allocation.employeeName} da turma ${allocation.team}?`
            : 'Tem certeza que deseja excluir essa alteração?'
        }
        confirmAction={handleConfirmAction}
        isOpen={isConfirmationModalOpen}
        onClose={handleCloseDeleteModal}
        discartAction={handleCloseDeleteModal}
      />
    </div>
  );
};

export default AllocationButtons;
