import IconButton from '@mui/material/IconButton';
import * as React from 'react';
import { CgSpinner } from 'react-icons/cg';
import { MdOutlineFileDownload } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';

import { TrainingTypeEnum } from '@/constants/trainingTypeOptions';
import { trackEvent } from '@/utils/trackEvent';

import api from '../../services/apiSgft';
import {
  JobTrainingMatrixFilters,
  jobTrainingMatrixFiltersAtom,
} from '../../state/JobTraningMatrixFilter.atom';
import LightTooltip from './LightTooltip';

interface ExtractTrainingMatrixButtonProps {
  filteredData: JobTrainingMatrixFilters;
}

export const ExtractTrainingMatrixButton: React.FC<
  ExtractTrainingMatrixButtonProps
> = (props) => {
  const [loading, setLoading] = React.useState(false);
  const filterValues = useRecoilValue(jobTrainingMatrixFiltersAtom);
  const isDisabled = loading || filterValues.location.length <= 0;

  async function downloadMatrix() {
    setLoading(true);
    try {
      const response = await api.get(`/extraction/job-training-matrix`, {
        params: {
          location: props.filteredData.location,
          trainings: props.filteredData.training,
          jobTitles: props.filteredData.jobTitle,
          trainingType: props.filteredData.trainingType,
          management: props.filteredData.management,
          areaCoordination: props.filteredData.areaCoordination,
          workstation: props.filteredData.workstation,
        },
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      );
      const link = document.createElement('a');

      const downloadFileName =
        props.filteredData.trainingType[0] === TrainingTypeEnum.REGULATORY
          ? `Matriz de Treinamentos ${TrainingTypeEnum.REGULATORY}s.xlsx`
          : `Matriz de Treinamentos de ${TrainingTypeEnum.PROCEDURE}s.xlsx`;

      link.href = url;
      link.setAttribute('download', downloadFileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      trackEvent('Download da Matriz de Treinamentos', 'file_download', url);
    } catch (e) {
      toast.error('Erro ao realizar extração', {
        theme: 'colored',
        toastId: 'error',
      });
      trackEvent(
        'Download da Matriz de Treinamentos',
        'file_download',
        'Erro ao realizar o Download',
      );
      throw e;
    } finally {
      setLoading(false);
    }
  }

  const buttonWithTooltip = (
    <div className="cursor-pointer ">
      <LightTooltip title="Extrair matriz de treinamentos">
        <IconButton onClick={downloadMatrix} disabled={isDisabled}>
          {loading ? (
            <CgSpinner
              size={30}
              color="#c1c1c1"
              className="h-full animate-spin"
            />
          ) : (
            <MdOutlineFileDownload />
          )}
        </IconButton>
      </LightTooltip>
    </div>
  );

  const buttonWithoutTooltip = (
    <div className="cursor-pointer ">
      <IconButton disabled={isDisabled}>
        {loading ? (
          <CgSpinner
            size={30}
            color="#c1c1c1"
            className="h-full animate-spin"
          />
        ) : (
          <MdOutlineFileDownload />
        )}
      </IconButton>
    </div>
  );

  return <>{isDisabled ? buttonWithoutTooltip : buttonWithTooltip}</>;
};
