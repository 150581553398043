import { atom } from 'recoil';

export interface TrainingFilesFiltersAtom {
  team: string[];
  employee: string[];
  management: string[];
  areaBoard: string[];
  timeRange: {
    startDate: string | undefined;
    endDate: string | undefined;
  };
  workStation: string[];
  role: string[];
}
export const trainingFilesDetailsFiltersAtom = atom<TrainingFilesFiltersAtom>({
  key: 'trainingFilesDetails',
  default: {
    timeRange: {
      startDate: undefined,
      endDate: undefined,
    },
    management: [],
    team: [],
    employee: [],
    areaBoard: [],
    role: [],
    workStation: [],
  },
});
