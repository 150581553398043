import EditIcon from '@mui/icons-material/Edit';
import { ButtonHTMLAttributes } from 'react';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: 'primary';
  displayValue?: string;
}

const EditButton = ({ onClick, color, displayValue, ...rest }: Props) => {
  const buttonStyle = displayValue ? { display: displayValue } : {};

  return (
    <button
      onClick={onClick}
      aria-label="edit"
      {...rest}
      style={buttonStyle}
      className="p-2 disabled:cursor-not-allowed disabled:opacity-35"
    >
      <EditIcon
        fontSize="small"
        color={color ? 'primary' : 'inherit'}
        sx={{
          fontSize: '18px',
          color: '#9CA3AF',
        }}
      />
    </button>
  );
};

export default EditButton;
