interface Props {
  title: string;
  width?: string;
  value: string;
  setState: (value: string) => void;
  fontColor?: string;
  fontSize?: string;
}

const SimpleTextField = ({
  title,
  value,
  setState,
  fontColor,
  fontSize,
  width,
}: Props) => {
  return (
    <div className={`${width ? width : 'w-full'}`}>
      <div
        className={`${fontSize ? fontSize : 'text-sm'} ${fontColor ? fontColor : ''}`}
      >
        {title}
      </div>
      <input
        type="text"
        value={value}
        onInput={(e) => setState(e.currentTarget.value as string)}
        className={`${width ? width : 'w-full'} text-gray-800 h-8 border-[1px] rounded-[4px] border-gray-400 text-xs pl-2`}
      />
    </div>
  );
};

export default SimpleTextField;
