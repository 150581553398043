import React from 'react';

import ErrorIcon from '../../assets/icons/error.svg';
import Spinner from '../atoms/Spinner';

interface TrainingPlanningWrapperProps {
  children: React.ReactNode;
  isLoading: boolean;
  isError: boolean;
}

const TrainingPlanningWrapper: React.FC<TrainingPlanningWrapperProps> = (
  props,
) => {
  return (
    <div className="flex min-h-[75vh] w-full justify-between rounded-[15px] bg-white px-4 pb-2 pt-2">
      {props.isLoading ? (
        <div className="my-4 flex h-full w-full items-center rounded-md bg-white">
          <Spinner size={40} />
        </div>
      ) : props.isError ? (
        <div className="h-full w-full px-[1%]">
          <div className="flex h-full w-full flex-col items-center justify-center rounded-md bg-white">
            <img src={ErrorIcon} alt="Ícone de erro" className="w-24" />
            <div className="flex flex-col items-center text-center">
              Não foi possível carregar os dados
              <span className="font-semibold text-blue-800">
                tente novamente mais tarde!
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="custom-scrollbar mt-4 flex h-full w-full flex-col gap-4 overflow-y-scroll">
          {props.children}
        </div>
      )}
    </div>
  );
};

export default TrainingPlanningWrapper;
