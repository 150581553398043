import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ptBR } from '@mui/x-date-pickers/locales';
import br from 'date-fns/locale/pt-BR';
import React from 'react';

import { removeTimeZone } from '../../utils/formatDate';

type SingleDateProps = {
  dateValue: string;
  setDate: any;
  value: string;
  id: number;
  required?: boolean;
  shouldDisable?: any;
  containerClassName?: string;
  width?: string;
  disabled?: boolean;
  minDate?: Date;
  name?: string;
  maxDate?: Date;
};

const SingleDate: React.FC<SingleDateProps> = ({
  dateValue,
  setDate,
  value,
  id,
  shouldDisable,
  required,
  containerClassName,
  width,
  disabled,
  minDate,
  maxDate,
  name,
}) => {
  return (
    <div className={containerClassName}>
      <div className="text-[12px] text-[#4a4a4a]">
        {value}
        {required && <span className="text-red">*</span>}
      </div>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        localeText={
          ptBR.components.MuiLocalizationProvider.defaultProps.localeText
        }
        adapterLocale={br}
      >
        <DatePicker
          key={id}
          onChange={(date) => {
            setDate &&
              setDate(
                date && date.toString().length > 20 ? date.toString() : '',
              );
          }}
          value={dateValue ? removeTimeZone(new Date(dateValue)) : null}
          slotProps={{
            textField: { size: 'small', disabled: true },
          }}
          name={name}
          sx={{
            width: width || '23rem',
            '& .MuiInputBase-root': {
              height: '2rem',
              fontSize: '12px',
            },
          }}
          disabled={disabled}
          shouldDisableDate={shouldDisable}
          minDate={minDate}
          maxDate={maxDate}
        />
      </LocalizationProvider>
    </div>
  );
};

export default SingleDate;
