import { Link, LinkProps } from 'react-router-dom';
import { useLocation } from 'react-router';
import { twMerge } from 'tailwind-merge';

const ActiveLink: React.FC<LinkProps> = ({ children, ...rest }) => {
  const isActive = useLocation().pathname == rest.to;

  return (
    <div className={`w-full ${isActive ? 'bg-btn-light' : ''}`}>
      <Link
        {...rest}
        className={twMerge(
          `text-primary font-medium flex no-underline hover:text-primary-dark transition-colors duration-200 whitespace-nowrap w-full min-h-[40px] px-[0.7rem] py-2 items-center gap-2 border-none rounded`,
          rest.className ?? '',
        )}
      >
        {children}
      </Link>
    </div>
  );
};

export default ActiveLink;
