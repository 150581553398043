import { useFieldArray, useFormContext } from 'react-hook-form';

import { BoardDto } from '@/pages/Board';

import ManagementRegisterCard from './ManagementRegisterCard';

interface Props {
  data?: BoardDto;
}

const ManagementRegister = ({ data }: Props) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `managements`,
  });

  const addManagement = () => {
    append({ name: '' });
  };

  return (
    <div className="flex flex-col">
      {fields.map((el, managementIndex) => (
        <ManagementRegisterCard
          key={data?.managements[managementIndex]?.id ?? undefined}
          managementIndex={managementIndex}
          control={control}
          remove={remove}
          data={data}
        />
      ))}
      <button
        type="button"
        className={`mt-2 flex justify-start text-[12px] font-semibold ${data && !data.active ? 'text-gray-400' : 'text-[#193CB9]'}`}
        onClick={addManagement}
        disabled={data && !data.active}
      >
        + Adicionar Gerência
      </button>
    </div>
  );
};

export default ManagementRegister;
