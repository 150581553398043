import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { differenceInCalendarDays } from 'date-fns';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';

import { queryClient } from '@/App';
import {
  OnNoticeAvailableDates,
  OnNoticeAvailableDatesSchema,
  OnNoticeData,
  OnNoticeForm,
  OnNoticeFormSchema,
  OnNoticeRequest,
} from '@/constants/OnNoticeConstants';
import { removeTimeZone } from '@/utils/formatDate';
import { trackEvent } from '@/utils/trackEvent';

import api from '../../services/apiSgft';
import { OnNoticeTypeAtom } from '../../state/OnNoticeType.atom';
import { Button } from '../atoms/Button';
import ControlledCalendar from '../atoms/ControlledCalendar';
import Line from '../atoms/Line';
import SubtitleText from '../atoms/SubtitleText';

interface Props {
  employeeId: number;
  editData: OnNoticeData | null;
}

const OnNoticeCard = ({ employeeId, editData }: Props) => {
  const [type, setType] = useRecoilState(OnNoticeTypeAtom);

  const getAvailableDates = async () => {
    try {
      const response = await api.get('/on-notice/available-dates', {
        params: {
          employeeId,
        },
      });
      return OnNoticeAvailableDatesSchema.parse(response.data);
    } catch (e) {
      return [];
    }
  };

  const { data: availableDates } = useQuery<OnNoticeAvailableDates>(
    ['on-notice-available-dates', employeeId],
    getAvailableDates,
  );

  const methods = useForm<OnNoticeForm>({
    resolver: zodResolver(OnNoticeFormSchema),
    defaultValues: {
      startDate: editData?.startDate ? new Date(editData.startDate) : undefined,
      endDate: editData?.endDate ? new Date(editData.endDate) : undefined,
    },
  });

  useEffect(() => {
    if (editData && type === 'edit') {
      methods.reset({
        startDate: editData.startDate
          ? new Date(editData.startDate)
          : undefined,
        endDate: editData.endDate ? new Date(editData.endDate) : undefined,
      });
    }
  }, [editData, methods, type]);

  useEffect(() => {
    if (!type || type === 'create') {
      methods.reset();
    }
  }, [type, methods]);

  const onSubmit = async (data: OnNoticeForm) => {
    const { startDate, endDate } = data;
    const days = differenceInCalendarDays(
      new Date(endDate),
      new Date(startDate),
    );

    if (days < 0) {
      toast.error('Data final não pode ser menor que a data inicial', {
        theme: 'colored',
        toastId: 'error',
      });
      return;
    }

    const body: OnNoticeRequest = {
      startDate: startDate?.toISOString().split('T')[0],
      endDate: endDate?.toISOString().split('T')[0],
    };

    if (type === 'create') {
      body.employeeId = Number(employeeId);
    } else {
      body.onNoticeId = editData?.id;
    }

    const method: 'post' | 'put' = type === 'create' ? 'post' : 'put';
    const endpoint = 'on-notice';

    try {
      const response = await api[method](endpoint, body);
      toast.success(response.data.message, {
        theme: 'colored',
        toastId: 'success',
      });
      trackEvent(
        `${days} dias`,
        'generate_on_notice',
        `${startDate} - ${endDate}`,
      );
      methods.reset({
        endDate: undefined,
        startDate: undefined,
      });
      queryClient.invalidateQueries(['team-structure']);
      queryClient.invalidateQueries(['on-notice', employeeId]);
      setType(null);
    } catch (error) {
      if (isAxiosError(error)) {
        const statusCode = error?.response?.status;
        const errorMessage =
          statusCode && statusCode < 500
            ? error?.response?.data?.message
            : 'Sua solicitação não foi processada. Tente novamente mais tarde!';
        toast.error(errorMessage, {
          theme: 'colored',
          toastId: 'error',
        });
      }
    }
  };

  const onCancel = () => {
    setType(null);
    methods.reset({
      endDate: undefined,
      startDate: undefined,
    });
  };

  const shouldDisableDate = (date: Date): boolean => {
    if (!availableDates || availableDates.length === 0) {
      return false;
    }

    return (
      availableDates.find((availableDate) => {
        return (
          removeTimeZone(new Date(availableDate)).toDateString() ===
          removeTimeZone(date).toDateString()
        );
      }) === undefined
    );
  };

  return (
    <div className="mb-5">
      {type && (
        <div>
          <SubtitleText
            subtitle={
              type === 'create' ? 'Planejar sobreaviso' : 'Editar sobreaviso'
            }
          />
          <Line />
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="mb-3 flex w-full justify-between">
                <ControlledCalendar
                  title="Data inicial"
                  required
                  name={'startDate'}
                  width="14rem"
                  control={methods.control}
                  fieldError={methods.formState.errors.startDate}
                  errorMessage="A data da realocação é obrigatória*"
                  shouldDisableDate={shouldDisableDate}
                />
                <ControlledCalendar
                  title="Data final"
                  required
                  name={'endDate'}
                  width="14rem"
                  control={methods.control}
                  fieldError={methods.formState.errors.endDate}
                  errorMessage="A data da realocação é obrigatória*"
                  shouldDisableDate={shouldDisableDate}
                />
              </div>

              <div className="flex justify-between p-5">
                <Button
                  type="button"
                  title="Cancelar"
                  variant="link"
                  className="min-w-40 font-semibold ring-1"
                  onClick={onCancel}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  className="min-w-40"
                  isLoading={methods.formState.isSubmitting}
                  disabled={!methods.formState.isValid}
                >
                  {type === 'create' ? '✔ Salvar' : '✔ Salvar alterações'}
                </Button>
              </div>
            </form>
          </FormProvider>
        </div>
      )}
    </div>
  );
};

export default OnNoticeCard;
