import { addMonths, endOfYear, startOfYear, subMonths } from 'date-fns';
import { ShortCut } from '../components/atoms/Calendar';

export const defaultShortCuts: Array<ShortCut> = [
  {
    key: 'last60Days',
    text: 'Últimos 60 dias',
    onClick: (onSelect) => {
      onSelect({
        from: subMonths(new Date(), 2),
        to: new Date(),
      });
    },
  },
  {
    key: 'last30Days',
    text: 'Últimos 30 dias',
    onClick: (onSelect) => {
      onSelect({
        from: subMonths(new Date(), 1),
        to: new Date(),
      });
    },
  },
  {
    key: 'next30Days',
    text: 'Próximos 30 dias',
    onClick: (onSelect) => {
      onSelect({
        from: new Date(),
        to: addMonths(new Date(), 1),
      });
    },
  },
  {
    key: 'next60Days',
    text: 'Próximos 60 dias',
    onClick: (onSelect) => {
      onSelect({
        from: new Date(),
        to: addMonths(new Date(), 2),
      });
    },
  },
  {
    key: 'thisYear',
    text: 'Esse Ano',
    onClick: (onSelect) => {
      onSelect({
        from: startOfYear(new Date()),
        to: endOfYear(new Date()),
      });
    },
  },
];
