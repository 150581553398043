import { Button, styled } from '@mui/material';
import * as React from 'react';
import { BiSolidCloudUpload } from 'react-icons/bi';
import { CgSpinner } from 'react-icons/cg';
import { toast } from 'react-toastify';

import api from '@/services/apiSgft';
import { userCanSeeTrainingControl } from '@/utils/handleSavePermissions';
import { trackEvent } from '@/utils/trackEvent';

import LightTooltip from './LightTooltip';

interface UploadProcedureTrainingMatrixButtonProps {
  handleMatrixRefetch: () => Promise<void>;
  handleResetFilter: () => Promise<void>;
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const UploadProcedureTrainingMatrixButton = ({
  handleMatrixRefetch,
  handleResetFilter,
}: UploadProcedureTrainingMatrixButtonProps) => {
  const [loading, setLoading] = React.useState(false);
  const canUpload = userCanSeeTrainingControl();
  async function uploadMatrix(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      setLoading(true);
      try {
        await api.post('procedure/upload', formData);
        toast.success('Upload de treinamentos realizado com sucesso!', {
          theme: 'colored',
          toastId: 'success',
        });
        trackEvent('Upload de treinamentos', 'file_upload', file.name);
      } catch (error: any) {
        trackEvent(
          'Upload de treinamentos',
          'file_upload',
          'Erro ao realizar o Upload',
        );
        toast.error(
          error?.response?.data?.message ??
            'Erro ao fazer upload de treinamentos!',
          {
            theme: 'colored',
            toastId: 'error',
          },
        );
      } finally {
        handleMatrixRefetch();
        setLoading(false);
        handleResetFilter();
      }
    }
  }

  return (
    <div>
      <LightTooltip title="Upload de Procedimentos">
        <Button
          sx={{
            backgroundColor: 'inherit',
            height: '2.7rem',
            width: '2.7rem',
            padding: '8px',
            minWidth: 0,
            borderRadius: '50%',
            '&:hover': {
              backgroundColor: '#EAEDF4 !important',
            },
          }}
          component="label"
          disabled={loading || !canUpload}
        >
          {loading ? (
            <CgSpinner
              size={30}
              color="#c1c1c1"
              className="h-full animate-spin"
            />
          ) : (
            <>
              <BiSolidCloudUpload color="grey" fontSize={'1.7rem'} />
              <VisuallyHiddenInput
                type="file"
                onChange={(e) => uploadMatrix(e)}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
            </>
          )}
        </Button>
      </LightTooltip>
    </div>
  );
};
