import { atom } from 'recoil';

export interface TrainingFilesFiltersAtom {
  trainings: string[];
  suppliers: string[];
  status: string[];
  trainingFileNumber: string[];
  trainingFileName: string[];
  trainingType: string[];
  responsible: string[];
  timeRange: {
    startDate: string | undefined;
    endDate: string | undefined;
  };
}
export const trainingFilesFiltersAtom = atom<TrainingFilesFiltersAtom>({
  key: 'trainingfiles',
  default: {
    trainings: [],
    suppliers: [],
    status: [],
    timeRange: {
      startDate: undefined,
      endDate: undefined,
    },
    trainingFileNumber: [],
    trainingFileName: [],
    trainingType: [],
    responsible: [],
  },
});
