import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Navigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { loginRequest } from '../../auth/authConfig';
import { Button } from '../../components/atoms/Button';
import Spinner from '../../components/atoms/Spinner';

const Login = ({ redirectHome = true, isLoading = false }) => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleRedirect = () => {
    instance
      .loginRedirect({
        ...loginRequest,
        prompt: 'create',
        redirectStartPage: window.location.href,
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  if (isAuthenticated && redirectHome) {
    return <Navigate to="/home" />;
  }

  return (
    <div className="flex h-screen items-center justify-center bg-[url(/login_background_workflow.jpg)] bg-cover">
      <form
        className="
          flex w-[30%] 
          flex-col 
          items-center justify-center 
          gap-6 rounded-2xl bg-white
          bg-contain
          px-10
          py-10
        "
        onSubmit={(e) => e.preventDefault()}
      >
        <img src="/logo.png" alt="Logo" className="w-1/2" />
        <h1
          className={twMerge(
            'max-w-[200px] text-center text-xl font-bold text-[#3F3F3F]',
            isLoading && 'max-w-[250px]',
          )}
        >
          {isLoading
            ? 'Seu usuário está sendo autenticado via SSO'
            : 'Faça o seu login com Origem SSO'}
        </h1>

        {isLoading ? (
          <Spinner />
        ) : (
          <Button onClick={handleRedirect} className="mt-4 text-base">
            ACESSAR MINHA CONTA
          </Button>
        )}
      </form>
    </div>
  );
};

export default Login;
