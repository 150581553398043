import { z } from 'zod';

// ESQUEMA PARA VERIFICACAO DE ERROS
export const createFormSchema = z.object({
  name: z
    .string()
    .transform((t) => t?.trim())
    .pipe(z.string().min(1)),
  management: z.number().min(1),
  area: z.coerce.number().min(-1),
  otherArea: z.string().optional(),
  sector: z
    .string()
    .transform((t) => t?.trim())
    .pipe(z.string().min(1)),
  otherSector: z.string().optional(),
  workstation: z.number().array().nonempty(),
  structureType: z
    .string()
    .transform((t) => t?.trim())
    .pipe(z.string().min(1)),
  teamsLength: z.number().min(1),
  scale: z
    .string()
    .transform((t) => t?.trim())
    .pipe(z.string().min(1)),
  onNotice: z
    .string()
    .transform((t) => t?.trim())
    .pipe(z.string().min(1)),
  mandatoryOnNotice: z
    .string()
    .transform((t) => t?.trim())
    .pipe(z.string().min(1)),
  confinement: z
    .string()
    .transform((t) => t?.trim())
    .pipe(z.string().min(1)),
});

export const editFormSchema = z.object({
  name: z
    .string()
    .transform((t) => t?.trim())
    .pipe(z.string().min(1)),
  management: z.number().min(1),
  area: z.coerce.number().min(-1),
  otherArea: z.string().optional(),
  sector: z
    .string()
    .transform((t) => t?.trim())
    .pipe(z.string().min(1)),
  otherSector: z.string().optional(),
  workstation: z.number().array().nonempty(),
  structureType: z.string().optional(),
  teamsLength: z.number().optional(),
  scale: z.string().optional(),
  onNotice: z
    .string()
    .transform((t) => t?.trim())
    .pipe(z.string().min(1)),
  mandatoryOnNotice: z
    .string()
    .transform((t) => t?.trim())
    .pipe(z.string().min(1)),
  confinement: z
    .string()
    .transform((t) => t?.trim())
    .pipe(z.string().min(1)),
});

// TIPOS DE TURMA
export const STRUCTURE_TYPES = {
  MEDICO: 'Médico',
  TURNO: 'TURNO',
  MOTORISTA: 'Motorista',
  SERVICOS: 'Serviços Especiais',
  ADM: 'ADM',
};
export const VALUES_STRUCTURE_TYPES = [
  { value: 'ADM', label: 'ADM' },
  { value: 'TURNO', label: 'TURNO' },
  { value: 'Médico', label: 'MÉDICO' },
  { value: 'Motorista', label: 'MOTORISTA' },
  { value: 'Serviços Especiais', label: 'SERVIÇOS ESPECIAIS' },
];

// TAMANHOS DAS TURMAS
export const SIZE_ADM = [{ value: 1, label: '1' }];
export const SIZE_SERVICOS_ESPECIAIS = [{ value: 2, label: '2' }];
export const SIZE_OUTROS = [
  { value: 2, label: '2' },
  { value: 4, label: '4' },
];

// ESCALAS DE ACORDO COM OS REGIMES DE ADM E DE TURNO
export const SCALE_ADM = [{ value: 'N/A', label: 'N/A' }];
export const SCALE_TURNO = [
  { value: '7x7', label: '7x7' },
  { value: '14x14', label: '14x14' },
];
export const SCALE_MEDICO = [{ value: '3x4', label: 'ADM 20h' }];
export const SCALE_MOTORISTA = [{ value: '7x7', label: '7x7' }];
export const SCALE_SERVICOS_ESPECIAIS = [{ value: '14x14', label: '14x14' }];

// ROTACAO DE TURMA
export const ROTATION_PADRAO = [
  { value: 'Padrão', label: 'Padrão (Horário padrão administrativo)' },
];
export const ROTATION_MEDICO = [
  { value: 'SEG-TER-SEX', label: 'SEG-TER-SEX' },
  { value: 'SEG-TER-QUA', label: 'SEG-TER-QUA' },
  { value: 'TER-QUA-QUI', label: 'TER-QUA-QUI' },
  { value: 'QUA-QUI-SEX', label: 'QUA-QUI-SEX' },
];
export const ROTATION_MOTORISTA = [
  { value: 'D', label: 'D (Embarque sempre em período diurno)' },
  { value: 'N', label: 'N (Embarque sempre em período noturno)' },
];
export const ROTATION_TURNO_7X7 = [
  { value: 'Padrão', label: 'Padrão (Horário padrão administrativo)' },
  {
    value: 'D',
    label:
      'D (No próximo embarque, cumpre escala diurna. Alterna o turno a cada embarque)',
  },
  {
    value: 'N',
    label:
      'N (No próximo embarque, cumpre escala noturna. Alterna o turno a cada embarque)',
  },
  {
    value: '4D/3N',
    label:
      '4D/3N (Embarca em período diurno por 4 dias, depois 3 dias em período noturno)',
  },
  {
    value: '3D/4N',
    label:
      '3D/4N (Embarca em período diurno por 3 dias, depois 4 dias em período noturno)',
  },
];
export const ROTATION_TURNO_14X14 = [
  { value: 'Padrão', label: 'Padrão (Horário padrão administrativo)' },
  {
    value: 'D',
    label:
      'D (No próximo embarque, cumpre escala diurna. Alterna o turno a cada embarque)',
  },
  {
    value: 'N',
    label:
      'N (No próximo embarque, cumpre escala noturna. Alterna o turno a cada embarque)',
  },
  {
    value: '7D/7N',
    label:
      '7D/7N (Embarca por 7 dias em período diurno, depois cumpre 7 em período noturno)',
  },
];

// SOBREAVISO
export const YES_NO = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NÃO', label: 'NÃO' },
];
export const NO = [{ value: 'NÃO', label: 'NÃO' }];

// ROTAS PARA OS SELECTS DE GERENCIA, AREA, SETOR E CAMPO
export const ROUTES = [
  { route: 'management', others: false },
  { route: 'area', others: true },
  { route: 'team-structure/sector', others: true },
  { route: 'work-station', others: false },
];
