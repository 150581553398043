import api from '../../services/apiSgft';
import { TrainingControlAtom } from '../../state/TrainingControl.atom';
import { Filter } from '../../types/Filter';
import { trainingTypeOptions } from '../trainingTypeOptions';

const fetchFilter = async (
  field: string,
  search: string,
  atom?: TrainingControlAtom,
) => {
  if (
    atom?.trainingType &&
    atom.trainingType.length > 0 &&
    atom?.trainingType.every((type) => type === 'Desenvolvimento') &&
    field === 'trainings/training-control/pole/filter'
  ) {
    return [];
  }

  const response = await api.get(`${field}`, {
    params: {
      size: 10,
      page: 1,
      search,
      trainingType: atom?.trainingType,
      employee: atom?.employee,
      role: atom?.role,
      rmStatus: atom?.rmStatus,
      training: atom?.training,
      modality: atom?.modality,
      part: atom?.part,
      completionStatus: atom?.completionStatus,
      expirationStatus: atom?.expirationStatus,
      management: atom?.management,
      areaCoordinator: atom?.areaCoordinator,
      pole: atom?.pole,
      trainingPole: atom?.trainingPole,
      workstation: atom?.workstation,
    },
  });
  if (
    field === 'employees/training-control/pole/filter' ||
    field === 'trainings/training-control/pole/filter'
  ) {
    return response.data.map((it: { name: string }) => ({
      label:
        it.name === 'TAMAC' ? 'Terminal Aquaviáro de Maceió (TAMAC)' : it.name,
      value: it.name,
    }));
  }
  return response.data.map((it: { name: string; id: string }) => ({
    label: it.name,
    value: String(it.id),
  }));
};

export const filters: Filter[] = [
  {
    key: 'pole',
    name: 'Polo',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: TrainingControlAtom) =>
      fetchFilter('employees/training-control/pole/filter', search, atom),
    isDisabled: (_: TrainingControlAtom) => false,
  },
  {
    key: 'management',
    name: 'Gerência',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: TrainingControlAtom) =>
      fetchFilter('employees/training-control/management/filter', search, atom),
    isDisabled: (_: TrainingControlAtom) => false,
  },
  {
    key: 'areaCoordinator',
    name: 'Coordenação',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: TrainingControlAtom) =>
      fetchFilter(
        'employees/training-control/area-coordinator/filter',
        search,
        atom,
      ),
    isDisabled: (_: TrainingControlAtom) => false,
  },
  {
    key: 'workstation',
    name: 'Local de Trabalho',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: TrainingControlAtom) =>
      fetchFilter(
        'employees/training-control/work-station/filter',
        search,
        atom,
      ),
    isDisabled: (_: TrainingControlAtom) => false,
  },
  {
    key: 'employee',
    name: 'Colaborador',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: TrainingControlAtom) =>
      fetchFilter('employees/training-control/filter', search, atom),
    isDisabled: (_: TrainingControlAtom) => false,
  },
  {
    key: 'role',
    name: 'Função',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: TrainingControlAtom) =>
      fetchFilter('employees/training-control/roles/filter', search, atom),
    isDisabled: (_: TrainingControlAtom) => false,
  },
  {
    key: 'rmStatus',
    name: 'Situação RM',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: TrainingControlAtom) =>
      fetchFilter('employees/training-control/status/filter', search, atom),
    isDisabled: (_: TrainingControlAtom) => false,
  },
  {
    key: 'trainingType',
    name: 'Tipo de Treinamento',
    options: trainingTypeOptions,
    isMulti: true,
    isDisabled: () => false,
  },
  {
    key: 'trainingPole',
    name: 'Matriz do Treinamento',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: TrainingControlAtom) =>
      fetchFilter('trainings/training-control/pole/filter', search, atom),
    isDisabled: (_: TrainingControlAtom) => false,
  },
  {
    key: 'training',
    name: 'Treinamento',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: TrainingControlAtom) =>
      fetchFilter('trainings/training-control/filter', search, atom),
    isDisabled: (_: TrainingControlAtom) => false,
  },
  {
    key: 'modality',
    name: 'Modalidade',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: TrainingControlAtom) =>
      fetchFilter('trainings/training-control/modality/filter', search, atom),
    isDisabled: (_: TrainingControlAtom) => false,
  },
  {
    key: 'part',
    name: 'Parte',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: TrainingControlAtom) =>
      fetchFilter('training-control/part/filter', search, atom),
    isDisabled: (_: TrainingControlAtom) => false,
  },
  {
    key: 'completionStatus',
    name: 'Situação de Treinamento',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: TrainingControlAtom) =>
      fetchFilter('training-control/completion-status/filter', search, atom),
    isDisabled: (_: TrainingControlAtom) => false,
  },
  {
    key: 'expirationStatus',
    name: 'Situação de Vencimento',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: TrainingControlAtom) =>
      fetchFilter('training-control/expiration-status/filter', search, atom),
    isDisabled: (_: TrainingControlAtom) => false,
  },
];
