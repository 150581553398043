import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import Login from '../../pages/Login';
import { checkSSO } from '../../utils/msal';
import Header from '../molecules/Header';
import Sidebar from '../molecules/SideBar';
const msalScopes = import.meta.env.VITE_MSAL_SCOPES;

export const AuthTemplate = () => {
  const { instance, inProgress } = useMsal();
  const [isAuth, setIsAuth] = useState(false);

  const accessTokenRequest = {
    scopes: [msalScopes],
    account: instance.getAllAccounts()[0],
  };

  useEffect(() => {
    if (!isAuth && inProgress === InteractionStatus.None) {
      checkSSO()
        .then(() => setIsAuth(true))
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
        });
    }
  }, [inProgress, isAuth, instance]);

  return isAuth ? (
    <div className="flex h-screen w-screen bg-background">
      <Sidebar />
      <div className="flex h-full max-h-screen w-full flex-col overflow-auto bg-background px-4 py-3">
        <Header />
        <Outlet />
      </div>
    </div>
  ) : (
    <Login isLoading redirectHome={false} />
  );
};
