import { twMerge } from 'tailwind-merge';
import TableData from '../../components/atoms/TableData';
import { getColorByTeamName } from '../../utils/getColorByTeamName';
import { ColumnDef } from '@tanstack/react-table';
import { Team} from '../../types/ViewSchedule';

export const columns: Array<ColumnDef<any>> = [
  {
    accessorKey: 'team',
    header: 'Turma',
    enableSorting: false,
    cell: (props) => (
      <span className="pr-4 pl-2 flex items-center">
        <TableData
          tableValue={props.getValue()}
          className={twMerge(
            'text-white text-center h-6 px-3 rounded-sm flex items-center',
            getColorByTeamName(props.getValue() as Team),
          )}
        />
      </span>
    ),
  },
];
