import { Tooltip } from '@mui/material';
import { HiPencil } from 'react-icons/hi';
import { PermissionMessages } from '../../constants/SGFTUserTypes';

interface MenuProps {
  canSave?: boolean;
  disabled?: boolean;
}
const HiPencilTooltip = ({ canSave, disabled }: MenuProps) => {
  return (
    <Tooltip
      title={PermissionMessages.NoPermission}
      disableHoverListener={canSave}
    >
      <span>
        <HiPencil
          fontSize="18px"
          color={disabled ? '#D6D4D4' : ''}
          className="mb-1"
        />
      </span>
    </Tooltip>
  );
};
export default HiPencilTooltip;
