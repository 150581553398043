import { z } from 'zod';

export const OnNoticeDataSchema = z.object({
  id: z.number(),
  startDate: z.string().nullable().optional().default(null),
  endDate: z.string().nullable().optional().default(null),
});

export const OnNoticeResponseSchema = z.object({
  name: z.string(),
  employee_number: z.string(),
  onNotice: z.array(OnNoticeDataSchema),
});

export const OnNoticeFormSchema = z.object({
  startDate: z.coerce.date(),
  endDate: z.coerce.date(),
});

export const OnNoticeAvailableDatesSchema = z.array(z.string());

export type OnNoticeForm = z.infer<typeof OnNoticeFormSchema>;

export type OnNoticeData = z.infer<typeof OnNoticeDataSchema>;

export type OnNoticeResponse = z.infer<typeof OnNoticeResponseSchema>;

export type OnNoticeAvailableDates = z.infer<
  typeof OnNoticeAvailableDatesSchema
>;

export type OnNoticeRequest = {
  employeeId?: number;
  onNoticeId?: number;
  startDate?: string | null;
  endDate?: string | null;
};
