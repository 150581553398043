import { atom } from 'recoil';

export interface TeamRegister extends Record<string, string | string[] | undefined | number | number[]> {
  id?: number;
  name: string;
  management: number;
  area: number;
  otherArea: string;
  sector: string;
  otherSector: string;
  workstation: number[];
  structureType: string;
  scale: string;
  teamsLength: number;
  onNotice: string;
  mandatoryOnNotice: string;
  confinement: string;
}

const teamRegisterAtom = atom<TeamRegister>({
  key: 'teamRegister',
  default: {
    name: "",
    management: 0,
    area: -2,
    otherArea: "",
    sector: "",
    otherSector: "",
    workstation: [],
    structureType: "",
    scale: "",
    teamsLength: 0,
    onNotice: "",
    mandatoryOnNotice: "",
    confinement: ""
  },
});

export { teamRegisterAtom };
