import IconButton from '@mui/material/IconButton';
import * as React from 'react';
import { CgSpinner } from 'react-icons/cg';
import { RiFileList2Line } from 'react-icons/ri';
import { toast } from 'react-toastify';

import { trackEvent } from '@/utils/trackEvent';

import { PermissionMessages, UserTypes } from '../../constants/SGFTUserTypes';
import api from '../../services/apiSgft';
import { isInRole } from '../../utils/handleSavePermissions';
import LightTooltip from './LightTooltip';

export const InconsistencyExtractionButton: React.FC = () => {
  const [loading, setLoading] = React.useState(false);

  async function downloadSchedule() {
    setLoading(true);
    try {
      const response = await api.get(`/extraction/inconsistencies`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      );
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', 'Relatório de Divergências RM x SGFT.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      trackEvent('Download do Relatorio de Divergencias', 'file_download', url);
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      trackEvent(
        'Download do Relatorio de Divergencias',
        'file_download',
        'Erro ao realizar o Download',
      );
      throw e;
    } finally {
      setLoading(false);
    }
  }
  const title =
    isInRole(UserTypes.Rh) || isInRole(UserTypes.Admin)
      ? 'Extração de Relatório de Divergências'
      : PermissionMessages.NoPermission;
  return (
    <div className="cursor-pointer ">
      <LightTooltip title={title}>
        <span>
          <IconButton
            onClick={downloadSchedule}
            disabled={
              loading || (!isInRole(UserTypes.Rh) && !isInRole(UserTypes.Admin))
            }
          >
            {loading ? (
              <CgSpinner
                size={30}
                color="#c1c1c1"
                className="h-full animate-spin"
              />
            ) : (
              <RiFileList2Line />
            )}
          </IconButton>
        </span>
      </LightTooltip>
    </div>
  );
};
