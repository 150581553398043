import { addDays, format, parseISO, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { DateRange, Matcher } from 'react-day-picker';

import { defaultShortCuts } from '../../constants/datepickerDefaultShortCuts';
import { cn } from '../../utils/cn';
import { getDateRange } from '../../utils/getDateRange';
import { Button } from './Button';
import { Calendar, ShortCut } from './Calendar';
import { Popover, PopoverContent, PopoverTrigger } from './Popover';

export interface DatePickerRange {
  startDate: string | undefined;
  endDate: string | undefined;
}
interface DatePickerProps {
  className?: {
    button?: string;
    p?: string;
  };
  value: DatePickerRange | undefined;
  onSelect: (dateRange: DatePickerRange | undefined) => void;
  showShortCuts?: boolean;
  showClearButton?: boolean;
  shortCuts?: Array<ShortCut>;
  startFrom?: Date;
  minDate?: Date;
  maxDate?: Date;
  disabledDates?: {
    startDate: Date;
    endDate: Date;
  }[];
}

export function DatePicker({
  value,
  onSelect,
  className,
  startFrom,
  shortCuts,
  minDate,
  maxDate,
  disabledDates = [],
  showShortCuts = false,
  showClearButton = true,
}: DatePickerProps) {
  const datePickerValue: DateRange | undefined = {
    from: value?.startDate ? parseISO(value?.startDate) : undefined,
    to: value?.endDate ? parseISO(value?.endDate) : undefined,
  };
  const handleSelect = (dateRange: DateRange | undefined) => {
    if (dateRange?.from && dateRange?.to) {
      const range = getDateRange(dateRange.from, dateRange.to);
      const hasDisabledDates = range.some((date) => {
        return disabledDates.find(
          (disabled) =>
            format(disabled.startDate, 'yyyy-MM-dd') ===
            format(date, 'yyyy-MM-dd'),
        );
      });
      if (hasDisabledDates) {
        return;
      }
    }
    onSelect({
      startDate: dateRange?.from
        ? format(dateRange?.from, 'yyyy-MM-dd')
        : undefined,
      endDate: dateRange?.to ? format(dateRange?.to, 'yyyy-MM-dd') : undefined,
    });
  };
  const getDisabledDates = (): Matcher[] => {
    const datesToBeDisabled = [] as Matcher[];
    if (minDate) {
      datesToBeDisabled.push({
        before: minDate,
      });
    }
    if (maxDate) {
      datesToBeDisabled.push({
        after: maxDate,
      });
    }
    disabledDates.forEach((disabledDate) => {
      datesToBeDisabled.push({
        after: subDays(disabledDate.startDate, 1),
        before: addDays(disabledDate.endDate, 1),
      });
    });
    return datesToBeDisabled;
  };
  const datesToBeDisabled = getDisabledDates();
  return (
    <div className="grid gap-2">
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            className={cn(
              ' justify-start bg-transparent text-left text-xs font-normal ring-1 ring-gray-300 hover:bg-transparent hover:ring-gray-600',
              !value && 'text-muted-foreground ',
              className?.button,
            )}
          >
            {datePickerValue?.from ? (
              datePickerValue.to ? (
                <p className={cn('text-gray-600', className?.p)}>
                  {format(datePickerValue.from, 'dd/MM/yyyy', { locale: ptBR })}{' '}
                  - {format(datePickerValue.to, 'dd/MM/yyyy', { locale: ptBR })}
                </p>
              ) : (
                <p className={cn('text-gray-600', className?.p)}>
                  {format(datePickerValue.from, 'dd/MM/yyyy', { locale: ptBR })}
                </p>
              )
            ) : (
              <span className={cn('text-xs text-gray-500', className?.p)}>
                dd/mm/aaaa - dd/mm/aaaa
              </span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className={cn(
            'z-[10000] w-auto p-0',
            (showShortCuts || showClearButton) && '-translate-x-20',
          )}
          align="start"
        >
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={startFrom ?? datePickerValue?.from}
            selected={datePickerValue}
            onSelect={handleSelect}
            numberOfMonths={2}
            className="bg-white"
            shortCuts={shortCuts || defaultShortCuts}
            showShortCuts={showShortCuts}
            showClearButton={showClearButton}
            disabled={datesToBeDisabled}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
