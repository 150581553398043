import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';

import { constantMotives } from '@/constants/ModalExtensionConstants';
import { trackEvent } from '@/utils/trackEvent';

import { queryClient } from '../../App';
import api from '../../services/apiSgft';
import { OnNoticeActivationTypeAtom } from '../../state/OnNoticeActivationType.atom';
import { Button } from '../atoms/Button';
import Line from '../atoms/Line';
import SimpleSelect from '../atoms/SimpleSelect';
import SimpleTextField from '../atoms/SimpleTextField';
import SingleDate from '../atoms/SingleDate';
import SubtitleText from '../atoms/SubtitleText';

type ActivationOnNoticeData = {
  employeeId?: number;
  onNoticeId?: number;
  startDate: string | undefined;
  motive: number | string;
  justification: string;
};

interface Props {
  employeeId: number;
  setUpdateData: Dispatch<SetStateAction<boolean>>;
  editData: any;
}

const OnNoticeActivationCard = ({
  employeeId,
  setUpdateData,
  editData,
}: Props) => {
  const [motive, setMotive] = useState<number | string>('');
  const [justification, setJustification] = useState('');
  const [date, setDate] = useState('');
  const [type, setType] = useRecoilState(OnNoticeActivationTypeAtom);
  const [isSavingData, setIsSavingData] = useState(false);

  useEffect(() => {
    if (editData) {
      setMotive(
        constantMotives.find((item) => item.label === editData.motive)?.value ??
          -1,
      );
      setJustification(editData.justification);
      setDate(editData.startDate.toString());
    }
  }, [editData]);

  const typeCreateOrEdit = type === 'edit' ? 'Editar' : 'Adicionar';

  const cleanInputs = () => {
    setUpdateData(true);
    setType('');
    setIsSavingData(false);
    setMotive(-1);
    setJustification('');
    setDate('');
  };

  const isAbleToSave = justification !== '' && date !== '' && motive !== '';

  const handleCreate = async () => {
    if (type !== '' && isAbleToSave) {
      setIsSavingData(true);
      const body: ActivationOnNoticeData = {
        startDate: date,
        motive: constantMotives[Number(motive)].label,
        justification,
      };

      if (type === 'create') body.employeeId = Number(employeeId);
      else body.onNoticeId = Number(editData.id);

      const method: 'post' | 'put' = type === 'create' ? 'post' : 'put';
      const endpoint = 'on-notice-activation';

      api[method](endpoint, body)
        .then((response: any) => {
          toast.success(response.data.message, {
            theme: 'colored',
            toastId: 'success',
          });
          trackEvent(date, 'generate_on_notice_activation', `${date}`);
          setUpdateData(true);
          cleanInputs();
          queryClient.invalidateQueries(['team-structure']);
        })
        .catch((error) => {
          const errorMessage =
            error?.response?.status < 500
              ? error?.response?.data?.message
              : 'Sua solicitação não foi processada. Tente novamente mais tarde!';
          toast.error(errorMessage, {
            theme: 'colored',
            toastId: 'error',
          });
        })
        .finally(() => {
          setIsSavingData(false);
        });
    }
  };

  return (
    <div className="mb-5">
      {type !== '' ? (
        <div>
          <SubtitleText
            subtitle={`${typeCreateOrEdit} novo acionamento de sobreaviso`}
          />
          <Line />
          <div className="mb-3 flex w-full justify-between">
            <SingleDate
              dateValue={date}
              setDate={setDate}
              value="Data de início"
              id={0}
              width="9rem"
            />
            <SimpleSelect
              width="15rem"
              title={'Motivo'}
              values={constantMotives}
              value={motive}
              setState={(id) => setMotive(Number(id))}
            />
            <SimpleTextField
              fontColor="text-[#4a4a4a]"
              fontSize="text-[12px]"
              title={'Justificativa'}
              value={justification}
              setState={setJustification}
              width="w-[22rem]"
            />
          </div>
          <div className="flex justify-between p-5">
            <Button
              type="button"
              title="Cancelar"
              variant="link"
              className="min-w-40  font-semibold ring-1"
              onClick={() => cleanInputs()}
            >
              Cancelar
            </Button>
            <Button
              type="button"
              className="min-w-40  underline"
              onClick={handleCreate}
              isLoading={isSavingData}
              disabled={!isAbleToSave}
            >
              {type === 'create' ? '✔ Salvar' : '✔ Salvar alterações'}
            </Button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default OnNoticeActivationCard;
