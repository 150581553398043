import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { twMerge } from 'tailwind-merge';

import NotAllowedTooltip from '@/components/atoms/NotAllowedTooltip';
import EmployeeTypeInfo from '@/components/organisms/EmployeeTypeInfo';
import { removeTimeZone } from '@/utils/formatDate';
import { shouldDisableScheduleMenu } from '@/utils/handleSavePermissions';

import TableData from '../../components/atoms/TableData';
import ViewScheduleMenu from '../../components/molecules/ViewScheduleMenu';
import { Team, TeamCollaborator } from '../../types/ViewSchedule';
import { getColorByTeamName } from '../../utils/getColorByTeamName';
import { getFirstAndLastName } from '../../utils/getFirstAndLastName';

export const columns: Array<ColumnDef<TeamCollaborator>> = [
  {
    accessorKey: 'name',
    header: 'Nome',
    meta: {
      headerClassName: 'px-7',
    },
    enableSorting: false,
    cell: (props) => {
      // const navigate = useNavigate();
      const onNotice =
        props.row.original.on_notice || props.row.original.covering_on_notice;
      const absenceEndDate = props.row.original.absenceEndDate;
      const absentToday = props.row.original.absentToday;
      const absentInPeriod = props.row.original.absentInPeriod;
      const absenceMessage = absenceEndDate
        ? `O colaborador está de licença até o dia ${format(removeTimeZone(new Date(absenceEndDate)), 'dd/MM/yyyy', { locale: ptBR })}`
        : 'O colaborador está de licença sem data de retorno prevista';
      return (
        <div className="flex items-center">
          <EmployeeTypeInfo
            isVacationReliefWorker={!!props.row.original.vacation_relief_worker}
            isFireFighter={!!props.row.original.fire_warden}
          />
          <TableData
            tableValue={
              props.row.original.alias ??
              getFirstAndLastName(props.getValue() as string)
            }
            className={`w-44 whitespace-nowrap px-3 font-semibold ${absentToday || absentInPeriod ? 'text-gray-400' : ''}`}
            title={`${props.getValue() as string} - ${props.row.getValue('employee_number')}`}
            // onClick={() =>
            //   navigate(`/home/colaborador/${props.row.original.id.toString()}`)
            // }
          />
          {absentInPeriod ? (
            <NotAllowedTooltip
              message={absenceMessage}
              className="mr-3 flex w-4 items-center justify-center"
            />
          ) : (
            <ViewScheduleMenu
              employeeId={props.row.original.id}
              onNotice={onNotice}
              disabled={shouldDisableScheduleMenu()}
              admissionDate={props.row.original.admissionDate}
            />
          )}
        </div>
      );
    },
  },
  {
    accessorKey: 'employee_number',
    header: 'employee_number',
    cell: (value) => <div> {value.getValue() as string} </div>,
  },
  {
    accessorKey: 'id',
    header: 'id',
    cell: (value) => <div> {value.getValue() as string} </div>,
  },
  {
    accessorKey: 'position',
    header: 'Cargo',
    meta: {
      headerClassName: 'px-3',
    },
    enableSorting: false,
    cell: (props) => {
      const absentToday = props.row.original.absentToday;
      const absentInPeriod = props.row.original.absentInPeriod;
      return (
        <TableData
          tableValue={props.getValue()}
          className={`w-60 overflow-hidden text-ellipsis whitespace-nowrap px-3 ${absentInPeriod || absentToday ? 'text-gray-400' : ''}`}
          title={props.getValue() as string}
        />
      );
    },
  },
  {
    accessorKey: 'team',
    header: 'Turma',
    meta: {
      headerClassName: 'px-2',
    },
    enableSorting: false,
    cell: (props) => (
      <span className="flex items-center pl-2 pr-4">
        <TableData
          tableValue={props.getValue()}
          className={twMerge(
            'flex h-6 w-14 items-center justify-center rounded-sm px-3 text-center text-white',
            getColorByTeamName(props.getValue() as Team),
          )}
        />
      </span>
    ),
  },
];
