import { atom } from 'recoil';

import { Training } from '@/types/Supplier';

export interface SupplierRegister
  extends Record<
    string,
    string | string[] | undefined | number | number[] | boolean | object
  > {
  id: number | undefined;
  name: string;
  email: string | undefined;
  onlineMaxDays?: number;
  inPersonMaxDays?: number;
  active: boolean | undefined;
  trainings: Training[];
  trainingTypes: string[];
}

const supplierRegisterAtom = atom<SupplierRegister>({
  key: 'supplierRegister',
  default: {
    id: undefined,
    name: '',
    email: '',
    onlineMaxDays: undefined,
    inPersonMaxDays: undefined,
    active: undefined,
    trainings: [],
    trainingTypes: [],
  },
});

export { supplierRegisterAtom };
