export const getDateRange = (startDate: Date, endDate: Date) => {
  const dateRange = [];
  const actualDate = new Date(startDate);

  while (actualDate <= endDate) {
    dateRange.push(new Date(actualDate));
    actualDate.setDate(actualDate.getDate() + 1);
  }
  return dateRange;
};
