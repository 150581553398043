import { Dialog, Transition } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';

import { queryClient } from '../../App';
import api from '../../services/apiSgft';
import { OnNoticeActivationTypeAtom } from '../../state/OnNoticeActivationType.atom';
import { Button } from '../atoms/Button';
import CloseButton from '../atoms/CloseButton';
import { DatePicker, DatePickerRange } from '../atoms/DatePicker';
import { OnNoticeActivationColumns } from '../atoms/OnNoticeActivationColumns';
import Spinner from '../atoms/Spinner';
import SubtitleText from '../atoms/SubtitleText';
import TextComponent from '../atoms/TextComponent';
import ExpandableSubtitle from '../molecules/ExpandableSubtitle';
import OnNoticeActivationCard from '../molecules/OnNoticeActivationCard';
import Table from './Table';

interface Props {
  employeeId: number;
  modalIsOpen: boolean;
  handleClose: () => void;
}

export default function OnNoticeActivationModal({
  employeeId,
  modalIsOpen,
  handleClose,
}: Props) {
  const [name, setName] = useState('');
  const [employeeNumber, setEmployeeNumber] = useState('');
  const [dates, setDates] = useState<DatePickerRange | undefined>({
    startDate: undefined,
    endDate: undefined,
  });
  const [updateData, setUpdateData] = useState(false);
  const [, setType] = useRecoilState(OnNoticeActivationTypeAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [editData, setEditData] = useState();
  const [data, setData] = useState([]);

  const fetchOnNoticeData = async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/on-notice-activation/data', {
        params: {
          employeeId,
          startDate: dates?.startDate ? dates?.startDate : null,
          endDate: dates?.endDate ? dates?.endDate : null,
        },
      });

      const newData = response.data.onNotice.map((data: any) => ({
        id: data.id,
        startDate: new Date(data.startDate).toISOString().slice(0, 10),
        motive: data.motive,
        justification: data.justification,
      }));

      setData(newData);
      setName(response.data.name);
      setEmployeeNumber(response.data.employee_number);
      setIsLoading(false);
      setUpdateData(false);
    } catch (e) {
      setIsLoading(false);
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const handleDelete = async (data: any) => {
    try {
      await api.delete(
        `/on-notice-activation?onNoticeActivationId=${Number(data.id)}`,
      );
      setUpdateData(true);
      setType('');
      queryClient.invalidateQueries(['team-structure']);
    } catch (e) {
      toast.error('Não foi possível deletar o sobreaviso', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  useEffect(() => {
    fetchOnNoticeData();
  }, [updateData, dates]);

  useEffect(() => {
    setType('');
  }, [modalIsOpen]);

  const handleEdit = async (data: any) => {
    setType('edit');
    setEditData(data);
  };

  return (
    <Transition appear show={true}>
      <Dialog className="relative z-50" onClose={handleClose}>
        <div className="fixed inset-0 flex w-screen items-center justify-center bg-black/30 p-4">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="max-h-[100vh] w-[50rem] flex-col justify-between overflow-auto overflow-y-scroll rounded-lg bg-white scrollbar-thin scrollbar-thumb-[#D9D9D9] scrollbar-thumb-rounded-full">
              <div className="w-full flex-col justify-between p-5">
                <div className="flex h-auto items-center justify-between">
                  <div className="flex justify-between">
                    <SubtitleText
                      className="text-[20px]"
                      subtitle={'Acionamento de Sobreaviso'}
                    />
                  </div>
                  <CloseButton onClick={handleClose} className="mt-[-1rem]" />
                </div>
                <div className="mt-5">
                  <div className="mb-5 flex w-full justify-between">
                    <TextComponent title="Nome" value={name} width="22rem" />
                    <TextComponent
                      title="Chapa"
                      value={employeeNumber}
                      width="12rem"
                    />
                    <div className="w-[12rem]">
                      <div className="font-graphie text-[12px] text-[#4A4A4A]">
                        Datas
                      </div>
                      <DatePicker
                        value={dates}
                        onSelect={(dateRange) => setDates(dateRange)}
                        className={{ button: 'h-8 w-[16em]' }}
                        showShortCuts
                      />
                    </div>
                  </div>
                  <>
                    <ExpandableSubtitle
                      subtitle={'Acionamentos de sobreavisos já sinalizados'}
                    >
                      {isLoading && (
                        <div className="flex h-40 w-full items-center">
                          <Spinner size={50} />
                        </div>
                      )}
                      {data.length > 0 ? (
                        <div className="mb-5">
                          <Table
                            columns={OnNoticeActivationColumns}
                            data={data}
                            isWhiteTable
                            onEditTableRow={handleEdit}
                            onDeleteTableRow={handleDelete}
                          />
                        </div>
                      ) : (
                        <div className="flex justify-center p-10 font-graphie text-[13px] font-bold text-[#747474]">
                          Ainda não existem acionamentos de sobreaviso
                          planejados
                        </div>
                      )}
                      <div className="flex justify-end">
                        <Button
                          type="button"
                          variant="link"
                          className="w-[200px] bg-white font-semibold text-[#193CB9] underline hover:bg-[#e9eaf1]"
                          onClick={() => {
                            setType('create');
                          }}
                        >
                          + Adicionar novo acionamento
                        </Button>
                      </div>
                    </ExpandableSubtitle>
                    <OnNoticeActivationCard
                      employeeId={employeeId}
                      setUpdateData={setUpdateData}
                      editData={editData}
                    />
                  </>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
