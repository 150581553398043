import { atom } from 'recoil';

export interface AllocationEditAtom extends Record<number, string> {
  id: number;
  isCurrent: boolean;
  employee_id: number;
  end_date: string;
  justification: string;
  management: string;
  start_date: string;
  team: string;
  teamStructure: string;
  team_id: number;
  isUpdated?: boolean;
  isPast?: boolean;
}

export const AllocationEditTypeAtom = atom<AllocationEditAtom>({
  key: 'allocationEdit',
  default: {
    id: 0,
    isCurrent: false,
    employee_id: 0,
    end_date: '',
    justification: '',
    management: '',
    start_date: '',
    team: '',
    teamStructure: '',
    team_id: 0,
    isUpdated: false,
    isPast: false,
  },
});
