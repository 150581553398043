import { Checkbox } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';

import TableCellDate from '@/components/atoms/TableCellDate';
import TableCellText from '@/components/atoms/TableCellText';
import { TrainingFileInstructor } from '@/types/TrainingFile';
import { dateInUTCFormat } from '@/utils/formatDate';

export const columns: (
  onSelect: (id: number) => void,
  planningInstructors: Array<number>,
) => Array<ColumnDef<TrainingFileInstructor>> = (
  onSelect,
  planningInstructors,
) => [
  {
    id: 'select',
    header: 'Selecionar Instrutor',
    meta: {
      headerClassName: 'pl-4',
    },

    cell: (props) => {
      return (
        <div className={`flex w-32  items-center  justify-center`}>
          <Checkbox
            onChange={() => onSelect(props.row.original.id)}
            disabled={false}
            defaultChecked={planningInstructors.includes(props.row.original.id)}
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'name',
    header: 'Nome',
    cell: (props) => {
      return (
        <div className="w-[14rem] overflow-hidden">
          <TableCellText text={props.getValue() as string} width="14rem" />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'entryDate',
    header: 'Embarque',
    cell: (props) => {
      return (
        <div className="w-24">
          <TableCellDate
            date={new Date(dateInUTCFormat(props.getValue() as string))}
          />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'exitDate',
    header: 'Desembarque',
    cell: (props) => {
      return (
        <div className="w-24">
          <TableCellDate
            date={new Date(dateInUTCFormat(props.getValue() as string))}
          />
        </div>
      );
    },
    enableSorting: false,
  },
];
