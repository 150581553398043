import { Handle, Position as Direction } from '@xyflow/react';
import React from 'react';
import { useRecoilValue } from 'recoil';

import { hideEmployeesAtom } from '@/state/HideEmployeesChart.atom';
import { PositionChartDoubleClickArgs } from '@/types/PositionChartDoubleClickArgs';

import { PositionWithAllocation } from '../organisms/ChartGraph';
import EmployeeCard from './EmployeeCard';
import PositionCard from './PositionCard';

interface CommonDataProps {
  active?: boolean;
  handleChange: (data: PositionChartDoubleClickArgs) => void;
  hideEmployees?: boolean;
  isOdd?: boolean;
  hasChildren?: boolean;
}

interface CustomNodeProps {
  id: string;
  data: PositionWithAllocation & CommonDataProps;
}

const CustomNode: React.FC<CustomNodeProps> = ({ data }) => {
  const hideEmployees = useRecoilValue(hideEmployeesAtom);

  return (
    <div className="custom-node">
      <div className="node-content">
        {hideEmployees ? (
          <PositionCard position={data} handleChange={data.handleChange} />
        ) : (
          <EmployeeCard position={data} handleChange={data.handleChange} />
        )}
      </div>
      <Handle
        type="source"
        position={Direction.Bottom}
        style={{ opacity: 0 }}
      />
      <Handle
        type="target"
        position={
          data.isIntermediary && !data.hasChildren
            ? data.isOdd
              ? Direction.Left
              : Direction.Right
            : Direction.Top
        }
        style={{ opacity: 0 }}
      />
    </div>
  );
};

export default CustomNode;
