import { useRecoilState } from 'recoil';

import { openJobtitleCreateModalAtom } from '@/state/CreatePositionModal.atom';
import { multipleReportsAtom } from '@/state/MultipleReports.atom';
import { multipleReportsPopoverAtom } from '@/state/MultipleReportsPopover.atom';

import { Button } from '../atoms/Button';

const MultipleReportsPopover = () => {
  const [multipleReportsPopover, setMultipleReportsPopover] = useRecoilState(
    multipleReportsPopoverAtom,
  );
  const [multipleReports, setMultipleReports] =
    useRecoilState(multipleReportsAtom);
  const [, setOpenJobtitleCreateModal] = useRecoilState(
    openJobtitleCreateModalAtom,
  );

  return (
    <div className="relative z-10 flex h-full">
      {multipleReportsPopover && (
        <div className="flex h-[12rem] w-[26rem] flex-col items-center justify-around rounded bg-white text-center font-graphie shadow-lg">
          <div className="w-full border-b-[1px] border-b-[#193CB9] p-1 text-[19px] font-semibold text-[#193CB9]">
            Criação de posição com reportes múltiplos
          </div>
          <div className="w-[70%] p-2 text-[14px]">
            Selecione as opções de reporte múltiplo para a nova posição!
          </div>
          <div className="flex w-full justify-between px-4">
            <Button
              type="button"
              className="h-8 min-w-36 border-2 border-[#193CB9] bg-white text-[#193CB9] hover:bg-[#e9eaf1]"
              onClick={() => {
                setMultipleReports(() => ({
                  parentsId: [],
                  positionId: null,
                }));
                setMultipleReportsPopover(null);
              }}
            >
              Cancelar
            </Button>
            <Button
              type="button"
              className="h-8 min-w-36"
              disabled={multipleReports.parentsId.length === 0}
              onClick={() => {
                setOpenJobtitleCreateModal(multipleReportsPopover);
                setMultipleReportsPopover(null);
              }}
            >
              Continuar
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MultipleReportsPopover;
