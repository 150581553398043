import { ColumnDef } from '@tanstack/react-table';

import { OnNoticeActivation } from '@/types/OnNoticeActivation';

import { removeTimeZone } from '../../utils/formatDate';
import TableCellDate from './TableCellDate';
import TableCellText from './TableCellText';

export const OnNoticeActivationColumns: Array<ColumnDef<OnNoticeActivation>> = [
  {
    accessorKey: 'startDate',
    header: 'Data de início',
    cell: (props) => {
      return (
        <div className="w-20">
          <TableCellDate
            date={
              (props.getValue() as string)
                ? removeTimeZone(new Date(props.getValue() as string))
                : undefined
            }
          />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'motive',
    header: 'Motivo',
    cell: (props) => {
      return (
        <div className="w-[15rem]">
          <TableCellText
            width="10rem"
            paddingX="0"
            text={props.getValue() as string}
          />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'justification',
    header: 'Justificativa',
    cell: (props) => {
      return (
        <div className="w-[15rem]">
          <TableCellText
            width="15rem"
            paddingX="0"
            text={props.getValue() as string}
          />
        </div>
      );
    },
    enableSorting: false,
  },
];
