/* eslint-disable no-unused-vars */
export enum TrainingTypeEnum {
  REGULATORY = 'Regulatório',
  PROCEDURE = 'Procedimento',
  DEVELOPMENT = 'Desenvolvimento',
}

export const trainingTypeOptions = [
  {
    value: TrainingTypeEnum.REGULATORY,
    label: TrainingTypeEnum.REGULATORY,
    disabled: false,
  },
  {
    value: TrainingTypeEnum.PROCEDURE,
    label: TrainingTypeEnum.PROCEDURE,
    disabled: false,
  },
  {
    value: TrainingTypeEnum.DEVELOPMENT,
    label: TrainingTypeEnum.DEVELOPMENT,
    disabled: false,
  },
];
