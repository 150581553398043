import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { Trash } from 'lucide-react';
import { useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { z } from 'zod';

import { queryClient } from '@/App';
import { FormSchema } from '@/constants/formSchema/TrainingFileTeamEmployeeFormSchema';
import api from '@/services/apiSgft';
import { TrainingFileTeamEmployee } from '@/types/TrainingFile';
import { removeTimeZone } from '@/utils/formatDate';

import { Button } from '../atoms/Button';
import Line from '../atoms/Line';
import Modal from '../atoms/Modal';
import { SingleDatePicker } from '../atoms/SingleDatePicker';
import { Checkbox } from '../ui/checkbox';
import { FormControl, FormField, FormItem, FormLabel } from '../ui/form';

interface EditEmployeeOnTrainingFileTeamModalProps {
  isOpen: boolean;
  onClose: () => void;
  employee: TrainingFileTeamEmployee;
  onlineTraining: boolean;
  asyncTraining: boolean;
}

const EditEmployeeOnTrainingFileTeamModal = ({
  isOpen,
  onClose,
  employee,
  asyncTraining,
  onlineTraining,
}: EditEmployeeOnTrainingFileTeamModalProps) => {
  const [isSubmiting, setIsSubmiting] = useState(false);

  const methods = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: employee.name,
      employeeNumber: employee.employeeNumber,
      phoneNumber: employee.phoneNumber,
      planningDays: employee.trainingPlanningEmployeeLinkDays.map(
        (employeeLink) => ({
          date: new Date(employeeLink.date),
          extraMeal: employeeLink.extraMeal,
          id: employeeLink.id,
          needTransport: employeeLink.needTransport,
          requestiaId: employeeLink.requestiaId,
          route: employeeLink.route,
          address: employeeLink.address ?? employee.address,
        }),
      ),
      extraAccommodations: employee.extraAccommodations.map(
        (accommodation) => ({
          entryDate: removeTimeZone(new Date(accommodation.entryDate)),
          exitDate: removeTimeZone(new Date(accommodation.exitDate)),
        }),
      ),
      confirmedPresence: Boolean(employee.confirmedPresence),
      absenceJustification: employee.absenceJustification,
    },
  });

  const phoneNumber = methods.watch('phoneNumber');
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'extraAccommodations',
  });
  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    setIsSubmiting(true);
    try {
      await api.put(`training-plannings/employee-link/${employee.id}`, data);
      toast.success('Planejamento alterado com sucesso', {
        theme: 'colored',
        toastId: 'success',
      });
      queryClient.invalidateQueries(['training-file-details']);
      onClose();
    } catch {
      toast.error('Não foi possível alterar o planejamento do colaborador', {
        theme: 'colored',
        toastId: 'error',
      });
    }
    setIsSubmiting(false);
  };
  const { register, handleSubmit, control, watch } = methods;
  const confirmationWatch = watch('confirmedPresence');
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Editar Colaborador"
      className="w-[60rem] max-w-[90vw]"
    >
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-4 p-4 pt-0"
        >
          <div className="flex h-12 w-full gap-4">
            <div className="flex w-full flex-col justify-between gap-1">
              <span className="text-xs text-gray-600">
                Nome <span className="text-red">*</span>
              </span>
              <input
                disabled
                {...register('name')}
                className="h-full w-full rounded-sm px-2 text-sm text-gray-700 ring-1 ring-gray-300 disabled:cursor-not-allowed disabled:bg-gray-100 "
                type="text"
              />
            </div>
            <div className="flex w-full flex-col justify-between gap-1">
              <span className="text-xs text-gray-600">
                Chapa <span className="text-red">*</span>
              </span>
              <input
                disabled
                {...register('employeeNumber')}
                className="h-full w-full rounded-sm px-2 text-sm text-gray-700 ring-1 ring-gray-300 disabled:cursor-not-allowed disabled:bg-gray-100 "
                type="text"
              />
            </div>

            <div className="flex h-12 w-full flex-col justify-between gap-1">
              <span className="text-xs text-gray-600">Telefone/Celular</span>
              <InputMask
                mask="(99) 99999-9999"
                {...register('phoneNumber')}
                className="h-full w-full rounded-sm px-2 text-sm text-gray-700 ring-1 ring-gray-300 disabled:bg-gray-100"
                type="text"
                value={phoneNumber || ''}
              />
            </div>
          </div>
          {!onlineTraining && (
            <>
              <div className="flex flex-col gap-2">
                <p className="text-sm font-semibold text-primary">
                  Refeição Extra
                </p>
                <Line className="mb-1" />
                <div className="flex-start flex flex-wrap gap-10 rounded-md bg-gray-100 p-4">
                  {employee.trainingPlanningEmployeeLinkDays.map(
                    (employeeDay, index) => {
                      return (
                        <div key={employeeDay.id}>
                          <FormField
                            control={control}
                            name={`planningDays.${index}.extraMeal`}
                            render={({ field }) => (
                              <FormItem className="flex flex-row items-start space-x-2 space-y-0">
                                <FormControl>
                                  <Checkbox
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                  />
                                </FormControl>
                                <div className="space-y-1 leading-none">
                                  <FormLabel className="text-xs text-gray-600">
                                    {format(
                                      new Date(employeeDay.date),
                                      'dd/MM/yyyy',
                                    )}
                                  </FormLabel>
                                </div>
                              </FormItem>
                            )}
                          />
                        </div>
                      );
                    },
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-sm font-semibold text-primary">
                  Alojamento Extra
                </p>
                <Line className="mb-1" />

                <div className="flex flex-col  gap-2 rounded-md bg-gray-100 p-4">
                  <p className="text-sm font-semibold text-gray-700">
                    Períodos de Alojamento
                  </p>
                  {fields.map((field, index) => {
                    return (
                      <div key={field.id}>
                        <div className="flex w-full items-end gap-4">
                          <div className="flex w-full flex-col justify-between gap-1">
                            <SingleDatePicker
                              name={`extraAccommodations.${index}.entryDate`}
                              form={methods}
                              label="Entrada"
                              showError={false}
                            />
                          </div>
                          <div className="flex w-full flex-col justify-between gap-1">
                            <SingleDatePicker
                              name={`extraAccommodations.${index}.exitDate`}
                              form={methods}
                              label="Saída"
                              showError={false}
                            />
                          </div>
                          <button
                            className="rounded-full bg-transparent p-2 hover:bg-primary/10"
                            onClick={() => remove(index)}
                            type="button"
                          >
                            <Trash className="text-primary" />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="mt-2 flex w-full justify-end">
                  <button
                    className="text-sm font-semibold text-primary"
                    type="button"
                    onClick={() =>
                      append({
                        entryDate: null,
                        exitDate: null,
                      })
                    }
                  >
                    + Adicionar Período
                  </button>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-sm font-semibold text-primary">Transporte</p>
                <Line className="mb-1" />

                <div className="flex flex-col gap-2 rounded-md bg-gray-100 p-3">
                  <p className="text-sm font-semibold text-gray-700">
                    Detalhamento dos Dias
                  </p>
                  <div className="flex flex-col space-y-4">
                    {employee.trainingPlanningEmployeeLinkDays.map(
                      (employeeDay, index) => {
                        return (
                          <div
                            className="flex flex-col gap-4 border-b-[1px] border-b-gray-300 pb-5 last:border-b-0"
                            key={`transport-${index}`}
                          >
                            <div key="" className="flex justify-between gap-6">
                              <div className="flex h-12 w-40 flex-col justify-between gap-1">
                                <span className="text-xs text-gray-600">
                                  Data
                                </span>
                                <input
                                  readOnly
                                  disabled
                                  value={format(
                                    removeTimeZone(new Date(employeeDay.date)),
                                    'dd/MM/yyyy',
                                  )}
                                  className="h-full w-full rounded-sm bg-transparent px-2 text-sm text-gray-700 ring-1 ring-gray-300 disabled:cursor-not-allowed disabled:bg-gray-200"
                                  type="text"
                                />
                              </div>
                              <div className="flex  w-28 flex-col gap-3 ">
                                <div className="leading-none">
                                  <FormLabel className="text-xs font-normal text-gray-600">
                                    Necessidade
                                  </FormLabel>
                                </div>
                                <FormField
                                  control={control}
                                  name={`planningDays.${index}.needTransport`}
                                  render={({ field }) => (
                                    <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                                      <FormControl>
                                        <Checkbox
                                          checked={field.value}
                                          onCheckedChange={field.onChange}
                                        />
                                      </FormControl>
                                    </FormItem>
                                  )}
                                />
                              </div>

                              <div className="flex h-12 w-60 flex-col justify-between gap-1">
                                <span className="text-xs text-gray-600">
                                  Requestia
                                </span>
                                <input
                                  disabled={true}
                                  {...register(
                                    `planningDays.${index}.requestiaId`,
                                  )}
                                  className="h-full w-full rounded-sm bg-transparent px-2 text-sm text-gray-700 ring-1 ring-gray-300"
                                  type="text"
                                />
                              </div>
                              <div className="flex h-12 w-80 flex-col justify-between gap-1"></div>
                            </div>
                            <div className="col-span-2 flex h-12 w-full flex-col justify-between gap-1">
                              <span className="text-xs text-gray-600">
                                Endereço
                              </span>
                              <input
                                {...register(`planningDays.${index}.address`)}
                                className="h-full w-full rounded-sm bg-transparent px-2 text-sm text-gray-700 ring-1 ring-gray-300 disabled:bg-gray-100"
                                type="text"
                              />
                            </div>
                          </div>
                        );
                      },
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          {!asyncTraining && (
            <div className="flex flex-col gap-2">
              <FormField
                control={control}
                name="confirmedPresence"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <div className="space-y-1 leading-none">
                      <FormLabel className="font-semibold  text-primary">
                        Confirmação de Presença
                      </FormLabel>
                    </div>
                  </FormItem>
                )}
              />
              <Line className="mb-1" />
              <div className="flex w-full flex-col justify-between gap-1 rounded-md bg-gray-100 p-3 pb-4">
                <span className="text-xs text-gray-600">Justificativa</span>
                <input
                  disabled={confirmationWatch}
                  {...register('absenceJustification')}
                  className="h-6 w-full rounded-sm px-2 text-sm text-gray-700 ring-1 ring-gray-300 disabled:bg-gray-100"
                  type="text"
                />
              </div>
            </div>
          )}
          {!asyncTraining && (
            <div className="flex w-full items-center justify-between">
              <Button
                type="button"
                className="h-8 min-w-36 border-2 border-[#193CB9] bg-white text-[#193CB9] hover:bg-[#e9eaf1]"
                onClick={onClose}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                className=" h-8 min-w-36"
                isLoading={isSubmiting}
              >
                Salvar
              </Button>
            </div>
          )}
        </form>
      </FormProvider>
    </Modal>
  );
};

export default EditEmployeeOnTrainingFileTeamModal;
