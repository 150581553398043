import InfoIcon from '@mui/icons-material/Info';
import { IconButton } from '@mui/material';

import LightTooltip from './LightTooltip';

interface Props {
  text: string;
  color?:
    | 'inherit'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
}

const TooltipIconText = ({ text, color }: Props) => {
  return (
    <LightTooltip
      title={
        <span>
          <div>{text}</div>
        </span>
      }
      placement="top"
      arrow
    >
      <IconButton
        sx={{ height: '2rem', width: '2rem' }}
        aria-label="information"
        size="small"
        color={`${color || 'default'}`}
      >
        <InfoIcon fontSize="inherit" />
      </IconButton>
    </LightTooltip>
  );
};

export default TooltipIconText;
