interface Employee {
  id: string;
  name: string;
}

export interface Scales {
  id: string;
  outOfPolicy: boolean;
  name: string;
  firstPeriod: number;
  secondPeriod?: number;
  thirdPeriod?: number;
}

export interface VacationScales {
  totalDays: number;
  scales: Scales[];
}

export interface CoverPerson {
  coverageType: string;
  employeeId?: string;
  startDate: string;
  endDate: string;
  structure?: { id: number; name: string };
}

export interface VacationPeriod {
  totalDays: number;
  startDate: string | Date | null;
  endDate: string | Date | null;
  salaryAdvance: boolean;
  vacationCovers: CoverPerson[];
  vacationCoversWorkDays?: { startDate: Date; endDate: Date }[];
}

export interface Vacation {
  id: string;
  employeeId: string;
  outOfPolicy: boolean;
  shiftOutOfPolicy: boolean;
  startDateOutOfPolicy: boolean;
  vacationTypeId: string;
  totalVacationDays: number;
  vacationPeriods: VacationPeriod[];
}
export interface ModalRequest {
  id: string;
  name: string;
  managementId: number;
  isDoctor: boolean;
  isADM: boolean;
  totalDays?: number;
  vacationScale?: string;
  coverageEmployees: Employee[];
  vacationScales: VacationScales[];
  vacation?: Vacation;
}

export interface ModalResponse {
  employeeId: string;
  totalVacationDays: number;
  vacationTypeId: string;
  outOfPolicy: boolean;
  vacationScale: string;
  vacationPeriods: VacationPeriod[];
}

export interface QualifyingPeriod {
  value: number;
  name: string;
}

export interface VacationDataResponse {
  id: string;
  name: string;
  managementId: number;
  isDoctor: boolean;
  isADM: boolean;
  coverageEmployees: Employee[];
  vacationScales: VacationScales[];
  vacation?: Vacation;
}

export interface CoverPeriod {
  startDate: Date;
  endDate: Date;
}
export interface WorkingDays {
  workDaysPeriods: CoverPeriod[];
}

export interface CoverageStructure {
  id: number;
  name: string;
  employees: {
    id: number;
    name: string;
  }[];
}

export const Coverage = {
  STANDARD: 'STANDARD',
  THIRD_PARTY: 'THIRD_PARTY',
  NO_NEED: 'NO_NEED',
  FROM_ANOTHER_AREA: 'FROM_ANOTHER_AREA',
  VIA_CLASS_CHANGE: 'VIA_CLASS_CHANGE',
};
