import { Dialog } from '@headlessui/react';
import { FC, ReactNode } from 'react';

import { cn } from '../../utils/cn';
import { Button } from '../atoms/Button';
import Modal from '../atoms/Modal';

interface ModalConfirmationProps {
  title: string;
  description?: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  confirmAction: () => void | Promise<void>;
  discartAction?: () => void;
  confirmButtonClassName?: string;
  containerClassName?: string;
  descriptionClassName?: string;
}

const ModalConfirmation: FC<ModalConfirmationProps> = (props) => {
  const confirmAction = async () => {
    await props.confirmAction();
    props.onClose();
  };

  const discartAction = () => {
    props.discartAction && props.discartAction();
    props.onClose();
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={discartAction}
      title={props.title}
      className={cn(
        'z-[1000] flex w-[30rem] max-w-[44rem] flex-col justify-between bg-white p-1 shadow-lg',
        props.containerClassName,
      )}
    >
      {props.description && (
        <Dialog.Description className="flex w-full flex-row justify-center pt-2">
          <div
            className={cn(
              'mx-auto flex max-w-[95%] flex-col justify-center px-4 text-center text-sm font-medium text-slate-600',
              props.descriptionClassName,
            )}
          >
            {props.description}
          </div>
        </Dialog.Description>
      )}

      <div className="flex h-fit w-full justify-between gap-2 px-8 py-6">
        <Button
          onClick={discartAction}
          className={cn(
            'min-w-40 border-2 border-[#193CB9] bg-white text-[#193CB9] hover:bg-[#e9eaf1]',
            props.confirmButtonClassName,
          )}
        >
          Cancelar
        </Button>
        <Button
          onClick={confirmAction}
          className={cn('min-w-40', props.confirmButtonClassName)}
        >
          Confirmar
        </Button>
      </div>
    </Modal>
  );
};

export default ModalConfirmation;
