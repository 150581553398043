import { cn } from '@/utils/cn';

interface LineProps {
  className?: string;
}
const Line = ({ className }: LineProps) => {
  return (
    <div className="flex w-full justify-center">
      <div
        className={cn('mb-4 h-px min-h-px w-full bg-[#193CB980]', className)}
      />
    </div>
  );
};

export default Line;
