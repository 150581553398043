import { CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import FileUploadComponent from '@/components/atoms/FileUpload';
import TableData from '@/components/atoms/TableData';
import { ViewFiles } from '@/components/atoms/ViewFiles';
import { EvidenceFile } from '@/types/EvidenceFile';
import { getFirstAndLastName } from '@/utils/getFirstAndLastName';

import { queryClient } from '../../App';
import EditableTableDate from '../../components/atoms/EditableTableDate';
import EditableTableInput from '../../components/atoms/EditableTableInput';
import { JustificationDialog } from '../../components/atoms/JustificationDialog';
import SimpleMenu from '../../components/atoms/Menu';
import TableCellDate from '../../components/atoms/TableCellDate';
import TableCellStatus from '../../components/atoms/TableCellStatus';
import TableCellText from '../../components/atoms/TableCellText';
import api from '../../services/apiSgft';
import { TrainingControl } from '../../types/TrainingControl';
import {
  CompletionStatus,
  ExpirationStatus,
  trainingModality,
} from '../../types/TrainingControlTypes';
import { removeTimeZone } from '../../utils/formatDate';
import {
  eadAllAllowedStatuses,
  eadAllowedStatuses,
  Modality,
  modalityOptions,
  presencialAllAllowedStatuses,
  presencialAllowedStatuses,
  StatusTrainingControl,
  StatusTrainingControlHistory,
  ValidStatusesForInvalidStatus,
  ValidStatusesForUploadEvidences,
} from '../TrainingConstants';

export const columns: Array<ColumnDef<TrainingControl>> = [
  {
    accessorFn: (row) => row.employee.name,
    header: 'NOME',
    meta: {
      headerClassName: 'sticky left-[-0.1rem] z-10 pl-2 bg-white text-start',
      stickyClassName: 'sticky left-[-0.1rem] z-10 bg-inherit',
    },
    cell: (props) => {
      const navigate = useNavigate();

      return (
        <div className={`flex w-32 items-start pl-2`}>
          <TableData
            tableValue={
              props.row.original.employee.alias ??
              getFirstAndLastName(props.getValue() as string)
            }
            className="flex w-40 cursor-pointer justify-start whitespace-nowrap text-[11px] font-semibold underline"
            title={props.getValue() as string}
            onClick={() =>
              navigate(
                `/home/colaborador/${props.row.original.employee.id.toString()}/historico-treinamentos`,
              )
            }
          />
        </div>
      );
    },
  },
  {
    accessorFn: (row) => row.employee.employeeNumber,
    header: 'CHAPA',
    meta: {
      headerClassName: 'sticky left-[125.9px] pl-1 bg-white text-start',
      stickyClassName: 'sticky left-[125.9px] z-10 bg-inherit',
    },
    cell: (props) => {
      return (
        <div className={`mr-5 flex w-24 items-start`}>
          <TableCellText
            text={(props.getValue() as string)?.toUpperCase()}
            align="start"
          />
        </div>
      );
    },
  },
  {
    accessorFn: (row) => row.employee.pole,
    header: 'POLO',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="mr-5 flex w-20 items-start">
        <TableCellText
          text={
            props.getValue()
              ? (props.getValue() as string)?.toUpperCase()
              : 'N/A'
          }
          align="start"
        />
      </div>
    ),
  },
  {
    accessorFn: (row) => row.employee.management,
    header: 'GERÊNCIA',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="mr-5 flex w-[12rem] items-start">
        <TableCellText
          text={
            props.getValue()
              ? (props.getValue() as string)?.toUpperCase()
              : 'N/A'
          }
          align="start"
          width="12rem"
        />
      </div>
    ),
  },
  {
    accessorFn: (row) => row.employee.areaCoordinator,
    header: 'COORDENAÇÃO',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="mr-5 flex w-40 items-start">
        <TableCellText
          text={
            props.getValue()
              ? (props.getValue() as string)?.toUpperCase()
              : 'N/A'
          }
          align="start"
        />
      </div>
    ),
  },
  {
    accessorFn: (row) => row.employee.workStation,
    header: 'LOCAL DE TRABALHO',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="mr-5 flex w-36 items-start">
        <TableCellText
          text={
            props.getValue()
              ? (props.getValue() as string)?.toUpperCase()
              : 'N/A'
          }
          align="start"
        />
      </div>
    ),
  },
  {
    accessorFn: (row) => row.employee.role,
    header: 'FUNÇÃO',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="mr-5 flex w-40 items-start">
        <TableCellText
          text={(props.getValue() as string)?.toUpperCase()}
          align="start"
          width="10rem"
        />
      </div>
    ),
  },

  {
    accessorFn: (row) => row.employee.status,
    header: 'SITUAÇÃO RM',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="flex w-36 items-start justify-start">
        <TableCellText
          text={(props.getValue() as string)?.toUpperCase()}
          align="start"
        />
      </div>
    ),
  },
  {
    accessorFn: (row) => row.training.trainingType,
    header: 'TIPO DE TREINAMENTO',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="mr-2 flex w-40 items-start justify-start">
        <TableCellText
          text={(props.getValue() as string)?.toUpperCase()}
          align="start"
        />
      </div>
    ),
  },
  {
    accessorFn: (row) => row.training.name,
    header: 'TREINAMENTO',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="flex w-40 items-start justify-start">
        <TableCellText
          text={(props.getValue() as string)?.toUpperCase()}
          width="15rem"
          align="start"
        />
      </div>
    ),
  },
  {
    accessorKey: 'trainingModality',
    header: 'MODALIDADE',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => {
      const [trainingModality, setTrainingModality] = useState<
        trainingModality | '-'
      >(props.getValue() as string as trainingModality);

      useEffect(() => {
        const currentModality = props.getValue() as string as trainingModality;
        if (trainingModality !== currentModality) {
          setTrainingModality(currentModality);
        }
      }, [props, trainingModality]);

      const handleTrainingModalityChange = async (
        value: string,
        id: number,
      ) => {
        try {
          await api.patch(`/training-control/${id}`, {
            trainingModality: value,
          });
          toast.success(
            `Modalidade de treinamento alterada para ${value} com sucesso!`,
            { theme: 'colored' },
          );
          queryClient.invalidateQueries({
            predicate: (query) => query.queryKey[0] === 'training-control',
          });
        } catch (error: any) {
          toast.error(`${error.response.data.message}`, {
            theme: 'colored',
            toastId: 'error',
          });
          throw error;
        }
      };

      const options = modalityOptions
        .filter((modality: { label: string; value: string }) =>
          props.row.original.training.modalities.includes(modality.value),
        )
        .map((item) => ({
          name: item.label,
          onClick: () =>
            handleTrainingModalityChange(item.value, props.row.original.id),
          disabled: false,
        }));
      const allowedStatusToUpdateModality = [
        StatusTrainingControl.AguardandoLiberacao,
        StatusTrainingControl.NaoPlanejado,
        StatusTrainingControl.Convalidado,
        StatusTrainingControl.Finalizado,
      ];
      const canUpdateModality = allowedStatusToUpdateModality.includes(
        props.row.original.completionStatus as StatusTrainingControl,
      );

      return (
        <div className="ml-2 flex w-32 items-start justify-between">
          <TableCellText text={trainingModality?.toUpperCase()} align="start" />
          {options.length > 1 && (
            <SimpleMenu
              options={options}
              edit
              disabled={!canUpdateModality}
              canSave={true}
            />
          )}
        </div>
      );
    },
  },
  {
    accessorKey: 'modality',
    header: 'PARTE',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="flex w-20 items-start">
        <TableCellText
          text={(props.getValue() as string)?.toUpperCase()}
          align="start"
        />
      </div>
    ),
  },
  {
    accessorFn: (row) => row.training.totalWorkload,
    header: 'CARGA HORÁRIA (H)',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="flex w-36 items-center">
        <TableCellText text={props.getValue() as string} align="center" />
      </div>
    ),
  },
  {
    accessorFn: (row) => row.training.validity,
    header: 'VALIDADE (ANOS)',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="flex w-36 items-center">
        <TableCellText text={props.getValue() as string} align="center" />
      </div>
    ),
  },
  {
    accessorKey: 'registerDate',
    header: 'DATA DE INSCRIÇÃO',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => {
      const [registerDate, setRegisterDate] = useState<Date | undefined>(
        props.getValue() !== '-'
          ? removeTimeZone(new Date(props.getValue() as string))
          : undefined,
      );

      useEffect(() => {
        const currentRegisterDate =
          props.getValue() !== '-'
            ? removeTimeZone(new Date(props.getValue() as string))
            : undefined;
        if (
          registerDate?.toDateString() !== currentRegisterDate?.toDateString()
        ) {
          setRegisterDate(currentRegisterDate);
        }
      }, [props.getValue(), registerDate]);

      const updateRegisterDate = async (e: Date) => {
        try {
          await api.patch(`/training-control/${props.row.original.id}`, {
            registerDate: (e as Date).toISOString(),
          });
          toast.success(
            `Data de inscrição alterada para ${format(e, 'dd-MM-yyyy')} com sucesso!`,
            {
              theme: 'colored',
            },
          );
          queryClient.invalidateQueries({
            predicate: (query) => query.queryKey[0] === 'training-control',
          });
        } catch (e: any) {
          const errorMessage =
            e?.response?.status === 400
              ? e?.response?.data?.message
              : 'Não foi possível alterar adata de inscrição!';
          toast.error(errorMessage, {
            theme: 'colored',
            toastId: 'error',
          });
        }
      };

      return (
        <div className="flex w-[12rem] items-start justify-start">
          <EditableTableDate value={registerDate} update={updateRegisterDate} />
        </div>
      );
    },
  },
  {
    accessorKey: 'completionDue',
    header: 'PRAZO PARA CONCLUSÃO (DIAS)',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => {
      const [completionDue, setCompletionDue] = useState<string>(
        props.getValue() ? (props.getValue() as string) : '',
      );

      const [editValue, setEditValue] = useState<string>(completionDue);

      const handleKeyDown = async (e: any) => {
        if (e.key === 'Enter') {
          try {
            await api.patch(`/training-control/${props.row.original.id}`, {
              completionDue: String(editValue),
            });
            toast.success(
              `Prazo de conclusão alterado para ${editValue} dias com sucesso!`,
              {
                theme: 'colored',
              },
            );
            e.target.blur();
            setCompletionDue(editValue);
            queryClient.invalidateQueries({
              predicate: (query) => query.queryKey[0] === 'training-control',
            });
          } catch (e) {
            toast.error('Erro ao salvar prazo de conclusão!', {
              theme: 'colored',
              toastId: 'error',
            });
          }
        }
      };

      const handleBlur = () => {
        setEditValue(editValue);
      };

      return (
        <div className="flex w-[14rem] items-start">
          <EditableTableInput
            title="Digite um prazo de conclusão"
            value={editValue}
            setState={setEditValue}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'completionDate',
    header: 'DATA DE ÚLTIMA CONCLUSÃO',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => {
      const [completionDate, setCompletionDate] = useState<Date | undefined>(
        props.getValue() !== '-'
          ? removeTimeZone(new Date(props.getValue() as string))
          : undefined,
      );
      const allowedStatusToUpdateDate = [
        StatusTrainingControl.Concluido,
        StatusTrainingControl.Convalidado,
      ];
      const canUpdateDate = allowedStatusToUpdateDate.includes(
        props.row.original.completionStatus as StatusTrainingControl,
      );

      useEffect(() => {
        const currentCompletionDate =
          props.getValue() !== '-'
            ? removeTimeZone(new Date(props.getValue() as string))
            : undefined;
        if (
          completionDate?.toDateString() !==
          currentCompletionDate?.toDateString()
        ) {
          setCompletionDate(currentCompletionDate);
        }
      }, [props.getValue(), completionDate]);

      const updateCompletionDate = async (e: Date) => {
        try {
          await api.patch(`/training-control/${props.row.original.id}`, {
            completionDate: (e as Date).toISOString(),
          });
          toast.success(
            `Data de conclusão alterada para ${format(e, 'dd-MM-yyyy')} com sucesso!`,
            {
              theme: 'colored',
            },
          );
          queryClient.invalidateQueries({
            predicate: (query) => query.queryKey[0] === 'training-control',
          });
        } catch (e: any) {
          const errorMessage =
            e?.response?.status === 400
              ? e?.response?.data?.message
              : 'Não foi possível alterar a data de conclusão!';
          toast.error(errorMessage, {
            theme: 'colored',
            toastId: 'error',
          });
        }
      };

      return (
        <div className="flex w-[14rem] items-start justify-between">
          <EditableTableDate
            value={completionDate}
            update={updateCompletionDate}
            disabled={!canUpdateDate}
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'completionStatus',
    header: 'SITUAÇÃO DE TREINAMENTO',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => {
      const [status, setStatus] = useState<CompletionStatus | '-'>(
        props.getValue() as string as CompletionStatus,
      );
      const [pendingStatus, setPendingStatus] = useState<
        CompletionStatus | '-'
      >('-');

      useEffect(() => {
        const currentStatus = props.getValue() as string as CompletionStatus;
        if (status !== currentStatus) {
          setStatus(currentStatus);
        }
      }, [props, status]);

      const [isJustificationDialogOpen, setIsJustificationDialogOpen] =
        useState(false);
      const [justification, setJustification] = useState('');

      const openJustificationDialog = () => setIsJustificationDialogOpen(true);
      const closeJustificationDialog = () =>
        setIsJustificationDialogOpen(false);

      const completionOptions =
        props.row.original.modality === Modality.Online
          ? ValidStatusesForInvalidStatus.includes(
              status as StatusTrainingControl,
            )
            ? eadAllAllowedStatuses
            : eadAllowedStatuses
          : ValidStatusesForInvalidStatus.includes(
                status as StatusTrainingControl,
              )
            ? presencialAllAllowedStatuses
            : presencialAllowedStatuses;

      const handleUpdateCompletionStatus = async (
        value: string,
        id: number,
        justification = '',
      ) => {
        try {
          await api.patch(`/training-control/${id}`, {
            completionStatus: value,
            ...((value === StatusTrainingControlHistory.Invalidado ||
              value === StatusTrainingControlHistory.NaoAplicavel) && {
              justification,
            }),
          });
          toast.success(
            `Situação de treinamento alterada para ${value} com sucesso!`,
            { theme: 'colored' },
          );
          queryClient.invalidateQueries({
            predicate: (query) => query.queryKey[0] === 'training-control',
          });
          closeJustificationDialog();
          setJustification('');
        } catch (error: any) {
          toast.error(`${error.response.data.message}`, {
            theme: 'colored',
            toastId: 'error',
          });
          throw error;
        }
      };

      const handleStatusChange = (itemValue: string, itemId: number) => {
        if (
          itemValue === StatusTrainingControlHistory.Invalidado ||
          itemValue === StatusTrainingControlHistory.NaoAplicavel
        ) {
          setPendingStatus(itemValue as CompletionStatus);
          openJustificationDialog();
        } else {
          handleUpdateCompletionStatus(itemValue, itemId);
        }
      };

      const options = completionOptions.map((item) => ({
        name: item.label,
        onClick: () => handleStatusChange(item.value, props.row.original.id),
        disabled: false,
      }));

      return (
        <div className="ml-2 flex w-[13rem] items-center justify-between">
          <TableCellText
            text={status?.toUpperCase()}
            align="start"
            width="13rem"
            className="whitespace-normal py-0"
          />
          <SimpleMenu options={options} edit disabled={false} canSave={true} />
          <JustificationDialog
            title={`Justifique a situação do treinamento`}
            justification={justification}
            setJustification={setJustification}
            isOpen={isJustificationDialogOpen}
            setIsOpen={setIsJustificationDialogOpen}
            onConfirmation={() =>
              handleUpdateCompletionStatus(
                pendingStatus,
                props.row.original.id,
                justification,
              )
            }
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'expirationStatus',
    header: 'SITUAÇÃO DE VALIDADE',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="mr-5 flex w-40 justify-center">
        <TableCellStatus
          status={props.getValue() as ExpirationStatus}
          upperCase={true}
        />
      </div>
    ),
  },
  {
    accessorKey: 'updatedAt',
    header: 'ÚLTIMA MODIFICAÇÃO',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="flex w-40">
        <TableCellDate
          date={removeTimeZone(new Date(props.getValue() as string))}
          className="flex justify-start"
        />
      </div>
    ),
  },
  {
    accessorKey: 'fileUpload',
    header: 'UPLOAD DE EVIDÊNCIAS',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => {
      const [isLoading, setIsLoading] = useState(false);
      const historicId = props.row.original?.equivalentHistoric?.id;
      const employeeName = props.row.original.employee.name;
      const fetchEvidences = async () => {
        if (!historicId) {
          return [];
        }
        try {
          const response = await api.get(
            `training-control/training-control-history/${historicId}/evidences/`,
          );
          return response.data.map((el: File) => {
            return {
              ...el,
              employeeName,
            };
          });
        } catch (error: any) {
          toast.error(
            error?.response?.data?.message ??
              'Erro ao buscar arquivos de evidências!',
            {
              theme: 'colored',
              toastId: 'error',
            },
          );
        }
      };
      const { data: files } = useQuery<EvidenceFile[]>(
        ['get-evidence-files', historicId],
        fetchEvidences,
        {
          retry: false,
          refetchOnMount: false,
        },
      );
      async function handleFilesSelected(files: File[], id: string) {
        setIsLoading(true);
        const formData = new FormData();
        for (const file of files) {
          formData.append('files', file);
        }

        formData.append('id', id);

        try {
          await api.post(
            `/training-control/${props.row.original.id}/upload-evidence/`,
            formData,
          );
          toast.success('Arquivo(s) foram salvos com sucesso!', {
            theme: 'colored',
          });
        } catch (e: any) {
          const errorMessage =
            e?.response?.status === 400
              ? e?.response?.data?.message
              : 'Não foi possível salvar o(s) arquivo(s) enviado(s)!';
          toast.error(errorMessage, {
            theme: 'colored',
            toastId: 'error',
          });
        } finally {
          setIsLoading(false);
          queryClient.invalidateQueries({
            queryKey: ['training-control'],
          });
          queryClient.invalidateQueries({
            queryKey: ['get-evidence-files'],
          });
          queryClient.invalidateQueries({
            queryKey: ['employee-trainings'],
          });
        }
      }
      const handleDeleteEvidence = async (evidenceId: number) => {
        try {
          await api.delete(`training-control/delete-evidence/${evidenceId}`);
          queryClient.invalidateQueries({
            queryKey: ['get-evidence-files'],
          });
          toast.success(`Evidência deletada com sucesso!`, {
            theme: 'colored',
            toastId: 'success',
          });
          queryClient.invalidateQueries({
            queryKey: ['employee-trainings'],
          });
        } catch {
          toast.error('Não foi possível deletar a evidência!', {
            theme: 'colored',
            toastId: 'error',
          });
        }
      };
      if (
        ValidStatusesForUploadEvidences.includes(
          props.row.original.completionStatus as StatusTrainingControl,
        ) &&
        props.row.original.completionDate !== '-'
      )
        return (
          <div className="ml-1 flex w-40 items-start justify-center">
            {isLoading ? (
              <CircularProgress className="ml-8" />
            ) : (
              <>
                <FileUploadComponent
                  onFilesSelected={(filesList: FileList | null) => {
                    const filesArray = filesList ? Array.from(filesList) : [];
                    handleFilesSelected(
                      filesArray,
                      String(props.row.original.id),
                    );
                  }}
                  rowId={String(props.row.original.id)}
                />
                <ViewFiles
                  files={files ?? []}
                  onDeleteFunction={handleDeleteEvidence}
                  deleteFileDescrption={`do histórico do colaborador ${props.row.original.employee.name} não poderá ser recuperado`}
                />
              </>
            )}
          </div>
        );
    },
  },
];
