import { Typography } from '@mui/material';
import { useState, MouseEvent } from 'react';
import LightTooltip from './LightTooltip';
import { getFirstAndLastName } from '../../utils/getFirstAndLastName';
import SimpleMenu from './Menu';

interface Props {
  value: string;
  options: any;
  ifValueIsEmpty: string;
  isName?: boolean;
  disabled: boolean;
  canSave: boolean;
}

const EditableTableCell = ({
  value,
  isName,
  ifValueIsEmpty,
  options,
  disabled,
  canSave,
}: Props) => {
  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  const handleShouldShow = ({ currentTarget }: MouseEvent<Element>) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth || isName) {
      setTooltipEnabled(true);
    }
  };

  return (
    <div className="w-[12rem] flex">
      <LightTooltip
        title={value}
        open={tooltipEnabled}
        onClose={() => setTooltipEnabled(false)}
        arrow
        placement="top-end"
      >
        {value ? (
          <Typography
            sx={{
              color: `${isName ? '#4B5563' : '#4A4A4A'}`,
              fontSize: '12px',
              fontFamily: 'inherit',
              paddingY: '8px',
              fontWeight: `${isName ? '600' : '400'}`,
            }}
            onMouseEnter={handleShouldShow}
            noWrap
          >
            {isName ? getFirstAndLastName(value) : value}
          </Typography>
        ) : (
          <Typography
            sx={{
              color: '#D6D4D4',
              fontSize: '12px',
              fontFamily: 'inherit',
              paddingY: '8px',
              fontWeight: '600',
            }}
          >
            {ifValueIsEmpty}
          </Typography>
        )}
      </LightTooltip>
      <SimpleMenu
        options={options}
        edit
        disabled={disabled}
        canSave={canSave}
      />
    </div>
  );
};

export default EditableTableCell;
