import { toast } from 'react-toastify';

import api from '@/services/apiSgft';
import { PendenciesFilters } from '@/state/PendenciesFilter.atom';

import { Filter, Option } from '../../types/Filter';
import {
  PendencyFilterOptionsSchema,
  PendencyFiltersOptions,
  PendencyStatus,
} from '../PendencyConstants';

const fetchFilter = async (
  field: keyof PendencyFiltersOptions,
  search: string,
  atom?: PendenciesFilters,
) => {
  try {
    const response = await api.get('pendencies/filters', {
      params: {
        status: PendencyStatus.OPEN,
        management: atom?.management,
        area: atom?.area,
        sector: atom?.sector,
        workStation: atom?.workStation,
        scale: atom?.scale,
        pendencyType: atom?.pendencyType,
        responsible: atom?.responsible,
      },
    });
    const parsedFilters = PendencyFilterOptionsSchema.parse(response.data);
    const fieldOptions: Option[] = parsedFilters[field] || [];
    return fieldOptions;
  } catch (e) {
    toast.error('Erro ao carregar os dados', {
      theme: 'colored',
      toastId: 'error',
    });
    throw e;
  }
};

export const filters: Filter[] = [
  {
    key: 'management',
    name: 'Gerência',
    options: [],
    isMulti: false,
    asyncFn: (search, atom?: PendenciesFilters) =>
      fetchFilter('managements', search, atom),
    isDisabled: (_: PendenciesFilters) => false,
  },
  {
    key: 'pendencyType',
    name: 'Tipo da Pendência',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: PendenciesFilters) =>
      fetchFilter('types', search, atom),
    isDisabled: (_: PendenciesFilters) => false,
  },
  {
    key: 'responsible',
    name: 'Responsável',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: PendenciesFilters) =>
      fetchFilter('responsibles', search, atom),
    isDisabled: (_: PendenciesFilters) => false,
  },
  {
    key: 'area',
    name: 'Área',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: PendenciesFilters) =>
      fetchFilter('areas', search, atom),
    isDisabled: (_: PendenciesFilters) => false,
  },
  {
    key: 'sector',
    name: 'Setor',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: PendenciesFilters) =>
      fetchFilter('sectors', search, atom),
    isDisabled: (_: PendenciesFilters) => false,
  },
  {
    key: 'workStation',
    name: 'Local de Trabalho',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: PendenciesFilters) =>
      fetchFilter('workstations', search, atom),
    isDisabled: (_: PendenciesFilters) => false,
  },
  {
    key: 'scale',
    name: 'Escala',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: PendenciesFilters) =>
      fetchFilter('scales', search, atom),
    isDisabled: (_: PendenciesFilters) => false,
  },
];
