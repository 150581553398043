import { useEffect, useState } from 'react';

import { columns } from '@/constants/tableColumns/EvidenceFilesColumns';
import { EvidenceFile } from '@/types/EvidenceFile';

import Table from '../organisms/Table';
import Modal from './Modal';

interface ViewFilesProps {
  files: EvidenceFile[];
  onDeleteFunction: (id: number) => Promise<void>;
  deleteFileDescrption: string;
  hideDeleteButton?: boolean;
}

export const ViewFiles = ({
  files,
  onDeleteFunction,
  deleteFileDescrption,
  hideDeleteButton,
}: ViewFilesProps) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!files || files.length === 0) {
      setIsOpen(false);
    }
  }, [files]);

  return (
    <div>
      <div className="flex items-center">
        <button
          type="button"
          className={`cursor-pointer border-none bg-transparent text-[12px] ${files.length === 0 ? 'text-[#b1b1b1]' : 'text-[#1E40B7]'} underline outline-none`}
          onClick={() => setIsOpen(true)}
          disabled={files.length === 0}
        >
          {`VISUALIZAR (${files.length ?? 0})`}
        </button>
      </div>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        className="max-h-[50vh] max-w-[45rem]"
        title="Arquivos Importados"
      >
        <div className="px-2 py-3">
          <Table
            columns={columns(
              onDeleteFunction,
              deleteFileDescrption,
              hideDeleteButton,
            )}
            data={files}
          />
        </div>
      </Modal>
    </div>
  );
};
