import { RedmineStatus } from '@/constants/TrainingPlanning';

export const getRedmineStatus = (status: string) => {
  switch (status) {
    case 'Solicitação Aprovada':
      return 'Aprovado';
    case 'Solicitação em Análise':
      return 'Pendente';
    case 'Solicitação Reprovada':
      return 'Negada';
    default:
      return '';
  }
};

export const getRedmineStatusColor = (status: string) => {
  switch (status) {
    case 'Solicitação Aprovada':
      return '#10AE88';
    case 'Solicitação em Análise':
      return '#FFA654';
    case 'Solicitação Reprovada':
      return '#F4503A';
    default:
      return '#454343';
  }
};

export const getRedmineStatusUpperCase = (status: string) => {
  switch (status) {
    case RedmineStatus.SolicitacaoAprovada:
      return 'APROVADA';
    case RedmineStatus.SolicitacaoCancelada:
      return 'CANCELADA';
    case RedmineStatus.SolicitacaoRejeitada:
      return 'REJEITADA';
    case RedmineStatus.SolicitacaoEmAnalise:
      return 'PENDENTE';
    default:
      return '-';
  }
};

export const getRedmineStatusColorUpperCase = (status: string) => {
  switch (status) {
    case RedmineStatus.SolicitacaoAprovada:
      return '#10AE88';
    case RedmineStatus.SolicitacaoRejeitada:
      return '#F4503A';
    case RedmineStatus.SolicitacaoCancelada:
      return '#F4503A';
    default:
      return '#454343';
  }
};
