import {
  number,
  object,
  optional,
  string,
  writableArray,
} from '@recoiljs/refine';
import { atom } from 'recoil';
import { urlSyncEffect } from 'recoil-sync';

export interface ChartFilterType {
  areaBoardFilter: Array<string>;
  areaBoardSearch: Array<string>;
  managementFilter: Array<string>;
  managementSearch: Array<string>;
  coordinationFilter: Array<string>;
  coordinationSearch: Array<string>;
  positionFilter?: number;
  employeeFilter?: number;
}

export const chartAtom = atom<ChartFilterType>({
  key: 'chart',
  default: {
    areaBoardFilter: [],
    areaBoardSearch: [],
    managementFilter: [],
    managementSearch: [],
    coordinationFilter: [],
    coordinationSearch: [],
    employeeFilter: undefined,
    positionFilter: undefined,
  },
  effects: [
    urlSyncEffect<ChartFilterType>({
      refine: object({
        areaBoardFilter: writableArray(string()),
        areaBoardSearch: writableArray(string()),
        managementFilter: writableArray(string()),
        managementSearch: writableArray(string()),
        coordinationFilter: writableArray(string()),
        coordinationSearch: writableArray(string()),
        employeeFilter: optional(number()),
        positionFilter: optional(number()),
      }),
      history: 'push',
    }),
  ],
});
