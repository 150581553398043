import { zodResolver } from '@hookform/resolvers/zod';
import { SelectChangeEvent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { z } from 'zod';

import NoPermissionText from '../../components/atoms/NoPermissionText';
import { RegisterAndEditTeam } from '../../components/templates/RegisterAndEditTeam';
import {
  createFormSchema,
  SCALE_ADM,
  SCALE_MEDICO,
  SCALE_MOTORISTA,
  SCALE_SERVICOS_ESPECIAIS,
  SCALE_TURNO,
  SIZE_ADM,
  SIZE_OUTROS,
  SIZE_SERVICOS_ESPECIAIS,
  STRUCTURE_TYPES,
} from '../../constants/TeamRegister';
import { teamRegisterAtom } from '../../state/TeamRegister.atom';
import { TeamRegister } from '../../types/TeamRegister';
import { userCanSeeTeamStructureForms } from '../../utils/handleSavePermissions';

type formData = z.infer<typeof createFormSchema>;

const TeamsRegister: React.FC = () => {
  if (!userCanSeeTeamStructureForms()) return <NoPermissionText />;

  const [team, setTeam] = useRecoilState(teamRegisterAtom);
  const [scales, setScales] = useState<{ value: string; label: string }[]>([]);
  const [teamsLength, setTeamsLength] = useState<
    { value: number; label: string }[]
  >([]);
  const navigate = useNavigate();
  const reset = useResetRecoilState(teamRegisterAtom);

  const methods = useForm<formData>({
    resolver: zodResolver(createFormSchema),
    defaultValues: {
      name: '',
      management: 0,
      area: -2,
      otherArea: '',
      sector: '',
      otherSector: '',
      workstation: [],
      structureType: '',
      teamsLength: 0,
      scale: '',
      onNotice: '',
      mandatoryOnNotice: '',
      confinement: '',
    },
  });

  const goToNextPage = (data: TeamRegister) => {
    setTeam({
      name: data.name,
      management: data.management,
      area: data.area,
      otherArea: data.otherArea || '',
      sector: data.sector,
      otherSector: data.otherSector || '',
      workstation: data.workstation,
      structureType: data.structureType,
      teamsLength: data.teamsLength,
      scale: data.scale,
      onNotice: data.onNotice,
      mandatoryOnNotice: data.mandatoryOnNotice,
      confinement: data.confinement,
    });
    navigate('preview');
  };

  const getScalesAndTeamsLength = (e?: SelectChangeEvent<unknown>) => {
    const value = e ? e.target.value : team.structureType;
    if (value === STRUCTURE_TYPES.ADM) {
      setScales(SCALE_ADM);
      setTeamsLength(SIZE_ADM);
    } else if (value === STRUCTURE_TYPES.MEDICO) {
      setScales(SCALE_MEDICO);
      setTeamsLength(SIZE_OUTROS);
    } else if (value === STRUCTURE_TYPES.MOTORISTA) {
      setScales(SCALE_MOTORISTA);
      setTeamsLength(SIZE_OUTROS);
    } else if (value === STRUCTURE_TYPES.SERVICOS) {
      setScales(SCALE_SERVICOS_ESPECIAIS);
      setTeamsLength(SIZE_SERVICOS_ESPECIAIS);
    } else if (value !== '') {
      setScales(SCALE_TURNO);
      setTeamsLength(SIZE_OUTROS);
    }
  };

  useEffect(() => {
    getScalesAndTeamsLength();
    setTimeout(() => {
      methods.reset({
        name: team.name,
        management: team.management,
        area: team.area,
        otherArea: team.otherArea,
        sector: team.sector,
        otherSector: team.otherSector,
        workstation: team.workstation,
        structureType: team.structureType,
        teamsLength: team.teamsLength,
        scale: team.scale,
        onNotice: team.onNotice,
        mandatoryOnNotice: team.mandatoryOnNotice,
        confinement: team.confinement,
      });
    }, 150);
  }, []);

  const resetFields = () => {
    reset();
    methods.reset();
    navigate(-1);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(goToNextPage)} noValidate>
        <RegisterAndEditTeam
          type="create"
          scales={scales}
          teamsLength={teamsLength}
          getScalesAndTeamsLength={getScalesAndTeamsLength}
          onAbort={resetFields}
        />
      </form>
    </FormProvider>
  );
};

export default TeamsRegister;
