import { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import {
  TrainingTypeEnum,
  trainingTypeOptions,
} from '@/constants/trainingTypeOptions';

import ControlledSimpleSelect from '../atoms/ControlledSimpleSelect';
import TrashButton from '../atoms/TrashButton';
import { DevelopmentTrainingCell } from './DevelopmentTrainingCell';
import ExpandableSubtitle from './ExpandableSubtitle';
import { RegulatoryTrainingCell } from './RegulatoryTrainingCell';

interface Props {
  methods: UseFormReturn<any, any, any>;
  updateDueInputs: () => void;
  idx: number;
  remove: (index: number) => void;
}

export const TrainingCell = ({
  methods,
  updateDueInputs,
  idx,
  remove,
}: Props) => {
  const [trainingName, setTrainingName] = useState(
    methods.getValues(`trainings.${idx}.name`),
  );
  const handleUpdateName = () => {
    setTrainingName(methods.getValues(`trainings.${idx}.name`));
  };
  const trainingTypeWatch = methods.watch(`trainings.${idx}.trainingType`);
  return (
    <ExpandableSubtitle
      subtitle={`${idx + 1} - ${trainingName ?? ''}`}
      className="mr-2 block  max-w-full overflow-hidden text-ellipsis whitespace-nowrap text-xs"
    >
      <div className="flex w-full flex-col">
        <div className="grid w-full grid-cols-2 gap-x-4 gap-y-2">
          <ControlledSimpleSelect
            title={'Tipos de Treinamentos'}
            required
            control={methods.control}
            name={`trainings.${idx}.trainingType`}
            width="100%"
            height="38px"
            values={trainingTypeOptions}
          />
          {trainingTypeWatch && (
            <>
              {trainingTypeWatch === TrainingTypeEnum.DEVELOPMENT ? (
                <DevelopmentTrainingCell
                  idx={idx}
                  methods={methods}
                  remove={remove}
                  updateDueInputs={updateDueInputs}
                  handleUpdateName={handleUpdateName}
                />
              ) : (
                <RegulatoryTrainingCell
                  idx={idx}
                  methods={methods}
                  remove={remove}
                  updateDueInputs={updateDueInputs}
                  handleUpdateName={handleUpdateName}
                />
              )}
            </>
          )}
        </div>
        <div className="flex w-full justify-end">
          <TrashButton onClick={() => remove(idx)} />
        </div>
      </div>
    </ExpandableSubtitle>
  );
};
