import { format } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import { FieldValues, Path, UseFormReturn } from 'react-hook-form';

import { removeTimeZone } from '@/utils/formatDate';

import { cn } from '../../utils/cn';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { Button } from './Button';
import { Calendar } from './Calendar';
import { Popover, PopoverContent, PopoverTrigger } from './Popover';

interface SingleDatePickerProps<T extends FieldValues> {
  form: UseFormReturn<T>;
  name: Path<T>;
  label: string;
  required?: boolean;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  className?: string;
  showError?: boolean;
}

export function SingleDatePicker<T extends FieldValues>({
  form,
  name,
  label,
  maxDate,
  minDate,
  required = false,
  disabled = false,
  className = '',
  showError = true,
}: SingleDatePickerProps<T>) {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <FormLabel>
            <span className="font-graphie text-xs text-gray-600">
              {label} {required && <span className="text-red">*</span>}
            </span>
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  type="button"
                  variant={'outline'}
                  className={cn(
                    'h-7  rounded-sm pl-3 text-left font-normal text-gray-600 ring-gray-400 hover:bg-transparent focus:ring-primary disabled:cursor-not-allowed disabled:bg-gray-100',
                    className,
                  )}
                  disabled={disabled}
                >
                  {field.value ? (
                    format(removeTimeZone(new Date(field.value)), 'dd/MM/yyyy')
                  ) : (
                    <span>dd/mm/aaaa</span>
                  )}
                  <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="z-[100000] w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={field.value}
                onSelect={field.onChange}
                disabled={(date) =>
                  (minDate ? date < minDate : false) ||
                  (maxDate ? date > maxDate : false)
                }
                initialFocus
                className="z-[100000]"
                showClearButton={true}
                defaultMonth={minDate ?? maxDate ?? new Date()}
              />
            </PopoverContent>
          </Popover>
          {showError && <FormMessage />}
        </FormItem>
      )}
    />
  );
}
