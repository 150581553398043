import React, { useCallback, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { CgSpinner } from 'react-icons/cg';
import { toast } from 'react-toastify';

import { Modality, SelectOption } from '@/constants/TrainingConstants';
import api from '@/services/apiSgft';
import { TrainingPlanningFormGroup } from '@/types/TrainingPlanning';
import { cn } from '@/utils/cn';
import { removeTimeZone } from '@/utils/formatDate';

import ControlledSimpleSelect from '../atoms/ControlledSimpleSelect';
import Input from '../atoms/Input';
import { SingleDatePicker } from '../atoms/SingleDatePicker';
import TooltipIconText from '../atoms/TooltipIconText';
import { Button } from '../ui/button';
import { Checkbox } from '../ui/checkbox';
import { Label } from '../ui/label';

interface TrainingPlanningDayCellProps {
  trainingFileId: string;
  methods: UseFormReturn<TrainingPlanningFormGroup>;
  startDate?: string;
  endDate?: string;
  index: number;
  workStations: SelectOption[];
}

export const TrainingPlanningDayCell: React.FC<
  TrainingPlanningDayCellProps
> = ({ methods, trainingFileId, endDate, startDate, index, workStations }) => {
  const { watch, register } = methods;
  const [shouldShowDateSuggestion, setShouldShowDateSuggestion] =
    useState(false);
  const training = watch('training');
  const modality = watch('modality');
  const management = watch('management');
  const coordination = watch('coordination');
  const workStation = watch('workStation');
  const role = watch('role');
  const state = watch('state');
  const status = watch('situation');
  const rmStatus = watch('RMSituation');
  const realization = watch('realization');
  const expirationStatus = watch('expirationStatus');
  const partModality = watch('partModality');

  const endTime = watch(`trainingDays.${index}.endTime`);
  const startTime = watch(`trainingDays.${index}.startTime`);
  const date = watch(`trainingDays.${index}.date`);

  const [trainingDateOptions, setTrainingDateOptions] = useState<string[]>([]);

  const [isLoadingDateOptions, setIsLoadingDateOptions] =
    useState<boolean>(false);
  const fetchAvailableTrainingDates = useCallback(
    async ({ trainingId }: { trainingId: string }) => {
      const trainingDays = methods.getValues('trainingDays') ?? [];

      const alreadySelectedDates =
        trainingDays?.filter((_, idx) => idx !== index).map((it) => it.date) ??
        [];
      setIsLoadingDateOptions(true);
      try {
        const response = await api.get(
          `training-plannings/training-files/${trainingFileId}/trainings/${trainingId}/available-training-dates`,
          {
            params: {
              modality,
              startTime,
              endTime,
              management,
              coordination,
              workStation,
              role,
              state,
              status,
              rmStatus,
              realization,
              expirationStatus,
              alreadySelectedDates,
            },
          },
        );
        setTrainingDateOptions(response.data.dates);
        setIsLoadingDateOptions(false);
      } catch (e) {
        toast.error('Erro ao carregar os dados', {
          theme: 'colored',
          toastId: 'error',
        });
        throw e;
      }
    },
    [
      methods,
      index,
      trainingFileId,
      modality,
      startTime,
      endTime,
      management,
      coordination,
      workStation,
      role,
      state,
      status,
      rmStatus,
      realization,
      expirationStatus,
    ],
  );

  useEffect(() => {
    if (shouldShowDateSuggestion && training) {
      fetchAvailableTrainingDates({ trainingId: training });
    }
  }, [fetchAvailableTrainingDates, shouldShowDateSuggestion, training]);

  const handleSetDate = (dateOption: string) => {
    methods.setValue(`trainingDays.${index}.date`, dateOption);
  };
  return (
    <div className="w-full rounded-md bg-gray-100 p-4">
      <h5 className="py-2 text-sm font-semibold text-gray-600">
        Dia {index + 1}
      </h5>
      <div className="flex max-w-full flex-wrap items-start justify-start gap-2 overflow-hidden">
        <div className="mr-20 flex flex-col items-start justify-between gap-2">
          {shouldShowDateSuggestion ? (
            <div className="flex flex-col items-start ">
              <div className="text-[12px] text-[#4a4a4a]">
                Data de treinamento
                <span className="text-red">*</span>
              </div>
              <div className="flex w-full items-center justify-start gap-2">
                {isLoadingDateOptions ? (
                  <CgSpinner
                    size={30}
                    color="#c1c1c1"
                    className="h-full animate-spin"
                  />
                ) : (
                  <>
                    {trainingDateOptions && trainingDateOptions.length > 0 ? (
                      <>
                        {trainingDateOptions.map((dateOption) => {
                          const [year, month, day] = dateOption.split('-');
                          const formattedDate = `${day}/${month}/${year}`;
                          return (
                            <Button
                              key={dateOption}
                              type="button"
                              className={cn(
                                'flex h-8 w-10 min-w-36 items-center border-2 border-[#193CB9] py-0',
                                date === dateOption
                                  ? 'bg-[#193CB9] text-white hover:bg-[#9ca0c0] hover:text-[#193CB9]'
                                  : 'bg-white text-[#193CB9] hover:bg-[#e9eaf1]',
                              )}
                              onClick={() => {
                                handleSetDate(dateOption);
                              }}
                            >
                              {formattedDate}
                            </Button>
                          );
                        })}
                        <TooltipIconText text="TOP 5 das datas com maior número de colaboradores com necessidade do treinamento disponíveis" />
                      </>
                    ) : (
                      <div className="p-4	 text-[12px] font-bold text-[#4A4A4A]">
                        Nenhuma data de treinamento sugerida disponível
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          ) : (
            <SingleDatePicker
              form={methods}
              className="ml-1 h-8 w-64 py-0"
              label="Data de treinamento"
              required
              minDate={
                startDate ? removeTimeZone(new Date(startDate)) : new Date()
              }
              maxDate={endDate ? removeTimeZone(new Date(endDate)) : new Date()}
              {...register(`trainingDays.${index}.date`)}
            />
          )}
          <div className="my-1 ml-2 flex items-center gap-2">
            <Checkbox
              title={'Treinamento não possui data definida'}
              name={`shouldShowDateSuggestion`}
              // disabled={modality === undefined}
              checked={shouldShowDateSuggestion}
              onCheckedChange={(checked) =>
                setShouldShowDateSuggestion(checked as boolean)
              }
              id="shouldShowDateSuggestion"
            />
            <Label
              className="cursor-pointer text-xs font-normal text-gray-800"
              htmlFor="shouldShowDateSuggestion"
            >
              Treinamento não possui data definida
            </Label>
          </div>
        </div>
        <div className="flex w-1/5 items-center justify-between gap-4">
          <div className="flex w-full flex-col">
            <span className="text-[12px] text-gray-600">
              Horário de início
              <span className="text-red">*</span>
            </span>
            <Input
              {...register(`trainingDays.${index}.startTime`)}
              className="h-8 w-40 rounded-sm bg-transparent px-2 text-[12px] text-gray-700  ring-gray-300"
              type="time"
            />
          </div>
          <div className="flex w-full flex-col">
            <span className="text-[12px] text-gray-600">
              Horário de fim
              <span className="text-red">*</span>
            </span>
            <Input
              {...register(`trainingDays.${index}.endTime`)}
              className="h-8 w-40 rounded-sm bg-transparent px-2 text-[12px] text-gray-700  ring-gray-300"
              type="time"
            />
          </div>
        </div>
      </div>
      {partModality === Modality.Presencial ? (
        <div className="mt-4 flex w-full  items-start justify-between gap-4 pb-6">
          <ControlledSimpleSelect
            title={'Local de realização'}
            required
            control={methods.control}
            name={`trainingDays.${index}.location`}
            width="100%"
            containerClassName="w-64"
            values={workStations.map((el) => {
              return {
                value: el.value,
                label: el.label,
              };
            })}
          />
          <Input
            label="Nome da sala"
            type="text"
            {...register(`trainingDays.${index}.roomName`)}
            required={false}
            key={'roomName'}
            labelClassName="text-[12px] text-[#4A4A4A] font-normal"
            className="mr-2 w-96 gap-0  bg-transparent" // Add right margin if needed
            containerClassname="gap-0"
          />
        </div>
      ) : (
        <div className="flex w-full flex-col items-start justify-between gap-4 py-6">
          <div className="flex w-full flex-col">
            <span className="text-[12px] text-gray-600">
              Local de realização
            </span>
            <input
              {...register(`trainingDays.${index}.onlineLocation`)}
              required={false}
              className="h-8 rounded-sm bg-transparent px-2 text-[12px] text-gray-700 ring-1 ring-gray-300"
              type="text"
            />
          </div>
        </div>
      )}
    </div>
  );
};
