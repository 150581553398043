import { useState } from 'react';
import { TbPinnedFilled } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

import { PositionChartDoubleClickArgs } from '@/types/PositionChartDoubleClickArgs';
import { getBackgroundColor } from '@/utils/getBackgroundColors';
import { getColor } from '@/utils/getColorByChart';
import { getFirstAndLastName } from '@/utils/getFirstAndLastName';

import BadgeAvatar from '../atoms/BadgeAvatar';
import { EmployeeModal } from '../atoms/EmployeeModal';
import SimpleMenu from '../atoms/Menu';
import { PositionWithAllocation } from '../organisms/ChartGraph';
import CreateFixedAllocationModal from './CreateFixedAllocationModal';

interface Props {
  position: PositionWithAllocation;
  image?: string;
  handleChange?: (data: PositionChartDoubleClickArgs) => void;
}

const EmployeeCard = ({ position, handleChange }: Props) => {
  const active = !!position.employee;
  const divergencesOnEmployeeNumber =
    position.amountOfExpectedEmployees !== position.amountOfAllocatedEmployees;
  const divergenceColor = '#F4503A';
  const border = active
    ? getColor(position.workStation.pole)
    : 'border-[2.5px] border-[#C4C4C4]';
  const borderStyle = position.isIntermediary
    ? 'border-dashed'
    : 'border-solid';
  const poleColor = active
    ? getBackgroundColor(position.workStation.pole)
    : '#C4C4C4';
  const bgColor = getBackgroundColor(position.regime);
  const textColor = getColor(`${position.regime}-text`);
  const bg =
    position.workStation?.pole === 'Alagoas' ? 'bg-black' : `bg-[${poleColor}]`;
  const reportsColor = position.numberOfReports > 0 ? bg : 'bg-[#C4C4C4]';

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenFixedAllocation, setIsOpenFixedAllocation] = useState(false);
  const navigate = useNavigate();

  const options = [
    {
      name: 'Ver detalhes',
      onClick: () => setIsOpen(true),
      disabled: !active,
    },
    {
      name: 'Ir para a linha do tempo',
      onClick: () => navigate('/home/planejamento-de-escalas-e-ferias'),
      disabled: true,
    },
    // TODO: descomentar assim que a tela de colaborador estiver funcional
    // {
    //   name: 'Ir para a tela do colaborador',
    //   onClick: () => navigate(`/home/colaborador/${position.employee?.id}`),
    //   disabled: !active,
    // },
    {
      name: 'Ir para histórico de treinamentos',
      onClick: () =>
        navigate(
          `/home/colaborador/${position.employee?.id}/historico-treinamentos`,
        ),
      disabled: !active,
    },
    {
      name: 'Alocar colaborador',
      onClick: () => setIsOpenFixedAllocation(true),
      disabled: false,
    },
  ];
  return (
    <>
      <div
        className={`${border} ${borderStyle} flex h-[155px]  w-[165px] cursor-pointer flex-col items-center justify-between overflow-hidden rounded-lg bg-white`}
        onDoubleClick={() =>
          handleChange?.({
            positionFilter: position.positionId,
            employeeFilter: position.employee?.id,
          })
        }
      >
        <div
          className={` relative flex h-full w-full flex-col items-center justify-between`}
        >
          <div className="absolute right-0 top-0">
            {<SimpleMenu options={options} upDotsButton />}
          </div>
          <div
            className={`flex w-full justify-between ${!active && 'min-h-[24px]'}`}
          >
            <div
              className={`m-1 flex h-4 min-w-12 items-center justify-center rounded-lg ${bg} p-1 text-[8px] text-white`}
            >
              {position.workStation.name ?? '-'}
            </div>
          </div>
          <div className="flex w-full flex-1 flex-col items-center px-1">
            <div className="relative">
              <BadgeAvatar
                name={position.employee?.alias ?? position.employee?.name}
                regime={position.regime}
                poleColor={
                  divergencesOnEmployeeNumber ? divergenceColor : poleColor
                }
                bgColor={bgColor}
                active={active}
                textColor={textColor}
                size={44}
              />
              {position.isFixedAllocation && (
                <div className="absolute  -right-1 -top-2 z-[1000] flex items-center justify-center rounded-full bg-gray-200/80 p-1">
                  <TbPinnedFilled
                    color={
                      position.employee?.thirdParty ? '#9B54FF' : '#FD8E28'
                    }
                    size={12}
                  />
                </div>
              )}
            </div>

            <div className="mt-2 flex flex-col items-center justify-center font-graphie font-medium">
              <div
                className={`text-[9px] text-[${divergencesOnEmployeeNumber ? divergenceColor : poleColor}]`}
              >
                {active
                  ? position.employee?.alias ??
                    getFirstAndLastName(position.employee?.name ?? '')
                  : '-'}
              </div>
              <div className="overflow-hidden">
                <p className="line-clamp-3 px-1 text-center text-[9px] font-normal text-gray-600">
                  {position.name}
                </p>
              </div>
            </div>
          </div>

          <div
            className={`mt-[1px] flex h-[14px] w-5/6 items-center justify-center  rounded-t text-center text-[10px] uppercase leading-[14px] text-[#ffffff] ${reportsColor}`}
          >
            {`${position.numberOfReports} ${position.numberOfReports === 1 ? 'reporte' : 'reportes'}`}
          </div>
        </div>
      </div>
      <EmployeeModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        position={position}
        poleColor={poleColor}
        bgColor={bgColor}
        textColor={textColor}
      />
      {isOpenFixedAllocation && (
        <CreateFixedAllocationModal
          isOpen={isOpenFixedAllocation}
          onClose={() => setIsOpenFixedAllocation(false)}
          data={position}
        />
      )}
    </>
  );
};

export default EmployeeCard;
