import { cn } from '@/utils/cn';

import TooltipIconText from './TooltipIconText';

interface Props {
  subtitle: string;
  fontSize?: string;
  hasTooltip?: boolean;
  tooltipText?: string;
  className?: string;
}

const SubtitleText = ({
  subtitle,
  hasTooltip,
  tooltipText,
  className,
}: Props) => {
  return (
    <div
      className={cn(
        'align-center flex h-8 items-center justify-start text-[14px] font-semibold text-primary',
        className || '',
      )}
    >
      {subtitle}
      {hasTooltip && tooltipText && <TooltipIconText text={tooltipText} />}
    </div>
  );
};

export default SubtitleText;
