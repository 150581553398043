import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';

import { queryClient } from '@/App';

import EmptyIcon from '../../assets/icons/empty.svg';
import EmployeesWrapper from '../../components/molecules/EmployeesWrapper';
import SearchInput from '../../components/molecules/SearchInput';
import SideFilter from '../../components/molecules/SideFilter';
import FirstAllocationModal from '../../components/organisms/FirstAllocationModal';
import InfiniteTable from '../../components/organisms/InfiniteTable';
import { filters } from '../../constants/filters/EmployeesFilter';
import { columns } from '../../constants/tableColumns/EmployeesTableColumns';
import api from '../../services/apiSgft';
import { employeeFiltersAtom } from '../../state/EmployeeFilter.atom';
import { FirstAllocationAtom } from '../../state/FirstAllocation.atom';
import { EmployeeFormGroup } from '../../types/Employee';

const Employees = () => {
  const filterValues = useRecoilValue(employeeFiltersAtom);
  const [filteredData, setFilteredData] = useState(filterValues);
  const [searchParams, setSearchParams] = useSearchParams();
  const [firstAllocation, setFirstAllocation] =
    useRecoilState(FirstAllocationAtom);
  const [updateData, setUpdateData] = useState(false);

  const methods = useForm<EmployeeFormGroup>();
  const { register, watch } = methods;

  const fetchEmployees = async ({
    pageParam = 1,
  }): Promise<{
    employees: any[];
    totalResults: number;
    nextPage: number | null;
  }> => {
    try {
      const response = await api.get(`/employees`, {
        params: {
          size: 20,
          page: pageParam,
          management: filteredData.management,
          workStation: filteredData.workplace,
          teamStructure: filteredData.teamStructure,
          employee: filteredData.employee,
        },
      });
      return response.data;
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  useEffect(() => {
    if (updateData) {
      queryClient.invalidateQueries(['all-employees']);
      setUpdateData(false);
    }
  }, [updateData]);

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(['all-employees', filteredData], fetchEmployees, {
    retry: false,
    getNextPageParam: (actualPage) => {
      return actualPage.nextPage;
    },
  });
  const employees = data?.pages.flatMap((page) => page.employees) ?? [];

  const handleApplyFilter = (isReseting: boolean) => {
    setSearchParams(searchParams);
    if (isReseting) {
      setFilteredData(() => ({
        management: [],
        workplace: [],
        teamStructure: [],
        employee: '',
      }));
    } else {
      setFilteredData(() => ({
        ...filterValues,
      }));
    }
  };

  const handleInputChange = (name: string) => {
    setFilteredData((prev) => ({ ...prev, employee: name }));
  };

  useEffect(() => {
    const subscription = watch(
      (value, { name, type }) =>
        name === 'name' &&
        type === 'change' &&
        typeof value?.name === 'string' &&
        handleInputChange(value?.name),
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <div
      className="relative mt-4 flex w-full flex-col items-start bg-white"
      style={{ height: 'calc(100% - 56px)' }}
    >
      <FormProvider {...methods}>
        <form className="w-full p-4 pb-0" onSubmit={(e) => e.preventDefault()}>
          <div className="w-full">
            <SearchInput
              placeholder="Pesquisar por colaborador"
              autoFocus
              {...register('name')}
            />
          </div>
        </form>
      </FormProvider>

      <EmployeesWrapper isLoading={isLoading} isError={isError}>
        <SideFilter
          refetchOnChange
          filters={filters}
          atom={employeeFiltersAtom}
          applyChanges={handleApplyFilter}
        />
        <div className="flex h-full w-full flex-col overflow-hidden rounded-md bg-white p-5">
          <div className="mb-2 flex gap-8">
            <div className="flex items-center gap-2">
              <div className="h-4 w-4 rounded-full bg-[#193CB9]" />
              <span>Dados RM</span>
            </div>
            <div className="flex items-center gap-2">
              <div className="h-4 w-4 rounded-full bg-[#1FBCDC]" />
              <span>Alocações SGFT</span>
            </div>
          </div>
          {employees?.length ? (
            <InfiniteTable
              hasNextPage={hasNextPage}
              data={employees}
              columns={columns}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
              alignTextLeft={true}
              isFirstColumn={true}
              isEmployeeList={true}
            />
          ) : (
            <div className="flex h-full w-full flex-col items-center justify-center">
              <img src={EmptyIcon} className="w-40" />
              <div className="w-full text-center text-sm">
                <span className="font-semibold text-blue-800">
                  Nenhum colaborador foi encontrado
                </span>
              </div>
            </div>
          )}
        </div>
      </EmployeesWrapper>
      {firstAllocation.isOpen === 'open' && (
        <FirstAllocationModal
          handleClose={() =>
            setFirstAllocation({
              ...firstAllocation,
              isOpen: 'closed',
            })
          }
          setUpdateData={setUpdateData}
        />
      )}
    </div>
  );
};

export default Employees;
