import { MenuItem, Select, SelectProps } from '@mui/material';
import { Controller } from 'react-hook-form';

import { cn } from '../../utils/cn';
import LightTooltip from './LightTooltip';

export interface Values {
  value: string | number;
  label: string | number;
  disabled?: boolean;
}

type Props = {
  title: string;
  values?: Values[];
  width?: string;
  height?: string;
  control: any;
  name: string;
  changeItemColor?: boolean;
  changeItems?: string[];
  LabelClassName?: string;
  containerClassName?: string;
  fieldError?: any;
  errorMessage?: string;
  tooltipMessage?: string;
} & SelectProps;

const ControlledSimpleSelect = ({
  title,
  values,
  width,
  height,
  name,
  changeItemColor,
  changeItems,
  LabelClassName,
  containerClassName,
  fieldError,
  errorMessage,
  tooltipMessage,
  ...rest
}: Props) => {
  return (
    <div className={containerClassName}>
      <LightTooltip title={tooltipMessage}>
        <div className={cn('text-[12px] text-[#4A4A4A]', LabelClassName)}>
          {title}
          {rest.required && <span className="text-red">*</span>}
        </div>
      </LightTooltip>
      <Controller
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <Select
            {...rest}
            sx={{
              width: `${width || '27rem'}`,
              height: `${height || '2rem'}`,
              fontSize: '12px',
              fontFamily: 'Graphie, sans-serif',
              color: rest.readOnly ? '#B1B1B1' : 'inherit',
              '& .MuiSelect-select': {
                color: rest.readOnly ? '#B1B1B1' : 'inherit',
              },
              '& .MuiSelect-icon': {
                color: rest.disabled ? '#B1B1B1' : 'inherit',
              },
              cursor: rest.readOnly ? 'not-allowed' : 'pointer',
              pointerEvents: rest.readOnly ? 'none' : 'auto',
            }}
            displayEmpty
            multiple={rest.multiple}
            inputRef={ref}
            disabled={rest.disabled || !values}
            defaultValue={rest.defaultValue}
            onChange={(e, a) => {
              onChange(e);
              rest.onChange && rest.onChange(e, a);
            }}
            variant={rest?.variant || 'outlined'}
            onBlur={onBlur}
            value={value || (rest.multiple ? [] : '')}
            inputProps={{ 'aria-label': 'Without label' }}
            className="read-only:cursor-not-allowed"
          >
            <MenuItem
              sx={{ fontSize: '12px', display: 'none' }}
              value=""
              disabled
            >
              {' '}
            </MenuItem>
            <MenuItem
              sx={{ fontSize: '12px', display: 'none' }}
              value={0}
              disabled
            >
              {' '}
            </MenuItem>
            <MenuItem
              sx={{ fontSize: '12px', display: 'none' }}
              value={-2}
              disabled
            >
              {' '}
            </MenuItem>
            {values?.map((el) => {
              if (changeItemColor) {
                return (
                  <MenuItem
                    sx={{
                      fontSize: '12px',
                      fontWeight: changeItems?.includes(el.value.toString())
                        ? 'bold'
                        : '',
                    }}
                    key={el.value}
                    value={el.value}
                    disabled={el.disabled}
                  >
                    {el.label}
                  </MenuItem>
                );
              } else {
                return (
                  <MenuItem
                    sx={{
                      fontSize: '12px',
                    }}
                    key={el.value}
                    value={el.value}
                    disabled={el.disabled}
                  >
                    {el.label}
                  </MenuItem>
                );
              }
            })}
          </Select>
        )}
        name={name}
        disabled={rest.disabled}
        control={rest.control}
      />
      {fieldError && (
        <div className="text-[10px] text-[#dd0000]">{errorMessage}</div>
      )}
    </div>
  );
};

export default ControlledSimpleSelect;
