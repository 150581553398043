import { MenuItem, Select } from '@mui/material';

interface Values {
  value: string | number;
  label: string | number;
}

interface Props {
  title: string;
  values: Values[];
  value: string | number | null;
  setState?: (value: number | string) => void;
  width?: string;
  disabled?: boolean;
  backgroundColor?: string;
  fontSize?: string;
}

const SimpleSelect = ({
  title,
  values,
  value,
  setState,
  width,
  backgroundColor,
  disabled,
  fontSize,
}: Props) => {
  return (
    <div>
      <div className="text-[12px] text-[#4A4A4A]">{title}</div>
      <Select
        sx={{
          width: `${width || '27rem'}`,
          height: '2rem',
          fontSize: `${fontSize || '12px'}`,
          background: backgroundColor || '',
          fontFamily: 'Graphie, sans-serif',
        }}
        value={value}
        disabled={setState === undefined || disabled}
        onChange={(e) => {
          setState && setState(e.target.value as number);
        }}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
      >
        {values.map((el) => {
          return (
            <MenuItem
              sx={{ fontSize: '12px', fontFamily: 'Graphie, sans-serif' }}
              value={el.value}
              key={el.label}
            >
              {el.label}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

export default SimpleSelect;
