import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { IconButton, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import { z } from 'zod';

import {
  editFormSchema,
  NO,
  ROUTES,
  VALUES_STRUCTURE_TYPES,
  YES_NO,
} from '../../constants/TeamRegister';
import api from '../../services/apiSgft';
import { Button } from '../atoms/Button';
import ControlledSimpleSelect, {
  Values,
} from '../atoms/ControlledSimpleSelect';
import Title from '../atoms/Title';
import UncontrolledInputText from '../atoms/UncontrolledInputText';
import Subtitle from '../molecules/Subtitle';

type methodsSchema = z.infer<typeof editFormSchema>;

interface Props {
  type: 'create' | 'edit';
  scales?: { value: string; label: string }[];
  teamsLength?: { value: number; label: string }[];
  getScalesAndTeamsLength?: (e?: SelectChangeEvent<unknown>) => void;
  onAbort: () => void;
}

export const RegisterAndEditTeam = ({
  type,
  scales,
  teamsLength,
  getScalesAndTeamsLength,
  onAbort,
}: Props) => {
  const methods = useFormContext<methodsSchema>();
  const areaWatch = methods.watch('area');
  const sectorWatch = methods.watch('sector');
  const onNoticeWatch = methods.watch('onNotice');
  const [onNoticeValues, setOnNoticeValues] = useState<
    { value: string; label: string }[]
  >([]);
  const [values, setValues] = useState<{
    management: Values[];
    area: Values[];
    sector: Values[];
    workstation: Values[];
  }>();

  useEffect(() => {
    if (onNoticeWatch === 'NÃO') {
      setOnNoticeValues(NO);
    } else {
      setOnNoticeValues(YES_NO);
    }
  }, [onNoticeWatch]);

  useEffect(() => {
    async function fetchValues() {
      const fetchPromises = ROUTES.map(async (el) => {
        const response = await api.get(`${el.route}/all`);
        const data = response.data.map((it: { name: any; id: any }) => ({
          label: it.name,
          value: it.id,
        }));
        if (el.others) {
          data.push({
            label: 'OUTRO',
            value: el.route === 'area/all' ? -1 : '-1',
          });
        }
        return { key: el.route, value: data };
      });

      const resolvedValues = await Promise.all(fetchPromises);

      const updatedValues = new Map();

      resolvedValues.forEach((item) => {
        updatedValues.set(item.key, item.value);
      });

      setValues({
        management: updatedValues.get('management') || [],
        area: updatedValues.get('area') || [],
        sector: updatedValues.get('team-structure/sector') || [],
        workstation: updatedValues.get('work-station') || [],
      });
    }

    try {
      fetchValues();
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  }, []);

  return (
    <div className="mx-auto mt-1 flex w-full max-w-[110rem] justify-center">
      <div className="w-full flex-col  ">
        <Title
          title={`${type === 'create' ? 'Cadastro de Estrutura de Turma' : 'Edição de Estrutura de Turma'}`}
        />
        <div className="flex min-h-[75vh] flex-col justify-between rounded-[15px] bg-white px-4 pb-2 pt-8">
          <div>
            <div className="center mb-7 flex w-full justify-start">
              <UncontrolledInputText
                className="mr-5"
                title={'Nome da Estrutura'}
                required
                width="18rem"
                {...methods.register('name')}
                fieldError={methods.formState.errors.name}
                errorMessage="O nome é obrigatório*"
              />
            </div>
            <Subtitle subtitle="Informações Gerais" />
            <div className="mb-7 flex w-full flex-wrap justify-between space-y-px">
              <ControlledSimpleSelect
                className="mr-5"
                title={'Gerência'}
                required
                name={'management'}
                width="18rem"
                values={values?.management}
                control={methods.control}
                fieldError={methods.formState.errors.management}
                errorMessage="A gerência é obrigatória*"
              />
              <div>
                <ControlledSimpleSelect
                  className="mr-5"
                  title={'Área'}
                  required
                  control={methods.control}
                  values={values?.area}
                  name={'area'}
                  width="18rem"
                  fieldError={methods.formState.errors.area}
                  errorMessage="A área é obrigatória*"
                />
                {String(areaWatch) === '-1' && (
                  <UncontrolledInputText
                    title={'Digite uma nova área'}
                    width="18rem"
                    {...methods.register('otherArea')}
                  />
                )}
              </div>
              <div>
                <ControlledSimpleSelect
                  className="mr-5"
                  title={'Setor'}
                  control={methods.control}
                  required
                  values={values?.sector}
                  name={'sector'}
                  width="18rem"
                  fieldError={methods.formState.errors.sector}
                  errorMessage="O setor é obrigatório*"
                />
                {sectorWatch === '-1' && (
                  <UncontrolledInputText
                    title={'Digite um novo setor'}
                    width="18rem"
                    {...methods.register('otherSector')}
                  />
                )}
              </div>
              <ControlledSimpleSelect
                title={'Campo'}
                control={methods.control}
                required
                values={values?.workstation}
                name={'workstation'}
                width="18rem"
                multiple
                fieldError={methods.formState.errors.workstation}
                errorMessage=" O campo é obrigatório*"
              />
            </div>
            {type === 'create' && (
              <div>
                <Subtitle subtitle="Regime e Especificidades" />
                <div className="mb-5 flex w-full flex-wrap justify-between">
                  <ControlledSimpleSelect
                    className="mr-5"
                    title="Tipo da Estrutura"
                    required
                    width="18rem"
                    values={VALUES_STRUCTURE_TYPES}
                    control={methods.control}
                    name={'structureType'}
                    fieldError={methods.formState.errors.structureType}
                    errorMessage="O tipo da estrutura é obrigatório*"
                    onChange={(e) => {
                      getScalesAndTeamsLength && getScalesAndTeamsLength(e);
                    }}
                  />
                  <ControlledSimpleSelect
                    className="mr-5"
                    title="Escala"
                    required
                    width="18rem"
                    values={scales}
                    disabled={scales?.length === 0}
                    control={methods.control}
                    name={'scale'}
                    fieldError={methods.formState.errors.scale}
                    errorMessage="A escala é obrigatória*"
                  />
                  <ControlledSimpleSelect
                    disabled={teamsLength?.length === 0}
                    title="Número de turmas"
                    required
                    values={teamsLength}
                    control={methods.control}
                    name={'teamsLength'}
                    width="18rem"
                    fieldError={methods.formState.errors.teamsLength}
                    errorMessage=" O número de turmas é obrigatório*"
                  />
                  <div className="w-[18rem]" />
                </div>
              </div>
            )}

            <Subtitle subtitle="Sobreaviso" />
            <div className="mb-[1rem] flex w-full flex-wrap justify-between">
              <ControlledSimpleSelect
                className="mr-5"
                title="Sobreaviso"
                required
                width="18rem"
                values={YES_NO}
                control={methods.control}
                name={'onNotice'}
                fieldError={methods.formState.errors.onNotice}
                errorMessage="O compo sobreaviso é obrigatório*"
              />
              <ControlledSimpleSelect
                className="mr-5"
                title="Sobreaviso obrigatório"
                required
                width="18rem"
                values={onNoticeValues}
                control={methods.control}
                name={'mandatoryOnNotice'}
                fieldError={methods.formState.errors.mandatoryOnNotice}
                errorMessage="O campo sobreaviso obrigatório é obrigatório*"
              />
              <ControlledSimpleSelect
                title="Confinamento"
                values={onNoticeValues}
                required
                control={methods.control}
                name={'confinement'}
                width="18rem"
                fieldError={methods.formState.errors.confinement}
                errorMessage="O campo confinamento é obrigatório*"
              />
              <div className="w-[18rem]" />
            </div>
          </div>
          <div className="mb-1 mt-5 flex justify-end">
            {type === 'create' ? (
              <div className="flex w-full justify-between">
                <Button
                  type="button"
                  className="mr-5 min-w-36 border-2 border-[#193CB9] bg-white text-[#193CB9] hover:bg-[#e9eaf1]"
                  onClick={() => onAbort()}
                >
                  Cancelar
                </Button>
                <Button type="submit" className="mb-5 min-w-36">
                  Prosseguir
                </Button>
              </div>
            ) : (
              <div className="flex w-full justify-between">
                <Button
                  type="button"
                  className="min-w-36 border-2 border-[#193CB9] bg-white text-[#193CB9] hover:bg-[#e9eaf1]"
                  onClick={() => onAbort()}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  disabled={!methods.formState.isDirty}
                  className="mb-5 min-w-36"
                >
                  Salvar Alterações
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
