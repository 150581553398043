import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { ptBR } from 'date-fns/locale';
import React, { useState } from 'react';

import { Calendar } from '../ui/calendar';
import EditButton from './EditButton';
import TableCellDate from './TableCellDate';

interface Props {
  value: Date | undefined;
  disabled?: boolean;
  update: (e: Date) => void;
  alignStart?: boolean;
}

const EditableTableDate = ({ value, disabled, update, alignStart }: Props) => {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  return (
    <div className="w-[80%]">
      <div className={`h-inherit flex items-center justify-between`}>
        <TableCellDate
          date={value}
          className={`flex w-20 ${alignStart ? 'justify-start' : 'justify-center'}`}
        />
        <EditButton onClick={handleClick} disabled={!!disabled} />
      </div>
      <BasePopup open={Boolean(anchor)} anchor={anchor}>
        <div className="rounded bg-white">
          <Calendar
            mode="single"
            selected={value}
            onSelect={async (e) => {
              setAnchor(null);
              update(e as Date);
            }}
            className="rounded-md border shadow"
            locale={ptBR}
          />
        </div>
      </BasePopup>
    </div>
  );
};

export default EditableTableDate;
