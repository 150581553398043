import * as r from '@recoiljs/refine';
import { atom } from 'recoil';
import { urlSyncEffect } from 'recoil-sync';

export interface PendenciesFilters extends Record<string, string[]> {
  management: string[];
  area: string[];
  sector: string[];
  workStation: string[];
  scale: string[];
  pendencyType: string[];
  responsible: string[];
}
const employee = JSON.parse(localStorage.getItem('employee') ?? '{}');
const managementId = employee?.management_id;
const stringArrayChecker = r.array(r.string()) as r.Checker<string[]>;
export const PendenciesFiltersAtom = atom<PendenciesFilters>({
  key: 'pendenciesFilters',
  default: {
    management: managementId ? [String(managementId)] : [],
    area: [],
    sector: [],
    workStation: [],
    scale: [],
    pendencyType: [],
    responsible: [],
  },
  effects: [
    urlSyncEffect<PendenciesFilters>({
      refine: r.object({
        management: stringArrayChecker,
        area: stringArrayChecker,
        sector: stringArrayChecker,
        workStation: stringArrayChecker,
        scale: stringArrayChecker,
        pendencyType: stringArrayChecker,
        responsible: stringArrayChecker,
      }),
      history: 'push',
    }),
  ],
});
