import { Popover } from '@headlessui/react';

import { Team } from '../../types/ViewSchedule';
import { cn } from '../../utils/cn';
import { getColorByTeamName } from '../../utils/getColorByTeamName';
import { subtitleEntries } from '../../utils/getSubtitleByTeamName';

const ScheduleSubtitle = () => {
  return (
    <Popover className="relative">
      <Popover.Button className="h-7 whitespace-nowrap rounded-[1rem] border-2 border-blue-800 px-4 text-xs font-medium text-blue-800">
        Ver Legendas
      </Popover.Button>

      <Popover.Panel className="absolute right-0 z-20">
        <div className=" mt-4 flex flex-col rounded-sm bg-gray-200 p-4 text-xs text-gray-600">
          <div className="absolute right-7 top-0 mt-1 h-0 w-0 border-b-[12px] border-l-[12px] border-r-[12px] border-b-gray-200 border-l-transparent border-r-transparent" />
          <h4 className="mb-2 font-semibold text-blue-800">Legenda</h4>
          <div className="grid grid-flow-col grid-rows-12 gap-x-8">
            {subtitleEntries.map(([team, legend]: [Team, string]) => (
              <div
                className="mb-2 flex w-full items-center gap-2 whitespace-nowrap"
                key={`legend-${team}`}
              >
                <div
                  className={cn(
                    getColorByTeamName(team),
                    'flex h-5 w-8 items-center justify-center rounded-sm font-normal',
                    team === 'SA' || team === 'SA*'
                      ? 'font-semibold text-[#fcff54]'
                      : 'text-white',
                  )}
                >
                  {team}
                </div>
                {legend}
              </div>
            ))}
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  );
};

export default ScheduleSubtitle;
