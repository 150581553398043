import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@radix-ui/react-tooltip';

import { availabilityColumns } from '@/constants/tableColumns/AvailabilityTableColumns';
import { Availability } from '@/constants/TrainingPlanning';
import { AvailabilityTrainingData } from '@/types/AvailabilityTrainingData';
import { cn } from '@/utils/cn';

import TableData from '../atoms/TableData';
import Table from '../organisms/Table';

interface AvailabilityTableCellProps {
  availabilityData: AvailabilityTrainingData;
}

export const AvailabilityTableCell = ({
  availabilityData,
}: AvailabilityTableCellProps) => {
  const available = availabilityData.every(
    (it) => it.availability.availability === Availability.Disponivel,
  );
  const availability = available
    ? Availability.Disponivel
    : Availability.Indisponivel;
  return (
    <TooltipProvider skipDelayDuration={100} delayDuration={200}>
      <Tooltip>
        <TooltipTrigger>
          <TableData
            tableValue={availability}
            className={cn(
              'flex h-6 w-28 items-center rounded-sm px-3 text-left ',
              availability === Availability.Disponivel
                ? 'text-[#10AE88]'
                : 'text-[#F4503A]',
              available ? 'cursor-default' : 'cursor-pointer',
            )}
          />
        </TooltipTrigger>
        {!available && (
          <TooltipContent className="z-[1000]">
            <TooltipArrow />

            <div className="z-[1000] rounded-md border-2  bg-white p-4 shadow-md">
              <div className="w-full text-start text-lg font-semibold text-[#193CB9]">
                Disponibilidade
              </div>
              <Table
                columns={availabilityColumns}
                data={availabilityData}
                containerClassname="text-center w-full"
                trBodyClassname="even:bg-white"
              />
            </div>
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );
};
